import React from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import { H4 } from "@components/Heading";
import { Text } from "@components/Text";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import Logo from "./Logo";
import LogoFull from "../assets/logo.png";
import LogoOnly from "../assets/logo-only.png";
import KlarnaLogo from "../assets/klarna-logo.svg";
import styled from "styled-components";
import Config from "@src/config";
import Divider from "@components/Divider";
import Support from "@components/Support";

const StyledDiv = styled(Div)`
  i {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
  }
`;

const socialIcons = [
  {
    name: "facebook",
  },
  {
    name: "twitter",
  },
  {
    name: "linkedin",
  },
];

const Footer = ({ hidePublisher = false }) => {
  const { messages } = useIntl();

  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  return (
    <>
      <Div
        width={1}
        display="flex"
        justifyContent="center"
        backgroundColor={"rgba(var(--light-blue-rgba), .2)"}
        px={["5%", "5%", "5%", "10%"]}
        className="support-section"
      >
        <Div width={1}>
          <Support />
        </Div>
      </Div>
      <Div
        width={1}
        pt={[3, 5]}
        pb={[3, 4]}
        px={["5%", "5%", "5%", "10%"]}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor={"var(--turquoise-light)"}
      >
        <Div width={1}>
          <Div display="flex" flexDirection="row">
            <Div
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              flexWrap="wrap"
              mr={[4, "auto"]}
            >
              <Div display={["flex", "none"]} width={1 / 8}>
                <Logo logo={LogoOnly} onClick={handleRedirect} />
              </Div>
              <Div display={["none", "block"]} width={1} mr={4}>
                <Logo
                  logo={LogoFull}
                  width={"200px"}
                  onClick={handleRedirect}
                />
              </Div>
            </Div>
            <Div
              width={9 / 10}
              display="flex"
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "space-evenly",
              ]}
              flexDirection={["column", "row", "row", "row"]}
              flexWrap="wrap"
              mb={[0, 0, 0, 20]}
            >
              <Div width={[1, 1, 1 / 2, 1 / 4]} mb={[3, 3, 3, 0]} ml={[0, 4]}>
                <H4 mb={3}>{messages.footer_address}</H4>
                <Div>
                  <Text mb={1}>{messages.address_line1}</Text>
                  <Text display="block" mb={1} mt={2}>
                    {messages.address_line2}
                  </Text>
                </Div>
                <LinkArrow
                  display={["none", "flex"]}
                  direction="left"
                  href={`${Config.WP_URL}/personuppgiftspolicy/`}
                  label={messages.privacy_policy}
                  mb={1}
                  mt={[0, 19]}
                >
                  <Icon ml={1} name="headerarrowright" />
                </LinkArrow>
              </Div>
              <Div width={[1, 1, 1 / 2, 1 / 3]} mb={[3, 3, 3, 0]} ml={[0, 4]}>
                <H4 mb={3} display="block">
                  {messages.contact}
                </H4>
                <Div>
                  <Text mb={2} display="block">
                    <Icon pr={13} name="call" />
                    {messages.phone}
                  </Text>
                  <Text mb={1} display="block">
                    <Icon pr={13} name="email" />
                    {messages.email}
                  </Text>
                </Div>
                <LinkArrow
                  display={["flex", "none"]}
                  direction="left"
                  href={`${Config.WP_URL}/personuppgiftspolicy/`}
                  label={messages.privacy_policy}
                  mb={1}
                  mt={[10, 19]}
                >
                  <Icon ml={1} name="headerarrowright" />
                </LinkArrow>
                <Logo logo={KlarnaLogo} width={"80px"} mb={14} mt={[12, 36]} />
              </Div>
            </Div>
          </Div>
          <Divider
            m={"0 auto !important"}
            bg="var(--grey) !important"
            opacity={0.1}
          />
          <Div
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            justifyContent={["space-around", "flex-start"]}
            mt={[20, 25]}
            pl={[5, 5, 0, 0]}
          >
            <Div
              width={[1, 1, 8 / 10, 9 / 10]}
              mb={[3, 3, 3, 0]}
              display="flex"
              justifyContent={[
                "flex-start",
                "center",
                "flex-start",
                "flex-start",
              ]}
            >
              <Text mb={1}>
                {!hidePublisher && (
                  <>
                    {messages.text_publisher}: {messages.publisher_name}
                  </>
                )}
              </Text>
            </Div>
            <StyledDiv
              display="flex"
              justifyContent={[
                "flex-start",
                "space-around",
                "flex-start",
                "center",
              ]}
            >
              {socialIcons.map((value, index) => (
                <Icon
                  color={"var(--blue-dark)"}
                  key={index}
                  p={0}
                  name={value.name}
                  ml={[3, 3, 0, 3]}
                  {...value}
                />
              ))}
            </StyledDiv>
          </Div>
        </Div>
      </Div>
    </>
  );
};

Footer.propTypes = {
  hidePublisher: Proptypes.bool,
};

export default Footer;
