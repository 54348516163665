import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Formik, Form } from "formik";
import styled from "styled-components";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Breadcrumb from "@components/Breadcrumb";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Dropdown from "@components/Dropdown";
import { Error, H2 } from "@components/Heading";
import Icon from "@components/Icon";
import InputText from "@components/InputText";
import SuccessDialog from "@components/SuccessDialog";
import { TextMediumWeight } from "@components/Text";

import AdminContainer from "@layout/AdminContainer";
import UserContainer from "@layout/UserContainer";

import ErrorModal from "@pages/user/OrderResults/ErrorModal";

import { INPUT, ROUTES } from "@utils/constant";
import countries from "@utils/countries.json";
import { INVOICE_TYPE } from "@utils/enum";
import {
  enumValueToTranslationKey,
  isAdminUser,
  removeZeroValuesFromObject,
} from "@utils/utils";

import Schema from "./CreateBillingAccountSchema";

import { createBillingAccountService } from "@app/services/billingAccounts/createBillingAccountService";

const mappedCountries = countries.map((country) => ({
  name: country.name,
  code: country.code,
}));

const INPUT_NAME = {
  COMPANY_NAME: "name",
  ORG_NUMBER: "registration_nr",
  INVOICE_ADDRESS_LINE1: "address_1",
  POSTAL_CODE: "zip_code",
  INVOICE_ADDRESS_LINE2: "address_2",
  CITY: "city",
  COUNTRY_CODE: "country_code",
  COUNTRY_NAME: "country_name",
  YOUR_REFERENCE: "your_reference",
  INVOICE_REMARK: "invoice_remark",
  LABELING: "labeling",
  INVOICE_TYPE: "invoice_type",
  EDI_IDENTIFICATION: "edi_identification",
  ELECTRONIC_ADDRESS_IN_PEPPOL: "peppol_address",
  GLN_NUMBER: "gln",
  VAN_OPERATOR: "van_operator",
  BILLING_EMAIL: "email_invoice",
};

const initialData = {
  name: "",
  registration_nr: "",
  address_1: "",
  zip_code: "",
  address_2: "",
  city: "",
  country_code: "",
  country_name: "",
  your_reference: "",
  invoice_remark: "",
  invoice_type: "",
  edi_identification: "",
  peppol_address: "",
  gln: "",
  van_operator: "",
  email_invoice: "",
};

const INVOICE_TYPE_OPTIONS = [
  {
    value: INVOICE_TYPE.E_INVOICE,
    label: (
      <FormattedMessage
        id={`label_${enumValueToTranslationKey(INVOICE_TYPE.E_INVOICE)}`}
      />
    ),
  },
  {
    value: INVOICE_TYPE.EMAIL_INVOICE,
    label: (
      <FormattedMessage
        id={`label_${enumValueToTranslationKey(INVOICE_TYPE.EMAIL_INVOICE)}`}
      />
    ),
  },
];

InputText.defaultProps = {
  curved: true,
  isLowercaseLabel: true,
  width: 1,
  labelAlignment: "left",
};

const InputGroup = styled(Div)``;

InputGroup.defaultProps = {
  flex: 1,
  display: "flex",
  flexDirection: ["column", "column", "row", "row"],
  gridGap: [0, 0, 4, 4],
};

const Filler = styled(Div)``;

Filler.defaultProps = {
  width: 1,
  display: ["none", "none", "block", "block"],
};

const CreateBillingAccount = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer: { id: userCustomerId = "" } = {} } = userInfo || {};

  const { id: customerIdFromParam = "" } = useParams();

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { TEXT, EMAIL: EMAIL_TYPE } = INPUT.TYPE;

  const breadCrumbItems = [
    {
      label: isAdminUser() ? messages.title_customers : messages.organisation,
      url: isAdminUser() ? ROUTES.ADMIN_CUSTOMERS.URL : ROUTES.COMPANY_INFO.URL,
    },
    {
      label: messages.title_billing,
    },
    {
      label: messages.title_create_billing_account,
    },
  ];

  const handleSubmit = async (values) => {
    try {
      let payload = {
        ...values,
        organisation_number: values.registration_nr,
      };

      let customer_id = "";

      if (isAdminUser()) {
        customer_id = customerIdFromParam;
      } else {
        customer_id = userCustomerId;
      }

      payload.customer_id = customer_id;

      const updatedPayload = removeZeroValuesFromObject(payload);

      await createBillingAccountService(updatedPayload);

      setSuccessMessage(messages.label_billing_account_creation_success);
    } catch (error) {
      setError(messages.exception_error_message);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleCloseErrorModal = () => {
    setError("");
  };

  const handleCloseSuccessModal = () => {
    setSuccessMessage("");

    handleCancel();
  };

  const WrapperComponent = isAdminUser() ? AdminContainer : UserContainer;

  const containerConfig = {
    title: messages.title_create_billing_account,
  };

  return (
    <WrapperComponent config={containerConfig}>
      <Breadcrumb items={breadCrumbItems} p={0} />

      {successMessage && (
        <SuccessDialog
          message={successMessage}
          onConfirm={handleCloseSuccessModal}
        />
      )}

      {error && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleCloseErrorModal}
        >
          <ErrorModal
            content={error}
            handleConfirmPopup={handleCloseErrorModal}
          />
        </Dialog>
      )}

      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={Schema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { errors, touched, values, handleChange, setFieldValue } =
            formik;

          return (
            <Form>
              <Div mt={4}>
                <H2>{messages.heading_basic_information}</H2>
                <InputGroup>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.COMPANY_NAME}
                      label={`${messages.label_company_name}*`}
                      placeholder={messages.label_company_name}
                      formikProps={formik}
                      value={values.name}
                      type={TEXT}
                    />
                  </Div>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.ORG_NUMBER}
                      label={`${messages.label_organisation_number}*`}
                      placeholder={messages.label_organisation_number}
                      formikProps={formik}
                      value={values.registration_nr}
                    />
                  </Div>
                </InputGroup>
                <InputGroup>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.INVOICE_ADDRESS_LINE1}
                      label={`${messages.label_invoice_address_line1}*`}
                      placeholder={messages.label_invoice_address_line1}
                      formikProps={formik}
                      value={values.address_1}
                      type={TEXT}
                    />
                  </Div>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.INVOICE_ADDRESS_LINE2}
                      label={messages.label_invoice_address_line2}
                      placeholder={messages.label_invoice_address_line2}
                      formikProps={formik}
                      value={values.address_2}
                      type={TEXT}
                    />
                  </Div>
                </InputGroup>
                <InputGroup>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.POSTAL_CODE}
                      label={`${messages.label_postal_code}*`}
                      placeholder={messages.label_postal_code}
                      formikProps={formik}
                      value={values.zip_code}
                    />
                  </Div>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.CITY}
                      label={`${messages.label_city}*`}
                      placeholder={messages.label_city}
                      formikProps={formik}
                      value={values.city}
                      type={TEXT}
                    />
                  </Div>
                </InputGroup>
                <InputGroup>
                  <Div width={1} mt={3} pt={1}>
                    <TextMediumWeight>
                      {`${messages.label_country}*`}
                    </TextMediumWeight>
                    <Dropdown
                      filter
                      width={1}
                      mt={2}
                      name={INPUT_NAME.COUNTRY_NAME}
                      value={{
                        name: values.country_name,
                        code: values.country_code,
                      }}
                      onChange={({ value }) => {
                        const {
                          code: countryCode = "",
                          name: countryName = "",
                        } = value;

                        setFieldValue(INPUT_NAME.COUNTRY_CODE, countryCode);
                        setFieldValue(INPUT_NAME.COUNTRY_NAME, countryName);
                      }}
                      options={mappedCountries}
                      optionLabel={"name"}
                      placeholder={messages.placeholder_choose_country}
                      dropdownIcon={<Icon name="chevrondown" />}
                      emptyFilterMessage={messages.label_no_available_options}
                    />
                  </Div>
                  <Filler />
                </InputGroup>
              </Div>

              <Div mt={4}>
                <H2>{messages.heading_invoice_reference}</H2>
                <InputGroup>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.YOUR_REFERENCE}
                      label={`${messages.label_your_reference_person}*`}
                      placeholder={messages.placeholder_your_reference_person}
                      formikProps={formik}
                      value={values.your_reference}
                      type={TEXT}
                    />
                  </Div>
                  <Filler />
                </InputGroup>
                <InputGroup>
                  <Div width={1}>
                    <InputText
                      name={INPUT_NAME.INVOICE_REMARK}
                      label={messages.heading_invoice_reference}
                      placeholder={messages.heading_invoice_reference}
                      formikProps={formik}
                      value={values.invoice_remark}
                      type={TEXT}
                    />
                  </Div>
                  <Filler />
                </InputGroup>
              </Div>

              <Div mt={4}>
                <H2>{messages.label_billing_info_payment}</H2>
                <InputGroup>
                  <Div width={1} mt={3} pt={1}>
                    <TextMediumWeight>
                      {`${messages.label_invoice_type}*`}
                    </TextMediumWeight>
                    <Dropdown
                      width={1}
                      mt={2}
                      name={INPUT_NAME.INVOICE_TYPE}
                      value={values.invoice_type}
                      onChange={handleChange}
                      options={INVOICE_TYPE_OPTIONS}
                      optionLabel="label"
                      placeholder={messages.placeholder_choose_payment_option}
                      dropdownIcon={<Icon name="chevrondown" />}
                    />
                    {errors.invoice_type && touched.invoice_type && (
                      <Div pb={1} pt={3} m="auto">
                        <Error>{errors.invoice_type}</Error>
                      </Div>
                    )}
                  </Div>
                  <Filler />
                </InputGroup>
                {values.invoice_type === INVOICE_TYPE.E_INVOICE && (
                  <>
                    <InputGroup>
                      <Div width={1}>
                        <InputText
                          name={INPUT_NAME.EDI_IDENTIFICATION}
                          label={`${messages.label_edi_identification}`}
                          placeholder={messages.label_edi_identification}
                          formikProps={formik}
                          value={values.edi_identification}
                          type={TEXT}
                        />
                      </Div>
                      <Div width={1}>
                        <InputText
                          name={INPUT_NAME.ELECTRONIC_ADDRESS_IN_PEPPOL}
                          label={`${messages.label_electronic_address_in_peppol}`}
                          placeholder={
                            messages.label_electronic_address_in_peppol
                          }
                          formikProps={formik}
                          value={values.peppol_address}
                          type={EMAIL_TYPE}
                        />
                      </Div>
                    </InputGroup>
                    <InputGroup>
                      <Div width={1}>
                        <InputText
                          name={INPUT_NAME.GLN_NUMBER}
                          label={`${messages.label_gln_number}`}
                          placeholder={messages.label_gln_number}
                          formikProps={formik}
                          value={values.gln}
                        />
                      </Div>
                      <Div width={1}>
                        <InputText
                          name={INPUT_NAME.VAN_OPERATOR}
                          label={`${messages.label_van_operator}`}
                          placeholder={messages.label_van_operator}
                          formikProps={formik}
                          value={values.van_operator}
                        />
                      </Div>
                    </InputGroup>
                  </>
                )}
                {values.invoice_type === INVOICE_TYPE.EMAIL_INVOICE && (
                  <InputGroup>
                    <Div width={1}>
                      <InputText
                        name={INPUT_NAME.BILLING_EMAIL}
                        label={`${messages.header_billing_email}`}
                        placeholder={messages.placeholder_billing_email}
                        formikProps={formik}
                        value={values.email_invoice}
                        type={EMAIL_TYPE}
                      />
                    </Div>
                    <Filler />
                  </InputGroup>
                )}
              </Div>

              <Div
                mt={4}
                display="flex"
                flexDirection={["column", "column", "row", "row"]}
                gridGap={[3, 3, 4, 4]}
              >
                <PrimaryButton
                  type="submit"
                  label={messages.label_create_billing_account}
                />
                <PrimaryButtonOutlined
                  type="button"
                  width={[1, 1, "150px", "150px"]}
                  label={messages.label_cancel}
                  onClick={handleCancel}
                />
              </Div>
            </Form>
          );
        }}
      </Formik>
    </WrapperComponent>
  );
};

export default CreateBillingAccount;
