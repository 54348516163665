import React from "react";
import styled from "styled-components";
import { TextMediumWeight } from "@components/Text";
import {
  CURRENCY,
  SVLANG,
  STATUS_COMPLETED,
  STATUS_DONE,
} from "@utils/constant";
import {
  default as PRIcon,
  SmallEllipseIcon,
  RoundedRectIcon,
} from "@components/Icon";
import Tooltip from "@components/ToolTip";
import Span from "@components/Span";

const Icon = styled(PRIcon)`
  color: var(--turquoise);
  &:hover {
    color: var(--blue-dark);
  }
`;

const dateAndTimeTemplate = (values, e) => {
  const [date, time] = values[e.field].split(",");
  return (
    <span title={`${date}, ${time}`}>
      <TextMediumWeight>{date}</TextMediumWeight>{" "}
      <TextMediumWeight color="var(--grey)!important">{time}</TextMediumWeight>
    </span>
  );
};

const BundleIcon = (
  <Span
    p={"3px 6px"}
    ml={2}
    bg="var(--blue-dark)"
    color="var(--white) !important"
    borderRadius="5px"
    bold
  >
    P
  </Span>
);

const serviceNameTemplate = (values, e) => {
  return (
    <TextMediumWeight title={values[e.field] || "-"}>
      {values[e.field]}
      {values["service_type"] === "bundle" ? BundleIcon : ""}
      <SmallEllipseIcon ml={2} className={`class-${values?.id}`} name="info">
        <Tooltip
          target={`.class-${values?.id}`}
          content={values["description"]}
        />
      </SmallEllipseIcon>
    </TextMediumWeight>
  );
};

const statusTemplate = (values, e) => {
  const status =
    values[e.field] === STATUS_COMPLETED ? STATUS_DONE : values[e.field];
  return (
    <TextMediumWeight
      title={values[e.field] || "-"}
      className={`${values[e.field]?.toString()?.toLowerCase()}`}
    >
      {status ? SVLANG[status?.replaceAll("-", "_")] : "-"}
    </TextMediumWeight>
  );
};

const selectTemplate = (values, e) => {
  return (
    <TextMediumWeight
      title={values[e.field] || "-"}
      className={`${values[e.field]?.toString()?.toLowerCase()}`}
    >
      {values[e.field] ? SVLANG.yes_label : SVLANG.no_label}
    </TextMediumWeight>
  );
};

const generalTemplate = (values, e) => {
  const { column: { props: { className = "" } = {} } = {} } = e;
  return (
    <TextMediumWeight
      title={values[e.field] || "-"}
      className={className || `${values[e.field]?.toString()?.toLowerCase()}`}
    >
      {values[e.field] || "-"}
    </TextMediumWeight>
  );
};

const currencyTemplate = (values, e) => {
  return (
    <TextMediumWeight title={`${values[e.field] || "0"} ${CURRENCY}`}>{`${
      values[e.field] || "0"
    } ${CURRENCY}`}</TextMediumWeight>
  );
};

const arrowActionTemplate = () => {
  return (
    <Icon name="headerarrowright" color="var(--turquoise)" cursor="pointer" />
  );
};

const cancelTemplate = () => {
  return (
    <RoundedRectIcon>
      <Icon
        name="rubber"
        color="var(--turquoise)"
        cursor="pointer"
        fontSize="var(--fs-link-m)"
      />
    </RoundedRectIcon>
  );
};

export const bodyTemplate = {
  general: generalTemplate,
  dateAndTime: dateAndTimeTemplate,
  currency: currencyTemplate,
  arrow: arrowActionTemplate,
  service: serviceNameTemplate,
  status: statusTemplate,
  select: selectTemplate,
  cancel: cancelTemplate,
};

export default bodyTemplate;
