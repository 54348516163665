import React from "react";
import { SmallEllipseIcon } from "@components/Icon";
import Tooltip from "@components/ToolTip";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { ReportStatus, ReportStatusToolTip } from "@src/enum/reportStatus";
export const StatusComponent = ({ value, db_fields }) => {
  const { messages } = useIntl();
  const date = new Date();
  date.setDate(date.getDate() - 30);
  const created_at = new Date(db_fields.created_at);
  const isExpired = date > created_at;
  const _value = isExpired ? "expired" : value;
  const statusKey = ReportStatus[_value];
  const statusToolTip = ReportStatusToolTip[_value];
  return statusKey ? (
    <>
      {messages[statusKey]}
      <SmallEllipseIcon
        ml={2}
        className={`class-tooltip-report-${db_fields["id"]}`}
        name="info"
      >
        <Tooltip
          boxShadow="none"
          target={`.class-tooltip-report-${db_fields["id"]}`}
          content={messages[statusToolTip]}
        />
      </SmallEllipseIcon>
    </>
  ) : (
    "-"
  );
};
StatusComponent.propTypes = {
  value: PropTypes.string,
  db_fields: PropTypes.object,
};
