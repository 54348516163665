import React from "react";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import { BigRoundedRectIcon } from "@components/Icon";

import Form from "./InviteEditUserForm";

const UserDialog = ({
  title,
  userType,
  userDetails,
  customerId,
  handleClose,
}) => {
  const renderDialogHeader = () => (
    <Div width="100%" m={0} p={0} mb={3} px={[3, 3, 3, 7]}>
      <Div flex={1} display="flex" alignItems="center">
        <Div flex={2} display="flex" alignItems="center">
          <H2 color="var(--blue-dark) !important" mr={5}>
            {title}
          </H2>
        </Div>

        <Div ml={5} display="flex" justifyContent="flex-end">
          <BigRoundedRectIcon
            name="close"
            cursor="pointer"
            onClick={handleClose}
          />
        </Div>
      </Div>
    </Div>
  );

  return (
    <Dialog
      hideCloseButton
      maximized
      draggable={false}
      visible="displayBasic"
      width="100%"
      height="100%"
      header={renderDialogHeader}
    >
      <Div pl={[3, 3, 3, 7]}>
        <Form
          userType={userType}
          userDetails={userDetails}
          customerId={customerId}
          onClose={handleClose}
        />
      </Div>
    </Dialog>
  );
};

UserDialog.propTypes = {
  title: PropTypes.string,
  userType: PropTypes.string,
  userDetails: PropTypes.object,
  customerId: PropTypes.string,
  handleClose: PropTypes.func,
};

export default UserDialog;
