import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@components/Container";
import DataTable from "@components/Table/Table";
import UserContainer from "@layout/UserContainer";
import {
  WATCH_LISTS,
  WRITE,
  ROUTES,
  ADMIN_NEW_WATCHLIST,
  ADMIN_WATCHLIST_URL,
  ADMIN_CUSTOMER_DETAILS,
  ADMIN,
} from "@utils/constant";

const List = ({ customerId = "" }) => {
  const history = useHistory();
  const { messages } = useIntl();
  const [reloadList] = useState(false);
  const { id = "" } = useParams();

  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const handleRedirectToDetails = (e) => {
    const { id: watchlistId } = e.data;
    if (userInfo.type === ADMIN) {
      const customer_id = !customerId ? id : customerId;
      history.push(
        `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_WATCHLIST_URL}/${watchlistId}/${ADMIN_CUSTOMER_DETAILS}`,
      );
    } else {
      history.push(`${ROUTES.USER_WATCH_LIST.URL}/${watchlistId}`);
    }
  };

  const handleCreateWatchList = () => {
    if (userInfo.type === ADMIN) {
      const customer_id = !customerId ? id : customerId;
      history.push(
        `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_NEW_WATCHLIST}`,
      );
    } else {
      history.push(ROUTES.NEW_WATCH_LIST.URL);
    }
  };

  let config = [
    {
      headerActions: [
        {
          id: "new-watch-list",
          type: "button",
          label: messages.label_new_watch_list,
          onClick: handleCreateWatchList,
          width: "auto",
          icon: "plus",
          variant: "header",
          height: 40,
          px: 4,
          py: 20,
          isHidden: permissions && permissions?.["watch-lists"] !== WRITE,
        },
      ],
      filterType: "dialog",
      filters: [],
      columns: [
        { field: "name", header: messages.label_list, primary: true },
        { field: "objects", header: messages.title_objects },
        { field: "created_at", header: messages.label_created },
        {
          field: "last_results",
          header: messages.label_last_results,
        },
        { field: "action", header: messages.title_actions },
      ],
      rowActions: [
        {
          id: "more",
          label: messages.label_more,
          type: "link",
          icon: "headerarrowright",
          variant: "column",
          isPrimary: true,
        },
      ],
      pagination: true,
      sortable: true,
      selectionMode: "multiple",
    },
  ];

  const headerConfig = {
    title: messages.watchlist_screen_title,
    headerActions: [
      {
        id: "new-watch-list",
        type: "button",
        label: messages.label_new_watch_list,
        onClick: handleCreateWatchList,
        width: "auto",
        icon: "plus",
        variant: "header",
        height: 40,
        px: 4,
        py: 20,
        isHidden: permissions && permissions?.["watch-lists"] !== WRITE,
      },
    ],
  };

  return (
    <UserContainer config={headerConfig}>
      <Container m={"0px !important"}>
        <DataTable
          config={config}
          screenName={WATCH_LISTS}
          handleRedirectToDetails={handleRedirectToDetails}
          reloadList={reloadList}
          customerId={customerId ? customerId : id}
        />
      </Container>
    </UserContainer>
  );
};

List.propTypes = {
  customerId: PropTypes.number,
};

export default List;
