import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import CreateSchema from "./CreateSchema";

const UpdateSchema = CreateSchema.shape({
  status: Yup.string().required(
    <FormattedMessage id="validation_enter_status" />,
  ),
});

export default UpdateSchema;
