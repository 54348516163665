import React, { useRef } from "react";
import PropTypes from "prop-types";

import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";

import { slk } from "survey-core";
import { editorLocalization } from "survey-creator-core";

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-creator-core/i18n/swedish";

import Config from "@src/config";

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};

slk(Config.SURVEY_JS_API_KEY);
editorLocalization.currentLocale = Config.language;

const SurveyBuilder = ({ data, activeTab, onUpdate }) => {
  const creatorRef = useRef(new SurveyCreator(creatorOptions));

  const saveSurveyJson = (json) => {
    onUpdate(json);
  };

  creatorRef.current.saveSurveyFunc = () => {
    saveSurveyJson(creatorRef.current.JSON);
  };

  creatorRef.current.activeTab = activeTab;

  if (data) {
    creatorRef.current.text = data;
  }

  return <SurveyCreatorComponent creator={creatorRef.current} />;
};

SurveyBuilder.propTypes = {
  data: PropTypes.string,
  activeTab: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default SurveyBuilder;
