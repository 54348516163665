import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { FieldArray, Formik, Field } from "formik";
import styled from "styled-components";

import { authActions } from "@app/reducers/authReducer";
import { klarnaActions } from "@app/reducers/klarnaReducer";
import { personInformationActions } from "@app/reducers/personInformationReducer";

import { PrimaryButton, PrimaryButtonIcon } from "@components/Button";
import CardV2 from "@components/CardV2";
import Checkbox from "@components/Checkbox";
import Dialog from "@components/Dialog";
import { default as Div } from "@components/Div";
import Divider from "@components/Divider";
import Form from "@components/Form";
import { H2, H3, H4, Error } from "@components/Heading";
import Icon from "@components/Icon";
import InputText from "@components/InputText";
import { Text } from "@components/Text";

import useUserPaymentInfo from "@hooks/useUserPaymentInfo";

import Header from "@pages/user/search/Header";
import ServiceList from "@pages/user/search/ServiceList";
import SearchResultErrorCardHeader from "@pages/user/search/SearchResultErrorCardHeader";

import {
  trackEvent,
  trackPageNotFound,
  trackPageFound,
} from "@src/analyticsFunctions";

import { CONSENT_OPTIONAL, CONSENT_MANDATORY } from "@utils/constant";

import SearchResultsSchema from "./Schema";
import ServiceUnavailableCard from "./ServiceUnavailableCard";

const StyledDiv = styled(Div)`
  button {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const AvailableServicesForCandidates = ({
  data,
  setData,
  ssnNumber,
  isError,
  consentStatus,
  setIsFetching,
  onNextStep,
  servicesData,
  setSSNNumber,
  subscriptionStatus,
  optionalInfoLabel,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { messages } = useIntl();

  const { getPaymentMethod, isUserHasCredits } = useUserPaymentInfo();

  const [showDialog, setShowDialog] = useState(false);

  const hasCredits = isUserHasCredits();

  const mapServices = (services, position) => {
    return services.map((service) =>
      service.id === position
        ? { ...service, isChecked: !service.isChecked }
        : service,
    );
  };

  const handleOnChange = (position, key, setValues, values) => {
    const candidates = values.candidates.map((o, i) => {
      if (i !== key) {
        return o;
      }

      const services = mapServices(o.services, position);

      return { ...o, services: services };
    });

    setValues({
      ...values,
      candidates: candidates,
    });
  };

  const handleRemoveResult = (index) => {
    const filteredCandidates = data.candidates.filter((_, i) => i !== index);

    setData((prevState) => ({
      ...prevState,
      candidates: filteredCandidates,
    }));

    const filterdeSSNNumbers = ssnNumber.filter((_, i) => i !== index);
    setSSNNumber(filterdeSSNNumbers);
    history.replace({
      pathname: location.pathname,
      search: `?ssnnumber=${filterdeSSNNumbers.join(",")}`,
    });
  };

  const handleHereClick = (isOpen) => {
    setShowDialog(isOpen);
  };

  if (isError) {
    trackPageNotFound();
  } else {
    trackPageFound();
  }

  const consentDetailsHandler = (index, setValues, values, event) => {
    const value = event.target.value;
    setValues({
      ...values,
      candidates: values.candidates.map((o, i) =>
        i === index ? { ...o, email: value } : { ...o },
      ),
    });
  };

  const dialogHeader = () => (
    <Div
      width={1}
      lineHeight={"27px"}
      my={3}
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
    >
      <H3>{messages.text_need_more_credits}</H3>
    </Div>
  );

  const validate = (values) => {
    const { candidates = [], consentCheck } = values;
    const ValuesThatHasSelectedServices = candidates.filter(
      (value) =>
        value?.services.filter((service) => service.isChecked).length > 0,
    ).length;
    return consentStatus === CONSENT_MANDATORY || consentCheck
      ? ValuesThatHasSelectedServices === candidates?.length
      : ValuesThatHasSelectedServices > 0;
  };

  const renderOrderButtonLabel = (dataLength, hasCredits) => {
    const label = hasCredits
      ? messages.consent_continue_ordering
      : messages.consent_to_checkout;
    const length = dataLength > 1 ? ` (${dataLength})` : "";
    return `${label}${length}`;
  };

  const handleShowAllServiceOnClick = (index, setValues, values) => {
    setValues({
      ...values,
      candidates: values.candidates.map((o, i) =>
        i === index ? { ...o, showAllServices: !o.showAllServices } : { ...o },
      ),
    });
  };

  const handleCheckoutRedirection = async (values) => {
    const { consentCheck, candidates } = values;

    setIsFetching(true);
    trackEvent();

    const selectedServicesCount = data.candidates.filter(
      (val) => val.isChecked !== false,
    ).length;

    const paymentMethod = getPaymentMethod(selectedServicesCount);

    dispatch(klarnaActions.allSSN(data.candidates.map((val) => val.ssn)));
    dispatch(
      authActions.isConsent(
        consentCheck || consentStatus === CONSENT_MANDATORY,
      ),
    );

    const oderDetails = candidates
      .filter((o) => o.services.filter((x) => x.isChecked).length > 0)
      .map((y) => ({
        ...y,
        consent: consentCheck || consentStatus === CONSENT_MANDATORY ? 1 : 0,
        services: y.services.filter((o) => o.isChecked),
      }));

    dispatch(personInformationActions.personInfo(oderDetails));
    dispatch(personInformationActions.setPaymentMethod(paymentMethod));

    setIsFetching(false);

    if (onNextStep) {
      onNextStep?.();
    }
  };

  const renderCandidateField = (index, prop, setValues, values) => (
    <Field name={`values[${index}].email`}>
      {({ field: { onChange = () => {} }, meta }) => (
        <>
          <InputText
            mt={0}
            curved
            placeholder={messages.placeholder_email_address}
            name={`values[${index}].email`}
            formikProps={prop}
            width={[1, 350]}
            label={messages.lable_candidate_email}
            onChange={onChange}
            onInput={consentDetailsHandler.bind(this, index, setValues, values)}
            err
            disabled={
              !values.consentCheck && consentStatus !== CONSENT_MANDATORY
            }
          />
          {meta.touched && meta.error && (
            <Div pb={1} pt={3} m="auto">
              <Error>{meta.error}</Error>
            </Div>
          )}
        </>
      )}
    </Field>
  );

  const renderCandidates = (prop, values, setValues) =>
    data.candidates.map((value, index) => {
      return value.error ? (
        <CardV2
          width={["50% Important", "50% !important", "100%", "100%"]}
          my={4}
          key={value.ssn}
          header={<SearchResultErrorCardHeader ssn={value.ssn} />}
        >
          <ServiceUnavailableCard />
        </CardV2>
      ) : (
        <CardV2
          my={4}
          key={value.ssn}
          header={
            <Header
              name={value.name}
              street={value.street}
              zipcode={value.zipcode}
              city={value.city}
              ssn={value.ssn}
              onRemoveObject={handleRemoveResult}
              index={index}
            />
          }
        >
          <ServiceList
            services={servicesData}
            onChange={handleOnChange}
            index={index}
            consentCheck={
              values.consentCheck || consentStatus === CONSENT_MANDATORY
            }
            checked={values.candidates[index]}
            formikProps={prop}
            subscriptionStatus={subscriptionStatus}
            handleShowAllServiceOnClick={handleShowAllServiceOnClick}
          />
          {(values.consentCheck || consentStatus === CONSENT_MANDATORY) && (
            <Div display="flex" flexDirection="column" alignItems="start">
              <Divider />
              {renderCandidateField(index, prop, setValues, values)}
            </Div>
          )}
        </CardV2>
      );
    });

  return (
    <>
      {showDialog && (
        <Dialog
          header={dialogHeader}
          visible="displayBasic"
          draggable={false}
          onHide={() => handleHereClick(false)}
          width={[1, 500]}
          m={[3, "auto"]}
        >
          <Div display="flex" flexDirection="column" alignItems="center">
            <Text my={3}>{messages.text_to_buy_more}</Text>
            <H3 mt={2} mb={1}>
              {messages.contact_number}
            </H3>
            <H3 mb={1}>{messages.text_or}</H3>
            <H3 mt={1} mb={4}>
              {messages.email}
            </H3>
            <PrimaryButton
              rounded
              semibold
              width={[1, "33%"]}
              mb={[30, 0]}
              label={messages.label_ok}
              onClick={() => handleHereClick(false)}
            />
          </Div>
        </Dialog>
      )}

      <Div>
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={SearchResultsSchema}
        >
          {(prop) => {
            const { dirty, isValid, values, setValues, handleChange } = prop;

            return (
              <Form>
                <FieldArray
                  name="values"
                  render={() => (
                    <>
                      <H2 my={4}>{messages.search_results}</H2>
                      {!data.candidates[0].error &&
                        (consentStatus === CONSENT_OPTIONAL ||
                          consentStatus === CONSENT_MANDATORY) && (
                          <Div
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            mt={4}
                            p={3}
                            mx={2}
                            borderColor={"var(--turquoise)"}
                            borderWidth="1px"
                            borderStyle="dashed"
                          >
                            <Div display="flex" alignItems="center">
                              <Icon
                                name="sign"
                                rounded={true}
                                mr={2}
                                width={36}
                                height={36}
                              />
                              <H4
                                pl={2}
                                textAlign="left"
                                style={{ whiteSpace: "break-word" }}
                              >
                                {optionalInfoLabel()}
                              </H4>
                            </Div>
                            {consentStatus === CONSENT_OPTIONAL && (
                              <Div
                                mt={3}
                                ml={4}
                                pl={3}
                                display="flex"
                                alignItems="center"
                              >
                                <Checkbox
                                  name="consentCheck"
                                  checked={values.consentCheck}
                                  onChange={handleChange}
                                />
                                <Text ml={2}>
                                  {messages.text_ask_for_consent}
                                </Text>
                              </Div>
                            )}
                          </Div>
                        )}

                      {renderCandidates(prop, values, setValues)}

                      {!isError && (
                        <StyledDiv>
                          <PrimaryButtonIcon
                            mt={4}
                            label={renderOrderButtonLabel(
                              data.length,
                              hasCredits,
                            )}
                            icon={<Icon ml={2} pt={1} name="arrowright" />}
                            onClick={handleCheckoutRedirection.bind(
                              this,
                              values,
                            )}
                            disabled={
                              values.consentCheck ||
                              consentStatus === CONSENT_MANDATORY
                                ? !(dirty && isValid && validate(values))
                                : !validate(values)
                            }
                          />
                        </StyledDiv>
                      )}
                    </>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </Div>
    </>
  );
};

AvailableServicesForCandidates.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
  ssnNumber: PropTypes.string,
  isError: PropTypes.bool,
  consentStatus: PropTypes.string,
  setIsFetching: PropTypes.func,
  onNextStep: PropTypes.func,
  servicesData: PropTypes.array,
  setSSNNumber: PropTypes.func,
  subscriptionStatus: PropTypes.string,
  optionalInfoLabel: PropTypes.string,
};

export default AvailableServicesForCandidates;
