import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import OrderDetails from "../../shared/orderDetails/OrderDetails";
import { ROUTES } from "@utils/constant";
import ProgressSpinner from "@components/ProgressSpinner";
import fetchOrderDetails from "@app/services/orders/fetchOrderDetails";
import { useIntl } from "react-intl";
import AdminContainer from "@layout/AdminContainer";

const AdminOrderDetails = () => {
  const { search = "" } = useLocation();
  const { messages } = useIntl();
  const [orderData, setOrderData] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const { id: orderId = "" } = queryString.parse(search);
    const getOrderDetails = () => {
      setIsFetching(true);
      fetchOrderDetails(orderId).then(
        (res) => {
          setOrderData(res);
          setIsFetching(false);
        },
        (e) => {
          throw new Error(e);
        },
      );
    };
    getOrderDetails();
  }, [search]);

  const headerConfig = {
    title: messages.label_order + " #" + orderData.number,
  };

  return (
    <AdminContainer config={headerConfig}>
      {isFetching ? (
        <ProgressSpinner />
      ) : (
        <OrderDetails
          orderSummaryProps={orderData}
          redirectTo={ROUTES.ADMIN_ORDERS_LIST.URL}
          isAdmin
          onUpdateOrderSummaryProps={setOrderData}
        />
      )}
    </AdminContainer>
  );
};

export default AdminOrderDetails;
