import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import AutoComplete from "@components/AutoComplete";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Calendar from "@components/Calendar";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Dropdown from "@components/Dropdown";
import { Error, H3 } from "@components/Heading";
import Icon from "@components/Icon";
import InputText from "@components/InputText";
import { Text, TextMediumWeight } from "@components/Text";

import { FORM_INPUT_TYPE } from "@utils/enum";
import { isAdminUser } from "@utils/utils";
import MultiSelect from "@components/MultiSelect";

InputText.defaultProps = {
  curved: true,
  isLowercaseLabel: true,
  width: 1,
  labelAlignment: "left",
};

const renderFiller = ({ key, index }) => (
  <Div
    key={`${key}_${index}`}
    flex="1 1 40%"
    display={["none", "none", "block", "block"]}
  />
);

const renderTextInput = ({ key, index, label, formik, required }) => {
  const { values } = formik;
  return (
    <Div key={`${key}_${index}`} flex="1 1 40%">
      <InputText
        name={key}
        label={`${label}${required ? "*" : ""}`}
        placeholder={label}
        formikProps={formik}
        value={values?.[key]}
      />
    </Div>
  );
};

const renderDropdown = ({
  key,
  index,
  label,
  placeholder,
  formik,
  options,
  optionFields,
  messages,
  selectorField,
  required,
}) => {
  const { values, handleChange, errors, touched } = formik;

  return (
    <>
      <Div key={`${key}_${index}`} flex="1 1 40%" mt={3} pt={1}>
        <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
        <Dropdown
          width={1}
          mt={2}
          name={key}
          value={values?.[key]}
          onChange={handleChange}
          options={options}
          optionField={selectorField}
          placeholder={placeholder}
          dropdownIcon={<Icon name="chevrondown" />}
          filter={options?.length > 5}
        />
        {errors?.[key] && touched?.[key] && (
          <Div pb={1} pt={3} m="auto">
            <Error>{errors?.[key]}</Error>
          </Div>
        )}
      </Div>

      {optionFields?.[values?.[key]]?.map(
        ({ key, translationKey, type }, index) => {
          const renderItem = components[type];

          return (
            <Div key={`${key}_${index}`} flex="1 1 40%">
              {renderItem?.({ key, label: messages[translationKey], formik })}
            </Div>
          );
        },
      )}
    </>
  );
};

const renderMultiselect = ({
  key,
  index,
  label,
  placeholder,
  formik,
  options,
  optionFields,
  messages,
  selectorField,
  required,
}) => {
  const { values, setFieldValue, errors, touched } = formik;
  const handleMultiSelectChange = (e) => {
    const selectedValues = e.value;
    setFieldValue(key, selectedValues);
  };
  return (
    <>
      <Div key={`${key}_${index}`} flex="1 1 40%" mt={3} pt={1}>
        <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
        <MultiSelect
          width={1}
          mt={2}
          name={key}
          value={values?.[key]}
          onChange={handleMultiSelectChange}
          options={options}
          optionField={selectorField}
          placeholder={placeholder}
          isMulti // Ensure multi-select mode
          dropdownIcon={<Icon name="chevrondown" />}
          filter={options?.length > 5}
        />
        {errors?.[key] && touched?.[key] && (
          <Div pb={1} pt={3} m="auto">
            <Error>{errors?.[key]}</Error>
          </Div>
        )}
      </Div>

      {values?.[key]?.map((selectedValue, selectedIndex) =>
        optionFields?.[selectedValue]?.map(
          ({ key, translationKey, type }, index) => {
            const renderItem = components[type];

            return (
              <Div key={`${key}_${selectedIndex}_${index}`} flex="1 1 40%">
                {renderItem?.({ key, label: messages[translationKey], formik })}
              </Div>
            );
          },
        ),
      )}
    </>
  );
};

const renderAutocomplete = ({
  key,
  index,
  label,
  placeholder,
  formik,
  options,
  selectorField,
  required,
  completeMethod,
  autocompleteItemOptions,
  messages,
}) => {
  const { values, handleChange, errors, touched } = formik;

  const renderItem = (item) => {
    const formattedDescription = autocompleteItemOptions
      .map(({ key, translationKey }) => ({
        label: messages[translationKey],
        value: item[key],
      }))
      .filter(({ value }) => value != null)
      .map(({ label, value }) => `${label}: ${value}`)
      .join(" | ");

    return (
      <Div display="flex" flexDirection="column" gridGap={1}>
        <TextMediumWeight TextMediumWeight>
          {item[selectorField]}
        </TextMediumWeight>
        <Text>{formattedDescription}</Text>
      </Div>
    );
  };

  return (
    <Div key={`${key}_${index}`} flex="1 1 40%" mt={3} pt={1}>
      <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
      <AutoComplete
        mt={2}
        width="100%"
        curved
        dropdown
        hideLoader
        name={key}
        field={selectorField}
        value={values?.[key]}
        placeholder={placeholder}
        delay={200}
        suggestions={options}
        itemTemplate={renderItem}
        onChange={handleChange}
        completeMethod={completeMethod}
        dropdownMode="current"
      />

      {errors?.[key] && touched?.[key] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const renderCalendar = ({ key, index, label, formik, required }) => {
  const { values, errors, touched, handleChange } = formik;
  const dateValue = values?.[key] ? new Date(values?.[key]) : "";

  return (
    <Div key={`${key}_${index}`} flex="1 1 40%" mt={3} pt={1}>
      <TextMediumWeight>{`${label}${required ? "*" : ""}`}</TextMediumWeight>
      <Calendar
        mt={2}
        width="100%"
        curved
        showIcon
        name={key}
        value={dateValue}
        onChange={handleChange}
        dateFormat="yy-mm-dd"
      />

      {errors?.[key] && touched?.[key] && (
        <Div pb={1} pt={3} m="auto">
          <Error>{errors?.[key]}</Error>
        </Div>
      )}
    </Div>
  );
};

const components = {
  [FORM_INPUT_TYPE.FILLER]: renderFiller,
  [FORM_INPUT_TYPE.TEXT]: renderTextInput,
  [FORM_INPUT_TYPE.DROPDOWN]: renderDropdown,
  [FORM_INPUT_TYPE.AUTOCOMPLETE]: renderAutocomplete,
  [FORM_INPUT_TYPE.CALENDAR]: renderCalendar,
  [FORM_INPUT_TYPE.MULTISELECT]: renderMultiselect,
};

const EditFormDialog = ({
  fullWidthInputs,
  title,
  config,
  // validationSchema,
  onHide,
  onSubmit,
}) => {
  const { messages } = useIntl();
  const initialValues = config
    .filter((item) => item.key !== undefined)
    .reduce((result, { key, value, optionFields }) => {
      if (optionFields) {
        const selectedOptionFields = optionFields[value];
        if (selectedOptionFields !== undefined) {
          selectedOptionFields
            .filter((optionField) => optionField.key !== undefined)
            .forEach((optionField) => {
              result[optionField.key] = optionField.value;
            });
        }
      }
      result[key] = value;
      return result;
    }, {});

  const handleSubmit = (values) => {
    onSubmit(values);
    onHide();
  };

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "auto", "auto"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
    >
      <H3 style={{ textAlign: "center" }}>{title}</H3>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        //validationSchema={validationSchema}
      >
        {(formik) => {
          const { dirty } = formik;

          return (
            <Form>
              <Div
                display="flex"
                flexDirection={
                  fullWidthInputs
                    ? "column"
                    : ["column", "column", "row", "row"]
                }
                flexWrap={
                  fullWidthInputs ? "nowrap" : ["nowrap", "wrap", "wrap"]
                }
                gridColumnGap={4}
              >
                {config.map(
                  (
                    {
                      key,
                      translationKey,
                      type,
                      placeholderKey,
                      options,
                      optionFields,
                      isAdminField,
                      isAdminInput,
                      isReadOnlyField,
                      selectorField,
                      required,
                      completeMethod,
                      autocompleteItemOptions,
                      editable = true,
                    },
                    index,
                  ) => {
                    if (
                      (isAdminField && !isAdminUser()) ||
                      (isAdminInput && !isAdminUser()) ||
                      isReadOnlyField
                    ) {
                      return <></>;
                    }

                    const renderItem = components[type];
                    return editable
                      ? renderItem?.({
                          key,
                          index,
                          label: messages[translationKey],
                          placeholder: messages[placeholderKey],
                          formik,
                          options,
                          optionFields,
                          messages,
                          selectorField,
                          required,
                          completeMethod,
                          autocompleteItemOptions,
                        })
                      : null;
                  },
                )}
              </Div>

              <Div
                display="flex"
                flexDirection={["column", "column", "row", "row"]}
                alignItems="center"
                justifyContent="center"
                gridGap={4}
                mt={4}
              >
                <PrimaryButton
                  width={[1, 1, "150px", "150px"]}
                  label={messages.label_save}
                  disabled={!dirty}
                  type="submit"
                />
                <PrimaryButtonOutlined
                  width={[1, 1, "150px", "150px"]}
                  label={messages.label_cancel}
                  onClick={onHide}
                />
              </Div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

EditFormDialog.propTypes = {
  fullWidthInputs: PropTypes.bool,
  title: PropTypes.string,
  config: PropTypes.array,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default EditFormDialog;
