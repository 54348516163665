import React from "react";
import { PropTypes } from "prop-types";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import Div from "@components/Div";
import UserHeaderMain from "./UserHeaderMain";

const UserContainer = (props) => {
  return (
    <ScrollToTop>
      <UserHeaderMain config={props.config} />
      <Div
        m={["16px", "16px", "16px", "32px"]}
        maxWidth={["100%", "100%", "100%", "1320px"]}
      >
        <Container m="0 !important">{props.children}</Container>
      </Div>
    </ScrollToTop>
  );
};

UserContainer.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node,
};

export default UserContainer;
