import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { useFormik } from "formik";

import getPermissionsService from "@app/services/users/getPermissionsService";
import getRolesService from "@app/services/users/getRolesService";
import inviteUserService from "@app/services/users/inviteUserService";
import updateUserService from "@app/services/users/updateUserService";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Card from "@components/Card";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import { H4 } from "@components/Heading";
import { InputCheckGroup, InputDropdown } from "@components/Input";
import InputText from "@components/InputText";
import ProgressSpinner from "@components/ProgressSpinner";
import SuccessDialog from "@components/SuccessDialog";
import { Text } from "@components/Text";

import { OTHER, PERMISSIONS } from "@utils/constant";
import { USER_TYPE } from "@utils/enum";
import { enumValueToTranslationKey } from "@utils/utils";

import InviteUserSchema from "./InviteUserSchema";
import EditUserSchema from "./EditUserSchema";

const INVITE_INITIAL_VALUES = {
  email: "",
  role: "",
  permissions: {},
};

const formatUserPermissions = (permissionsData, selectedUserPermissions) => {
  return Object.entries(permissionsData).reduce(
    (acc, [permissionsCategory, permissionsObject]) => {
      permissionsObject.options.forEach((option) => {
        const userHasPermission = selectedUserPermissions.some(
          (selectedPermission) => selectedPermission.name === option.value,
        );

        if (!userHasPermission) {
          return;
        }

        if (!acc[permissionsCategory]) {
          acc[permissionsCategory] = {};
        }

        acc[permissionsCategory][option.value] = true;
      });

      return acc;
    },
    {},
  );
};

const Form = ({ userType, userDetails, customerId, onClose }) => {
  const { messages } = useIntl();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rolesData, setRolesData] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);

  const isEditMode = !!userDetails;

  const fetchRoles = useCallback(async () => {
    try {
      const data = (await getRolesService(userType)) || [];

      const formattedData = data?.map(({ value, ...rest }) => {
        const translationKey = value?.startsWith("admin-")
          ? value?.replace("admin-", "")
          : value;

        return {
          ...rest,
          value,
          name: messages[translationKey],
        };
      });

      return formattedData;
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    }
  }, [messages, userType]);

  const fetchPermissions = useCallback(
    async (selectedRole = "") => {
      try {
        const { data: permissions = [] } = await getPermissionsService(
          userType,
          selectedRole,
        );
        const mappedPermissions = permissions?.map(({ name, ...rest }) => ({
          ...rest,
          value: name,
          label: messages[enumValueToTranslationKey(name)],
        }));
        const groupedPermissions = Object.groupBy(
          mappedPermissions,
          ({ group }) => group || OTHER,
        );
        const formattedPermissions = Object.entries(groupedPermissions).reduce(
          (acc, [key, value]) => {
            acc[key] = {
              title: messages[`label_${key}`] || messages[`title_${key}`],
              options: value,
            };

            return acc;
          },
          {},
        );

        return formattedPermissions;
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      }
    },
    [messages, userType],
  );

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);

      try {
        const [roles, permissions] = await Promise.all([
          fetchRoles(),
          fetchPermissions(),
        ]);

        setRolesData(roles);
        setPermissionsData(permissions);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, [fetchRoles, fetchPermissions, messages.exception_error_message]);

  const initialValues = useMemo(() => {
    if (!userDetails) {
      return INVITE_INITIAL_VALUES;
    }

    const { firstname, lastname, email, phone, roles } = userDetails;
    const [selectedRole] = roles;
    const selectedRoleName = selectedRole?.name;
    const selectedUserPermissions = selectedRole?.permissions;

    const formattedPermissions = formatUserPermissions(
      permissionsData,
      selectedUserPermissions,
    );

    return {
      firstName: firstname,
      lastName: lastname,
      email,
      phone,
      role: selectedRoleName,
      permissions: formattedPermissions,
    };
  }, [userDetails, permissionsData]);

  const validationSchema = useMemo(
    () => (isEditMode ? EditUserSchema : InviteUserSchema),
    [isEditMode],
  );

  const handleInviteUser = useCallback(
    async ({ permissions, ...values }) => {
      const formattedPermissions = Object.values(permissions)
        .flatMap((permissionsCategory) => Object.entries(permissionsCategory))
        .filter(([, value]) => value)
        .map(([key]) => key);
      const isSubUser = userType === USER_TYPE.CUSTOMER;
      const payload = {
        ...values,
        type: userType,
        permissions: formattedPermissions,
      };

      if (isSubUser) {
        payload.customer_id = customerId;
      }

      return await inviteUserService(payload);
    },
    [customerId, userType],
  );

  const handleUpdateUser = useCallback(
    async ({
      permissions,
      firstName: firstname,
      lastName: lastname,
      ...values
    }) => {
      const { customer_id, id: userId, status } = userDetails;
      const formattedPermissions = Object.values(permissions)
        .flatMap((permissionsCategory) => Object.entries(permissionsCategory))
        .filter(([, value]) => value)
        .map(([key]) => key);
      const isSubUser = userType === USER_TYPE.CUSTOMER;
      const isActiveUser = status === "active";
      const payload = {
        ...values,
        type: userType,
        permissions: formattedPermissions,
      };

      if (isSubUser) {
        payload.customer_id = customer_id;
      }

      if (isActiveUser) {
        payload.firstname = firstname;
        payload.lastname = lastname;
      }

      return await updateUserService(payload, userId);
    },
    [userDetails, userType],
  );

  const handleSubmit = useCallback(
    async (values) => {
      setIsLoading(true);

      try {
        const response = isEditMode
          ? await handleUpdateUser(values)
          : await handleInviteUser(values);

        if (response) {
          const message = isEditMode
            ? messages.title_user_updated || "User updated"
            : messages.title_user_invited || "User invited";

          setSuccessMessage(message);
        }
      } catch (error) {
        setErrorMessage(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    },
    [isEditMode, handleInviteUser, handleUpdateUser, messages],
  );

  const handleCloseSuccessDialog = () => {
    setSuccessMessage("");
    onClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  const renderEditContent = () => (
    <>
      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="firstName"
          label={messages.label_name}
          placeholder={`${messages.label_name}...`}
          value={formik.values.firstName}
          onChange={formik.handleChange}
        />
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="lastName"
          label={messages.label_surname}
          placeholder={`${messages.label_surname}...`}
          value={formik.values.lastName}
          onChange={formik.handleChange}
        />
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="email"
          label={messages.label_email}
          placeholder={`${messages.label_email}...`}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="phone"
          label={messages.label_phone}
          placeholder={`${messages.label_phone}...`}
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
      </Div>
    </>
  );

  const renderInviteContent = () => (
    <>
      <Div mt={3} width={1}>
        <Text>{messages.message_enter_invite_email}</Text>
      </Div>

      <Div flex="1 1 40%">
        <InputText
          width={1}
          formikProps={formik}
          name="email"
          label={messages.label_email}
          placeholder={`${messages.label_email}...`}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
      </Div>
      <Div flex="1 1 40%" />
    </>
  );

  const contentToRender = !isEditMode ? renderInviteContent : renderEditContent;

  const renderCard = (title, children) => (
    <Card mt={4} p={3} borderRadius={20}>
      <H4>{title}</H4>

      <Div
        mt={3}
        width="100%"
        height={1}
        backgroundColor="var(--grey-lightest)"
      />

      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        {children}
      </Div>
    </Card>
  );

  const formatPermissions = (permissions) => {
    return Object.entries(permissions).reduce(
      (acc, [permissionsCategory, permissionsObject]) => {
        permissionsObject.options.forEach((option) => {
          if (!acc[permissionsCategory]) {
            acc[permissionsCategory] = {};
          }
          acc[permissionsCategory][option.value] = true;
        });
        return acc;
      },
      {},
    );
  };

  const handleChangeRole = async (event, formik) => {
    const { value } = event;

    const permissions = await fetchPermissions(value);
    const formattedPermissions = formatPermissions(permissions);

    formik.setFieldValue(PERMISSIONS, formattedPermissions);
    formik.handleChange(event);
  };

  const renderRolesAndPermissions = () => {
    return (
      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        <InputDropdown
          flex="1 1 40%"
          width={1}
          formikProps={formik}
          name="role"
          label={messages.label_role}
          placeholder={messages.placeholder_choose}
          value={formik.values.role}
          onChange={(event) => handleChangeRole(event, formik)}
          options={rolesData}
          optionLabel="name"
        />
        <Div flex="1 1 40%" />

        {Object.entries(permissionsData).map(([key, value]) => (
          <InputCheckGroup
            key={`permissions.${key}`}
            groupName={key}
            flex="1 1 40%"
            width={1}
            formikProps={formik}
            name={`permissions.${key}`}
            options={value.options}
            label={value.title}
            onChange={formik.handleChange}
            value={formik.values.permissions}
          />
        ))}
      </Div>
    );
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  return (
    <Div
      display="flex"
      flexDirection="column"
      width={[1, 1, "60%"]}
      gridGap={4}
    >
      {isLoading && <ProgressSpinner />}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      {successMessage && (
        <SuccessDialog
          message={successMessage}
          onConfirm={handleCloseSuccessDialog}
        />
      )}

      {renderCard(messages.title_user_details, contentToRender())}

      {renderCard(
        messages.title_role_and_permissions,
        renderRolesAndPermissions(),
      )}

      <Div
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        alignItems="center"
        gridGap={4}
      >
        <PrimaryButton
          width={[1, 1, "150px", "150px"]}
          label={messages.label_save}
          disabled={!formik.dirty}
          onClick={formik.handleSubmit}
        />
        <PrimaryButtonOutlined
          width={[1, 1, "150px", "150px"]}
          label={messages.label_cancel}
          onClick={onClose}
        />
      </Div>
    </Div>
  );
};

Form.propTypes = {
  userType: PropTypes.string,
  userDetails: PropTypes.object,
  customerId: PropTypes.string,
  onClose: PropTypes.func,
};

export default Form;
