import styled from "styled-components";
import {
  compose,
  space,
  display,
  layout,
  color,
  typography,
  flexbox,
} from "styled-system";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";

const mobileBreakPoint = Config.mobileBreakPoint;

export const TextLarge = styled("span")`
  ${compose(space, display, layout, typography)};
  color: var(--grey-dark);
  font-weight: var(--light-weight);
  font-size: var(--fs-text);
  line-height: var(--lh-text);
  text-decoration: ${({ textDecoration }) => textDecoration};
  word-break: ${({ wordBreak }) => wordBreak};
  white-space: ${({ whiteSpace }) => whiteSpace};
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    font-size: var(--fs-text-m);
    line-height: var(--lh-text-m);
  }
`;
TextLarge.defaultProps = {
  textDecoration: "none",
  wordBreak: "normal",
  whiteSpace: "inherit",
};

export const TextLargeSemiBoldWeight = styled(TextLarge)`
  ${compose(space, display, color, typography, flexbox)};
  color: var(--grey-dark);
  font-weight: var(--semibold-weight);
  font-size: var(--fs-text);
  line-height: var(--lh-text);
`;

export const Text = styled("span")`
  ${compose(space, display, color, typography)};
  color: var(--grey-dark);
  font-weight: var(--light-weight);
  font-size: var(--fs-text-secondary);
  line-height: var(--lh-text-secondary);
  word-break: ${({ wordBreak }) => wordBreak};
`;
export const TextSmall = styled(Text)`
  ${compose(space, display, color, typography)};
  color: var(--grey);
  font-size: var(--fs-text-small);
  font-weight: var(--medium-weight);
`;
Text.defaultProps = {
  wordBreak: "normal",
};
export const TextColoured = styled(Text)`
  ${compose(space, display, color, typography)};
`;

export const TextUpperCase = styled(Text)`
  ${compose(space, display, typography)};
  text-transform: uppercase;
  font-weight: var(--medium-weight);
`;
export const TextUpperCaseSemiBold = styled(TextUpperCase)`
  ${compose(space, display, typography)};
  font-weight: var(--semibold-weight);
`;

export const TextMediumWeight = styled(Text)`
  ${compose(space, display, color, typography, flexbox, layout)};
  font-weight: var(--medium-weight);
  color: ${({ error }) => (error ? "var(--red-dark)" : "")};
`;
export const ColouredMediumWeight = styled(TextMediumWeight)`
  ${compose(space, display, typography)};
  font-weight: var(--medium-weight);
  color: ${({ error }) => (error ? "var(--red-dark)" : "var(--blue-dark)")};
`;
export const TextSemiBoldWeight = styled(Text)`
  ${compose(space, display)};
  font-size: var(--fs-text);
  font-weight: var(--semibold-weight);
`;
export const EllipseIconText = styled(Text)`
  ${compose(space, display)};
  font-size: var(--fs-icon-number);
  font-weight: var(--semibold-weight);
  line-height: var(--lh-icon);
  color: var(--white);
`;

export const ColouredSemiBoldText = styled(TextLargeSemiBoldWeight)`
  ${compose(space, display, color)};
`;

export const PriceText = styled(TextLargeSemiBoldWeight)`
  ${compose(space, display, color)};
  font-size: var(--fs-h3);
  line-height: var(--lh-h3);
`;
export const TextUpperCaseLargeSemiBoldWeight = styled(PriceText)`
  ${compose(space, display, color)};
  text-transform: uppercase;
`;
export const ColouredSemiText = styled(Text)`
  ${compose(space, display, color)};
  font-size: var(--fs-h4);
  font-weight: var(--semibold-weight);
  color: ${({ alert }) => (alert ? "var(--red)" : "var(--primary)")};
`;
export const TextColouredSemiBold = styled(TextSemiBoldWeight)`
  ${compose(space, display, color)};

  color: ${({ color }) => (color ? color : "var(--blue-dark)")};
`;
export const TextLargeColoredSemiBold = styled(TextColouredSemiBold)`
  ${compose(space, display)};
  font-size: var(--fs-h1);
`;
