import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { default as Div } from "@components/Div";
import { H1, H2, H4 } from "@components/Heading";
import Icon from "@components/Icon";
import Dialog from "@components/Dialog";
import ListItem from "@components/ListItem";
import Link from "@components/Link";
import { PrimaryButtonIcon } from "@components/Button";
import ModalHeader from "./ModalHeader";
import EditForm from "./EditForm";
import { TextLarge, TextLargeSemiBoldWeight } from "@components/Text";
import ConfirmationDialog from "@pages/shared/ConfirmationDialog";
import styled from "styled-components";
import Config from "@src/config";
import { PERSONAL_DATA_POLICY } from "@utils/constant";
import UserContainer from "@layout/UserContainer";
import AdminContainer from "@layout/AdminContainer";

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const EditDialog = (prop) => {
  const { editType, handleModal, onCloseModal } = prop;
  return (
    <Dialog
      header={<ModalHeader editType={editType} />}
      visible="displayBasic"
      draggable={false}
      onHide={handleModal(editType)}
      editType={editType}
      m={[3, 3, 3, "auto"]}
    >
      <EditForm
        {...prop}
        onCancelClick={handleModal()}
        onCloseModal={onCloseModal}
      />
    </Dialog>
  );
};
export const UserInfo = ({ isAdmin = false }) => {
  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const [openDialog, setOpenDialog] = useState(false);
  const [editType, setEditType] = useState("user");
  const { messages } = useIntl();

  const {
    name,
    email,
    phone: phoneNumber,
    id,
    customer = {},
    showConfirmDialog,
    isError = false,
  } = userInfo;
  const { type: customerType = "" } = customer;

  const handleCloseModal = () => {
    setOpenDialog(!openDialog);
  };
  const handleModal = (editTypeArg) => () => {
    setEditType(editTypeArg);
    setOpenDialog(!openDialog);
  };

  const handleClick = () => {
    window.open(`${Config.WP_URL}/${PERSONAL_DATA_POLICY}}/`);
  };

  let headerConfig = {
    title: messages.title_user_info,
  };

  return (
    <>
      {isAdmin ? (
        <AdminContainer config={headerConfig}>
          {openDialog && (
            <EditDialog
              editType={editType}
              handleModal={handleModal}
              onCloseModal={handleCloseModal}
              id={id}
              customerType={customerType}
              userInfo={userInfo}
            />
          )}
          {showConfirmDialog && (
            <ConfirmationDialog
              isError={isError}
              byline={
                isError ? messages.invalid_data : messages.text_update_sucessful
              }
            />
          )}
          <Div>
            {!!isAdmin && <H1 mb={3}>{messages.title_my_profile}</H1>}
            <H2 mb={3} display={["flex", "flex", "flex", "none"]}>
              {messages.title_user_info}
            </H2>
            <Div>
              <ul>
                <ListItem pb={3}>
                  <TextLargeSemiBoldWeight semibold py={3}>
                    {messages.label_name}:
                  </TextLargeSemiBoldWeight>
                  <TextLarge mt={2} mx={1} fontWeight={300}>
                    {name}
                  </TextLarge>
                </ListItem>
                <ListItem pb={3}>
                  <TextLargeSemiBoldWeight semibold py={3}>
                    {messages.label_email}:
                  </TextLargeSemiBoldWeight>
                  <TextLarge mt={2} mx={1} fontWeight={300}>
                    {email}
                  </TextLarge>
                </ListItem>
                <ListItem pb={3}>
                  <TextLargeSemiBoldWeight semibold py={3}>
                    {messages.label_phone}:
                  </TextLargeSemiBoldWeight>
                  <TextLarge mt={2} mx={1} fontWeight={300}>
                    {phoneNumber || "-"}
                  </TextLarge>
                </ListItem>
              </ul>
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt={3}
            >
              <Link
                label={messages.label_edit_user_info}
                direction="right"
                onClick={handleModal(isAdmin ? "adminUser" : "user")}
                iconPos="left"
              >
                <Icon name="pen" mr={2} />
              </Link>
              <Link
                mt={2}
                label={messages.label_change_password}
                direction="right"
                onClick={handleModal("password")}
                iconPos="left"
              >
                <Icon name="pen" mr={2} />
              </Link>
            </Div>
            <Div
              display={["block", "block", "flex", "flex"]}
              alignItems="center"
              mt={4}
              p={3}
              mx={2}
              borderColor={"var(--turquoise)"}
              borderWidth="1px"
              borderStyle="dashed"
            >
              <Div
                mb={[3, 3, 0, 0]}
                display="flex"
                alignItems="center"
                width={["unset", "unset", "61%"]}
              >
                <Icon
                  name="sign"
                  rounded={true}
                  mr={2}
                  width={36}
                  height={36}
                />
                <H4 pl={[0, 0, 2, 2]} textAlign="left">
                  {messages.byline_note}
                </H4>
              </Div>
              <Div>
                <H4
                  onClick={handleClick}
                  pl={[0, 0, 1, 1]}
                  display="flex"
                  alignItems="center"
                >
                  <StyledIcon
                    name="info"
                    rounded={true}
                    ml={[0, 0, 1, 1]}
                    mr={2}
                    borderWidth={2}
                    onClick={handleClick}
                    width={36}
                    height={36}
                  />
                  {messages.label_read_our}
                  <Link
                    color="var(--grey-dark) !important"
                    fontSize="var(--fs-h4) !important"
                    fontWeight="var(--medium-weight) !important"
                    textDecoration="underline"
                    target="_blank"
                    pl="4px"
                  >
                    {messages.text_privacy_policy}.
                  </Link>
                </H4>
              </Div>
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt={4}
            >
              <Div>
                <PrimaryButtonIcon
                  rounded
                  semibold
                  mr={3}
                  px={25}
                  icon={<Icon mr={1} name="rubber" />}
                  onClick={handleModal("deleteProfile")}
                  label={messages.label_delete_profile}
                />
              </Div>
            </Div>
          </Div>
        </AdminContainer>
      ) : (
        <UserContainer config={headerConfig}>
          {openDialog && (
            <EditDialog
              editType={editType}
              handleModal={handleModal}
              onCloseModal={handleCloseModal}
              id={id}
              customerType={customerType}
              userInfo={userInfo}
            />
          )}
          {showConfirmDialog && (
            <ConfirmationDialog
              isError={isError}
              byline={
                isError ? messages.invalid_data : messages.text_update_sucessful
              }
            />
          )}
          <Div>
            {!!isAdmin && <H1 mb={3}>{messages.title_my_profile}</H1>}
            <H2 mb={3} display={["flex", "flex", "flex", "none"]}>
              {messages.title_user_info}
            </H2>
            <Div>
              <ul>
                <ListItem pb={3}>
                  <TextLargeSemiBoldWeight semibold py={3}>
                    {messages.label_name}:
                  </TextLargeSemiBoldWeight>
                  <TextLarge mt={2} mx={1} fontWeight={300}>
                    {name}
                  </TextLarge>
                </ListItem>
                <ListItem pb={3}>
                  <TextLargeSemiBoldWeight semibold py={3}>
                    {messages.label_email}:
                  </TextLargeSemiBoldWeight>
                  <TextLarge mt={2} mx={1} fontWeight={300}>
                    {email}
                  </TextLarge>
                </ListItem>
                <ListItem pb={3}>
                  <TextLargeSemiBoldWeight semibold py={3}>
                    {messages.label_phone}:
                  </TextLargeSemiBoldWeight>
                  <TextLarge mt={2} mx={1} fontWeight={300}>
                    {phoneNumber || "-"}
                  </TextLarge>
                </ListItem>
              </ul>
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt={3}
            >
              <Link
                label={messages.label_edit_user_info}
                direction="right"
                onClick={handleModal(isAdmin ? "adminUser" : "user")}
                iconPos="left"
              >
                <Icon name="pen" mr={2} />
              </Link>
              <Link
                mt={2}
                label={messages.label_change_password}
                direction="right"
                onClick={handleModal("password")}
                iconPos="left"
              >
                <Icon name="pen" mr={2} />
              </Link>
            </Div>
            <Div
              display={["block", "block", "flex", "flex"]}
              alignItems="center"
              mt={4}
              p={3}
              mx={2}
              borderColor={"var(--turquoise)"}
              borderWidth="1px"
              borderStyle="dashed"
            >
              <Div
                mb={[3, 3, 0, 0]}
                display="flex"
                alignItems="center"
                width={["unset", "unset", "61%"]}
              >
                <Icon
                  name="sign"
                  rounded={true}
                  mr={2}
                  width={36}
                  height={36}
                />
                <H4 pl={[0, 0, 2, 2]} textAlign="left">
                  {messages.byline_note}
                </H4>
              </Div>
              <Div>
                <H4
                  onClick={handleClick}
                  pl={[0, 0, 1, 1]}
                  display="flex"
                  alignItems="center"
                >
                  <StyledIcon
                    name="info"
                    rounded={true}
                    ml={[0, 0, 1, 1]}
                    mr={2}
                    borderWidth={2}
                    onClick={handleClick}
                    width={36}
                    height={36}
                  />
                  {messages.label_read_our}
                  <Link
                    color="var(--grey-dark) !important"
                    fontSize="var(--fs-h4) !important"
                    fontWeight="var(--medium-weight) !important"
                    textDecoration="underline"
                    target="_blank"
                    pl="4px"
                  >
                    {messages.text_privacy_policy}.
                  </Link>
                </H4>
              </Div>
            </Div>
            <Div
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt={4}
            >
              <Div>
                <PrimaryButtonIcon
                  rounded
                  semibold
                  mr={3}
                  px={25}
                  icon={<Icon mr={1} name="rubber" />}
                  onClick={handleModal("deleteProfile")}
                  label={messages.label_delete_profile}
                />
              </Div>
            </Div>
          </Div>
        </UserContainer>
      )}
    </>
  );
};

UserInfo.propTypes = {
  isAdmin: PropTypes.bool,
};

export default UserInfo;
