import React, { useState, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { H3, H4 } from "@components/Heading";
import Icon, { SmallEllipseIconRed } from "@components/Icon";
import Div from "@components/Div";
import { Text } from "@components/Text";
import Switch from "@components/Switches";
import Dropdown from "@components/Dropdown";
import Calendar from "@components/Calendar";
import { default as StyledInputText } from "@components/StyledInputText";
import { PrimaryButton, PrimaryButtonIcon } from "@components/Button";
import Tag from "@components/Tag";
import Dialog from "@components/Dialog";
import RecurranceConfirmationModal from "@pages/user/watchlist/components/RecurranceConfirmationModal";
import updateWatchlist from "@app/services/watchlist/updateWatchlist";
import { Form, Formik } from "formik";
import { formatDate } from "@utils/utils";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import { handleException } from "@utils/common";

const StyledH4 = styled(H4)`
  text-transform: uppercase;
`;

const InputText = styled(StyledInputText)`
  border-radius: 40px;
`;

const StyledDiv = styled(Div)`
  display: block;
  align-items: center;
  margin-top: 3px;
  padding: 2px;
  margin-left: 2px;
  border-color: var(--turquoise);
  border-width: 1px;
  border-style: dashed;

  @media screen and (min-width: 768px) {
    display: grid;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding-left: 1.5em;

  li {
    padding: 0.2em 0.7em;
  }

  li::before {
    content: "";
    display: inline-block;
    width: 0.3em;
    height: 0.3em;
    border-radius: 50%;
    background-color: #000;
    margin-right: 0.5em;
    vertical-align: middle;
  }
`;

const StyledSpan = styled.span`
  color: red;
  margin-top: 2px;
`;

export const StyledPrimaryButton = styled(PrimaryButtonIcon)`
  .p-button-icon {
    background-color: var(--white);
    color: var(--blue-dark);
    border-radius: 50%;
    padding: 3px;
  }
`;

const WatchlistRecurringTab = ({
  watchList = {},
  plan,
  notificationEmails,
  handleWatchListUpdate,
  isWatchlistWritable,
  customerPlanCredits,
}) => {
  const { messages } = useIntl();
  const currentDate = new Date();
  const tomorrow = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 1,
  );
  const RECCURANCE_DURATION = useMemo(() => {
    return {
      3: messages.watchlist_option_3_months,
      6: messages.watchlist_option_6_months,
      12: messages.watchlist_option_12_months,
      18: messages.watchlist_option_18_months,
    };
  }, [
    messages.watchlist_option_3_months,
    messages.watchlist_option_6_months,
    messages.watchlist_option_12_months,
    messages.watchlist_option_18_months,
  ]);

  const [recurringActivated, setRecurringActivated] = useState(
    watchList?.is_recurrent || false,
  );
  const [reccurenceDuration, setReccurenceDuration] = useState(
    watchList?.recurrent_duration
      ? RECCURANCE_DURATION[watchList?.recurrent_duration]
      : RECCURANCE_DURATION[3],
  );
  const [nextRecurrentDate, setNextRecurrentDate] = useState(
    watchList?.next_recurrent_date
      ? new Date(watchList?.next_recurrent_date)
      : tomorrow,
  );
  const [emailTags, setEmailTags] = useState(notificationEmails || []);
  const [email, setEmail] = useState("");
  const [confirmDeactivation, setConfirmDeactivation] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [creditsPerYear, setCreditsPerYear] = useState(0);
  const [validEmail, setValidEmail] = useState(true);
  const [requiredError, setRequiredError] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isEditCalendar = !watchList?.is_recurrent;

  useEffect(() => {
    setCreditsPerYear(
      (12 /
        Object.keys(RECCURANCE_DURATION).find(
          (key) => RECCURANCE_DURATION[key] === reccurenceDuration,
        )) *
        watchList?.watch_list_objects?.length,
    );
  }, [reccurenceDuration, watchList?.watch_list_objects, RECCURANCE_DURATION]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (editMode) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [editMode]);

  const handleRecurringActivation = () => {
    if (recurringActivated) {
      setConfirmDeactivation(true);
    } else {
      setRecurringActivated(!recurringActivated);
      setEditMode(true);
    }
  };

  const handleRecurringDurationChange = (setFieldTouched, event) => {
    setReccurenceDuration(event.value);
    setFieldTouched("reccurenceDuration", true);
  };

  const handleDateSelect = (setFieldTouched, event) => {
    setNextRecurrentDate(event.value);
    setFieldTouched("nextRecurrentDate", true);
  };

  const handleEmailAdd = () => {
    setValidEmail(true);
    setRequiredError(false);
    if (email.trim() !== "") {
      const isValid =
        emailRegex.test(email) && !emailTags.includes(email.trim());
      if (isValid) {
        setEmailTags([...emailTags, email.trim()]);
        setEmail("");
      } else {
        setValidEmail(isValid);
      }
    }
  };

  const handleEmailRemove = (setFieldTouched, event) => {
    const updatedEmailTags = emailTags.filter((t) => t !== event);
    setEmailTags(updatedEmailTags);
    setFieldTouched("emailTags", true);
  };

  const handleClosePopup = () => {
    setConfirmDeactivation(false);
  };

  const handleConfirmPopup = () => {
    setConfirmDeactivation(false);
    setRecurringActivated(!recurringActivated);
    setEditMode(true);
  };

  const handleSetEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleErrorPopup = () => {
    setShowErrorModal(false);
  };

  const handleSave = async () => {
    try {
      if (
        !recurringActivated ||
        (recurringActivated && emailTags?.length > 0)
      ) {
        const updatedWatchList = {
          ...watchList,
          is_recurrent: recurringActivated,
          next_recurrent_date: recurringActivated
            ? formatDate(nextRecurrentDate)
            : null,
          recurrent_duration: recurringActivated
            ? Object.keys(RECCURANCE_DURATION).find(
                (key) => RECCURANCE_DURATION[key] === reccurenceDuration,
              )
            : null,
          notification_emails: recurringActivated ? emailTags : null,
        };
        await updateWatchlist(watchList.id, updatedWatchList);
        handleWatchListUpdate(updatedWatchList);
        if (!recurringActivated) {
          setNextRecurrentDate(tomorrow);
          setEmailTags(notificationEmails);
          setReccurenceDuration(RECCURANCE_DURATION[3]);
        }
      } else if (recurringActivated && emailTags?.length < 1) {
        setRequiredError(true);
        return;
      }
      setEditMode(false);
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  };

  return (
    <Div>
      <Formik
        initialValues={{
          reccurenceDuration: reccurenceDuration,
          nextRecurrentDate: nextRecurrentDate,
          email: email,
        }}
      >
        {(formik) => {
          const { touched, setFieldTouched } = formik;
          return (
            <Form>
              <H3 mt={3} mb={3}>
                {messages.watchlist_title_recurring_tab}
              </H3>
              <Div mb={3}>
                <Text>{messages.watchlist_sub_title_recurring_tab}</Text>
              </Div>
              <StyledDiv>
                <Text display="flex" align-items="center" p={2}>
                  <Icon
                    name="info"
                    rounded={true}
                    ml={[0, 0, 1, 1]}
                    mr={2}
                    height={10}
                    width={10}
                  />
                  {messages.watchlist_info_recurring_tab}
                </Text>
              </StyledDiv>
              <StyledH4
                mt={3}
                mb={3}
              >{`${messages.watchlist_label_status}:`}</StyledH4>
              <Text>{messages.watchlist_title_recurring_toggle}</Text>
              <Div mt={3} display="flex" alignItems="center">
                <Switch
                  name={"recurringActivated"}
                  checked={recurringActivated}
                  onChange={handleRecurringActivation}
                  mr={3}
                  disabled={isWatchlistWritable}
                />
                <H4>
                  {recurringActivated
                    ? messages.watchlist_label_recurring_toggle_activate
                    : messages.watchlist_label_recurring_toggle_deactivate}
                </H4>
              </Div>
              <Div display="grid">
                {recurringActivated && (
                  <>
                    <StyledH4 mt={3} mb={3}>
                      {messages.watchlist_label_repeat}
                    </StyledH4>
                    <Text>{messages.watchlist_label_recurrence}</Text>
                    <Dropdown
                      mt={2}
                      mb={3}
                      name={"reccurenceDuration"}
                      options={Object.values(RECCURANCE_DURATION)}
                      onChange={handleRecurringDurationChange.bind(
                        this,
                        setFieldTouched,
                      )}
                      value={reccurenceDuration}
                      width={[1, 1, 350, 350]}
                      disabled={isWatchlistWritable}
                    />
                    {creditsPerYear > customerPlanCredits && (
                      <StyledDiv>
                        <Div p={3}>
                          <Div display="flex" align-items="center" mb={2}>
                            <SmallEllipseIconRed
                              name="sign"
                              rounded={true}
                              ml={[0, 0, 1, 1]}
                              mr={2}
                              height={10}
                              width={10}
                            />
                            <FormattedMessage
                              id="watchlist_recurrent_not_enough_credits"
                              values={{
                                plan: plan,
                                totalcredits: customerPlanCredits,
                                creditsperyear: creditsPerYear.toFixed(2),
                              }}
                            />
                          </Div>
                          <BulletList>
                            <li>
                              {
                                messages.watchlist_message_choose_another_frequency
                              }
                            </li>
                            <li>{messages.watchlist_label_upgrade_plan}</li>
                          </BulletList>
                        </Div>
                      </StyledDiv>
                    )}
                    <StyledH4 mt={3} mb={3}>
                      {messages.watchlist_title_choose_start_date}
                    </StyledH4>
                    <Text>
                      {!isEditCalendar
                        ? formatDate(nextRecurrentDate)
                        : messages.watchlist_label_choose_start_date}
                    </Text>
                    {isEditCalendar && (
                      <Calendar
                        mt={2}
                        id="nextRecurrentDate"
                        name="nextRecurrentDate"
                        showIcon
                        icon={<Icon name="calendar" />}
                        value={nextRecurrentDate}
                        onChange={handleDateSelect.bind(this, setFieldTouched)}
                        readOnlyInput
                        minDate={tomorrow}
                        width={[1, 1, 350, 350]}
                        dateFormat="yy-mm-dd"
                      />
                    )}
                    <StyledH4 mt={3} mb={3}>
                      {messages.watchlist_title_notifications}
                    </StyledH4>
                    <Text>{messages.watchlist_label_notifications}</Text>
                    {!isWatchlistWritable && (
                      <Div
                        display="flex"
                        width={[1, 1, 350, 350]}
                        position="relative"
                        mt={3}
                        mb={2}
                      >
                        <InputText
                          width={1}
                          height="36px"
                          placeholder={messages.watchlist_label_email}
                          value={email}
                          onChange={handleSetEmail}
                        />
                        <PrimaryButton
                          position="absolute"
                          right={0}
                          label={messages.watchlist_label_add}
                          onClick={handleEmailAdd}
                          height="36px"
                        />
                      </Div>
                    )}
                    {!validEmail && (
                      <StyledSpan>
                        <FormattedMessage
                          id="validation_valid_email_input"
                          defaultMessage="Invalid email"
                        />
                      </StyledSpan>
                    )}
                    {requiredError && (
                      <StyledSpan>
                        <FormattedMessage
                          id="validation_required_email_input"
                          defaultMessage="Email required"
                        />
                      </StyledSpan>
                    )}
                    <Div mt={!validEmail ? 2 : 0}>
                      {emailTags?.map((tag) => (
                        <Tag
                          name={"emailTags"}
                          tag={tag}
                          handleRemoveTag={handleEmailRemove.bind(
                            this,
                            setFieldTouched,
                          )}
                          showButton={!isWatchlistWritable}
                          key={tag}
                        />
                      ))}
                    </Div>
                  </>
                )}
                <H4 mt={3}>
                  <FormattedMessage
                    id="watchlist_label_cost_per_batch"
                    values={{
                      totalcredits: watchList?.watch_list_objects?.length,
                    }}
                  />
                </H4>
                {!isWatchlistWritable && (
                  <StyledPrimaryButton
                    mt={3}
                    label={messages.watchlist_label_save_plan}
                    icon={"icon-tick-mark"}
                    onClick={handleSave}
                    width={[0.5, 220]}
                    disabled={!(editMode || Object.keys(touched).length > 0)}
                  />
                )}
              </Div>
            </Form>
          );
        }}
      </Formik>
      {(confirmDeactivation || showErrorModal) && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleClosePopup}
          width={["90%", "90%", "90%", 445]}
        >
          {confirmDeactivation && (
            <RecurranceConfirmationModal
              onCloseModal={handleClosePopup}
              handleConfirmPopup={handleConfirmPopup}
            />
          )}
          {showErrorModal && (
            <ErrorModal
              handleConfirmPopup={handleErrorPopup}
              content={errorMessage}
            />
          )}
        </Dialog>
      )}
    </Div>
  );
};

WatchlistRecurringTab.propTypes = {
  watchList: PropTypes.object,
  plan: PropTypes.string,
  notificationEmails: PropTypes.array,
  handleWatchListUpdate: PropTypes.func,
  isWatchlistWritable: PropTypes.bool,
  customerPlanCredits: PropTypes.number,
};

export default WatchlistRecurringTab;
