import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import queryString from "query-string";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import { H2 as StyledH2, H3, H1, H5 as StyledH5 } from "@components/Heading";
import PersonDetails from "./PersonDetails";
import Deviations from "./Deviations";
import RiskStatement from "./RiskStatement";
import Method from "./Method";
import Recommendatations from "./Recommendation";
import StatementScale from "./StatementScale";
import { headingProps } from "./config";
import {
  Text as StyledText,
  TextMediumWeight as StyledTextMediumWeight,
} from "@components/Text";
import {
  REPORT_ADDRESS,
  REPORT_CONSULT_NUM,
  REPORT_CONSULT_EMAIL,
} from "@utils/constant";
import Icon from "@components/Icon";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";
import getResults from "@app/services/results/getResults";
import { trackPageView } from "@src/analyticsFunctions";
import { isCustomerUser } from "@utils/utils";
import getReportAnalys from "@app/services/reports/getReportDetails";

const StyledDiv = styled(Div)`
  page-break-before: always;
`;

const H2 = styled(StyledH2)`
  font-size: var(--fs-h4);
  line-height: var(--fs-text);
`;

const H5 = styled(StyledH5)`
  font-size: var(--fs-grade);
  font-weight: var(--semibold-weight);
`;

const Text = styled(StyledText)`
  font-size: var(--fs-grade) !important;
  line-height: 150%;
`;

const TextMediumWeight = styled(StyledTextMediumWeight)`
  font-size: var(--fs-text-s);
  line-height: 14px;
`;

export const ReportPagePdfContent = () => {
  const { messages } = useIntl();
  const [report, setReport] = useState({});
  const { title, subTitle, description } = headingProps;
  const { search = "" } = useLocation();
  const isLoggedIn = localStorage.getItem("token");
  const { product: _product = {} } = report;
  const { grade_mapping: gradeMapping = {} } = _product;

  const getdetails = async () => {
    try {
      const { id: reportId = "" } = queryString.parse(search);
      const response =
        isLoggedIn && isCustomerUser()
          ? await getReportAnalys(reportId)
          : await getResults(reportId);

      const { data: { data: _report = {} } = {} } = response;
      setReport(_report);
    } catch (_e) {
      //ignore this error
    }
  };

  const reloadDetails = () => {
    getdetails();
  };

  useEffect(() => {
    trackPageView();
    getdetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Div px={3}>
      <>
        <Div
          display={["flex", "flex", "flex", "none"]}
          alignItems="center"
          flexDirection="row"
        >
          <Div
            width={1}
            display={["flex", "flex", "flex", "none"]}
            flexDirection="column"
          >
            <H3
              color="var(--blue-dark) !important"
              fontSize="var(--fs-h5) !important"
              mx={[3, 3, 3, 64]}
              mb={2}
            >
              {messages[title]}
            </H3>
            <H1 mx={[3, 3, 3, 64]} mb={2} fontSize="var(--fs-h3) !important">
              {messages[subTitle]}
            </H1>
          </Div>
          <Div mr={3}>
            <Logo logo={LogoFull} width="auto" maxHeight="60px" />
          </Div>
        </Div>
        <Section mx="auto" p={"16px !important"}>
          <Container bg={"white !important"} py={"0 !important"}>
            <Div display={["none", "none", "none", "flex"]} alignItems="center">
              <Div
                width={1}
                display={["none", "none", "none", "flex"]}
                flexDirection="column"
              >
                <H3 color="var(--blue-dark) !important" my={3}>
                  {messages[title]}
                </H3>
                <H1 mb={24}>{messages[subTitle]}</H1>
              </Div>
              <Div>
                <Logo logo={LogoFull} width="auto" maxHeight="60px" />
              </Div>
            </Div>
            <Div px={20} py={15} bg={"var(--yellow-light)"} display="block">
              <TextMediumWeight>{messages[description]}</TextMediumWeight>
            </Div>
          </Container>
        </Section>
      </>
      <Section
        mx="auto"
        pb={"0px !important"}
        px={"16px !important"}
        pt="8px !important"
      >
        <Container>
          <Div>
            <PersonDetails
              report={report}
              isPrintable
              config={gradeMapping.personalDetails}
            />
            <RiskStatement report={report} isPrintable config={gradeMapping} />
            <Div mt={3} pt={2}>
              <H2 color="var(--blue-dark) !important">
                {messages.title_recommendations}
              </H2>
              <Recommendatations isPrintable />
            </Div>
            <Div>
              <H2 color="var(--blue-dark) !important" mt={3}>
                {messages.title_method}
              </H2>
              <Method isPrintable />
            </Div>

            <StyledDiv>
              <Deviations
                report={report}
                isPrintable
                reloadDetails={reloadDetails}
              />
            </StyledDiv>
          </Div>
        </Container>
      </Section>
      <Section mx="auto" px={"16px !important"} pt={"0px !important"}>
        <Container>
          <Div>
            <StyledDiv>
              <H2 color="var(--blue-dark) !important">
                {messages.title_statement_scale}
              </H2>
            </StyledDiv>
            <StatementScale isPrintable config={gradeMapping} />
          </Div>
          <Div>
            <H2 color="var(--blue-dark) !important" mt={3} mb={2}>
              {messages.title_contact_details}
            </H2>
            <Div display="flex" mb={1}>
              <H5 mr={1}>{messages.label_address}:</H5>
              <Text>{REPORT_ADDRESS}</Text>
            </Div>
            <Div display="flex">
              <H5 mr={1}>{messages.contact}:</H5>
              <Icon name="call" alt="icon" mr={1} />
              <Text>{REPORT_CONSULT_NUM}</Text>
              <Icon name="email" alt="icon" ml={2} mr={1} />
              <Text>{REPORT_CONSULT_EMAIL}</Text>
            </Div>
          </Div>
        </Container>
      </Section>
    </Div>
  );
};

export default ReportPagePdfContent;
