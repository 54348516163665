import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Proptypes from "prop-types";
import Div from "@components/Div";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import {
  MYACCOUNTS_NAV_SIDEBAR,
  INDIVIDUAL,
  COMPANY,
  GRADES,
} from "@utils/constant";
import { authActions } from "@app/reducers/authReducer";
import getUserDetails from "@app/services/auth/getUserDetails";
import Config from "@src/config";
import { TextMediumWeight } from "@components/Text";

import SideMenuContent from "./SideMenuContent";
import { BACKGROUND_SE } from "../utils/constant";

export const StyledDiv = styled(Div)`
  display: flex;
`;
const handleRedirect = () => {
  window.location = `${Config.WP_URL}/`;
};

const LogoComponent = () => (
  <Div mt={3} mb={4}>
    <Logo
      display="block"
      logo={LogoFull}
      width={140}
      height={"auto"}
      mx={3}
      py={4}
      onClick={handleRedirect}
    />
  </Div>
);

export const CustomerLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state) => state.authReducer);
  const { customer = {}, permissions = [] } = userInfo;
  const { type = INDIVIDUAL, prepaid_account: isPrepaidUser } = customer;

  let myAccountsNav = MYACCOUNTS_NAV_SIDEBAR.filter((i) => {
    if (type === INDIVIDUAL) {
      if (isPrepaidUser === 1) {
        return i.label !== "FÖRETAGSINFO" && i.label !== "ANVÄNDARE";
      } else {
        return !(
          i.label === "ABONNEMANG" ||
          i.label === "FÖRETAGSINFO" ||
          i.label === "ANVÄNDARE"
        );
      }
    }
    if (type === COMPANY && isPrepaidUser === 0) {
      return i.label !== "ABONNEMANG";
    }
    return i;
  });

  if (customer.status !== "active") {
    myAccountsNav = myAccountsNav.filter((i) => i.label !== "BEVAKNING");
  }

  const availablePages = Object.keys(permissions).filter(
    (obj) => permissions[obj] !== GRADES,
  );
  availablePages.push("search");
  availablePages.push("user-info");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getUserDetails();
        const { data: { data: user = {} } = {}, status = "" } = response;
        if (status === 401) {
          localStorage.clear();
          dispatch(authActions.storeUserInfo({ user: {}, isLogout: true }));
        } else {
          dispatch(authActions.storeUserInfo(user));
        }
      } catch (e) {
        //catch the error
      }
    };

    if (!userInfo.id) {
      fetchUserInfo();
    }
  }, [dispatch, userInfo]);

  let menuItems = myAccountsNav.filter((obj) =>
    availablePages.includes(obj.value),
  );

  if (
    availablePages.includes("self-orders") &&
    !availablePages.includes("orders")
  ) {
    menuItems = [
      ...menuItems,
      ...[
        {
          label: "BESTÄLLNINGAR",
          value: "orders",
          redirectTo: "/mittkonto/bestallningar",
        },
      ],
    ];
  }

  const prop = {
    Component: LogoComponent,
    navItems: [...menuItems],
  };

  return (
    <Div display="flex">
      <Div
        width={[1, 1, 1, "192px"]}
        minHeight="100vh"
        minWidth={192}
        display={["none", "none", "none", "block"]}
        border={"2px solid var(--grey-lightest)"}
      >
        <Div
          position="fixed"
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <SideMenuContent {...prop} />
          <Div mb={"50px"}>
            <LinkArrow
              height={44}
              px={2}
              display={"flex"}
              href={`${Config.WP_URL}/kontakta-oss/`}
              target="_blank"
              rel="noreferrer"
            >
              <TextMediumWeight
                wordBreak="break-all"
                fontSize={"14px !important"}
              >
                {"Support"}
              </TextMediumWeight>
            </LinkArrow>
            <LinkArrow
              height={46}
              px={2}
              display={"flex"}
              href={`${BACKGROUND_SE}`}
              target="_blank"
              rel="noreferrer"
            >
              <TextMediumWeight
                wordBreak="break-all"
                fontSize={"14px !important"}
              >
                {"BA Website"}
              </TextMediumWeight>
              <Icon name="Arrow-diagonal" ml={2} />
            </LinkArrow>
          </Div>
        </Div>
      </Div>
      <Div width={1} backgroundColor={"var(--site-background-color)"}>
        {children}
      </Div>
    </Div>
  );
};

CustomerLayout.propTypes = {
  children: Proptypes.node,
};

export default CustomerLayout;
