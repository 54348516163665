import React from "react";
import PropTypes from "prop-types";

import Checkbox from "@components/Checkbox";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";

import InputWrapper from "./InputWrapper";

const InputCheckGroup = (props) => {
  const { name, groupName, value, onChange, options } = props;

  return (
    <InputWrapper {...props}>
      <Div mt={3} display="flex" flexDirection="column" gridGap={2}>
        {options?.map((option) => (
          <Div
            key={`${name}.${option.value}`}
            display="flex"
            alignItems="center"
            gridGap={2}
          >
            <Checkbox
              inputId={`${name}.${option.value}`}
              name={`${name}.${option.value}`}
              onChange={onChange}
              checked={value?.[groupName]?.[option.value]}
            />
            <TextMediumWeight>{option.label}</TextMediumWeight>
          </Div>
        ))}
      </Div>
    </InputWrapper>
  );
};

InputCheckGroup.propTypes = {
  name: PropTypes.string,
  groupName: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default InputCheckGroup;
