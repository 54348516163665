import React, { useEffect, useState } from "react";
import { PrimaryButtonIcon } from "@components/Button";
import {
  TextLargeSemiBoldWeight,
  Text,
  TextSemiBoldWeight,
} from "@components/Text";
import Card from "@components/Card";
import { H2 } from "@components/Heading";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import Section from "@components/Section";
import {
  loadServicesData,
  redirectOnSubmit,
  calculateSingleAnalysisPrice,
} from "@utils/common";
import { useHistory } from "react-router-dom";
import { SPECIAL_PACKAGE, SUBSCRIPTION_STATUS } from "@utils/constant";
const SubscriptionPackages = (prop) => {
  const { messages } = useIntl();
  const {
    activePackage: { product_id = "", status = "" },
    activePackage = "",
  } = prop;
  const history = useHistory();
  const [subsPackages, setSubsPackages] = useState([]);
  useEffect(() => {
    const { customer = {} } = prop.userInfo;
    const { id: customer_id = "" } = customer;
    let package_list = loadServicesData(customer_id);
    package_list.then((result) => {
      setSubsPackages(result);
      prop.subscriptionListLoaded();
    });
  }, [prop]);
  return (
    <Section px={2} py={3}>
      <Div display="flex" flexWrap="wrap">
        {subsPackages.length > 0
          ? subsPackages.map((subscriptionPackage, key) => {
              const {
                id,
                name,
                credits,
                price = "",
                discount,
              } = subscriptionPackage;
              if (name !== SPECIAL_PACKAGE) {
                return (
                  <Div pr={[0, 0, 0, 4]} width={[1, 1, 0.5]} key={key}>
                    <Card>
                      <Div
                        textAlign={["center"]}
                        py={22}
                        pl={3}
                        mb={3}
                        backgroundColor="var(--turquoise-light)"
                      >
                        <H2>{name}</H2>
                      </Div>

                      <Div mb={4}>
                        <Div
                          px={4}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Div mb={3}>
                            <TextLargeSemiBoldWeight display="block" mt={1}>
                              {messages.analysiss}
                            </TextLargeSemiBoldWeight>
                          </Div>
                          <Div display="flex" flexDirection="column">
                            <TextLargeSemiBoldWeight
                              pb={1}
                              color="var(--blue-dark) !important"
                            >
                              {credits}
                            </TextLargeSemiBoldWeight>
                          </Div>
                        </Div>
                        <Div
                          px={4}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Div mb={3}>
                            <TextLargeSemiBoldWeight display="block" mt={1}>
                              {messages.pricee}
                            </TextLargeSemiBoldWeight>
                          </Div>
                          <Div display="flex" flexDirection="column">
                            <TextLargeSemiBoldWeight
                              pb={1}
                              color="var(--blue-dark) !important"
                            >
                              {`${price?.toLocaleString("sv-SE")} ${
                                messages.price_currency
                              }`}
                            </TextLargeSemiBoldWeight>
                          </Div>
                        </Div>
                        <Div
                          textAlign={["center"]}
                          py={3}
                          backgroundColor="var(--grey-lighter)"
                        >
                          <Text>
                            {`${calculateSingleAnalysisPrice(price, credits)} ${
                              messages.price_currency
                            } ${messages.static_styck.toLowerCase()}`}
                          </Text>
                          <TextSemiBoldWeight display="block" mt={2}>
                            {`${messages.subscription_discount_for_analys}: ${discount} %`}
                          </TextSemiBoldWeight>
                        </Div>
                        <Div py={3} textAlign={["center"]}>
                          {activePackage &&
                          product_id &&
                          product_id === id &&
                          status !== SUBSCRIPTION_STATUS.TERMINATED ? (
                            <PrimaryButtonIcon
                              minHeight="40px"
                              width={[1, 1, 0.5]}
                            >
                              {messages.current}
                            </PrimaryButtonIcon>
                          ) : (
                            <PrimaryButtonIcon
                              minHeight="40px"
                              width={[1, 1, 0.5]}
                              onClick={redirectOnSubmit.bind(this, id, history)}
                            >
                              {`${messages.buy} ${price?.toLocaleString(
                                "sv-SE",
                              )} ${messages.price_currency}`}
                            </PrimaryButtonIcon>
                          )}
                        </Div>
                      </Div>
                    </Card>
                  </Div>
                );
              }
            })
          : prop.pageSectionShow && (
              <Div pr={[0, 0, 0, 4]} width={[1, 1, 0.5]}>
                {messages.no_payment_received_yet}
              </Div>
            )}
      </Div>
    </Section>
  );
};
export default SubscriptionPackages;
