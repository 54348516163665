import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { authActions } from "@app/reducers/authReducer";

import getUserDetails from "@app/services/auth/getUserDetails";

import { LOGIN } from "@utils/constant";
import { isAuthenticated } from "@utils/utils";

const INITIAL_USER_INFO = {
  user: {},
  isLogout: true,
};

export const UserInfoContext = createContext({
  userInfo: INITIAL_USER_INFO,
  initializeUserInfo: () => {},
});

export const UserInfoProvider = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState(INITIAL_USER_INFO);

  const clearUserInfo = useCallback(() => {
    setUserInfo(INITIAL_USER_INFO);

    dispatch(authActions.storeUserInfo(INITIAL_USER_INFO));
  }, [dispatch]);

  const initializeUserInfo = useCallback(async () => {
    if (!isAuthenticated()) {
      clearUserInfo();

      return;
    }

    try {
      const { data: { data: userData = {} } = {}, status = "" } =
        await getUserDetails();

      if (status === 401) {
        clearUserInfo();

        localStorage.clear();

        history.push(LOGIN);

        return;
      }

      setUserInfo(userData);

      dispatch(authActions.storeUserInfo(userData));
    } catch (error) {
      clearUserInfo();
    }
  }, [clearUserInfo, dispatch, history]);

  useEffect(() => {
    initializeUserInfo();
  }, [initializeUserInfo]);

  const value = useMemo(
    () => ({
      userInfo,
      initializeUserInfo,
    }),
    [userInfo, initializeUserInfo],
  );

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
};

UserInfoProvider.propTypes = {
  children: PropTypes.node,
};
