import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Tabs from "@components/Tabs";
import UnBilledAccounts from "./UnBilledAccounts";
import BillingAccounts from "./BillingAccounts";
import Invoices from "./Invoices";
import { ROUTES } from "@utils/constant";
import AdminContainer from "@layout/AdminContainer";

const List = () => {
  const { messages } = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();

  const billingtabContent = [
    {
      title: messages.label_not_invoiced,
      content: <UnBilledAccounts />,
      url: `${ROUTES.ADMIN_UNBILLED_ACCOUNTS.URL}`,
    },
    {
      title: messages.subscription_order_invoice,
      content: <Invoices />,
      url: `${ROUTES.ADMIN_INVOICE_ACCOUNTS.URL}`,
    },
    {
      title: messages.title_billing_accounts,
      content: <BillingAccounts />,
      url: `${ROUTES.ADMIN_BILLING_ACCOUNTS.URL}`,
    },
  ];

  useEffect(() => {
    const path = history.location.pathname;
    const [unbilledAcc, invoices, billingAcc] = billingtabContent;
    let indexValue;
    switch (path) {
      case unbilledAcc?.url:
        indexValue = 0;
        break;
      case invoices?.url:
        indexValue = 1;
        break;
      case billingAcc?.url:
        indexValue = 2;
        break;
      default:
        indexValue = 0;
    }
    if (indexValue !== activeIndex) {
      setActiveIndex(indexValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const handleOnTabChange = (e) => {
    if (e?.index !== activeIndex) {
      history.push({
        pathname: billingtabContent[e.index]?.url,
        search: "",
      });
    }
  };

  const headerConfig = {
    title: messages.invoices,
  };

  return (
    <AdminContainer config={headerConfig}>
      <Tabs
        content={billingtabContent}
        onTabChange={handleOnTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default List;
