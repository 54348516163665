import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { H1 } from "@components/Heading";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";
import Form from "./Form";
import ProgressSpinner from "@components/ProgressSpinner";
import createNewProduct from "@app/services/services/createNewProduct";
import { ANALYSIS, BUNDLE, ROUTES } from "@utils/constant";
import getDataSources from "@app/services/products/getDataSources";

const CreateService = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);

  useEffect(() => {
    const fetchDataSources = async () => {
      setIsLoading(true);

      try {
        const { data: response = [] } = await getDataSources();
        setDataSourceTypes(
          response.map((o) => {
            return { name: messages[`datasource_${o}`], code: o };
          }),
        );
        setIsLoading(false);
      } catch (e) {
        //catch error
        setIsLoading(false);
      }
    };
    fetchDataSources();
  }, [messages]);

  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: messages.title_create_service,
    },
  ];

  const handleCreateService = async (values) => {
    setIsLoading(true);
    const { service_type } = values;
    let payload;
    const { data_source, analysis_type, child, ...otherProperties } = values;

    if (service_type === BUNDLE) {
      payload = { ...otherProperties, child: child.map((o) => o.code) };
    } else if (service_type === ANALYSIS) {
      payload = {
        ...otherProperties,
        data_source: data_source.map((o) => o.code),
        analysis_type,
      };
    } else {
      payload = { ...otherProperties, analysis_type: "manual" };
    }

    try {
      await createNewProduct(payload);
      history.push(ROUTES.ADMIN_PRODUCTS_LIST.URL);
    } catch (e) {
      //catch error;
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <Div px={2} py={3}>
          <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 10]} />
          <Div
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width={[1, 1, 1, 800]}
          >
            <H1>{messages.title_create_service}</H1>
            {!isLoading && (
              <Form
                onSubmit={handleCreateService}
                dataSourceTypes={dataSourceTypes}
              />
            )}
          </Div>
        </Div>
      )}
    </>
  );
};

export default CreateService;
