import React, { useState } from "react";
import { useIntl } from "react-intl";

import ErrorDialog from "@components/ErrorDialog";
import DataTable from "@components/DataTableV2/DataTable";

const SEARCHABLE_FIELDS = ["id", "from", "to"];

const EmailLogListTab = () => {
  const { messages } = useIntl();

  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const dataTableConfig = {
    header: {
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    is_filter_options_updated: true,
    filters: [
      {
        title: messages.label_date,
        id: "filter[created_at]",
        type: "calendar",
        options: [
          {
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
            label: messages.calendar_date_select_placeholder,
            value: "",
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[direction]",
        type: "checkbox",
        options: [],
      },
    ],
    enable_csv_download: false,
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: "/email-messages",
      method: "GET",
      search_fields: SEARCHABLE_FIELDS,
    },
    default_sort: {
      field: "created_at",
      order: "desc",
    },
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "text",
        formatter: (value) => (value ? `#${value}` : "-"),
        with: "8%",
      },
      {
        db_field: "created_at",
        title: "label_sent",
        type: "date",
        sortable: true,
      },
      {
        db_field: "from",
        title: "filter_date_from",
        type: "text",
      },
      {
        db_field: "to",
        title: "filter_date_to",
        type: "text",
      },
      {
        db_field: "subject",
        title: "label_subject",
        type: "text",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: "Email details",
            icon: "icon-headerarrowright",
            type: "link",
            href: (value) => `${value}`,
          },
        ],
      },
    ],
  };

  return (
    <>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <DataTable config={dataTableConfig} />
    </>
  );
};

export default EmailLogListTab;
