import React from "react";
import PropTypes from "prop-types";
// import Container from "@components/Container";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { ROUTES, ORDERS_API } from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";

const searchableColumns = [
  "id",
  "number",
  "customer.customer_name",
  "user.firstname",
  "user.lastname",
];

const Orders = ({ customerId }) => {
  const { messages } = useIntl();
  const fetchUserName = (orderItems) => {
    const personNames = orderItems.map((i) => {
      return i.person_initials;
    });
    const uniqueUsers = [...new Set(personNames)];
    const usersLength = uniqueUsers.length;
    if (!usersLength) return "-";
    if (uniqueUsers[0] !== "") {
      if (usersLength === 1) return uniqueUsers[0];
      else return `${uniqueUsers[0]} + ${usersLength - 1}`;
    } else return "-";
  };

  const config = {
    header: {
      title: "label_order_page",
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: "orders.csv",
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_customer_orders_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ORDERS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          ["id", "created_at", "total_price", "customer_id", "user_id"],
        ],
        ["include", ["customer", "user", "orderItems", "payments"]],
        ["filter[customer_id]", customerId],
        ["filter[type]", "PO"],
      ],
    },
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "number",
        type: "id",
        className: "text-blue",
        width: "100px",
        href: (values) => {
          const { id } = values;
          return `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${id}`;
        },
        sortable: true,
        sortField: "id",
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "text",
        sortable: true,
        formatter: (value) => {
          return value ? formatDateAndTime(value) : "-";
        },
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
      },
      {
        title: "title_person",
        db_field: "order_items",
        type: "text",
        formatter: (value) => {
          return fetchUserName(value);
        },
      },
      {
        title: "label_service",
        db_field: "order_items",
        type: "text",
        formatter: (value) => {
          const totalOrderItems = value?.length;
          return totalOrderItems > 1
            ? `${value[0]?.name} + ${totalOrderItems - 1}`
            : value[0]?.name;
        },
      },
      {
        title: "watchlist_label_price",
        db_field: "total_price",
        type: "text",
        formatter: (value) => {
          return value ? `${value} kr` : "0 kr";
        },
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: ({ id }) => `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${id}`,
          },
        ],
      },
    ],
  };

  return <DataTable config={config} />;
};

Orders.propTypes = {
  customerId: PropTypes.string,
};
export default Orders;
