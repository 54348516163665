import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { H2, H5 as StyledH5 } from "@components/Heading";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";
import Span from "@components/Span";
import Div from "@components/Div";
import { deviationProps } from "../config";
import Icon from "@components/Icon";
import Link from "@components/Link";
import {
  CRIMINAL_CASES,
  SUMMARY_IMPOSITION_FINE,
  INDICTMENT,
  JUDGMENTS_CIVIL_LAW,
  RULING_RENT_TRIBUNAL,
  INVOLMENT_BUSINESSES,
  BANKRUPTCIES,
  NUMBER_OF_VECHICLES,
  ECONOMY,
  NUMBER_OF_MOVES,
  SMALL_CLAIM_CASES,
  RULING_ADMIN_COURT,
  RULING_ADMIN_COURT_APPEAL,
  CIVIL_DISPUTE,
  CONFIDENTIALITY_MARKINGS,
  CITIZENSHIP,
  COMPANY,
} from "@utils/constant";
import { formatYear, isAuthenticated, isCustomerUser } from "@utils/utils";
import Dialog from "@components/Dialog";
import OrderReportModal from "../OrderReportModal";
import { compose, space } from "styled-system";

const StyledDiv = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const StyledUnorderList = styled.ul`
  ${compose(space)}
  list-style: disc;
  margin-left: 16px;
`;

const Deviations = ({
  report = {},
  reloadDetails = () => {},
  isAnalysisDetailPage = false,
  isPrintable = false,
}) => {
  const { messages } = useIntl();
  const { deviation = {} } = report || {};
  const { id: reportID = "", credit_report = {} } = report;
  const { customer = {} } = useSelector((state) => state.authReducer.userInfo);
  const { type = "" } = customer;
  const isAuthenticatedFlag = isAuthenticated();
  const userLogin = isAuthenticatedFlag && !isAnalysisDetailPage;
  const { sectionTitle, descriptionRight, descriptionLeft, footer } =
    deviationProps;
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [deviationOpen, setDeviationOpen] = useState(isPrintable || false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isLeftContent, setIsLeftContent] = useState(false);

  const handleExpandAccordion = (index, leftContent) => {
    setActiveIndex(activeIndex === index ? -1 : index);
    setIsLeftContent(leftContent);
  };

  const toggleDeviation = () => {
    setDeviationOpen(!deviationOpen);
    setActiveIndex(-1);
  };

  const H5 = styled(StyledH5)`
    line-height: ${isPrintable && "150% !important"};
    font-size: ${isPrintable ? "var(--fs-text-s)" : "var(--fs-h5)"};
  `;

  const StyledIcon = styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: ${isPrintable ? "24px" : "36px"};
    height: ${isPrintable ? "24px" : "36px"};
    font-size: ${isPrintable && "10px"};
    margin-bottom: 16px;
  `;

  const handleConfirmPopup = () => {
    setConfirmPopup(!confirmPopup);
  };

  const judgmentsCivilLaw = (value) => {
    const count = value.count;
    switch (value.keyname) {
      case SMALL_CLAIM_CASES:
        return count === 1 ? (
          <StyledDiv>
            <H5>{`${messages.text_occurs_in} ${count} ${messages.text_simplified_litigation_singular}`}</H5>
          </StyledDiv>
        ) : count > 1 ? (
          <StyledDiv>
            <H5>
              {`${messages.text_occurs_in} ${count} ${messages.text_simplified_litigation}`}
            </H5>
          </StyledDiv>
        ) : null;
      case RULING_ADMIN_COURT:
        return count === 1 ? (
          <StyledDiv>
            <H5>
              {`${messages.text_occurs_in} ${count} ${messages.text_case_in_administrative_law}`}
            </H5>
          </StyledDiv>
        ) : count > 1 ? (
          <StyledDiv>
            <H5>
              {`${messages.text_occurs_in} ${count} ${messages.text_cases_in_administrative_law}`}
            </H5>
          </StyledDiv>
        ) : null;
      case RULING_ADMIN_COURT_APPEAL:
        return count === 1 ? (
          <StyledDiv>
            <H5>
              {`${messages.text_occurs_in} ${count} ${messages.text_case_in_the_court_appeal}`}
            </H5>
          </StyledDiv>
        ) : count > 1 ? (
          <StyledDiv>
            <H5>
              {`${messages.text_occurs_in} ${count} ${messages.text_cases_in_the_court_appeal}`}
            </H5>
          </StyledDiv>
        ) : null;
      case CIVIL_DISPUTE:
        return count >= 1 ? (
          <StyledDiv>
            <H5>
              {messages.text_occurs_in} {count} {messages.text_litigation}
            </H5>
          </StyledDiv>
        ) : null;
      default:
        return "";
    }
  };

  const crimeList = (crimeCases) => {
    const newCrimeList = [];
    crimeCases.map((o) => {
      const { crime = [], date = "", case_date = "", case_nr = "" } = o;
      if (Array.isArray(crime)) {
        crime.map((obj) => {
          newCrimeList.push(
            `${obj} (${formatYear(date || case_date)}), ${case_nr}`,
          );
          return obj;
        });
      } else {
        newCrimeList.push(
          `${crime} (${formatYear(date || case_date)}), ${case_nr}`,
        );
      }
      return o;
    });
    return newCrimeList;
  };

  const deviationInformation = (value = "", data = {}) => {
    const cases = Array.isArray(data.cases)
      ? data.cases
      : Object.values(data.cases || {});
    const {
      has_criminal_case = "",
      value: deviationValue = "",
      count: deviationCount = "",
    } = data;
    const [caseOne = {}, caseTwo = {}] = cases || [];
    const { has_blacklisted_company: isBlacklistedCompany = false } = caseTwo;
    const { hover_value: hoverValue = 0 } = caseOne;
    const caseList = cases?.flat();
    let maxTextLength = false;
    caseList?.forEach((element) => {
      if (element.length > 50) {
        maxTextLength = true;
      }
    });
    switch (value) {
      case CRIMINAL_CASES:
        if (deviationValue === 0) {
          return (
            <H5 mb={isPrintable ? 2 : 14}>
              {messages.text_no_crime_convicted}
            </H5>
          );
        } else {
          return maxTextLength ? (
            <H5>{messages.text_content_cannot_be_read}</H5>
          ) : (
            <>
              <StyledUnorderList>
                {caseList?.length &&
                  caseList
                    .filter(
                      (value) => value.crime !== "" && value.crime !== null,
                    )
                    .map((o, i) => (
                      <>
                        {i < 1 && (
                          <H5
                            mb={isPrintable ? 1 : 2}
                            ml="-18px"
                            display={"block"}
                            fontWeight={"var(--light-weight) !important"}
                          >
                            {messages.text_person_conviction_list}
                          </H5>
                        )}
                        <li key={`o-${[i]}`}>
                          <H5 mb={1}>{`${o?.crime?.join(
                            ", ",
                          )} (${o?.date?.slice(0, -6)}) B ${
                            o?.case_nr
                          }, ${o?.court?.charAt(0)?.toUpperCase()}${o?.court
                            ?.slice(1)
                            ?.toLowerCase()}`}</H5>
                        </li>
                        <>
                          {isCustomerUser() && o.mail_to_link && (
                            <Link
                              iconPos="left"
                              label={messages.label_request_mail}
                              fontSize="14px"
                              mb={isPrintable ? 1 : 2}
                              href={o.mail_to_link}
                            >
                              <Span pr={1}>
                                <Icon name="file-download" />
                              </Span>
                            </Link>
                          )}
                        </>
                      </>
                    ))}
              </StyledUnorderList>
              <StyledUnorderList my={3}>
                {caseList?.length &&
                  caseList
                    .filter(
                      (value) => value.crime === "" || value.crime === null,
                    )
                    .map((o, i) => (
                      <>
                        {i < 1 && (
                          <H5
                            ml="-18px"
                            mb={isPrintable ? 1 : 2}
                            display={"block"}
                            fontWeight={"var(--light-weight) !important"}
                          >
                            {messages.text_bottom_line_judgement}
                          </H5>
                        )}
                        <li key={`o-${[i]}`}>
                          <H5 mb={1}>{`${
                            o?.crime === "" ? messages.text_target_number : ""
                          } B ${o?.case_nr}, ${o?.court
                            ?.charAt(0)
                            ?.toUpperCase()}${o?.court
                            ?.slice(1)
                            ?.toLowerCase()}`}</H5>
                        </li>
                        <>
                          {isCustomerUser() && o.mail_to_link && (
                            <Link
                              iconPos="left"
                              label={messages.label_request_mail}
                              fontSize="14px"
                              mb={2}
                              href={o.mail_to_link}
                            >
                              <Span pr={1}>
                                <Icon name="file-download" />
                              </Span>
                            </Link>
                          )}
                        </>
                      </>
                    ))}
              </StyledUnorderList>
            </>
          );
        }
      case SUMMARY_IMPOSITION_FINE:
        if (deviationValue === 0) {
          return <H5>{messages.text_no_criminal_injunction}</H5>;
        } else {
          return (
            <>
              <H5
                mb={isPrintable ? 1 : 2}
                display={"block"}
                fontWeight={"var(--light-weight) !important"}
              >
                {messages.text_penalties_list}
              </H5>
              <StyledUnorderList>
                {crimeList(caseList).map((o, i) => (
                  <li key={i}>
                    <H5 mb={1}>{o}</H5>
                  </li>
                ))}
              </StyledUnorderList>
            </>
          );
        }
      case INDICTMENT:
        if (deviationValue === 0) {
          return <H5>{messages.text_not_prosecuted}</H5>;
        }
        if (has_criminal_case || deviationValue >= 1) {
          return (
            <>
              <H5
                mb={isPrintable ? 1 : 2}
                display={"block"}
                fontWeight={"var(--light-weight) !important"}
              >
                {messages.charges_filed_indiciment}
              </H5>
              <StyledUnorderList>
                {caseList.map((o, i) => (
                  <li key={i}>
                    <H5 mb={1}>{`${o?.crime} (${o?.case_date?.slice(
                      0,
                      -6,
                    )}) B ${o?.case_nr}${o.authority ? "," : ""} ${
                      o.authority ? o.authority : ""
                    }`}</H5>
                  </li>
                ))}
              </StyledUnorderList>
            </>
          );
        }
        return;
      case RULING_RENT_TRIBUNAL:
        return (
          <>
            {deviationCount >= 1 ? (
              <Div display="flex" flexDirection="column" flexWrap="wrap">
                <H5>{`${messages.text_occurs_in} ${deviationCount} ${messages.text_case_with_the_rent_tribunal}`}</H5>
                {caseList.map((obj, i) => (
                  <H5 key={i}>
                    {messages.text_target_number} {obj.case_nr}
                  </H5>
                ))}
              </Div>
            ) : (
              <H5>
                {messages.text_does_not_appear_in_a_case_with_the_rent_tribunal}
              </H5>
            )}
          </>
        );
      case BANKRUPTCIES:
        return (
          <>
            {deviationCount === 1 && (
              <H5>
                {`${messages.text_occurs_with} ${deviationCount} ${messages.text_singular_bankruptcy}`}
              </H5>
            )}
            {deviationCount > 1 && (
              <H5>
                {`${messages.text_occurs_with} ${deviationCount} ${messages.text_multiple_bankruptcy}`}
              </H5>
            )}
            {!deviationCount && (
              <H5>{messages.text_does_not_occur_with_any_bankruptcy}</H5>
            )}
          </>
        );

      case NUMBER_OF_MOVES:
        return (
          <>
            {deviationCount >= 10 && (
              <H5>{messages.text_strong_deviating_moving_pattern}</H5>
            )}
            {deviationCount >= 6 && deviationCount < 10 && (
              <H5>{messages.text_deviating_moving_pattern}</H5>
            )}
            {(!deviationCount || deviationCount < 6) && (
              <H5>{messages.text_normal_moving_pattern}</H5>
            )}
          </>
        );
      case NUMBER_OF_VECHICLES:
        return deviationCount >= 1 ? (
          <H5>{`${deviationCount} ${messages.text_vehicles_connected_to_the_address}`}</H5>
        ) : (
          <H5>{messages.text_no_deviating_vehicle_possession}</H5>
        );
      case ECONOMY:
        return deviationValue >= 1 ? (
          <Div>
            <H5 mb={isPrintable ? 1 : 2}>
              <FormattedMessage
                id="text_financial_deviations_exist"
                defaultMessage="Financial deviations exist"
                values={{ break: <br /> }}
              />
            </H5>
          </Div>
        ) : (
          <H5>
            <FormattedMessage
              id="text_no_financial_deviation"
              defaultMessage="No financial deviation"
              values={{ break: <br /> }}
            />
          </H5>
        );
      case INVOLMENT_BUSINESSES: {
        if (isBlacklistedCompany) {
          const companyNames = cases[1].company_names
            ? JSON.parse(cases[1].company_names)
            : [];
          return (
            <>
              {companyNames.length > 0 ? (
                <>
                  <H5
                    mb={isPrintable ? 1 : 2}
                    display={"block"}
                    fontWeight={"var(--light-weight) !important"}
                  >
                    {messages.text_involvement_in_blacklisted_company}
                  </H5>
                  <StyledUnorderList>
                    {companyNames.map((o, i) => (
                      <li key={i}>
                        <H5>{o}</H5>
                      </li>
                    ))}
                  </StyledUnorderList>
                </>
              ) : (
                <H5>{messages.text_involvement_in_blacklisted_company}</H5>
              )}
            </>
          );
        }
        if (hoverValue >= 1) {
          return (
            <>
              <H5
                mb={isPrintable ? 1 : 2}
                display={"block"}
                fontWeight={"var(--light-weight) !important"}
              >
                {messages.text_association_involvement}
              </H5>
              <StyledUnorderList>
                {caseList[0].cases.map((o, i) => (
                  <li key={i}>
                    <H5 mb={1}>{o}</H5>
                  </li>
                ))}
              </StyledUnorderList>
            </>
          );
        } else {
          return <H5>{messages.text_no_company_involvement}</H5>;
        }
      }
      case JUDGMENTS_CIVIL_LAW:
        if (!deviationValue) {
          return <H5>{messages.text_does_not_appear_in_a_civil_judgment}</H5>;
        } else {
          return (
            <StyledUnorderList>
              {caseList.length > 0 &&
                caseList.map((value, i) => {
                  const civilLaw = judgmentsCivilLaw(value, data);
                  return civilLaw ? (
                    <li key={i}>
                      <H5 mb={1}>{civilLaw}</H5>
                    </li>
                  ) : null;
                })}
            </StyledUnorderList>
          );
        }
      case CONFIDENTIALITY_MARKINGS:
        return <H5>{messages.text_no_privacy_mark}</H5>;
      case CITIZENSHIP:
        return <H5>{messages.text_swidesh_citizenship}</H5>;
    }
  };

  const getEconomyDialog = (value) => {
    if (value.includes("economy") && confirmPopup) {
      return (
        <Dialog
          visible="displayBasic"
          draggable={false}
          width={[1, 445, 445, 445]}
          onHide={handleConfirmPopup}
          m={[3, 3, 3, "auto"]}
        >
          <OrderReportModal
            reportID={reportID}
            handleConfirmPopup={handleConfirmPopup}
            reloadDetails={reloadDetails}
          />
        </Dialog>
      );
    }
    return null;
  };

  const getIconBasedOnDeviation = (error) => {
    return error === 2 ? (
      <StyledIcon
        color="var(--white)"
        name="error"
        border="solid var(--red-medium) !important"
        bg="var(--red-medium) !important"
      />
    ) : error === 1 ? (
      <StyledIcon
        name="warning"
        bg="var(--yellow-medium) !important"
        border="solid var(--yellow-medium) !important"
      />
    ) : (
      <StyledIcon
        color="var(--white)"
        name="tick-mark"
        bg="var(--green-dark) !important"
        border="solid var(--green-dark) !important"
      />
    );
  };

  const getContentForEconomyDeviation = (deviationValue) => {
    return (
      <>
        <Div
          px={3}
          py={13}
          mb={isPrintable ? 1 : 2}
          mr={3}
          width={isPrintable ? "88%" : [1, "83%"]}
        >
          {deviationValue === 0 ? (
            <H5>
              <FormattedMessage
                id="text_no_financial_deviation"
                defaultMessage="No financial deviation"
                values={{ break: <br /> }}
              />
            </H5>
          ) : (
            <H5 mb={isPrintable ? 1 : 3}>
              <FormattedMessage
                id="text_financial_deviations_exist"
                defaultMessage="Financial deviations exist"
                values={{ break: <br /> }}
              />
            </H5>
          )}
          {userLogin && (
            <>
              {!credit_report ? (
                <Link
                  mt={2}
                  display="flex"
                  alignItems="center"
                  handleClick={handleConfirmPopup}
                >
                  <Icon
                    name="file-download"
                    mr={2}
                    fontSize="var(--fs-icon-m)"
                  />
                  {messages.order_report}
                </Link>
              ) : (
                <>
                  <Link
                    mt={2}
                    display="flex"
                    alignItems="center"
                    handleClick={handleConfirmPopup}
                  >
                    <Icon name="email" mr={2} fontSize="var(--fs-icon-m)" />
                    {messages.label_send_mail_again}
                  </Link>
                </>
              )}
            </>
          )}
        </Div>
      </>
    );
  };

  const constructDeviations = (arr, leftContent = false) => {
    return arr.map((obj, index) => {
      const { label, value } = obj;
      const deviationsData = deviation[value] || {};
      const error = deviationsData ? deviationsData.value : "";
      const devitationActive =
        (activeIndex === index && isLeftContent === leftContent) ||
        (deviationOpen && isPrintable ? error > 0 : deviationOpen);
      const economyDeviation = value.includes("economy") && devitationActive;
      const { value: deviationValue = "" } = deviationsData;
      return (
        <>
          <>{getEconomyDialog(value)}</>
          <Div key={index} display="flex" alignItems="center">
            <Div
              px={3}
              py={isPrintable ? "6px" : 11}
              mb={isPrintable ? 2 : 3}
              mr={3}
              backgroundColor="var(--turquoise-light)"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width={["85%", "83%"]}
              onClick={handleExpandAccordion.bind(this, index, leftContent)}
            >
              <TextLargeSemiBoldWeight
                lineHeight={isPrintable && "var(--fs-text-m) !important"}
                fontSize={isPrintable && "var(--fs-grade-text) !important"}
              >
                {messages[label]}
              </TextLargeSemiBoldWeight>
              {!isPrintable &&
                (devitationActive ? (
                  <Icon name="arrowbig-up" fontSize="var(--fs-icon-xs)" />
                ) : (
                  <Icon name="arrowbig-down" fontSize="var(--fs-icon-xs)" />
                ))}
              {isPrintable && error === 0 && (
                <Text fontSize="var(--fs-grade) !important">
                  Ingen avvikelse
                </Text>
              )}
            </Div>
            {getIconBasedOnDeviation(error)}
          </Div>
          {!value.includes("economy") && devitationActive && (
            <Div
              px={3}
              pb={deviationsData.keyname === "criminal_case" ? 0 : 10}
              mb={1}
              mr={3}
              width={isPrintable ? "88%" : [1, "83%"]}
            >
              {deviationInformation(value, deviationsData)}
            </Div>
          )}
          {economyDeviation && deviationValue < 1 && type !== COMPANY && (
            <Div
              px={3}
              pb={10}
              mb={1}
              mr={3}
              width={isPrintable ? "88%" : [1, "83%"]}
            >
              <H5>
                <FormattedMessage
                  id="text_no_financial_deviation"
                  defaultMessage="No financial deviation"
                  values={{ break: <br /> }}
                />
              </H5>
            </Div>
          )}
          {economyDeviation && deviationValue >= 1 && type !== COMPANY && (
            <Div
              px={3}
              pb={10}
              mb={1}
              mr={3}
              width={isPrintable ? "88%" : [1, "83%"]}
            >
              <H5 mb={isPrintable ? 1 : 3}>
                <FormattedMessage
                  id="text_financial_deviations_exist"
                  defaultMessage="Financial deviations exist"
                  values={{ break: <br /> }}
                />
              </H5>
            </Div>
          )}
          {economyDeviation &&
            type === COMPANY &&
            getContentForEconomyDeviation(deviationValue)}
        </>
      );
    });
  };

  return (
    <Div>
      <Div
        width={[1, 1, "88%", "96%"]}
        display={["block", "block", "flex", "flex"]}
        alignItems="center"
        justifyContent="space-between"
        mt={4}
      >
        <Div>
          <H2
            color="var(--blue-dark) !important"
            mt={isPrintable ? 0 : 3}
            mb={2}
            pb={1}
            lineHeight={isPrintable && "var(--fs-text) !important"}
            fontSize={isPrintable && "var(--fs-h4) !important"}
          >
            {messages[sectionTitle]}
          </H2>
        </Div>
        {!isPrintable && (
          <Div mb={[3, 0]}>
            <Link
              display="flex"
              alignItems="flex-end"
              mb={["3", "3", "0", "0"]}
              handleClick={toggleDeviation}
            >
              <Icon name="bullets" mr={2} />
              {deviationOpen ? (
                <>
                  {messages.label_hide_deviation}{" "}
                  <Icon
                    name="arrowbig-up"
                    fontSize="7px"
                    ml={2}
                    color="var(--blue-dark) !important"
                  />
                </>
              ) : (
                <>
                  {messages.label_show_deviation}
                  <Icon
                    name="arrowbig-down"
                    fontSize="7px"
                    ml={2}
                    color="var(--blue-dark) !important"
                  />
                </>
              )}
            </Link>
          </Div>
        )}
      </Div>
      <Div
        display="flex"
        flexDirection={
          isPrintable ? "column" : ["column", "column", "column", "row"]
        }
      >
        <Div width={isPrintable ? 1 : [1, 1, 1, 1 / 2]}>
          {constructDeviations(descriptionRight, true)}
        </Div>
        <Div width={isPrintable ? 1 : [1, 1, 1, 1 / 2]}>
          {constructDeviations(descriptionLeft)}
        </Div>
      </Div>
      <Span
        display="block"
        mt={isPrintable ? 2 : 3}
        mb={[3, 4]}
        color={isPrintable && "var(--grey) !important"}
        lineHeight={isPrintable && "150% !important"}
        fontSize={isPrintable && "var(--fs-text-s) !important"}
      >
        {!isAnalysisDetailPage && messages[footer]}
      </Span>
    </Div>
  );
};

Deviations.propTypes = {
  analysData: PropTypes.array,
  report: PropTypes.object,
  isAnalysisDetailPage: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  reloadDetails: PropTypes.func,
  isPrintable: PropTypes.bool,
};

export default Deviations;
