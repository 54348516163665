import React from "react";
import PropTypes from "prop-types";
import ResultsCard from "./ResultsCard";
import Divider from "@components/Divider";
import { H5 } from "@components/Heading";
import ScrollToTop from "@utils/ScrollToTop";
import Container from "@components/Container";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import styled from "styled-components";
import Card from "@components/Card";
import WatchListTables from "./WatchListTables";
import { Text, TextLargeSemiBoldWeight, TextSmall } from "@components/Text";
import { addHyphenToNumber } from "@utils/utils";
import {
  ROLE_ANALYSIS,
  STATUS_COMPLETED,
  ASSIGNMENT,
  BUNDLE,
} from "@utils/constant";

import usePermissions from "@hooks/usePermissions";
import { PERMISSIONS } from "@utils/enum";

const ObjectServicesCard = ({
  status,
  orderItems,
  isAdmin = false,
  isDetails = false,
  isWatchList = false,
  reports = [],
  assignments = [],
  isObject = false,
  historyReports = [],
  showDelivery = false,
  showAnalysLink = false,
}) => {
  const StyledContainer = styled(Container)`
    position: relative;
    background: var(--blue-lightest);
    box-shadow: none;
  `;
  const StyledCard = styled(Card)`
    border-radius: 20px;
  `;

  const { messages } = useIntl();
  const { checkForReadPermission } = usePermissions();

  const hasPricesPermission = checkForReadPermission(PERMISSIONS.PRICES);

  const assignmentInducedOrderItems = orderItems.map((orderItem) => {
    const correspondingAssignment = assignments.find(
      (assignment) => assignment.order_item.id === orderItem.id,
    );

    return {
      ...orderItem,
      assignment: correspondingAssignment || null,
    };
  });
  const groupedData = assignmentInducedOrderItems.reduce((result, item) => {
    const category = item.personal_number;
    if (!result[category]) {
      result[category] = {
        items: [],
        person_name: item.person_name,
        personal_number: item.personal_number,
        id: item.id,
      };
    }
    result[category].items.push(item);
    return result;
  }, {});

  return (
    <>
      {!isWatchList && (
        <ScrollToTop>
          {Object.keys(groupedData).map((category) => {
            const { items, person_name, personal_number } =
              groupedData[category];

            const parentChildMap = {};

            items.forEach((item) => {
              if (item.parent === null) {
                if (!parentChildMap[item.product_id]) {
                  parentChildMap[item.product_id] = { ...item, children: [] };
                }
              }
            });

            items.forEach((item) => {
              if (item.parent !== null && parentChildMap[item.parent]) {
                parentChildMap[item.parent].children.push(item);
              }
            });

            const result = Object.values(parentChildMap).filter(
              (item) => item.parent === null,
            );

            return (
              <Div key={category}>
                <StyledCard mb={4} p={0} maxWidth="1110px">
                  <Div>
                    <StyledContainer
                      color="var(--blue-lightest)"
                      width={1}
                      py={[3, 4]}
                      px={[3, 4]}
                      maxWidth="1110px"
                    >
                      <Div
                        display="flex"
                        alignItems={["left", "center"]}
                        justifyContent="space-between"
                        flexDirection={["column", "row"]}
                      >
                        <Div
                          display="flex"
                          flexDirection={["row", "column"]}
                          justifyContent="space-between"
                          alignItems={["center", "flex-start"]}
                        >
                          <Text>{messages.label_name}</Text>
                          <TextLargeSemiBoldWeight mt={2}>
                            {person_name}
                          </TextLargeSemiBoldWeight>
                        </Div>
                        <Div
                          pt={[1, 0]}
                          mt={[1, 0]}
                          display="flex"
                          flexDirection={["row", "column"]}
                          justifyContent="space-between"
                          alignItems={["center", "flex-end"]}
                        >
                          <Text>{messages.label_personal_number}</Text>
                          <TextLargeSemiBoldWeight mt={2}>
                            {addHyphenToNumber(personal_number?.toString(), 8)}
                          </TextLargeSemiBoldWeight>
                        </Div>
                      </Div>
                    </StyledContainer>
                    <Container
                      width={1}
                      pt={3}
                      px={[3, 4]}
                      minWidth={["0px", "610px"]}
                      maxWidth="1110px"
                    >
                      <Div
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Div minWidth={["0px", "575px"]}>
                          <H5>{messages.object_services_title_services}</H5>
                        </Div>
                        {!isDetails && (
                          <Div
                            display={["none", "flex"]}
                            textAlign={"right"}
                            ml={4}
                          >
                            <H5>{messages.object_services_title_delivery}</H5>
                          </Div>
                        )}
                        {hasPricesPermission && isDetails && (
                          <Div textAlign={"right"} minWidth={["0px", "125px"]}>
                            <H5>{messages.price}</H5>
                          </Div>
                        )}
                      </Div>
                    </Container>
                  </Div>
                  {result.map((item) => (
                    <Div key={item.id}>
                      <Container
                        width={1}
                        py={[2, 3]}
                        px={[3, 4]}
                        color={"var(--grey-dark)"}
                      >
                        <ResultsCard
                          isChild={false}
                          showDelivery={showDelivery}
                          orderItem={item}
                          status={status}
                          isDetails={isDetails}
                          isWatchList={isWatchList}
                          report={item.report}
                          assignment={item.assignment}
                          isObject={isObject}
                          historyReports={historyReports}
                          product={item?.product}
                          isAdmin={isAdmin}
                          showAnalysLink={showAnalysLink}
                        />
                        <Div
                          display={["flex", "none"]}
                          justifyContent="space-between"
                          pb={3}
                        >
                          <H5>{messages.object_services_title_delivery}</H5>
                          <TextSmall>
                            {item?.product?.analysis_type === ROLE_ANALYSIS &&
                            item?.report?.report_status === STATUS_COMPLETED &&
                            item?.report?.grades === null &&
                            !isDetails
                              ? messages.label_one_day
                              : !isDetails &&
                                (item.service_type === ASSIGNMENT ||
                                  item.service_type === BUNDLE ||
                                  item.report.report_status ===
                                    STATUS_COMPLETED) &&
                                item?.product?.delivery_time}
                          </TextSmall>
                        </Div>
                        {item.children?.length > 0 && <Divider></Divider>}
                        {item.children?.length > 0 &&
                          item.children?.map((childItem, index) => (
                            <>
                              <Container
                                width={1}
                                py={[0]}
                                pl={[3, 4]}
                                color={"var(--grey-dark)"}
                              >
                                <ResultsCard
                                  isChild={true}
                                  key={childItem.id}
                                  showDelivery={showDelivery}
                                  orderItem={childItem}
                                  status={status}
                                  isDetails={isDetails}
                                  isWatchList={isWatchList}
                                  report={childItem.report}
                                  assignment={childItem.assignment}
                                  isObject={isObject}
                                  historyReports={historyReports}
                                  product={childItem?.product}
                                  isAdmin={isAdmin}
                                  showAnalysLink={showAnalysLink}
                                />
                              </Container>
                              {index !== item.children?.length - 1 && (
                                <Divider></Divider>
                              )}
                            </>
                          ))}
                      </Container>
                      <Divider m={"0px !important"}></Divider>
                    </Div>
                  ))}
                </StyledCard>
              </Div>
            );
          })}
        </ScrollToTop>
      )}
      <ScrollToTop>
        <WatchListTables
          isWatchList={isWatchList}
          reports={reports}
          isObject={isObject}
          historyReports={historyReports}
          isAdmin={isAdmin}
        />
      </ScrollToTop>
    </>
  );
};
ObjectServicesCard.propTypes = {
  showDelivery: PropTypes.bool,
  showAnalysLink: PropTypes.bool,
  status: PropTypes.string,
  isAdmin: PropTypes.bool,
  isDetails: PropTypes.bool,
  isWatchList: PropTypes.bool,
  reports: PropTypes.array,
  assignments: PropTypes.array,
  orderItems: PropTypes.array,
  isObject: PropTypes.bool,
  historyReports: PropTypes.array,
};
export default ObjectServicesCard;
