import React from "react";
import { useIntl } from "react-intl";
import Container from "@components/Container";
import { H2 } from "@components/Heading";
import Div from "@components/Div";
import Card from "@components/Card";
import { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import {
  Text,
  TextUpperCaseSemiBold,
  TextLargeSemiBoldWeight,
  TextColouredSemiBold,
  TextMediumWeight,
} from "@components/Text";
import ActivityLog from "@components/ActivityLog";
import { ROUTES, SUBSCRIPTION_STATUS } from "@utils/constant";
import { formatDate } from "@utils/utils";
import { useHistory } from "react-router-dom";

const ActiveSubscription = (prop) => {
  const {
    activePackage,
    activityLogs,
    isShowChangePlanButton = true,
    isAdmin = false,
    redirectToSubscriptionDetailPage,
  } = prop;
  const { messages } = useIntl();
  const history = useHistory();
  const redirectOnDowngrdUpgrde = () => {
    if (isAdmin) {
      redirectToSubscriptionDetailPage();
    } else {
      return history.push(ROUTES.UPGRADE_SUBSCRIPTION.URL);
    }
  };

  const activePackageStatus = (status) => {
    let packageStatus = "";
    switch (status) {
      case SUBSCRIPTION_STATUS.ACTIVE:
        packageStatus = messages.active;
        break;
      case SUBSCRIPTION_STATUS.STOPPED:
        packageStatus = messages.stopped_label;
        break;
      case SUBSCRIPTION_STATUS.TERMINATED:
        packageStatus = messages.terminated_label;
        break;
      default:
        packageStatus = activePackage.status;
    }
    return packageStatus;
  };
  return (
    <Container m={"0px !important"} maxWidth={"905px !important"}>
      <H2 my={2} mb={["30px", "25px"]}>
        {messages.title_prepaid_account}
      </H2>
      {activePackage.status !== SUBSCRIPTION_STATUS.TERMINATED ? (
        <Div>
          <Card>
            <Div
              backgroundColor={"var(--turquoise-light)"}
              py={3}
              px={[3, 4]}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
            >
              <Div width={[1, 0.5, 0.5]}>
                <Div mb={2}>
                  <Text>{messages.type_of_package_label}:</Text>
                </Div>
                <Div>
                  <TextUpperCaseSemiBold fontSize="var(--fs-text-sm)">
                    {activePackage.name}
                  </TextUpperCaseSemiBold>
                </Div>
              </Div>
              {((activePackage.status !== SUBSCRIPTION_STATUS.STOPPED &&
                isShowChangePlanButton) ||
                isAdmin) && (
                <Div
                  display="flex"
                  justifyContent={["flex-start", "flex-end"]}
                  width={[1, 0.5, 0.5]}
                  mt={["25px", "0px"]}
                >
                  <LinkArrow
                    onClick={redirectOnDowngrdUpgrde.bind(this)}
                    direction="left"
                    label={messages.change_package_label}
                  >
                    <Icon ml={1} name="headerarrowright" />
                  </LinkArrow>
                </Div>
              )}
            </Div>
            <Div
              py={[0, "10px"]}
              px={[3, 4]}
              pt={[3, "20px"]}
              pb={[3, "10px"]}
              display="flex"
              flexWrap="wrap"
            >
              <Div width={[1, 1, 0.5]} pb={[3, 0]}>
                <Div mb={2}>
                  <Text>{messages.label_status}:</Text>
                </Div>
                <Div>
                  <TextLargeSemiBoldWeight>
                    {activePackageStatus(activePackage.status)}
                  </TextLargeSemiBoldWeight>
                </Div>
              </Div>
              <Div width={[1, 1, 0.5]}>
                <Div mb={2}>
                  <Text>{messages.renewal_label}</Text>
                </Div>
                <Div>
                  <TextColouredSemiBold
                    color={
                      activePackage.status === SUBSCRIPTION_STATUS.STOPPED
                        ? "var(--red)"
                        : "var(--grey-dark)"
                    }
                  >
                    {formatDate(activePackage.renewal_date)}
                  </TextColouredSemiBold>
                </Div>
              </Div>
            </Div>
            <Div
              py={[0, "10px"]}
              px={[3, 4]}
              pb={[3, "30px"]}
              display="flex"
              flexWrap="wrap"
            >
              <Div width={[1, 1, 0.5]} pb={[3, 0]}>
                <Div mb={2}>
                  <Text>{messages.remaining_label}:</Text>
                </Div>
                <Div>
                  <TextMediumWeight fontSize="var(--fs-h5)">
                    <TextColouredSemiBold
                      color={
                        activePackage.available_credits === 0
                          ? "var(--red)"
                          : ""
                      }
                      alert={!activePackage.available_credits}
                    >
                      {activePackage.available_credits}
                    </TextColouredSemiBold>
                    /{activePackage.total_credits}
                  </TextMediumWeight>
                </Div>
              </Div>
              <Div width={[1, 1, 0.5]}>
                <Div mb={2}>
                  <Text>{messages.pricee}</Text>
                </Div>
                <Div>
                  <TextLargeSemiBoldWeight>
                    {Number(activePackage.price)?.toLocaleString("sv-SE")}{" "}
                    {`${messages.price_currency}`}
                  </TextLargeSemiBoldWeight>
                </Div>
              </Div>
            </Div>
          </Card>
        </Div>
      ) : (
        messages.subscription_terminated
      )}
      <ActivityLog activityLogs={activityLogs} />
    </Container>
  );
};

export default ActiveSubscription;
