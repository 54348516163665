import React, { useState } from "react";
import Div from "@components/Div";
import PaymentOption from "./PaymentOption";
import { renderSnippet } from "@utils/utils";
import { KLARNA_CHECKOUT } from "@utils/constant";

const MultipleOrderSummary = (prop) => {
  const [html, setHTML] = useState("");
  const {
    paymentMethod = "",
    isInvoice = false,
    isCreditOnly = false,
    isConsent = false,
    disablePayment = false,
    promoCode = "",
    billingAccountId = null,
    onNextStep,
  } = prop;
  const htmlSnippet = (val) => setHTML(val.html_snippet);

  return (
    <Div mt={4}>
      <PaymentOption
        htmlSnippet={htmlSnippet}
        paymentMethod={paymentMethod}
        isInvoice={isInvoice}
        isConsent={isConsent}
        disablePayment={disablePayment}
        promoCode={promoCode}
        billingAccountId={billingAccountId}
        onNextStep={onNextStep}
      />
      <Div mt={5} id="my-checkout-container">
        {!isCreditOnly &&
          paymentMethod === KLARNA_CHECKOUT &&
          !!html.length &&
          renderSnippet(html)}
      </Div>
    </Div>
  );
};

export default MultipleOrderSummary;
