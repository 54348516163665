import React from "react";
import { Provider } from "react-redux";
import { store } from "@app/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { IntlProvider } from "react-intl";
import ErrorBoundary from "@components/ErrorBoundary";
import AppRoute from "./AppRoute";
import messages_en from "@translation/en.json";
import messages_sv from "@translation/sv.json";

import "primereact/resources/primereact.min.css";
import "@sass/pr-theme.css";
import "primeicons/primeicons.css";
import "@sass/app.scss";
import cookieConfig from "./cookieConfig";
import CookieConsent from "@components/CookieConsent";
import { ROUTES } from "@utils/constant";
import Config from "@src/config";
import { StepperProvider } from "./pages/shared/Wizard/StepperContext";
import { UserInfoProvider } from "@contexts/UserInfoContext";

const messages = {
  en: messages_en,
  sv: messages_sv,
};

const disableCookie = window.location.pathname === ROUTES.REPORT_PDF.URL;

const applanguage = Config.language || "sv";

let persistor = persistStore(store);

let currentPath = window.location.pathname;
currentPath = currentPath?.split("/")[1];

function App() {
  return (
    <div className={`App ${currentPath}`}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <IntlProvider
              defaultLocale="sv"
              locale={applanguage}
              messages={messages[applanguage]}
            >
              <UserInfoProvider>
                <StepperProvider>
                  <AppRoute />
                  {!disableCookie && <CookieConsent config={cookieConfig} />}
                </StepperProvider>
              </UserInfoProvider>
            </IntlProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
