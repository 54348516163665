import React from "react";
import CheckBoxes from "./Utils/Checkbox";
import Button from "./Utils/Button";
import ButtonOutlined from "./Utils/ButtonOutlined";
import ButtonRaised from "./Utils/ButtonRaised";
import Calendar from "./Utils/Calendar";
import UtilsLink from "./Utils/Link";
import SubTitle from "./Utils/SubTitle";
import Input from "./Utils/Inputtext";
import { useIntl } from "react-intl";
import ExpiredLink from "./Utils/ExpiredLinkText";
import FailedLink from "./Utils/FailedLinkText";
import InProgressLink from "./Utils/InProgressLinkText";
import CompletedLink from "./Utils/CompletedLinkText";
import PendingConsentLink from "./Utils/PendingConsentLinkText";
import PendingConsentShareLink from "./Utils/PendingConsentShareLinkText";
import ExpiredNoConsentLink from "./Utils/ExpiredNoConsentLinkText";

import MultiSelect from "./Utils/MultiSelect";
import MenuButton from "./Utils/MenuButton";
import SearchBar from "./Utils/SearchBar";

const ActionTemplate = (type, value, rowData) => {
  const { messages } = useIntl();
  const [inputvalues = {}] = value?.options || [];
  const {
    name = "",
    value: inputValue = "",
    onClick = () => {},
    options = [],
    width = 1,
    placeholder = "",
    id = "",
  } = inputvalues;
  const { isHidden = false } = value;
  if (!isHidden) {
    return (
      <>
        {(() => {
          switch (type) {
            case "checkbox":
              return (
                <CheckBoxes type={type} value={value} options={value.options} />
              );

            case "input":
              return (
                <Input
                  type={type}
                  placeholder={messages.label_name}
                  name={name}
                  onChange={onClick}
                  value={inputValue}
                  id={name}
                />
              );
            case "button":
              return <Button type={value} />;

            case "button-outlined":
              return <ButtonOutlined type={value} />;

            case "button-raised":
              return <ButtonRaised type={value} />;

            case "calendar":
              return <Calendar value={value} />;

            case "link":
              return (
                <UtilsLink
                  type={{
                    ...value,
                    rowData,
                    href:
                      value?.generateLink && value?.generateLink(rowData?.id),
                  }}
                />
              );

            case "pending-consent-text":
              return <PendingConsentLink type={value} />;

            case "pending-consent-share-text":
              return <PendingConsentShareLink type={value} />;

            case "expired-text":
              return <ExpiredLink type={value} />;

            case "failed-text":
              return <FailedLink type={value} />;

            case "in-progress-text":
              return <InProgressLink type={value} />;

            case "expired-no-consent":
              return <ExpiredNoConsentLink type={value} />;

            case "completed-text":
              return <CompletedLink type={value} />;

            case "subTitle":
              return <SubTitle type={value} />;
            case "multiselect":
              return (
                <MultiSelect
                  type={type}
                  placeholder={placeholder}
                  name={name}
                  onChange={onClick}
                  value={inputValue}
                  id={id}
                  options={options}
                  width={width}
                />
              );
            case "search":
              return <SearchBar type={value} />;
            case "menu-button":
              return <MenuButton type={value} />;
          }
        })()}
      </>
    );
  }
  return <></>;
};

export default ActionTemplate;
