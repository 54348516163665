import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";
import StyledIcon from "@components/Icon";
import updateWatchlist from "@app/services/watchlist/updateWatchlist";
import WatchListDeleteButtons from "@pages/user/watchlist/components/WatchListDeleteButtons";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import { handleException } from "@utils/common";

const DeleteWatchlistObjectModal = ({
  onCloseModal,
  selectedWatchlistObjects = [],
  watchlist,
  handleReloadList,
}) => {
  const { messages } = useIntl();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    try {
      const updatedWatchListObjects = watchlist?.watch_list_objects.filter(
        (obj) =>
          !selectedWatchlistObjects.find(
            (selectedObj) => selectedObj.id === obj.id,
          ),
      );
      const updatedWatchlist = {
        ...watchlist,
        watch_list_objects: updatedWatchListObjects,
        notification_emails: watchlist.notification_emails,
      };
      await updateWatchlist(updatedWatchlist.id, updatedWatchlist);
      handleReloadList(updatedWatchlist);
      onCloseModal();
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  };

  const handleClosePopup = () => {
    setShowErrorModal(false);
    onCloseModal();
  };

  return (
    <Div
      p={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
      maxWidth="450px"
    >
      <Div display="flex" alignItems="center" flexDirection="column">
        <H3>
          {selectedWatchlistObjects.length === 1
            ? messages.watchlist_title_delete_object
            : messages.watchlist_title_delete_chosen}
        </H3>
      </Div>
      <Div display="grid" mt={3} alignItems="center" justifyContent="center">
        {selectedWatchlistObjects.length === 1 && (
          <>
            <Div display="flex" justifySelf="center" alignItems="center">
              <StyledIcon
                mr={1}
                name="account-circle"
                color="var(--turquoise)"
                fontSize="var(--fs-icon-m)"
              />
              <TextLargeSemiBoldWeight>
                {selectedWatchlistObjects[0].name}
              </TextLargeSemiBoldWeight>
            </Div>
            <TextLargeSemiBoldWeight textAlign="center" mt={3}>
              {watchlist.name}
            </TextLargeSemiBoldWeight>
          </>
        )}
        <Text mt={3} textAlign="center">
          {selectedWatchlistObjects.length === 1
            ? messages.watchlist_message_confirm_delete_single_object
            : messages.watchlist_message_confirm_delete_chosen_object}
        </Text>
      </Div>
      <WatchListDeleteButtons
        handleSubmit={handleSubmit}
        onCloseModal={onCloseModal}
      />
      {showErrorModal && (
        <ErrorModal
          handleConfirmPopup={handleClosePopup}
          content={errorMessage}
        />
      )}
    </Div>
  );
};
DeleteWatchlistObjectModal.propTypes = {
  onCloseModal: PropTypes.func,
  selectedWatchlistObjects: PropTypes.array,
  watchlist: PropTypes.object,
  handleReloadList: PropTypes.func,
};
export default DeleteWatchlistObjectModal;
