import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";
import Dropdown from "@components/Dropdown";
import Icon from "@components/Icon";
import updateWatchlist from "@app/services/watchlist/updateWatchlist";
import ProgressSpinner from "@components/ProgressSpinner";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";

const StyledList = styled.ul`
  columns: ${({ length }) => (length > 5 ? "2" : "1")};
`;

const MoveWatchListObjectModal = ({
  onCloseModal,
  selectedWatchListObjects = [],
  watchList = {},
  customerWatchLists,
  handleReloadList,
  selectedWatchlistObjects,
}) => {
  const { messages } = useIntl();
  const [destinationWatchList, setDestinationWatchList] = useState("");
  const [showMovedSuccessfully, setShowMovedSuccessfully] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [updatedWatchList, setUpdatedWatchList] = useState({});

  const handleSubmit = () => {
    moveWatchList().catch((error) => {
      handleException(error);
    });
  };

  const moveWatchList = async () => {
    setIsMoving(true);
    try {
      const selectedWatchListIds = selectedWatchListObjects.map(
        (selectedObj) => selectedObj.id,
      );
      const existingWatchListObjects = watchList?.watch_list_objects.filter(
        (obj) => !selectedWatchListIds.includes(obj.id),
      );
      const existingWatchList = {
        ...watchList,
        watch_list_objects: existingWatchListObjects,
      };
      const sourceWatchList = await updateWatchlist(
        watchList.id,
        existingWatchList,
      );
      setUpdatedWatchList(sourceWatchList?.data?.data);
      const newWatchListObjects = selectedWatchListObjects.map((obj) => ({
        name: obj.name,
        ssn_number: obj.ssn_number,
      }));
      const movedWatchList = {
        ...destinationWatchList,
        watch_list_objects: [
          ...destinationWatchList.watch_list_objects,
          ...newWatchListObjects,
        ],
      };
      await updateWatchlist(destinationWatchList.id, movedWatchList);
      setShowMovedSuccessfully(true);
      setIsMoving(false);
    } catch (error) {
      handleException(error);
    }
  };

  const handleDone = () => {
    onCloseModal();
    handleReloadList(updatedWatchList);
  };

  const handleWatchListUpdate = (e) => {
    setDestinationWatchList(
      customerWatchLists.find((item) => item.name.includes(e.value)),
    );
  };

  const handleException = (error = {}) => {
    if (error.response.status >= 400 && error.response.status < 600) {
      setShowMovedSuccessfully(false);
      if (error.response.status === 422) {
        setShowErrorModal(true);
        setErrorMessage(error.response.data.message);
        return;
      }
      setShowErrorModal(true);
      setErrorMessage(messages.exception_error_message);
    }
  };

  const renderSelectedWatchListObjects = () => {
    if (selectedWatchlistObjects.length > 1) {
      return (
        <Div
          display="flex"
          bg={"rgba(var(--grey-lightest-rgba), 0.4)"}
          p={3}
          borderRadius={8}
          mt={2}
        >
          <StyledList length={selectedWatchlistObjects.length}>
            {selectedWatchlistObjects.map((object) => (
              <li key={object.id}>
                <Text>{object.name}</Text>
              </li>
            ))}
          </StyledList>
        </Div>
      );
    } else {
      return (
        <Div mt={3} justifySelf="center" display="flex" alignItems="center">
          <Icon
            mr={1}
            name="account-circle"
            color="var(--turquoise)"
            fontSize="var(--fs-icon-m)"
          />
          <TextLargeSemiBoldWeight>
            {selectedWatchlistObjects[0]?.name}
          </TextLargeSemiBoldWeight>
        </Div>
      );
    }
  };

  return (
    <Div
      py={0}
      display="flex"
      alignItems="center"
      flexDirection="column"
      maxWidth="450px"
    >
      {!showErrorModal && isMoving && <ProgressSpinner />}
      {!showErrorModal && !showMovedSuccessfully && (
        <>
          <Div display="flex" alignItems="center" flexDirection="column" mt={2}>
            <H3>{messages.watchlist_title_move_object}</H3>
            <TextLargeSemiBoldWeight mt={3}>
              {watchList?.name}
            </TextLargeSemiBoldWeight>
            {renderSelectedWatchListObjects()}
            <Text textAlign="center" mt={3}>
              {messages.watchlist_message_move_object}
            </Text>
            <Dropdown
              mt={3}
              name={"watchListNames"}
              options={customerWatchLists
                ?.filter((item) => item.id !== watchList.id)
                .map((item) => item.name)}
              onChange={handleWatchListUpdate}
              value={destinationWatchList?.name}
              width={[1, 1, 400, 400]}
              placeholder={`${messages.watchlist_label_choose}...`}
              disabled={isMoving}
            />
          </Div>
          <Div
            mt={4}
            display="flex"
            flexDirection={["column-reverse", "row"]}
            justifyContent="center"
          >
            <PrimaryButtonOutlined
              rounded
              semibold
              label={messages.watchlist_label_cancel}
              onClick={onCloseModal}
              width={[280, 150]}
              mr={[0, 4]}
              disabled={isMoving}
            />
            <PrimaryButtonIcon
              rounded
              semibold
              width={[280, 150]}
              mb={[3, 0]}
              onClick={handleSubmit}
              label={messages.watchlist_label_confirm}
              disabled={isMoving}
            />
          </Div>
        </>
      )}
      {!showErrorModal && showMovedSuccessfully && (
        <>
          <Div
            display="flex"
            alignItems="center"
            flexDirection="column"
            mt={2}
            p={3}
          >
            <H3>{`${messages.watchlist_label_done}!`}</H3>
            {renderSelectedWatchListObjects()}
            <Div mt={4}>
              <FormattedMessage
                id="watchlist_message_moved_object"
                values={{
                  sourceName: watchList?.name,
                  destinationName: destinationWatchList?.name,
                }}
              />
            </Div>
            <Div
              mt={3}
              display="flex"
              flexDirection={["column-reverse", "row"]}
              justifyContent="center"
            >
              <PrimaryButtonIcon
                mt={3}
                rounded
                semibold
                width={[280, 180]}
                mb={[3, 0]}
                onClick={handleDone}
                label={messages.watchlist_label_ok}
              />
            </Div>
          </Div>
        </>
      )}
      {showErrorModal && (
        <ErrorModal handleConfirmPopup={onCloseModal} content={errorMessage} />
      )}
    </Div>
  );
};
MoveWatchListObjectModal.propTypes = {
  onCloseModal: PropTypes.func,
  selectedWatchListObjects: PropTypes.array,
  watchList: PropTypes.object,
  customerWatchLists: PropTypes.array,
  handleReloadList: PropTypes.func,
  selectedWatchlistObjects: PropTypes.array,
};
export default MoveWatchListObjectModal;
