import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";

import usePermissions from "@hooks/usePermissions";

import { FORM_INPUT_TYPE, PERMISSIONS, SERVICE_TYPE } from "@utils/enum";
import { enumValueToTranslationKey } from "@utils/utils";

const getConfig = (data) => {
  const configuration = {
    details: [
      {
        key: "id",
        translationKey: "title_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data.id,
      },
      {
        key: "created_at",
        translationKey: "created_at",
        type: FORM_INPUT_TYPE.CALENDAR,
        isReadOnlyField: true,
        value: data.created_at,
      },
      {
        key: "name",
        translationKey: "label_product_name",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.name,
        fullWidth: true,
      },
      {
        key: "state",
        translationKey: "label_status",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="active" />,
            value: "active",
          },
          {
            label: <FormattedMessage id="inactive" />,
            value: "inactive",
          },
        ],
        selectorField: "label",
        value: data.state,
        fullWidth: true,
      },
      {
        key: "price",
        translationKey: "price",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.price,
        fullWidth: true,
      },
      {
        key: "delivery_time",
        translationKey: "delivery_time_label",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.delivery_time,
      },
      {
        key: "listing_order",
        translationKey: "label_listing_order",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.listing_order,
      },
      {
        key: "sku",
        translationKey: "label_sku",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.sku,
        fullWidth: true,
      },
      {
        key: "default_selected",
        translationKey: "label_default_selected",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.default_selected,
      },
      {
        key: "default_available",
        translationKey: "label_default_availability",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.default_available,
      },
    ],
    description: [
      {
        key: "description",
        translationKey: "",
        type: FORM_INPUT_TYPE.EDITOR,
        value: data.description,
        fullWidth: true,
      },
    ],
    configuration: [
      {
        key: "type",
        translationKey: "label_product_type",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.type,
        isReadOnlyField: true,
      },
      {
        key: "service_type",
        translationKey: "label_service_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="analysis" />,
            value: SERVICE_TYPE.ANALYSIS,
          },
          {
            label: <FormattedMessage id="assignment" />,
            value: SERVICE_TYPE.ASSIGNMENT,
          },
          {
            label: <FormattedMessage id="servicetype_bundle" />,
            value: SERVICE_TYPE.BUNDLE,
          },
        ],
        selectorField: "label",
        value: data.service_type,
        fullWidth: true,
      },
      {
        key: "analysis_type",
        translationKey: "label_analysis_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="general_analysis" />,
            value: "general-analysis",
          },
          {
            label: <FormattedMessage id="role_specific_analysis" />,
            value: "role-specific-analysis",
          },
          {
            label: <FormattedMessage id="general_analysis_actapublica" />,
            value: "general-analysis-actapublica",
          },
        ],
        selectorField: "label",
        value: data.analysis_type,
        fullWidth: true,
      },
      {
        key: "process_type",
        translationKey: "label_process_type",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="label_automatic" />,
            value: "automatic",
          },
          {
            label: <FormattedMessage id="label_manual" />,
            value: "manual",
          },
        ],
        selectorField: "label",
        value: data.process_type || data.analysis_type,
        fullWidth: true,
      },
      {
        key: "data_source",
        translationKey: "label_data_source",
        type: FORM_INPUT_TYPE.MULTISELECT,
        options: data?.data_source_types || [],
        selectorField: "name",
        selectAllLabel: <FormattedMessage id="label_all_sources" />,
        value: data.data_source,
        fullWidth: true,
      },
      {
        key: "fortnox_article_number",
        translationKey: "label_fortnox_article_number",
        type: FORM_INPUT_TYPE.TEXT,
        value: data.fortnox_article_number,
        fullWidth: true,
      },
      {
        key: "case",
        translationKey: "label_case",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.case,
      },
      {
        key: "report",
        translationKey: "label_report",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data.report,
      },
    ],
  };
  return configuration;
};

const DetailsTab = ({ data, dataSourceTypes, onUpdateService }) => {
  const { messages } = useIntl();
  const { checkForWritePermission } = usePermissions();

  const hasProductsPermission = checkForWritePermission(PERMISSIONS.PRODUCTS);

  const formattedData = {
    ...data,
    type: messages[`label_${enumValueToTranslationKey(data?.type)}`],
    data_source_types: dataSourceTypes,
  };

  const { details, description, configuration } = getConfig(formattedData);

  const handleUpdateService = (values) => {
    const payload = {
      ...data,
      ...values,
    };

    onUpdateService(payload);
  };

  return (
    <Div
      width={[1, 1, 1, 800]}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      <EditableFieldsCard
        title={messages.label_details}
        config={details}
        data={formattedData}
        onSubmit={handleUpdateService}
        isEditPermission={hasProductsPermission}
      />

      <EditableFieldsCard
        title={messages.title_product_description}
        config={description}
        data={formattedData}
        onSubmit={handleUpdateService}
        isEditPermission={hasProductsPermission}
      />

      <EditableFieldsCard
        title={messages.title_configuration}
        config={configuration}
        data={formattedData}
        onSubmit={handleUpdateService}
        isEditPermission={hasProductsPermission}
      />
    </Div>
  );
};

DetailsTab.propTypes = {
  data: PropTypes.object,
  dataSourceTypes: PropTypes.array,
  onUpdateService: PropTypes.func,
};

export default DetailsTab;
