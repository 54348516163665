import React, { memo } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { TextMediumWeight } from "@components/Text";
import { default as PRIcon, SmallEllipseIcon } from "@components/Icon";
import Tooltip from "@components/ToolTip";
import Span from "@components/Span";
import InputText from "@components/StyledInputText";
import Dropdown from "@components/Dropdown";
import { formatDateAndTime, getFormattedNumber } from "@utils/utils";
import MenuButton from "../Table/Utils/MenuButton";
import { Link as RouterLink } from "react-router-dom";

const Icon = styled(PRIcon)`
  color: var(--turquoise);
  &:hover {
    color: var(--blue-dark);
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: var(--blue-medium);
`;

const dateAndTimeTemplate = (values, e) => {
  const createdAt = formatDateAndTime(values[e.field]);
  const [date, time] = createdAt.split(",");
  return (
    <span title={`${date}, ${time}`}>
      <TextMediumWeight>{date}</TextMediumWeight>{" "}
      <TextMediumWeight color="var(--grey)!important">{time}</TextMediumWeight>
    </span>
  );
};

const BundleIcon = memo(
  <Span
    p={"3px 6px"}
    ml={2}
    bg="var(--blue-dark)"
    color="var(--white) !important"
    borderRadius="5px"
    bold
  >
    P
  </Span>,
);

const serviceNameTemplate = (values, e) => {
  const { column: { props: { formatter } = {} } = {} } = e;
  const value = formatter ? formatter(values[e.field]) : values[e.field];

  return (
    <TextMediumWeight>
      {value || "-"}
      {values["service_type"] === "bundle" ? BundleIcon : ""}
      <SmallEllipseIcon ml={2} className={`class-${values?.id}`} name="info">
        <Tooltip
          target={`.class-${values?.id}`}
          content={values["description"]}
        />
      </SmallEllipseIcon>
    </TextMediumWeight>
  );
};

const enumTemplate = (values, e) => {
  const { column: { props: { formatter } = {} } = {} } = e;
  const status = formatter ? formatter(values[e.field]) : values[e.field];
  return (
    <TextMediumWeight
      title={status || "-"}
      className={`${status?.toString()?.toLowerCase()}`}
    >
      {status ? (
        <FormattedMessage
          id={`${status?.replaceAll("-", "_")}`}
          defaultMessage="-"
        />
      ) : (
        "-"
      )}
    </TextMediumWeight>
  );
};

const generalTemplate = (values, e) => {
  const { column: { props: { className = "", formatter } = {} } = {} } = e;
  const value = values[e.field];
  return (
    <TextMediumWeight
      title={value?.toString() || "-"}
      className={className || `${value?.toString()?.toLowerCase()}`}
    >
      {formatter ? formatter(value, values) : value || "-"}
    </TextMediumWeight>
  );
};

const renderLink = (values, primaryAction) => (
  <Link to={primaryAction.href(values)}>
    <Icon
      name="headerarrowright"
      color="var(--turquoise)"
      cursor="pointer"
      href={(e) => primaryAction?.onClick(values, e)}
    />
  </Link>
);

const renderArrow = (values, primaryAction) =>
  (primaryAction?.showWhen === undefined ||
    primaryAction?.showWhen(values)) && (
    <Link to={primaryAction.href(values)}>
      <Icon
        name="headerarrowright"
        color="var(--turquoise)"
        cursor="pointer"
        onClick={(e) => primaryAction?.onClick(values, e)}
      />
    </Link>
  );

const renderLinkArrow = (values, primaryAction) =>
  primaryAction?.type === "link"
    ? renderLink(values, primaryAction)
    : renderArrow(values, primaryAction);

const arrowActionTemplate = (values, e) => {
  const { column: { props: { actions = [] } = {} } = {} } = e;
  const [primaryAction = {}] = actions;
  return actions?.length > 1 ? (
    <MenuButton
      type={{
        icon: "more-vertical",
        items: actions.map((o) => {
          const { type, href } = o;
          if (type === "link") {
            return {
              ...o,
              url: href(values),
            };
          }
          return {
            ...o,
            label: o?.formatter ? o?.formatter(values) : o?.label,
            icon: o?.iconSelector ? o?.iconSelector(values) : o?.icon,
            command: () => {
              o?.onClick(values);
            },
          };
        }),
        has_transparent_bg: true,
        iconSize: "var(--fs-icon-m)",
      }}
    />
  ) : (
    renderLinkArrow(values, primaryAction)
  );
};

const editableDropDownTemplate = (options) => (
  <Dropdown
    placeholder={
      <FormattedMessage id="placeholder_choose" defaultMessage={"Choose"} />
    }
    value={options?.value}
    name={options?.field}
    options={options?.actions}
    onChange={(e) => options.editorCallback(e.target.value)}
    width={"-webkit-fill-available"}
    mb={0}
  />
);

const editableInputTextTemplate = (options) => (
  <InputText
    type="text"
    value={options?.value}
    name={options?.field}
    width={"-webkit-fill-available"}
    onChange={(e) => options.editorCallback(e.target.value)}
  />
);

const linkTemplate = (values, e) => {
  const {
    column: { props: { className = "", href = () => {}, formatter } = {} } = {},
  } = e;
  const value = values[e.field] || "-";
  return (
    <Link
      to={href(values)}
      className={className || `${value?.toString()?.toLowerCase()}`}
    >
      {formatter ? formatter(value, values) : value}
    </Link>
  );
};

const currencyTemplate = (values, e) => {
  const { column: { props: { className = "" } = {} } = {} } = e;
  const value = values[e.field];
  const formattedValue = value ? `${getFormattedNumber(value)} kr` : `-`;
  return (
    <TextMediumWeight
      title={value?.toString() || "-"}
      className={className || `${value?.toString()?.toLowerCase()}`}
    >
      {formattedValue}
    </TextMediumWeight>
  );
};

const idTemplate = (values, e) => {
  const { column: { props: { className = "", href = () => {} } = {} } = {} } =
    e;
  const value = values[e.field];
  const formattedValue = value ? `#${value.toString().padStart(6, "0")}` : "-";
  const content = (
    <TextMediumWeight
      title={value?.toString() || "-"}
      className={className || `${value?.toString()?.toLowerCase()}`}
      color="var(--blue-medium)!important"
    >
      {formattedValue}
    </TextMediumWeight>
  );

  return href(values) ? (
    <Link
      to={href(values)}
      className={className || `${value?.toString()?.toLowerCase()}`}
    >
      {content}
    </Link>
  ) : (
    content
  );
};

export const bodyTemplate = {
  text: generalTemplate,
  date: dateAndTimeTemplate,
  actions: arrowActionTemplate,
  service: serviceNameTemplate,
  enum: enumTemplate,
  dropdown: editableDropDownTemplate,
  input: editableInputTextTemplate,
  link: linkTemplate,
  currency: currencyTemplate,
  id: idTemplate,
};

export default bodyTemplate;
