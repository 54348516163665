import React from "react";
import { useSelector } from "react-redux";
import SideMenuContent from "./SideMenuContent";
import { ADMIN_NAV } from "@utils/constant";
import Div from "@components/Div";
import Logo from "@components/Logo";
import LogoFull from "@assets/adminLOGO.svg";
import Config from "@src/config";

const handleRedirect = () => {
  window.location = `${Config.WP_URL}/`;
};

const LogoComponent = () => (
  <Div display="flex" justifyContent="center" my={4}>
    <Logo
      display="block"
      logo={LogoFull}
      height={"auto"}
      onClick={handleRedirect}
    />
  </Div>
);

const AdminSideMenu = () => {
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;
  const availablePages = Object.keys(permissions).map((obj) => obj);
  availablePages.push("search");

  const menuItems = ADMIN_NAV.filter(
    (obj) => availablePages.includes(obj.value) || obj.value === "search",
  );

  const props = {
    Component: LogoComponent,
    navItems: [...menuItems],
  };
  return <SideMenuContent {...props} />;
};

export default AdminSideMenu;
