import React, { useState } from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf";
import { useIntl } from "react-intl";

import Div from "@components/Div";
import { H5 } from "@components/Heading";
import { paginatorTemplate } from "@components/DataTable/PaginatorTemplate";
import ProgressSpinner from "@components/ProgressSpinner";

import styled from "styled-components";

const StyledNavigation = styled.div`
  display: flex;
  align-items: center;

  h5 {
    font-size: var(--fs-h3);
  }
`;

const PDFPreview = ({ previewFile }) => {
  const { messages } = useIntl();

  const [currentPage, setCurrentPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);

  const handlePrevPage = () => {
    if (currentPage == 1) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage == pagesCount) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const prevPageOptions = {
    onClick: handlePrevPage,
    disabled: currentPage === 1,
  };

  const nextPageOptions = {
    onClick: handleNextPage,
    disabled: currentPage === pagesCount,
  };

  const renderCurrentPage = () => (
    <H5>
      {`${messages.label_page} ${currentPage} ${messages.text_of} ${pagesCount}`}
    </H5>
  );

  const renderPaginatorTemplate = () => (
    <StyledNavigation>
      {paginatorTemplate.PrevPageLink(prevPageOptions)}
      {renderCurrentPage()}
      {paginatorTemplate.NextPageLink(nextPageOptions)}
    </StyledNavigation>
  );

  const handlePageLoadSuccess = (event) => {
    setPagesCount(event.numPages);
  };

  const renderLoader = () => (
    <Div height={400} width={1}>
      <ProgressSpinner />
    </Div>
  );

  return (
    <Div mt={3} display="flex" flexDirection="column" alignItems="center">
      <Document
        file={previewFile || previewFile.url}
        onLoadSuccess={handlePageLoadSuccess}
        loading={renderLoader()}
      >
        <Page pageNumber={currentPage} scale={1.5} />
      </Document>
      <Div display="flex" flexDirection="row" alignItems="center" mb={3}>
        {pagesCount > 1 && renderPaginatorTemplate()}
      </Div>
    </Div>
  );
};

PDFPreview.propTypes = {
  previewFile: PropTypes.object,
};

export default PDFPreview;
