import React, { createContext, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const StepperContext = createContext({
  steps: [],
  activeStepIndex: 0,
  setupSteps: () => {},
  onNextStep: () => {},
  onPrevStep: () => {},
});

export const StepperProvider = ({ children }) => {
  const [steps, setSteps] = useState([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const setupSteps = useCallback(
    (initialSteps) => {
      setSteps(initialSteps);
    },
    [setSteps],
  );

  const onNextStep = useCallback(() => {
    const nextIndex = activeStepIndex + 1;

    if (nextIndex >= steps.length) {
      return;
    }

    setActiveStepIndex(nextIndex);
  }, [activeStepIndex, steps.length]);

  const onPrevStep = useCallback(() => {
    const prevIndex = activeStepIndex - 1;

    if (prevIndex < 0) {
      return;
    }

    setActiveStepIndex(prevIndex);
  }, [activeStepIndex]);

  const resetSteps = useCallback(() => {
    setActiveStepIndex(0);
  }, []);

  const value = useMemo(
    () => ({
      steps,
      activeStepIndex,
      setupSteps,
      onNextStep,
      onPrevStep,
      resetSteps,
    }),
    [activeStepIndex, steps, setupSteps, onNextStep, onPrevStep, resetSteps],
  );

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.node,
};
