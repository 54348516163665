import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from "@components/DataTableV2/DataTable";
import { ROUTES, ORDERS_API, WRITE, READ } from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";

const searchableColumns = [
  "id",
  "number",
  "customer.customer_name",
  "user.firstname",
  "user.lastname",
];
import UserContainer from "@layout/UserContainer";

const List = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo || {};

  const handleCreateOrder = () => {
    history.push(ROUTES.SEARCH.URL);
  };

  const fetchUserName = (orderItems) => {
    const personNames = orderItems.map((o) => {
      return o.person_initials;
    });
    const uniqueUsers = [...new Set(personNames)];
    const lengthOfUsers = uniqueUsers.length;
    if (!lengthOfUsers) return "-";
    if (uniqueUsers[0] !== "") {
      if (lengthOfUsers === 1) return uniqueUsers[0];
      else return `${uniqueUsers[0]} + ${lengthOfUsers - 1}`;
    } else return "-";
  };

  const config = {
    header: {
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "orders.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_customer_orders_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ORDERS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          ["id", "created_at", "total_price", "customer_id", "user_id"],
        ],
        ["include", ["customer", "user", "orderItems", "payments"]],
        ["filter[type]", "PO"],
      ],
    },
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "number",
        type: "id",
        className: "text-blue",
        width: "100px",
        href: (values) => {
          const { id } = values;
          return `${ROUTES.ORDERS_DETAILS.URL}?id=${id}`;
        },
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "text",
        sortable: true,
        formatter: (value) => {
          return value ? formatDateAndTime(value) : "-";
        },
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
      },
      {
        title: "title_person",
        db_field: "order_items",
        type: "text",
        formatter: (value) => {
          return fetchUserName(value);
        },
      },
      {
        title: "label_service",
        db_field: "order_items",
        type: "text",
        formatter: (value) => {
          const totalOrderItems = value?.length;
          return totalOrderItems > 1
            ? `${value[0]?.name} + ${totalOrderItems - 1}`
            : value[0]?.name;
        },
      },
    ],
  };

  const priceColumn = {
    title: "watchlist_label_price",
    db_field: "total_price",
    type: "text",
    formatter: (value) => {
      return value ? `${value} kr` : "0 kr";
    },
  };

  const rowActions = {
    type: "actions",
    width: "50px",
    actions: [
      {
        type: "link",
        href: ({ id }) => `${ROUTES.ORDERS_DETAILS.URL}?id=${id}`,
      },
    ],
  };

  const headerConfig = {
    title: messages.title_orders,
    headerActions: [
      {
        id: "new-order",
        type: "button",
        label: messages.label_new_order,
        onClick: handleCreateOrder,
        width: [1, "auto"],
        icon: "plus",
        variant: "header",
        isHidden:
          !(
            permissions &&
            (permissions?.orders === WRITE ||
              permissions?.["self-orders"] === WRITE)
          ) ||
          (permissions?.orders === WRITE &&
            permissions?.["self-orders"] === READ),
      },
    ],
  };

  if (permissions?.prices === READ) {
    config.columns.push(priceColumn);
  }

  config.columns.push(rowActions);

  return (
    <UserContainer config={headerConfig}>
      <DataTable config={config} />
    </UserContainer>
  );
};

export default List;
