import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import { H2, H5 as StyledH5 } from "@components/Heading";
import Accordion from "@components/DeviationAccordion";
import Div from "@components/Div";
import { deviationProps } from "../config";
import Icon from "@components/Icon";
import Link from "@components/Link";
import Span from "@components/Span";
import { compose, space } from "styled-system";

const prepareDeviation = ({
  title = "title_deviations",
  grade = 0,
  description = "title_report",
  details = [],
  placeholder = {},
}) => ({
  title,
  grade,
  description,
  details,
  placeholder,
  headerTemplate: <></>,
  content: <></>,
});

const StyledUnorderList = styled.ul`
  ${compose(space)}
  list-style: disc;
  margin-left: 16px;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-bottom: 16px;
`;

const ValueIcons = {
  1: (
    <StyledIcon
      key={0}
      color="var(--white)"
      name="tick-mark"
      bg="var(--green-dark) !important"
      border="solid var(--green-dark) !important"
    />
  ),
  0: (
    <StyledIcon
      key={1}
      name="warning"
      bg="var(--yellow-medium) !important"
      border="solid var(--yellow-medium) !important"
    />
  ),
  "-1": (
    <StyledIcon
      key={2}
      color="var(--white)"
      name="error"
      border="solid var(--red-medium) !important"
      bg="var(--red-medium) !important"
    />
  ),
};

const DeviationToggleDisplay = {
  true: {
    text: "label_hide_deviation",
    icon: (
      <Icon
        name="arrowbig-up"
        fontSize="7px"
        ml={2}
        color="var(--blue-dark) !important"
      />
    ),
  },
  false: {
    text: "label_show_deviation",
    icon: (
      <Icon
        name="arrowbig-down"
        fontSize="7px"
        ml={2}
        color="var(--blue-dark) !important"
      />
    ),
  },
};

const H5 = styled(StyledH5)`
  display: block;
  font-size: var(--fs-h5)};
  font-weight: var(--light-weight) !important;
`;

const HeaderTemplate = ({ title = "", grade = 0 }) => (
  <Div className="p-accordion-header-template">
    <Div className="p-accordion-header-text">{title}</Div>
    <Div className="p-accordion-value-icon">{ValueIcons[grade]}</Div>
  </Div>
);

HeaderTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  grade: PropTypes.number.isRequired,
};

const hasLength = (items = []) => items?.length > 0;

const arrayOfN = (n) => [...Array(n)].map((_, index) => index);

const Content = ({ description = "", details = [""], placeholder = {} }) => (
  <>
    <H5 mb={hasLength(details) ? 2 : 0}>
      <FormattedMessage
        id={description}
        defaultMessage="No string defined"
        values={{ break: <br />, ...placeholder }}
      />
    </H5>
    {hasLength(details) && (
      <StyledUnorderList>
        {details.map((li) => (
          <>
            {typeof li === "string" && (
              <li key={li}>
                <H5 mb={1}>{li}</H5>
              </li>
            )}

            {typeof li !== "string" && (
              <li key={li.filename}>
                {li.document_download_link ? (
                  <Link
                    iconPos="left"
                    label={li.filename.split(".")[0]}
                    fontSize="14px"
                    mb={2}
                    href={li.document_download_link}
                  >
                    <Span pr={1}>
                      <Icon name="file-download" />
                    </Span>
                  </Link>
                ) : (
                  <H5 mb={1}>
                    {Object.keys(li)
                      .map((key) => li[key])
                      .join(" ")}
                  </H5>
                )}
              </li>
            )}
          </>
        ))}
      </StyledUnorderList>
    )}
  </>
);

Content.propTypes = {
  description: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.object,
};

const Deviations = ({ report = {} }) => {
  const { messages } = useIntl();
  const { sectionTitle } = deviationProps;
  const [activeIndex, setActiveIndex] = useState({
    left: [],
    right: [],
  });

  const handleTabChange = (target, event) => {
    const _activeIndex = {
      ...activeIndex,
      [target]: event.index,
    };
    setActiveIndex(_activeIndex);
  };

  const { leftColumn, rightColumn } = useMemo(() => {
    const leftColumn = [];
    const rightColumn = [];
    const deviations = report?.deviation || [];
    for (let i = 0; i < deviations.length; i++) {
      const deviation = prepareDeviation({
        ...deviations[i],
      });

      deviation.headerTemplate = (
        <HeaderTemplate
          title={messages[deviation?.title]}
          grade={deviation?.grade}
        />
      );

      deviation.content = (
        <Content
          description={deviation?.description}
          details={deviation?.details}
          placeholder={deviation?.placeholder}
        />
      );
      if (i % 2 === 0) {
        leftColumn.push(deviation);
      } else {
        rightColumn.push(deviation);
      }
    }
    return { leftColumn, rightColumn };
  }, [messages, report?.deviation]);

  const toggleDeviation = (target) => {
    const _activeIndex = target
      ? {
          left: [],
          right: [],
        }
      : {
          left: arrayOfN(leftColumn.length),
          right: arrayOfN(rightColumn.length),
        };
    setActiveIndex(_activeIndex);
  };

  const isVeviationOpen = useMemo(() => {
    const allLeftColumnOpened =
      activeIndex?.left?.length === leftColumn?.length;
    const allRightColumnOpened =
      activeIndex?.right?.length === rightColumn?.length;
    return allLeftColumnOpened && allRightColumnOpened;
  }, [activeIndex, leftColumn, rightColumn]);

  const ToggleDisplay = DeviationToggleDisplay[isVeviationOpen];

  return (
    <>
      <Div
        width={[1, 1, "88%", "96%"]}
        display={["block", "block", "flex", "flex"]}
        alignItems="center"
        justifyContent="space-between"
        mt={4}
      >
        <Div>
          <H2 color="var(--blue-dark) !important" mt={3} mb={2} pb={1}>
            {messages[sectionTitle]}
          </H2>
        </Div>
        <Div mb={[3, 0]}>
          <Link
            display="flex"
            alignItems="flex-end"
            mb={["3", "3", "0", "0"]}
            handleClick={toggleDeviation.bind(this, isVeviationOpen)}
          >
            <Icon name="bullets" mr={2} />
            {messages[ToggleDisplay?.text]}
            {ToggleDisplay.icon}
          </Link>
        </Div>
      </Div>
      <Div display="flex" flexDirection={["column", "column", "column", "row"]}>
        <Div width={[1, 1, 1, 1 / 2]}>
          <Accordion
            content={leftColumn}
            activeIndex={activeIndex.left}
            onTabChange={handleTabChange.bind(this, "left")}
            messages={messages}
            showCreditReport={true}
            reportId={report?.id}
            creditReport={report?.credit_report}
          />
        </Div>
        <Div width={[1, 1, 1, 1 / 2]}>
          <Accordion
            content={rightColumn}
            activeIndex={activeIndex.right}
            onTabChange={handleTabChange.bind(this, "right")}
            messages={messages}
            showCreditReport={true}
            reportId={report?.id}
            creditReport={report?.credit_report}
          />
        </Div>
      </Div>

      <Span display="block" mt={3} mb={[3, 4]}>
        {messages["report_actapublica_footer_deviation_props"]}
      </Span>
    </>
  );
};

Deviations.propTypes = {
  report: PropTypes.object,
};

export default Deviations;
