import React from "react";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { TextLargeSemiBoldWeight, Text } from "@components/Text";
import Checkbox from "@components/Checkbox";
import Icon, { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import Link from "@components/Link";
import { isCustomerUser, getFormattedNumber } from "@utils/utils";
import { SUBSCRIPTION_STATUS, BUNDLE } from "@utils/constant";
import Tooltip from "@components/ToolTip";
import { H5 } from "@components/Heading";

import usePermissions from "@hooks/usePermissions";
import { PERMISSIONS } from "@utils/enum";

const getDisplayedServices = (
  allServices = [],
  isAllServicesVisible = false,
) => {
  if (isAllServicesVisible) {
    return allServices;
  }

  const collapsedServicesList = allServices.slice(0, 3);
  collapsedServicesList.push(
    ...allServices.slice(
      Math.ceil(allServices.length / 2),
      Math.ceil(allServices.length / 2) + 3,
    ),
  );

  return collapsedServicesList;
};

const ServiceList = ({
  onChange,
  checked,
  index,
  subscriptionStatus,
  handleShowAllServiceOnClick,
  formikProps,
}) => {
  const { messages } = useIntl();
  const { checkForReadPermission } = usePermissions();
  const { setValues, values } = formikProps;

  const { services = [] } = checked;

  const displayedServices = getDisplayedServices(
    services,
    checked?.showAllServices,
  );

  const itemsPerColumn = Math.ceil(displayedServices.length / 2);

  const hasPricePermission = checkForReadPermission(PERMISSIONS.PRICES);

  const getPriceString = (service) => {
    const shouldRenderPrepaidLabel =
      isCustomerUser() &&
      service.purchase_by_credits &&
      subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE;

    if (shouldRenderPrepaidLabel) {
      return messages.prepaid_price_label;
    }

    if (!hasPricePermission) {
      return "";
    }

    const currencyString = `${getFormattedNumber(service.price)} ${
      messages.search_label_currency
    }`;

    return currencyString;
  };

  const renderPrice = (service) => (
    <Div
      ml={2}
      display="inline-flex"
      justifyContent="end"
      flex={service.purchase_by_credits ? 3 : [3, 3, 2, 2]}
    >
      {" "}
      <TextLargeSemiBoldWeight
        fontSize={[
          "var(--fs-text-m) !important",
          "var(--fs-text-m) !important",
          "var(--fs-text-m)",
          "var(--fs-text-m)",
        ]}
        whiteSpace={"nowrap"}
      >
        {getPriceString(service)}
      </TextLargeSemiBoldWeight>
    </Div>
  );

  return (
    <Div
      display="inline-flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
    >
      <Div display="flex" justifyContent="space-between" pb={10}>
        <Text display={["none", "none", "block", "block"]}>
          {`${messages.search_label_service_available}:`}
        </Text>
        <Text
          display={["inline-flex", "inline-flex", "none", "none"]}
          fontSize="var(--fs-text-m) !important"
        >
          {`${messages.search_label_service_available}:`}
        </Text>
        {hasPricePermission && (
          <>
            <Text display={["none", "none", "block", "block"]}>
              {messages.search_label_price_excluded}
            </Text>
            <Text
              display={["inline-flex", "inline-flex", "none", "none"]}
              fontSize="var(--fs-text-m) !important"
            >
              {messages.search_label_price_excluded}
            </Text>
          </>
        )}
      </Div>
      <Div
        display="inline-flex"
        justifyContent="space-between"
        flexWrap="wrap"
        pt={10}
      >
        {[...Array(2)].map((_, columnIndex) => (
          <Div
            key={services[columnIndex]?.listing_order}
            display="flex"
            flexDirection="column"
            width={["100%", "45%"]}
          >
            {displayedServices
              .slice(
                columnIndex * itemsPerColumn,
                (columnIndex + 1) * itemsPerColumn,
              )
              .map((service) => (
                <Div
                  key={service.id}
                  display="flex"
                  alignItems="start"
                  className="serviceData"
                  justifyContent="space-between"
                  pt={["20px", "20px", "10px", "10px"]}
                >
                  <Div
                    display="inline-flex"
                    wordBreak={"break-word"}
                    pb="10px"
                    flex={service.purchase_by_credits ? 5 : [9, 9, 7, 7]}
                    alignItems="flex-start"
                  >
                    <Checkbox
                      mt={"3px"}
                      checked={service?.isChecked}
                      onChange={onChange.bind(
                        this,
                        service.id,
                        index,
                        setValues,
                        values,
                      )}
                    />
                    <Div
                      ml={2}
                      display={["none", "none", "flex", "flex"]}
                      alignItems="center"
                    >
                      <H5>
                        {service.name}
                        {service.service_type === BUNDLE && (
                          <BundleIcon
                            mt={"3px"}
                            ml={2}
                            name="bundle-tag"
                          ></BundleIcon>
                        )}
                        <SmallEllipseIcon
                          mt={"3px"}
                          ml={2}
                          name="info"
                          className={`serviceName-${service.id}`}
                        >
                          <Tooltip
                            id={`${service.id}-${service.name}`}
                            key={`${service.id}-${service.name}`}
                            content={service.description}
                            target={`.serviceName-${service.id}`}
                          />
                        </SmallEllipseIcon>
                      </H5>
                    </Div>
                    <H5
                      ml={2}
                      display={["flex", "flex", "none", "none"]}
                      fontWeight="var(--semibold-weight) !important"
                    >
                      {service.name}
                      {service.service_type === BUNDLE && (
                        <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
                      )}
                      <SmallEllipseIcon
                        ml={2}
                        name="info"
                        className={`serviceName-${service.id}`}
                      >
                        <Tooltip
                          id={`${service.id}-${service.name}`}
                          key={`${service.id}-${service.name}`}
                          content={service.description}
                          target={`.serviceName-${service.id}`}
                        />
                      </SmallEllipseIcon>
                    </H5>
                  </Div>
                  {renderPrice(service)}
                </Div>
              ))}
          </Div>
        ))}
      </Div>
      {services.length > 4 && (
        <Div mt="20px">
          <Link
            width={1}
            label={
              checked.showAllServices
                ? messages.search_label_see_less
                : messages.search_label_see_all
            }
            onClick={handleShowAllServiceOnClick.bind(
              this,
              index,
              setValues,
              values,
            )}
          >
            <Icon
              ml={1}
              name={checked.showAllServices ? "up-arrow" : "download"}
            />
          </Link>
        </Div>
      )}
    </Div>
  );
};

ServiceList.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  index: PropTypes.number,
  subscriptionStatus: PropTypes.string,
  handleShowAllServiceOnClick: PropTypes.func,
  formikProps: PropTypes.object,
};

export default ServiceList;
