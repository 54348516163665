import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import Dialog from "@components/Dialog";
import Proptypes from "prop-types";
import { H3, H2 } from "@components/Heading";
import {
  ROUTES,
  SPECIAL_PACKAGE,
  INDIVIDUAL,
  COMPANY,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import { Ellipse24IconDark } from "@components/Icon";
import {
  loadServicesData,
  loadActiveSubscriptionData,
  calculateSingleAnalysisPrice,
} from "@utils/common";
import {
  Text,
  TextLarge,
  TextLargeSemiBoldWeight,
  TextMediumWeight,
  TextSemiBoldWeight,
  TextColouredSemiBold,
} from "@components/Text";
import Header from "../pages/user/ManageSubscription/Header";
import Card from "@components/Card";
import {
  PrimaryButtonIcon,
  PrimaryButton,
  PrimaryButtonOutlined,
  ButtonLink,
} from "@components/Button";
import { useIntl } from "react-intl";
import updateSubscription from "@app/services/subscription/updateSubscription";
import { useSelector } from "react-redux";
import ContactUs from "@components/contactUs";
const UpgradePackage = (props) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [downgradePopup, setDowngradePopup] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [activePackages, setActivePackages] = useState([]);
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  useEffect(() => {
    const { customer = {} } = userInfo;
    const { id: customer_id = "" } = customer;
    let package_list = loadServicesData(customer_id);
    package_list.then((result) => setPackages(result));
  }, [userInfo]);
  useEffect(() => {
    const activePackageData = loadActiveSubscriptionData({
      customer: userInfo,
    });
    activePackageData.then((result) => setActivePackages(result));
  }, [userInfo]);
  useEffect(() => {
    let message = "";
    if (Object.keys(userInfo).length !== 0) {
      if (userInfo.type === INDIVIDUAL) {
        message = messages.subscription_access_denied_for_individual;
      } else if (
        userInfo.type === COMPANY &&
        userInfo.permissions.subscription !== SUBSCRIPTION_STATUS.WRITE
      ) {
        message = messages.permissions_access_denied_for_subscription;
      }
    }
    if (message !== "") {
      history.push(`${ROUTES.ACCESSDENIED.URL}`, {
        message: message,
      });
    }
  }, [userInfo, history, messages]);

  const redirectOnSubmit = (id) => {
    history.push(ROUTES.SUBSCRIPTION_CHECKOUT.URL + "?id=" + id, {
      previous_route: ROUTES.UPGRADE_SUBSCRIPTION.URL,
    });
  };
  const handleDowngradePopup = (id, name, price, credits) => {
    let selected_data = {
      subscription_id: id,
      name: name,
      price: price,
      credits: credits,
    };
    setSelectedPackage(selected_data);
    setDowngradePopup(!downgradePopup);
  };
  const handleConfirmPopup = () => {
    setConfirmPopup(!confirmPopup);
  };
  function handleOkClicked() {
    history.push(ROUTES.COMPANY_INFO.URL);
  }
  const downgradeSubscription = async () => {
    const subscription_data = {
      product_id: selectedPackage.subscription_id,
      price: selectedPackage.price,
      user_id: userInfo.id,
      customer_id: userInfo.id,
      signed_by: "",
    };
    subscription_data.status = activePackages.status;
    await updateSubscription(subscription_data, activePackages.id);
    setDowngradePopup(!downgradePopup);
    setConfirmPopup(!confirmPopup);
  };
  return (
    <Section py={["40px", "40px", "60px", "60px"]}>
      <Container>
        <Div>
          <H2>
            {activePackages?.status === SUBSCRIPTION_STATUS.ACTIVE
              ? messages.current_package_heading
              : messages.buy_subscription}
          </H2>
          <Div mt={"25px"} width={1}>
            <TextLarge>{messages.choose_new_best_plan}</TextLarge>
          </Div>
          {props && !props.show_package_list && (
            <Div mt={["20px", "38px", "38px", "38px"]}>
              <Div display="flex" alignItems="baseline">
                <Ellipse24IconDark
                  mr={"-12px"}
                  name="tick-mark"
                  fontSize="var(--fs-text-s)"
                  p={"3px"}
                  zIndex="1"
                  rounded
                  backgroundColor="var(--blue-dark)"
                ></Ellipse24IconDark>
                <Div
                  backgroundColor={"var(--white)"}
                  py={"7px"}
                  pl={"22px"}
                  pr={"40px"}
                  width={1}
                >
                  <TextMediumWeight lineHeight="var(--lh-h3) !important">
                    {messages.current_package}: {activePackages.name}.{" "}
                    {messages.current_package_description1}{" "}
                    {activePackages.available_credits}/
                    {activePackages.total_credits}{" "}
                    {messages.current_package_description2} (
                    {`${Number(activePackages?.price)?.toLocaleString(
                      "sv-SE",
                    )} `}
                    {messages.price_currency}){" "}
                    {messages.current_package_description3}
                  </TextMediumWeight>
                </Div>
              </Div>
            </Div>
          )}
        </Div>

        <Div
          mt={["30px", "40px", "40px", "40px"]}
          display="flex"
          flexWrap="wrap"
          alignItems="start"
        >
          {packages.length > 0 &&
            packages.map((subscriptionPackage, packageKey) => {
              const { id, name, credits, price, discount } =
                subscriptionPackage;
              if (name !== SPECIAL_PACKAGE) {
                return (
                  <Div
                    key={packageKey}
                    mb={[3, 3, 0]}
                    pr={packages.length === packageKey + 1 ? 0 : [0, 3, 3, 3]}
                    width={[1, 1 / 2, 1 / 4]}
                  >
                    <Card header={<Header title={name} />} width={1}>
                      <Div px={3} display="flex" justifyContent="space-between">
                        <TextSemiBoldWeight>
                          {messages.analysiss}
                        </TextSemiBoldWeight>
                        <TextColouredSemiBold>{credits}</TextColouredSemiBold>
                      </Div>
                      <Div p={3} display="flex" justifyContent="space-between">
                        <TextSemiBoldWeight>
                          {messages.price}
                        </TextSemiBoldWeight>
                        <TextColouredSemiBold
                          display="flex"
                          alignItems="center"
                        >
                          {price?.toLocaleString("sv-SE")} kr
                        </TextColouredSemiBold>
                      </Div>
                      <Div
                        backgroundColor={"rgba(var(--grey-lightest-rgba), 0.4)"}
                        textAlign="center"
                        p={3}
                      >
                        <Text py={2} display="block">
                          {`${calculateSingleAnalysisPrice(
                            price,
                            credits,
                          ).toLocaleString("sv-SE")}
                          ${messages.price_currency}
                          ${messages.static_styck.toLowerCase()}`}
                        </Text>
                        <TextLargeSemiBoldWeight display="block">
                          {`${messages.subscription_discount_for_analys}: ${discount} %`}
                        </TextLargeSemiBoldWeight>
                      </Div>
                      <Div p={3} textAlign="center">
                        {activePackages.product_id &&
                          activePackages.product_id == id && (
                            <ButtonLink
                              color={"var(--grey-dark) !important"}
                              display="block"
                              width={1}
                            >
                              {messages.current}
                            </ButtonLink>
                          )}
                        {activePackages &&
                          activePackages.product_id &&
                          activePackages.product_id != id &&
                          activePackages.price < price && (
                            <PrimaryButtonIcon
                              display="block"
                              width={1}
                              onClick={redirectOnSubmit.bind(this, id)}
                            >
                              {messages.upgrade}
                            </PrimaryButtonIcon>
                          )}
                        {activePackages &&
                          activePackages.product_id &&
                          activePackages.product_id != id &&
                          activePackages.price > price && (
                            <PrimaryButtonIcon
                              display="block"
                              width={1}
                              onClick={handleDowngradePopup.bind(
                                this,
                                id,
                                name,
                                price,
                                credits,
                              )}
                            >
                              {messages.downgrade}
                            </PrimaryButtonIcon>
                          )}
                        {!activePackages && (
                          <PrimaryButtonIcon
                            display="block"
                            width={1}
                            onClick={redirectOnSubmit.bind(this, id)}
                          >
                            {messages.buy} {price?.toLocaleString("sv-SE")}{" "}
                            {messages.price_currency}
                          </PrimaryButtonIcon>
                        )}
                      </Div>
                    </Card>
                  </Div>
                );
              }
            })}
        </Div>
      </Container>
      <Container>
        <ContactUs />
      </Container>
      {downgradePopup && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          width={[1, 1, 1, 445]}
          onHide={handleDowngradePopup}
          m={[3, 3, 3, "auto"]}
        >
          <Div mt={2} textAlign={["center"]}>
            <H3 mb={3}>{messages.downgrade}</H3>
            <Text>
              {messages.confirm_text_for_downgrade} {selectedPackage.name}{" "}
              {messages.package_label}: {selectedPackage.credits}{" "}
              {messages.analyses_for} {selectedPackage.price}
              {messages.price_currency}.
            </Text>
            <Div
              mt={30}
              display="flex"
              alignItems="start"
              justifyContent="center"
            >
              <PrimaryButtonOutlined
                mx={15}
                minWidth="150px"
                minHeight="40px"
                justifyContent="center"
                onClick={handleDowngradePopup}
              >
                {messages.label_cancel}
              </PrimaryButtonOutlined>
              <PrimaryButton
                mx={15}
                minWidth="150px"
                minHeight="40px"
                justifyContent="center"
                onClick={downgradeSubscription.bind(this)}
              >
                {messages.downgrade}
              </PrimaryButton>
            </Div>
          </Div>
        </Dialog>
      )}
      {confirmPopup && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          width={[1, 1, 1, 445]}
          onHide={handleConfirmPopup}
          m={[3, 3, 3, "auto"]}
        >
          <Div mt={2} textAlign={["center"]}>
            <H3 mb={3}>{messages.label_done} </H3>
            <Text>
              {messages.renewal_package} {selectedPackage.name}{" "}
              {messages.package_label}: {selectedPackage.credits}{" "}
              {messages.analyses_for} {selectedPackage.price}
              {messages.price_currency}
            </Text>
            <Div
              mt={30}
              display="flex"
              alignItems="start"
              justifyContent="center"
            >
              <PrimaryButton
                mx={15}
                minWidth="150px"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                minHeight="40px"
                onClick={handleOkClicked}
              >
                {messages.label_ok}
              </PrimaryButton>
            </Div>
          </Div>
        </Dialog>
      )}
    </Section>
  );
};
UpgradePackage.propTypes = {
  show_package_list: Proptypes.bool,
};
export default UpgradePackage;
