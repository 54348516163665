import React from "react";
import ActiveSubscription from "../../../user/userProfile/ActiveSubscription";

export const Subscription = (prop) => {
  const { activePackage, customerData, redirectToSubscriptionDetailPage } =
    prop;
  const { orders = [] } = activePackage;
  return (
    <>
      <ActiveSubscription
        activePackage={activePackage}
        activityLogs={orders}
        userInfo={customerData}
        isAdmin
        redirectToSubscriptionDetailPage={redirectToSubscriptionDetailPage}
      />
      {/* )} */}
    </>
  );
};

export default Subscription;
