import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";
import StyledIcon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import styled from "styled-components";
import createWatchListOrder from "@app/services/watchlist/createWatchListOrder";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import { handleException } from "@utils/common";
import ProgressSpinner from "@components/ProgressSpinner";

const StyledList = styled.ul`
  columns: ${({ length }) => (length > 5 ? "2" : "1")};
`;

const AnalyseWatchListModal = ({
  onCloseModal,
  analyseMode,
  watchlistName,
  watchListId,
  customerAvailableCredits,
  selectedWatchlistObjects = [],
  handleRedirectToUpgradePlan,
  clearSelectedProducts,
}) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const handleSubmit = async () => {
    setIsCreatingOrder(true);
    try {
      await createWatchListOrder(watchListId, {
        watch_list_objects: selectedWatchlistObjects,
      });
      setIsCreatingOrder(false);
      setShowDoneModal(true);
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  };

  const handleClosePopup = () => {
    setShowErrorModal(false);
    onCloseModal();
  };

  const handleDone = () => {
    setShowDoneModal(false);
    clearSelectedProducts();
    onCloseModal();
    history.go(0);
  };

  return (
    <>
      {!showDoneModal && !showErrorModal && (
        <Div
          py={0}
          display="flex"
          alignItems="center"
          flexDirection="column"
          maxWidth="500px"
        >
          {isCreatingOrder && <ProgressSpinner />}
          <Div display="flex" alignItems="center" flexDirection="column" mt={2}>
            <H3>
              {analyseMode === "all"
                ? messages.watchlist_title_analyse_all_watchlist
                : messages.watchlist_title_analyse_selected_watchlist}
            </H3>
          </Div>
          <Div display="grid" mt={2} justifyContent="center">
            <TextLargeSemiBoldWeight justifySelf="center">
              {watchlistName}
            </TextLargeSemiBoldWeight>
            <TextLargeSemiBoldWeight
              mt={2}
              justifySelf="center"
              display="flex"
              alignItems="center"
            >
              <StyledIcon
                mr={1}
                name="account-circle"
                color="var(--turquoise)"
                fontSize="var(--fs-icon-m)"
              />
              {analyseMode === "all"
                ? `${selectedWatchlistObjects.length}  ${messages.watchlist_title_person}`
                : `${selectedWatchlistObjects.length}  ${messages.watchlist_label_person_chosen}`}
            </TextLargeSemiBoldWeight>
            {analyseMode === "selected" && (
              <Div
                display="flex"
                bg={"rgba(var(--grey-lightest-rgba), 0.4)"}
                p={3}
                borderRadius={8}
                mt={2}
              >
                <StyledList length={selectedWatchlistObjects.length}>
                  {selectedWatchlistObjects.map((object) => (
                    <li key={object.id}>
                      <Text>{object.name}</Text>
                    </li>
                  ))}
                </StyledList>
              </Div>
            )}
            {customerAvailableCredits < selectedWatchlistObjects.length && (
              <Div
                display="grid"
                mt={2}
                justifySelf="center"
                borderColor={"var(--turquoise)"}
                borderWidth="1px"
                borderStyle="dashed"
              >
                <Text pt={3} px={3}>
                  {messages.watchlist_message_not_enough_credits}
                </Text>
                <Div p={3}>
                  <LinkArrow
                    direction="left"
                    label={messages.watchlist_label_upgrade_plan}
                    onClick={handleRedirectToUpgradePlan}
                    disabled={isCreatingOrder}
                  >
                    <StyledIcon ml={1} name="headerarrowright" />
                  </LinkArrow>
                </Div>
              </Div>
            )}
          </Div>
          <Div
            mt={3}
            display="flex"
            flexDirection={["column-reverse", "row"]}
            justifyContent="center"
          >
            <PrimaryButtonOutlined
              rounded
              semibold
              label={messages.watchlist_label_cancel}
              onClick={onCloseModal}
              width={[300, 160]}
              mr={[0, 4]}
              disabled={isCreatingOrder}
            />
            <PrimaryButtonIcon
              rounded
              semibold
              width={[300, 160]}
              mb={[3, 0]}
              onClick={handleSubmit}
              label={messages.watchlist_label_confirm}
              disabled={isCreatingOrder}
            />
          </Div>
        </Div>
      )}
      {showDoneModal && !showErrorModal && (
        <Div display="flex" alignItems="center" flexDirection="column" mt={2}>
          <H3>{`${messages.watchlist_label_done}!`}</H3>
          <Text mt={4}>{messages.watchlist_message_analyse_done}</Text>
          <Div
            mt={4}
            display="flex"
            flexDirection={["column-reverse", "row"]}
            justifyContent="center"
          >
            <PrimaryButtonIcon
              rounded
              semibold
              width={150}
              mb={[3, 0]}
              onClick={handleDone}
              label={messages.watchlist_label_ok}
            />
          </Div>
        </Div>
      )}
      {showErrorModal && (
        <ErrorModal
          handleConfirmPopup={handleClosePopup}
          content={errorMessage}
        />
      )}
    </>
  );
};

AnalyseWatchListModal.propTypes = {
  onCloseModal: PropTypes.func,
  analyseMode: PropTypes.string,
  watchlistName: PropTypes.string,
  watchListId: PropTypes.number,
  customerAvailableCredits: PropTypes.number,
  selectedWatchlistObjects: PropTypes.array,
  handleRedirectToUpgradePlan: PropTypes.func,
  clearSelectedProducts: PropTypes.func,
};

export default AnalyseWatchListModal;
