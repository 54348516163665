import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { ButtonLinkIcon } from "@components/Button";
import ContextMenu from "@components/ContextMenu";
import Div from "@components/Div";
import { H4 } from "@components/Heading";
import Icon, { SmallEllipseIcon } from "@components/Icon";

import SurveyBuilderDialog from "./SurveyBuilderDialog";

import { FORM_TYPE, SURVEY_BUILDER_DEFAULT_TAB } from "@utils/enum";

const FormListItem = ({ type, data, onUpdate }) => {
  const { messages } = useIntl();

  const [isSurveyBuilderDialogVisible, setIsSurveyBuilderDialogVisible] =
    useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [surveyCreatorActiveTab, setSurveyCreatorActiveTab] = useState(
    SURVEY_BUILDER_DEFAULT_TAB.DESIGNER,
  );

  const menuRef = useRef(null);

  const isFormCreated = data !== null;

  const handleSurveyBuilderVisibility = () => {
    setIsSurveyBuilderDialogVisible(!isSurveyBuilderDialogVisible);
  };

  const handleOpenPreview = () => {
    setSurveyCreatorActiveTab(SURVEY_BUILDER_DEFAULT_TAB.PREVIEW);
    handleSurveyBuilderVisibility();
  };

  const handleOpenDesigner = () => {
    setSurveyCreatorActiveTab(SURVEY_BUILDER_DEFAULT_TAB.DESIGNER);
    handleSurveyBuilderVisibility();
  };

  const handleOpenLogic = () => {
    setSurveyCreatorActiveTab(SURVEY_BUILDER_DEFAULT_TAB.LOGIC);
    handleSurveyBuilderVisibility();
  };

  const handleOpenJSONEditor = () => {
    setSurveyCreatorActiveTab(SURVEY_BUILDER_DEFAULT_TAB.EDITOR);
    handleSurveyBuilderVisibility();
  };

  const handleDeleteForm = () => {
    onUpdate(null);
  };

  const contextMenuItems = [
    {
      id: 0,
      label: messages.label_preview,
      icon: <Icon name="menu-eye" />,
      command: handleOpenPreview,
    },
    {
      id: 1,
      label: messages.label_designer,
      icon: <Icon name="menu-edit" />,
      command: handleOpenDesigner,
    },
    {
      id: 2,
      label: messages.label_logic,
      icon: <Icon name="menu-list" />,
      command: handleOpenLogic,
    },
    {
      id: 3,
      label: messages.label_json_editor,
      icon: <Icon name="menu-report" />,
      command: handleOpenJSONEditor,
    },
    {
      id: 4,
      label: messages.label_delete_form,
      icon: <Icon name="menu-trash" />,
      command: handleDeleteForm,
    },
  ];

  const fromTitle = {
    [FORM_TYPE.CUSTOMER]: messages.title_customer_form,
    [FORM_TYPE.REPORT]: messages.title_report_form,
    [FORM_TYPE.CANDIDATE]: messages.title_candidate_form,
  };

  const getTitleColorByFormCreationStatus = () => {
    return isFormCreated
      ? "var(--grey-dark) !important"
      : "var(--grey) !important";
  };

  const getMenuButtonBackgroundColor = () => {
    return isMenuVisible
      ? "var(--blue-dark) !important"
      : "var(--grey-lightest) !important";
  };

  const getMenuButtonIconColor = () => {
    return isMenuVisible
      ? "var(--white) !important"
      : "var(--blue-dark) !important";
  };

  const handleShowContextMenu = (event) => {
    menuRef.current?.show(event);
  };

  const handleMenuVisible = () => {
    setIsMenuVisible(true);
  };

  const handleMenuNotVisible = () => {
    setIsMenuVisible(false);
  };

  return (
    <Div
      mt={3}
      p={3}
      width={[1, 1, 1, "80%"]}
      height="60px"
      borderRadius={20}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 30px rgba(0, 0, 0, 0.15)"
    >
      <H4
        fontWeight="var(--semibold-weight) !important"
        color={getTitleColorByFormCreationStatus()}
      >
        {fromTitle[type]}
      </H4>
      <>
        {isFormCreated ? (
          <>
            <ContextMenu
              items={contextMenuItems}
              menuRef={menuRef}
              onShow={handleMenuVisible}
              onHide={handleMenuNotVisible}
            />
            <SmallEllipseIcon
              name="more-vertical"
              backgroundColor={getMenuButtonBackgroundColor()}
              color={getMenuButtonIconColor()}
              onClick={handleShowContextMenu}
            />
          </>
        ) : (
          <ButtonLinkIcon
            label={messages.label_create_form}
            onClick={handleSurveyBuilderVisibility}
            iconPos="left"
            icon={<Icon mr={1} name="plus" />}
          />
        )}
      </>
      {isSurveyBuilderDialogVisible && (
        <SurveyBuilderDialog
          data={data}
          surveyCreatorActiveTab={surveyCreatorActiveTab}
          onHide={handleSurveyBuilderVisibility}
          onUpdate={onUpdate}
        />
      )}
    </Div>
  );
};

FormListItem.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default FormListItem;
