import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";

const QuestionDialog = ({ couponName, onConfirm, onCancel }) => {
  const { messages } = useIntl();

  return (
    <Dialog
      hideCloseButton
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "auto", "auto"]}
      m={[3, 3, "auto", "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={4}
      >
        <H3>{messages.title_delete_coupon}</H3>
        <Text textAlign="center">
          <FormattedMessage
            id="message_delete_coupon"
            values={{
              coupon_name: couponName,
            }}
          />
        </Text>
        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={4}
          width={1}
          px={3}
        >
          <PrimaryButton
            label={messages.label_ok}
            onClick={onConfirm}
            width={1}
          />
          <PrimaryButtonOutlined
            label={messages.label_cancel}
            onClick={onCancel}
            width={1}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

QuestionDialog.propTypes = {
  couponName: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default QuestionDialog;
