import React from "react";
import { PropTypes } from "prop-types";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import Div from "@components/Div";
import AdminHeaderMain from "./AdminHeaderMain";

const AdminContainer = (props) => {
  return (
    <ScrollToTop>
      <Div
        backgroundColor={"var(--white)"}
        borderBottom={"2px solid var(--grey-lightest)"}
        maxWidth={["100%", "100%", "100%", "1320px"]}
        width={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
      >
        <AdminHeaderMain config={props.config} />
      </Div>

      <Div
        m={["16px", "16px", "16px", "32px"]}
        maxWidth={["100%", "100%", "100%", "1320px"]}
      >
        <Container m="0 !important">{props.children}</Container>
      </Div>
    </ScrollToTop>
  );
};

AdminContainer.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node,
};

export default AdminContainer;
