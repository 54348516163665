import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTable from "@components/Table/Table";
import Span from "@components/Span";
import {
  GET_SUBSCRIPTION,
  SPECIAL_PACKAGE,
  INPUT,
  ADMIN,
  GET_SUBSCRIPTION_DETAIL,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import Dialog from "@components/Dialog";
import { Formik, Form as FormikForm } from "formik";
import { InputText } from "@components/InputText";
import RadioButton from "@components/RadioButton";
import Label from "@components/Label";
import Dropdown from "@components/Dropdown";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import createSubscription from "@app/services/subscription/createSubscription";
import customerList from "@app/services/customers/customerService";
import userService from "@app/services/users/userService";
import ErrorMessage from "../../shared/ErrorMessage";
import { Text } from "@components/Text";
import { H3, H4 } from "@components/Heading";
import Div from "@components/Div";
import { loadServicesData } from "@utils/common";
import SubscriptionValidation from "@pages/auth/schema/SubscriptionValidation";
import SpecialSubscriptionValidation from "@pages/auth/schema/SpecialSubscriptionValidation";
import AutoComplete from "@components/AutoComplete";
import AdminContainer from "@layout/AdminContainer";

const SubscriptionList = () => {
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const history = useHistory();
  const [showNewSubscriptionPopup, setShowNewSubscriptionPopup] =
    useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [specialInputs, setspecialInputs] = useState(false);
  const [packages, setPackage] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [customersList, setcustomersList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showSelectedUser, setShowSelectedUser] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [subsPackages, setSubsPackages] = useState([]);
  const [filterPackages, setFilterPackages] = useState([]);

  let initialData = {
    user_id: "",
    customer_id: "",
    package: "",
    availableanalysis: "",
    totalanalysis: "",
    price: "",
    specialInputs: specialInputs,
  };
  //* INPUT VALUE Const is here */
  const {
    CUSTOMER_ID,
    USER_ID,
    PACKAGE,
    AVAILABLE_ANALYSIS,
    TOTAL_ANALYSIS,
    PRICE,
  } = INPUT.NAME;

  let InfoSchemaPackages = SpecialSubscriptionValidation.concat(
    SpecialSubscriptionValidation.pick([PACKAGE]),
  )
    .concat(SubscriptionValidation.pick([CUSTOMER_ID]))
    .concat(SubscriptionValidation.pick([USER_ID]));
  if (packages.name === SPECIAL_PACKAGE) {
    SpecialSubscriptionValidation.concat(
      SpecialSubscriptionValidation.pick([AVAILABLE_ANALYSIS]),
    )
      .concat(SpecialSubscriptionValidation.pick([TOTAL_ANALYSIS]))
      .concat(SpecialSubscriptionValidation.pick([PRICE]));
  }
  // Form Validation End here

  const { TEXT } = INPUT.TYPE;

  const [subscriptionPermission, setSubscriptionPermission] = useState(
    SUBSCRIPTION_STATUS.READ,
  );

  const handleRedirectToDetails = (e) => {
    const { customer_id } = e.data;
    history.push(`${GET_SUBSCRIPTION_DETAIL}/${customer_id}`);
  };

  const handleCreateSubscription = () => {
    setShowNewSubscriptionPopup(!showNewSubscriptionPopup);
  };
  const createSubscriptionByAdmin = async (user_data) => {
    const { customer_id: { value = "" } = {} } = user_data;
    const subscription_data = {
      name: packages.name,
      product_id: packages.id,
      user_id: user_data.user_id,
      customer_id: value,
      signed_by: ADMIN + " " + userInfo.email,
    };
    if (packages.name === SPECIAL_PACKAGE) {
      subscription_data.price = user_data.price;
      subscription_data.available_credits = user_data.availableanalysis;
      subscription_data.total_credits = user_data.totalanalysis;
    } else {
      subscription_data.price = packages.price;
    }
    try {
      await createSubscription(subscription_data);
      setShowNewSubscriptionPopup(!showNewSubscriptionPopup);
      setshowSuccessPopup(!showSuccessPopup);
    } catch (e) {
      const getThings = ({ response: { data } }) => {
        return data;
      };
      let errors_data = getThings(e);
      let { error = "" } = errors_data;
      setErrorMessage(error);
      throw new Error(error);
    }
  };

  const onCancel = () => {
    setReloadList(!reloadList);
    setshowSuccessPopup(!showSuccessPopup);
  };
  //* Radio Buttom Checked Handler*/

  const handleSelectedPackage = (event, values, setValues) => {
    let special;
    if (event.name === SPECIAL_PACKAGE) {
      setspecialInputs(true);
      special = true;
    } else {
      setspecialInputs(false);
      special = false;
    }
    values.package = event.id;
    values.specialInputs = special;
    setValues(values);
    setPackage(event);
  };
  //* Get Dropdown list form DB Start Here */

  useEffect(() => {
    let customerData = [];
    if (customersList.length <= 0) {
      customerList().then((result) => {
        customerData = result.data.data.map((obj) => {
          const { customer_name: name = "", id = "" } = obj;
          return {
            label: `${name} - #${id}`,
            value: id,
          };
        });
        setcustomersList(customerData);
      });
    }
    const { permissions: { subscription = "" } = {} } = userInfo;
    setSubscriptionPermission(subscription);
  }, [userInfo, customersList]);

  const handleBlurCustomer = (onBlur, values, setFieldValue) => async (e) => {
    onBlur(e);
    const { customer_id: { value = "" } = {} } = values;
    if (value) {
      setShowSelectedUser(value);
      fetchUsers(value, setFieldValue);
    }
  };

  const fetchUsers = (customer_id, setFieldValue) => {
    let userData = [];
    userService("per_page=1000&filter[customer.id]=" + customer_id).then(
      (result) => {
        let { data: { data: users = [] } = {} } = result;
        const { id: userId = "" } = users[0] || {};
        setFieldValue(USER_ID, userId);
        userData = users.map((object) => {
          const { name = "", id = "" } = object;
          return {
            label: name,
            value: id,
          };
        });
        setUserList(userData);
      },
    );
  };
  //* Start Get Packages from API */
  useEffect(() => {
    const getPackages = async () => {
      const products = await loadServicesData();
      setSubsPackages(products);
      const packageFilter = products.map((plan) => {
        const { name, id } = plan;
        return { label: name, value: id, checked: false };
      });
      setFilterPackages(packageFilter);
    };
    getPackages();
  }, []);
  //* End Get Packages from API */
  let config = [
    {
      title: messages.subscripttion_list,
      emptyMessage: messages.no_subscription_found,
      headerActions: [
        {
          id: "new-subscription",
          type: "button",
          onClick: handleCreateSubscription,
          icon: "plus",
          variant: "header",
          label: messages.label_new_subscription,
          height: 0,
          isHidden: subscriptionPermission !== SUBSCRIPTION_STATUS.WRITE,
        },
        {
          id: "filter",
          type: "button-raised",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
        },
      ],
      columns: [
        { field: "status", header: messages.label_status },
        { field: "name", header: messages.package_label, primary: true },
        { field: "customer", header: messages.title_customer, sortable: true },
        {
          field: "available_credits",
          header: messages.left_analysis_label,
          sortable: false,
        },
        {
          field: "renewal_date",
          header: messages.next_payment_label,
          sortable: true,
        },
        { field: "action", header: messages.title_actions },
      ],
      filterType: "dialog",
      filters: [
        {
          title: messages.label_status,
          id: "status",
          type: "checkbox",
          width: 0.33,

          options: [
            {
              label: messages.active,
              value: SUBSCRIPTION_STATUS.ACTIVE,
              checked: false,
            },
            {
              label: messages.stopped_label,
              value: SUBSCRIPTION_STATUS.STOPPED,
              checked: false,
            },
            {
              label: messages.terminated_label,
              value: SUBSCRIPTION_STATUS.TERMINATED,
              checked: false,
            },
          ],
        },
        {
          title: messages.package_label,
          id: "products",
          type: "checkbox",
          options: filterPackages,
        },
        {
          title: messages.label_date,
          id: "date",
          type: "calendar",
          label: messages.label_date,
          options: [
            {
              label: messages.filter_date_from,
              value: "",
              id: "start_date",
            },
            {
              label: messages.filter_date_to,
              value: "",
              id: "end_date",
            },
          ],
        },
      ],
      rowActions: [
        {
          id: "more",
          label: messages.label_more,
          type: "link",
          icon: "headerarrowright",
          variant: "column",
          isPrimary: true,
        },
      ],
      pagination: true,
      sortable: true,
      selectionMode: "multiple",
    },
  ];
  let headerConfig = [
    {
      title: messages.subscripttion_list,
      headerActions: [
        {
          id: "new-subscription",
          type: "button",
          onClick: handleCreateSubscription,
          icon: "plus",
          variant: "header",
          label: messages.label_new_subscription,
          height: 0,
          isHidden: subscriptionPermission !== SUBSCRIPTION_STATUS.WRITE,
        },
      ],
    },
  ];
  const searchCustomers = (event) => {
    let filteredcustomers;
    if (!event.query.trim().length) {
      filteredcustomers = [customersList];
    } else {
      filteredcustomers = customersList.filter((obj) => {
        return (
          obj.label &&
          obj.label.toLowerCase().includes(event.query.toLowerCase())
        );
      });
    }
    setFilteredCustomers(filteredcustomers);
  };
  return (
    <>
      <AdminContainer config={headerConfig}>
        <DataTable
          config={config}
          screenName={GET_SUBSCRIPTION}
          handleRedirectToDetails={handleRedirectToDetails}
          reloadList={reloadList}
        />
        {showNewSubscriptionPopup && (
          <Dialog
            visible="displayBasic"
            draggable={false}
            width={[1, 1, 1, 500]}
            m={[3, 3, 3, "auto"]}
            onHide={handleCreateSubscription}
          >
            <Div mt={2} textAlign={["center"]}>
              <H3>{messages.new_subscription_label}</H3>
              <Div my={4}>
                <Text>{messages.new_subscription_description}</Text>
              </Div>
              <Div>
                <Formik
                  initialValues={initialData}
                  validationSchema={InfoSchemaPackages}
                  onSubmit={createSubscriptionByAdmin}
                >
                  {(formik) => {
                    const {
                      isValid,
                      values,
                      touched,
                      setValues,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    } = formik;
                    return (
                      <FormikForm
                        textAlign={["center", "center", "center", "left"]}
                      >
                        <Div>
                          <Div width={[1]}>
                            <Div
                              display="flex"
                              flexWrap="wrap"
                              alignItems="start"
                            >
                              <Div mb={4} width={[1]}>
                                <H4>{messages.customer_label}</H4>
                                <AutoComplete
                                  value={values && values.customer_id}
                                  field="label"
                                  display="grid"
                                  placeholder={`${messages.search_text}...`}
                                  curved
                                  py={3}
                                  name={CUSTOMER_ID}
                                  type="search"
                                  width={"100% !important"}
                                  completeMethod={searchCustomers}
                                  suggestions={filteredCustomers}
                                  onChange={handleChange}
                                  onBlur={handleBlurCustomer(
                                    handleBlur,
                                    values,
                                    setFieldValue,
                                  )}
                                />
                              </Div>
                              <Div mb={4} width={[1]}>
                                <H4>{messages.user_label}</H4>
                                <Dropdown
                                  placeholder={messages.user_label}
                                  value={values && values.user_id}
                                  label="User"
                                  name={USER_ID}
                                  options={userList}
                                  disabled={
                                    !(showSelectedUser && values.customer_id)
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  display="flex"
                                  mt={3}
                                />
                              </Div>

                              <Div width={[1]}>
                                <H4>{messages.package_label}:</H4>
                                <Div
                                  display="flex"
                                  flexWrap="wrap"
                                  textAlign="left"
                                >
                                  {subsPackages.map((subscriptionPackage) => {
                                    return (
                                      <Div
                                        key={subscriptionPackage.id}
                                        mt="3"
                                        width={[1, 1 / 2, 1 / 3]}
                                      >
                                        <RadioButton
                                          data={subscriptionPackage.id}
                                          name={PACKAGE}
                                          value={values && values.package}
                                          onChange={handleSelectedPackage.bind(
                                            this,
                                            subscriptionPackage,
                                            values,
                                            setValues,
                                          )}
                                          checked={
                                            packages.id ===
                                            subscriptionPackage.id
                                          }
                                          formikProps={formik}
                                        />
                                        <Label ml={2}>
                                          {subscriptionPackage.name}
                                        </Label>
                                      </Div>
                                    );
                                  })}
                                  {specialInputs && (
                                    <Div display="flex" flexWrap="wrap">
                                      <Div pr={3} width={[1, 1 / 2, 1 / 2]}>
                                        <InputText
                                          curved
                                          placeholder="1 000"
                                          label="Available Analysis:"
                                          value={values.availableanalysis}
                                          name={AVAILABLE_ANALYSIS}
                                          formikProps={formik}
                                          type={TEXT}
                                          labelAlignment="left"
                                          width={1}
                                        />
                                      </Div>
                                      <Div pl={3} width={[1, 1 / 2, 1 / 2]}>
                                        <InputText
                                          curved
                                          placeholder="1 000"
                                          label="Total Analysis:"
                                          value={values.totalanalysis}
                                          name={TOTAL_ANALYSIS}
                                          formikProps={formik}
                                          type={TEXT}
                                          labelAlignment="left"
                                          width={1}
                                        />
                                      </Div>
                                      <Div pr={3} width={[1, 1 / 2, 1 / 2]}>
                                        <InputText
                                          curved
                                          placeholder="537 000"
                                          label="Price, kr:"
                                          value={values.price}
                                          name={PRICE}
                                          formikProps={formik}
                                          type={TEXT}
                                          labelAlignment="left"
                                          width={1}
                                        />
                                      </Div>
                                    </Div>
                                  )}
                                </Div>
                                {errorMessage && (
                                  <Div display="flex" justifyContent="center">
                                    <ErrorMessage errorMessage={errorMessage} />
                                  </Div>
                                )}
                                <Div mt={4}>
                                  <PrimaryButtonOutlined
                                    mx={2}
                                    onClick={handleCreateSubscription}
                                  >
                                    {messages.label_cancel}
                                  </PrimaryButtonOutlined>
                                  <PrimaryButton
                                    mx={2}
                                    type="submit"
                                    disabled={
                                      !(
                                        isValid &&
                                        Object.keys(touched).length > 0
                                      )
                                    }
                                  >
                                    {messages.label_create}
                                  </PrimaryButton>
                                </Div>
                              </Div>
                            </Div>
                          </Div>
                        </Div>
                      </FormikForm>
                    );
                  }}
                </Formik>
              </Div>
            </Div>
          </Dialog>
        )}
        {showSuccessPopup && (
          <Dialog
            visible="displayBasic"
            draggable={false}
            width={[1, 1, 1, 500]}
            m={[3, 3, 3, "auto"]}
            onHide={onCancel}
          >
            <Div display="flex" flexDirection="column" alignItems="center">
              <Div mt={3} mb={4}>
                <Span>{messages.success_order_created}</Span>
              </Div>
              <PrimaryButton
                rounded
                semibold
                mb={[30, 0]}
                label={messages.label_done}
                onClick={onCancel}
              />
            </Div>
          </Dialog>
        )}
      </AdminContainer>
    </>
  );
};

export default SubscriptionList;
