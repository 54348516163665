import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { APPLICATION_TYPE } from "@utils/enum";

const Schema = Yup.object({
  code: Yup.string().required(
    <FormattedMessage id="validation_enter_coupon_name" />,
  ),
  discount_type: Yup.string().required(
    <FormattedMessage id="validation_enter_discount_type" />,
  ),
  discount_value: Yup.string().required(
    <FormattedMessage id="validation_enter_size_on_discount" />,
  ),
  valid_from: Yup.string().required(
    <FormattedMessage id="validation_enter_valid_from" />,
  ),
  valid_to: Yup.string().required(
    <FormattedMessage id="validation_enter_expire_date" />,
  ),
  max_allowed_usages: Yup.number().required(
    <FormattedMessage id="validation_enter_number_of_uses" />,
  ),
  application_type: Yup.string().required(
    <FormattedMessage id="validation_enter_product_type" />,
  ),
  products: Yup.object()
    .typeError(<FormattedMessage id="validation_enter_product" />)
    .when("application_type", {
      is: (productType) => productType === APPLICATION_TYPE.ENTIRE_ORDER,
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema.required(<FormattedMessage id="validation_enter_product" />),
    }),
});

export default Schema;
