import React from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Container from "@components/Container";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";

import { ROUTES, READ, WRITE } from "@utils/constant";
import Timelog from "./Timelog";
import ObjectServicesCard from "./ObjectServicesCard";
import OrderInfo from "./OrderInfo";
import OrderBillingCard from "./OrderBillingCard";
import { isAdminUser } from "@utils/utils";

const OrderDetails = ({
  orderSummaryProps,
  redirectTo = ROUTES.ORDERS_LIST.URL,
  isCustomer,
  isAdmin,
  onUpdateOrderSummaryProps,
}) => {
  const {
    showTimeLog = isCustomer,
    status = "",
    number: orderNum = "",
    watchList = {},
    reports = [],
    assignments = [],
    order_items: orderItems = [],
    payments = [],
  } = orderSummaryProps;

  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const isBillingPermission = isAdminUser()
    ? permissions["billing"] === WRITE || permissions["billing"] === READ
    : permissions["company-information"] === WRITE ||
      permissions["company-information"] === READ;

  const paymentMethodInvoice = payments.some(
    (payment) =>
      payment.payment_method_id === 2 || payment.payment_method_id === 5,
  );

  const breadCrumbItems = [
    { label: messages.title_orders, url: redirectTo },
    { label: `#${orderNum}` },
  ];

  const [report = {}] = reports;

  return (
    <Div mb={40}>
      <Div>
        <Breadcrumb pl={0} my={3} items={breadCrumbItems} />
      </Div>
      <Container m={"0 !important"}>
        <Div pb={2}>
          <Div my={3} py={2}>
            <OrderInfo
              data={orderSummaryProps}
              isWatchList={watchList && watchList !== null}
            />
          </Div>
        </Div>

        <Div>
          <ObjectServicesCard
            orderItems={orderItems}
            showDelivery={false}
            isDetails={true}
            status={status}
            isAdmin={isAdmin}
            isWatchList={watchList && watchList !== null}
            reports={reports}
            assignments={assignments}
            showAnalysLink={true}
          />
        </Div>

        {paymentMethodInvoice && isBillingPermission && (
          <Div mt={2}>
            <OrderBillingCard
              orderSummaryProps={orderSummaryProps}
              onUpdateOrderSummaryProps={onUpdateOrderSummaryProps}
            />
          </Div>
        )}

        {showTimeLog && (
          <Timelog logs={report.logs} ssn={report.personal_number} />
        )}
      </Container>
    </Div>
  );
};

OrderDetails.propTypes = {
  orderSummaryProps: Proptypes.array,
  redirectTo: Proptypes.string,
  showTimeLog: Proptypes.bool,
  isCustomer: Proptypes.bool,
  isAdmin: Proptypes.bool,
  onUpdateOrderSummaryProps: Proptypes.func,
};

export default OrderDetails;
