import messages_en from "@translation/en.json";
import messages_sv from "@translation/sv.json";

const messages = {
  en: messages_en,
  sv: messages_sv,
};
const applanguage = "sv";
const language = messages[applanguage];

export default {
  description: `${language.text_we_use_cookie} <a data-cc=c-settings>${language.text_customize_your_choice}</a>`,
  primary_btn_text: language.text_accept_cookie,
  secondary_btn_text: language.text_reject_cookie,
  settings_modal: {
    title: language.text_cookie_preference,
    save_settings_btn: language.text_save_and_accept,
    // accept_all_btn: "Accept all",
    blocks: [
      {
        title: language.text_overview_and_settings,
        description: `${language.text_we_use_cookie_2} <a href=https://bakgrundsanalys.se/personuppgiftspolicy/>${language.privacy_policy}</a>. ${language.text_as_a_user}`,
      },
      {
        title: language.text_necessary,
        description: language.text_necessary_description,
        toggle: {
          value: "necessary",
          enabled: true,
          readonly: true,
        },
      },
      {
        title: language.analysis,
        description: language.text_analysis_description,
        toggle: {
          value: "analytics",
          enabled: false,
          readonly: false,
        },
      },
    ],
  },
};
