import {
  SVLANG,
  ROUTES,
  LOGIN,
  REGISTER_ACCOUNT,
  REGISTER_WITH_BANKID,
  ORDERS_DETAILS,
  CHECKOUT,
  ORDER_CONFIRMATION,
  SUBSCRIPTION_ORDER_CONFIRMATION,
  ORDER_RESULTS,
  SEARCH,
  ADMIN_SEARCH,
  RESET_PASSWORD,
  SET_PASSWORD,
  FORGOT_PASSWORD,
  HOME,
  USER_PROFILE,
  CUSTOMER_DETAILS,
  CUSTOMER_ORDERS,
  CUSTOMER_CREDITS,
  CUSTOMER_USERS,
  CUSTOMER_ANALYSIS,
  CUSTOMER_PRODUCTS,
  REGISTRATION_SUCCESSFUL,
  PAGE_NOT_FOUND,
  ADMIN_CUSTOMERS,
  ASSIGNMENT_DETAILS,
  ADMIN_ORDER_DETAILS,
  ADMIN_ASSIGNMENTS,
  ADMIN_ORDERS,
  ADMIN_ANALYSIS_LIST,
  ADMIN_USERS,
  ADMIN_ADMINS_LIST,
  ADMIN_CUSTOMERS_LIST,
  ORDERS,
  ANALYSIS_API,
  SUB_USERS,
  USER_INFO,
  COMPANY_INFO,
  CREATE_BILLING_ACCOUNT,
  BILLING_ACCOUNT_INFO,
  ADMIN_COMPLETE_REGISTRATION,
  USER_COMPLETE_REGISTRATION,
  ADMIN_FORGOT_PASSWORD_SUCCESSFUL,
  USER_FORGOT_PASSWORD_SUCCESSFUL,
  ADMIN_REGISTRATION_COMPLETED,
  USER_REGISTRATION_COMPLETED,
  ADMIN_CREATE_CUSTOMER,
  ADMIN_MY_PROFILE,
  USER_CREDITS,
  SUBSCRIPTION_CHECKOUT,
  ACCESS_DENIED,
  UPGRADE_SUBSCRIPTION,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTIONS,
  SUBSCRIPTION_LIST,
  ANALYS_DETAILS,
  REPORT_PDF,
  CANDIDATE,
  WATCH_LIST,
  ADMIN_CREATE_WATCHLIST,
  ADMIN_WATCHLIST,
  ADMIN_BILLING_ACCOUNT_INFO,
  ADMIN_WATCH_LIST_DETAIL,
  ADMIN_WATCH_LIST_OBJECT_DETAIL,
  ADMIN_CREATE_SUBSCRIPTION,
  ADMIN_CREATE_SERVICE,
  ADMIN_PRODUCTS_LIST,
  ADMIN_UPDATE_SUBSCRIPTION,
  ADMIN_PRODUCTS_SUBSCRIPTION_LIST,
  ADMIN_UPDATE_SERVICE_PRODUCT,
  BILLING_ACCOUNTS_LIST,
  ADMIN_BILLING_ACCOUNTS_LIST,
  ADMIN_CREATE_BILLING_ACCOUNT,
  INTEGRATIONS_API_LIST,
  ADMIN_INTEGRATIONS_API_LIST,
  ADMIN_COUPONS_LIST,
  ADMIN_CREATE_COUPON,
  ADMIN_EDIT_COUPON,
  ERROR_404,
  ADMIN_CASES_LIST,
  ADMIN_EMAIL_LOG,
} from "@utils/constant";
import Search from "@pages/user/search/Search";
import AdminLogin from "@pages/admin/AdminLogin";
import UserLogin from "@pages/user/UserLogin";
import AdminForgotPassword from "@pages/admin/AdminForgotPassword";
import UserForgotPassword from "@pages/user/UserForgotPassword";
import CandidateConsent from "@pages/candidate/CandidateConsent";

import ResetPassword from "@pages/auth/resetPassword/ResetPassword";
import SetPassword from "@pages/auth/setPassword/SetPassword";
import UserProfile from "@pages/user/userProfile/UserProfile";
import PageNotFound from "@pages/shared/PageNotFound";
import CustomerDetails from "@pages/admin/customers/Details";
import Services from "@pages/shared/Services";
import BankRegister from "@pages/auth/registerAccount/bank/BankRegister";
import CustomerList from "@pages/admin/customers/list";
import UsersList from "@pages/admin/users/list";
import OrdersList from "@pages/admin/orders/list";
import AssignmentsList from "@pages/admin/assignments/list";
import AdminOrderDetails from "@pages/admin/orderDetails/AdminOrderDetails";
import AssignmentDetails from "@pages/admin/assignments/Details";
import OrderDetails from "@pages/user/orderDetails/UserOrderDetails";
import CompanyInfo from "@pages/user/userProfile/company/CompanyInfo";
import UserInfo from "@pages/user/userProfile/private/UserInfo";
import Checkout from "@pages/user/guest/Checkout";
import OrderConfirmation from "@pages/user/guest/OrderConfirmation";
import SubscriptionOrderConfirmation from "@pages/user/guest/SubscriptionOrderConfirmation";
import RegistrationSuccessful from "@pages/shared/RegistrationSuccessful";
import UserRegistrationCompleted from "@pages/user/UserRegistrationCompleted";
import AdminRegistrationCompleted from "@pages/admin/AdminRegistrationCompleted";
import UserForgotPasswordSuccessful from "@pages/user/UserForgotPasswordSuccessful";
import AdminForgotPasswordSuccessful from "@pages/admin/AdminForgotPasswordSuccessful";
import UserCompleteRegistration from "@pages/user/UserCompleteRegistration";
import AdminCompleteRegistration from "@pages/admin/AdminCompleteRegistration";
import SubUsers from "@pages/user/SubUsers";
import OrderResults from "@pages/user/OrderResults/OrderResults";
import ReportPagePdfContent from "@pages/user/OrderResults/ReportPagePdfContent";
import CreateCustomer from "@pages/admin/customers/CreateCustomer";
import CustomerOrdersList from "@pages/user/orders/list";
import CustomerWatchList from "@pages/user/watchlist/list";
import BillingAccountInfo from "@pages/user/billingAccountInfo/BillingAccountInfo";
import AdminMyProfile from "@pages/admin/myProfile/UserInfo";
import Credits from "@pages/user/userProfile/Credits";
import SubscriptionCheckout from "@pages/user/subscription/Checkout";
import AccessDenied from "@pages/user/subscription/accessDenied";
import UpgradeSubscription from "@pages/user/ManageSubscription/UpgradeSubscription";
import SubscriptionDetails from "@pages/admin/subscriptions/SubscriptionDetails";
import Subscription from "@pages/user/userProfile/Subscription";
import SubscriptionList from "@pages/admin/subscriptions/SubscriptionList";
import AnalysisList from "@pages/user/analysis/list";
import AdminAnalysisList from "@pages/admin/analysis/list";
import AdminAnalysDetails from "@pages/admin/analys/AdminAnalysDetails";
import CustomerCreateWatchList from "@pages/user/watchlist/CreateWatchlist";
import WatchListObjectOrderDetails from "@pages/shared/orderDetails/WatchListObjectOrderDetails";
import WatchlistDetail from "@pages/user/watchlist/WatchlistDetail";
import CreateSubscription from "@pages/admin/subscriptions/CreateSubscription";
import CreateService from "@pages/admin/services/CreateService";
import AdminProductsList from "@pages/admin/products/List";
import UpdateSubscription from "@pages/admin/subscriptions/UpdateSubscription";
import UpdateService from "@pages/admin/services/UpdateService";
import ExternalRedirectComponent from "../pages/auth/redirect/redirect";

import CreateBillingAccount from "@pages/user/createBillingAccount/CreateBillingAccount";
import BillingAccountsList from "@pages/admin/billings/list";
import Error404 from "@pages/admin/error404/error404";

import AdminCreateCoupon from "@pages/admin/coupons/CreateCoupon";
import AdminUpdateCoupon from "@pages/admin/coupons/UpdateCoupon";

import AdminCasesList from "@pages/admin/cases/list";

export const RoutesList = [
  {
    name: HOME,
    url: ROUTES.HOME.URL,
    title: "HOME",
    component: [Services],
  },
  {
    name: LOGIN,
    url: ROUTES.LOGIN.URL,
    title: SVLANG.login,
    component: [UserLogin],
  },
  {
    name: FORGOT_PASSWORD,
    url: ROUTES.FORGOT_PASSWORD.URL,
    title: SVLANG.title_forgot_password,
    component: [UserForgotPassword],
  },
  {
    name: CANDIDATE,
    url: ROUTES.CANDIDATE.URL,
    title: SVLANG.title_candidate,
    component: [CandidateConsent],
  },
  {
    name: RESET_PASSWORD,
    url: ROUTES.RESET_PASSWORD.URL,
    title: SVLANG.label_set_password,
    component: [ResetPassword],
  },
  {
    name: SET_PASSWORD,
    url: ROUTES.SET_PASSWORD.URL,
    title: SVLANG.label_set_password,
    component: [SetPassword],
  },
  {
    name: REGISTER_ACCOUNT,
    url: ROUTES.REGISTER_ACCOUNT.URL,
    title: SVLANG.title_registration,
    component: [ExternalRedirectComponent],
  },
  {
    name: SEARCH,
    url: ROUTES.SEARCH.URL,
    title: SVLANG.search_text,
    component: [Search],
    showSidemenu: true,
    authenticatedUser: true,
  },
  {
    name: ADMIN_SEARCH,
    url: ROUTES.ADMIN_SEARCH.URL,
    title: SVLANG.search_text,
    component: [Search],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_SEARCH.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: USER_PROFILE,
    url: ROUTES.USER_PROFILE.URL,
    title: SVLANG.title_my_profile,
    component: [UserProfile],
    authenticatedUser: true,
  },
  {
    name: LOGIN,
    url: ROUTES.ADMIN_LOGIN.URL,
    title: SVLANG.login,
    component: [AdminLogin],
    isAdmin: ROUTES.ADMIN_LOGIN.IS_ADMIN,
  },
  {
    name: FORGOT_PASSWORD,
    url: ROUTES.ADMIN_FORGOT_PASSWORD.URL,
    title: SVLANG.title_forgot_password,
    component: [AdminForgotPassword],
    isAdmin: ROUTES.ADMIN_RESET_PASSWORD.IS_ADMIN,
  },
  {
    name: RESET_PASSWORD,
    url: ROUTES.ADMIN_RESET_PASSWORD.URL,
    title: SVLANG.label_set_password,
    component: [ResetPassword],
    isAdmin: ROUTES.ADMIN_RESET_PASSWORD.IS_ADMIN,
  },
  {
    name: REGISTRATION_SUCCESSFUL,
    url: ROUTES.REGISTRATION_SUCCESSFUL.URL,
    title: SVLANG.title_registration,
    component: [RegistrationSuccessful],
  },
  {
    name: CUSTOMER_DETAILS,
    url: ROUTES.CUSTOMER_DETAILS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_ORDERS,
    url: ROUTES.CUSTOMER_ORDERS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_ORDERS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_USERS,
    url: ROUTES.CUSTOMER_USERS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_USERS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_CREDITS,
    url: ROUTES.CUSTOMER_CREDITS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_CREDITS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_ANALYSIS,
    url: ROUTES.CUSTOMER_ANALYSIS.URL,
    title: SVLANG.title_customers,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_ANALYSIS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: CUSTOMER_PRODUCTS,
    url: ROUTES.CUSTOMER_PRODUCTS.URL,
    title: SVLANG.label_products,
    component: [CustomerDetails],
    isAdmin: ROUTES.CUSTOMER_PRODUCTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: REGISTER_WITH_BANKID,
    url: ROUTES.REGISTER_WITH_BANKID.URL,
    title: SVLANG.title_customers,
    component: [BankRegister],
  },
  {
    name: ASSIGNMENT_DETAILS,
    url: ROUTES.ASSIGNMENT_DETAILS.URL,
    title: SVLANG.title_assignments,
    component: [AssignmentDetails],
    isAdmin: ROUTES.ASSIGNMENT_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: PAGE_NOT_FOUND,
    url: ROUTES.PAGE_NOT_FOUND.URL,
    title: SVLANG.title_pagenotfound,
    component: [PageNotFound],
  },
  {
    name: ADMIN_CUSTOMERS,
    url: ROUTES.ADMIN_CUSTOMERS.URL,
    title: SVLANG.title_customers,
    component: [CustomerList],
    isAdmin: ROUTES.ADMIN_CUSTOMERS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_CUSTOMER,
    url: ROUTES.ADMIN_CREATE_CUSTOMER.URL,
    title: SVLANG.title_customers,
    component: [CreateCustomer],
    isAdmin: ROUTES.ADMIN_CREATE_CUSTOMER.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_USERS,
    url: ROUTES.ADMIN_USERS_LIST.URL,
    title: SVLANG.title_users,
    component: [UsersList],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ADMINS_LIST,
    url: ROUTES.ADMIN_ADMINS_LIST.URL,
    title: SVLANG.title_users,
    component: [UsersList],
    isAdmin: ROUTES.ADMIN_ADMINS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CUSTOMERS_LIST,
    url: ROUTES.ADMIN_CUSTOMERS_LIST.URL,
    title: SVLANG.title_users,
    component: [UsersList],
    isAdmin: ROUTES.ADMIN_CUSTOMERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ORDERS,
    url: ROUTES.ADMIN_ORDERS_LIST.URL,
    title: SVLANG.title_orders,
    component: [OrdersList],
    isAdmin: ROUTES.ADMIN_ORDERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ASSIGNMENTS,
    url: ROUTES.ADMIN_ASSIGNMENTS_LIST.URL,
    title: SVLANG.title_assignments,
    component: [AssignmentsList],
    isAdmin: ROUTES.ADMIN_ASSIGNMENTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ORDER_DETAILS,
    url: ROUTES.ADMIN_ORDER_DETAILS.URL,
    title: SVLANG.title_orders,
    component: [AdminOrderDetails],
    isAdmin: ROUTES.ADMIN_ORDER_DETAILS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ORDERS_DETAILS,
    url: ROUTES.ORDERS_DETAILS.URL,
    title: SVLANG.title_orders,
    component: [OrderDetails],
    authenticatedUser: true,
  },
  {
    name: COMPANY_INFO,
    url: ROUTES.COMPANY_INFO.URL,
    title: SVLANG.label_company_info,
    component: [CompanyInfo],
    authenticatedUser: true,
  },
  {
    name: CREATE_BILLING_ACCOUNT,
    url: ROUTES.CREATE_BILLING_ACCOUNT.URL,
    title: SVLANG.title_create_billing_account,
    component: [CreateBillingAccount],
    authenticatedUser: true,
  },
  {
    name: BILLING_ACCOUNT_INFO,
    url: ROUTES.BILLING_ACCOUNT_INFO.URL,
    title: SVLANG.title_billing_account_info,
    component: [BillingAccountInfo],
    authenticatedUser: true,
  },
  {
    name: USER_INFO,
    url: ROUTES.USER_INFO.URL,
    title: SVLANG.title_user_info,
    component: [UserInfo],
    authenticatedUser: true,
  },
  {
    name: SUB_USERS,
    url: ROUTES.SUB_USERS_LIST.URL,
    title: SVLANG.title_user_info,
    component: [SubUsers],
    authenticatedUser: true,
  },
  {
    name: ORDERS,
    url: ROUTES.ORDERS_LIST.URL,
    title: SVLANG.title_orders,
    component: [CustomerOrdersList],
    authenticatedUser: true,
  },
  {
    name: WATCH_LIST,
    url: ROUTES.USER_WATCH_LIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerWatchList],
    authenticatedUser: true,
  },
  {
    name: WATCH_LIST,
    url: ROUTES.NEW_WATCH_LIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerCreateWatchList],
    authenticatedUser: true,
  },
  {
    name: WATCH_LIST,
    url: ROUTES.WATCH_LIST_DETAILS.URL,
    title: SVLANG.menu_watchlist,
    component: [WatchlistDetail],
    authenticatedUser: true,
  },
  {
    name: CHECKOUT,
    url: ROUTES.CHECKOUT.URL,
    title: SVLANG.title_checkout,
    component: [Checkout],
  },
  {
    name: SUBSCRIPTION_CHECKOUT,
    url: ROUTES.SUBSCRIPTION_CHECKOUT.URL,
    title: SVLANG.title_checkout,
    component: [SubscriptionCheckout],
  },
  {
    name: ORDER_CONFIRMATION,
    url: ROUTES.ORDER_CONFIRMATION.URL,
    title: SVLANG.title_order_confirmation,
    component: [OrderConfirmation],
  },
  {
    name: SUBSCRIPTION_ORDER_CONFIRMATION,
    url: ROUTES.SUBSCRIPTION_ORDER_CONFIRMATION.URL,
    title: SVLANG.title_subscription_order_confirmation,
    component: [SubscriptionOrderConfirmation],
  },
  {
    name: USER_REGISTRATION_COMPLETED,
    url: ROUTES.USER_REGISTRATION_COMPLETED.URL,
    title: SVLANG.title_registration_completed,
    component: [UserRegistrationCompleted],
  },
  {
    name: ADMIN_REGISTRATION_COMPLETED,
    url: ROUTES.ADMIN_REGISTRATION_COMPLETED.URL,
    title: SVLANG.title_registration_completed,
    component: [AdminRegistrationCompleted],
    isAdmin: ROUTES.ADMIN_REGISTRATION_COMPLETED.IS_ADMIN,
  },
  {
    name: USER_FORGOT_PASSWORD_SUCCESSFUL,
    url: ROUTES.USER_FORGOT_PASSWORD_SUCCESSFUL.URL,
    title: SVLANG.title_forgot_password_successful,
    component: [UserForgotPasswordSuccessful],
  },
  {
    name: ADMIN_FORGOT_PASSWORD_SUCCESSFUL,
    url: ROUTES.ADMIN_FORGOT_PASSWORD_SUCCESSFUL.URL,
    title: SVLANG.title_forgot_password_successful,
    component: [AdminForgotPasswordSuccessful],
    isAdmin: ROUTES.ADMIN_FORGOT_PASSWORD_SUCCESSFUL.IS_ADMIN,
  },
  {
    name: USER_COMPLETE_REGISTRATION,
    url: ROUTES.USER_COMPLETE_REGISTRATION.URL,
    title: SVLANG.title_complete_registration,
    component: [UserCompleteRegistration],
  },
  {
    name: ADMIN_COMPLETE_REGISTRATION,
    url: ROUTES.ADMIN_COMPLETE_REGISTRATION.URL,
    title: SVLANG.title_complete_registration,
    component: [AdminCompleteRegistration],
    isAdmin: ROUTES.ADMIN_COMPLETE_REGISTRATION.IS_ADMIN,
  },
  {
    name: ORDER_RESULTS,
    url: ROUTES.ORDER_RESULTS.URL,
    title: SVLANG.title_report,
    component: [OrderResults],
  },
  {
    name: REPORT_PDF,
    url: ROUTES.REPORT_PDF.URL,
    title: SVLANG.title_report,
    component: [ReportPagePdfContent],
  },
  {
    name: ADMIN_MY_PROFILE,
    url: ROUTES.ADMIN_MY_PROFILE.URL,
    title: SVLANG.title_my_profile,
    component: [AdminMyProfile],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_MY_PROFILE.IS_ADMIN,
  },
  {
    name: USER_CREDITS,
    url: ROUTES.USER_CREDITS.URL,
    title: SVLANG.title_credits,
    component: [Credits],
    authenticatedUser: true,
  },
  {
    name: ACCESS_DENIED,
    url: ROUTES.ACCESSDENIED.URL,
    title: SVLANG.title_credits,
    component: [AccessDenied],
    authenticatedUser: false,
  },
  {
    name: UPGRADE_SUBSCRIPTION,
    url: ROUTES.UPGRADE_SUBSCRIPTION.URL,
    title: SVLANG.title_credits,
    component: [UpgradeSubscription],
    authenticatedUser: false,
  },
  {
    name: SUBSCRIPTION_DETAILS,
    url: ROUTES.SUBSCRIPTION_DETAILS.URL,
    title: SVLANG.title_credits,
    component: [SubscriptionDetails],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: SUBSCRIPTIONS,
    url: ROUTES.SUBSCRIPTION.URL,
    title: SVLANG.title_credits,
    component: [Subscription],
    authenticatedUser: true,
  },
  {
    name: SUBSCRIPTION_LIST,
    url: ROUTES.SUBSCRIPTION_LIST.URL,
    title: SVLANG.title_credits,
    component: [SubscriptionList],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ANALYSIS_API,
    url: ROUTES.ALLANALYSIS_LIST.URL,
    title: SVLANG.title_credits,
    component: [AnalysisList],
    authenticatedUser: true,
  },
  {
    name: ANALYS_DETAILS,
    url: ROUTES.ANALYS_DETAILS.URL,
    title: SVLANG.analys_details,
    component: [AdminAnalysDetails],
    isAdmin: ROUTES.ADMIN_USERS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_ANALYSIS_LIST,
    url: ROUTES.ADMIN_ANALYSIS_LIST.URL,
    title: SVLANG.title_credits,
    component: [AdminAnalysisList],
    isAdmin: ROUTES.ADMIN_ANALYSIS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ORDERS_DETAILS,
    url: ROUTES.WATCH_LIST_OBJECT_DETAIL.URL,
    title: SVLANG.title_orders,
    component: [WatchListObjectOrderDetails],
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_WATCHLIST,
    url: ROUTES.ADMIN_CREATE_WATCH_LIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerCreateWatchList],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CREATE_WATCH_LIST.IS_ADMIN,
  },
  {
    name: ADMIN_WATCHLIST,
    url: ROUTES.ADMIN_WATCHLIST.URL,
    title: SVLANG.menu_watchlist,
    component: [CustomerWatchList],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_WATCHLIST.IS_ADMIN,
  },
  {
    name: ADMIN_BILLING_ACCOUNT_INFO,
    url: ROUTES.ADMIN_BILLING_ACCOUNT_INFO.URL,
    title: SVLANG.title_billing_account_info,
    component: [BillingAccountInfo],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNT_INFO.IS_ADMIN,
  },
  {
    name: ADMIN_WATCH_LIST_DETAIL,
    url: ROUTES.ADMIN_WATCH_LIST_DETAIL.URL,
    title: SVLANG.menu_watchlist,
    component: [WatchlistDetail],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_WATCH_LIST_DETAIL.IS_ADMIN,
  },
  {
    name: ADMIN_WATCH_LIST_OBJECT_DETAIL,
    url: ROUTES.ADMIN_WATCH_LIST_OBJECT_DETAIL.URL,
    title: SVLANG.title_orders,
    component: [WatchListObjectOrderDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_WATCH_LIST_OBJECT_DETAIL.IS_ADMIN,
  },
  {
    name: ADMIN_CREATE_SUBSCRIPTION,
    url: ROUTES.ADMIN_CREATE_SUBSCRIPTION.URL,
    title: SVLANG.title_orders,
    component: [CreateSubscription],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CREATE_SUBSCRIPTION.IS_ADMIN,
  },
  {
    name: ADMIN_CREATE_SERVICE,
    url: ROUTES.ADMIN_CREATE_SERVICE.URL,
    title: SVLANG.title_orders,
    component: [CreateService],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_CREATE_SERVICE.IS_ADMIN,
  },
  {
    name: ADMIN_PRODUCTS_LIST,
    url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    title: SVLANG.title_orders,
    component: [AdminProductsList],
    isAdmin: ROUTES.ADMIN_PRODUCTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_PRODUCTS_SUBSCRIPTION_LIST,
    url: ROUTES.ADMIN_PRODUCTS_SUBSCRIPTION_LIST.URL,
    title: SVLANG.title_orders,
    component: [AdminProductsList],
    isAdmin: ROUTES.ADMIN_PRODUCTS_SUBSCRIPTION_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UPDATE_SUBSCRIPTION,
    url: ROUTES.ADMIN_UPDATE_SUBSCRIPTION.URL,
    title: SVLANG.title_orders,
    component: [UpdateSubscription],
    isAdmin: ROUTES.ADMIN_UPDATE_SUBSCRIPTION.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_UPDATE_SERVICE_PRODUCT,
    url: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT.URL,
    title: SVLANG.title_orders,
    component: [UpdateService],
    isAdmin: ROUTES.ADMIN_UPDATE_SERVICE_PRODUCT.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: BILLING_ACCOUNTS_LIST,
    url: ROUTES.BILLING_ACCOUNTS_LIST.URL,
    title: SVLANG.label_company_info,
    component: [CompanyInfo],
    authenticatedUser: true,
  },
  {
    name: ADMIN_BILLING_ACCOUNTS_LIST,
    url: ROUTES.ADMIN_BILLING_ACCOUNTS_LIST.URL,
    title: SVLANG.title_orders,
    component: [CustomerDetails],
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNTS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_BILLING_ACCOUNT,
    url: ROUTES.ADMIN_CREATE_BILLING_ACCOUNT.URL,
    title: SVLANG.title_create_billing_account,
    component: [CreateBillingAccount],
    isAdmin: true,
    authenticatedUser: true,
  },
  {
    name: INTEGRATIONS_API_LIST,
    url: ROUTES.INTEGRATIONS_API_LIST.URL,
    title: SVLANG.header_api,
    component: [CompanyInfo],
    showSidemenu: true,
    authenticatedUser: true,
  },
  {
    name: ADMIN_INTEGRATIONS_API_LIST,
    url: ROUTES.ADMIN_INTEGRATIONS_API_LIST.URL,
    title: SVLANG.header_api,
    component: [CustomerDetails],
    authenticatedUser: true,
    isAdmin: ROUTES.ADMIN_INTEGRATIONS_API_LIST.IS_ADMIN,
  },
  {
    name: "ADMIN_UNBILLED_ACCOUNTS",
    url: ROUTES.ADMIN_UNBILLED_ACCOUNTS.URL,
    title: SVLANG.title_billing,
    component: [BillingAccountsList],
    isAdmin: ROUTES.ADMIN_UNBILLED_ACCOUNTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: "ADMIN_INVOICE_ACCOUNTS",
    url: ROUTES.ADMIN_INVOICE_ACCOUNTS.URL,
    title: SVLANG.title_billing,
    component: [BillingAccountsList],
    isAdmin: ROUTES.ADMIN_INVOICE_ACCOUNTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: "ADMIN_BILLING_ACCOUNTS",
    url: ROUTES.ADMIN_BILLING_ACCOUNTS.URL,
    title: SVLANG.title_billing,
    component: [BillingAccountsList],
    isAdmin: ROUTES.ADMIN_BILLING_ACCOUNTS.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_COUPONS_LIST,
    url: ROUTES.ADMIN_COUPONS_LIST.URL,
    title: SVLANG.title_coupons,
    component: [AdminProductsList],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_COUPONS_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_CREATE_COUPON,
    url: ROUTES.ADMIN_CREATE_COUPON.URL,
    title: SVLANG.title_create_coupon,
    component: [AdminCreateCoupon],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CREATE_COUPON.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EDIT_COUPON,
    url: ROUTES.ADMIN_EDIT_COUPON.URL,
    title: SVLANG.title_edit_coupon,
    component: [AdminUpdateCoupon],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_EDIT_COUPON.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ERROR_404,
    url: ROUTES.ERROR_404.URL,
    title: SVLANG.title_create_billing_account,
    component: [Error404],
  },
  {
    name: ADMIN_CASES_LIST,
    url: ROUTES.ADMIN_CASES_LIST.URL,
    title: SVLANG.title_cases,
    component: [AdminCasesList],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_CASES_LIST.IS_ADMIN,
    authenticatedUser: true,
  },
  {
    name: ADMIN_EMAIL_LOG,
    url: ROUTES.ADMIN_EMAIL_LOG.URL,
    title: SVLANG.title_email_log,
    component: [AdminCasesList],
    showSidemenu: true,
    isAdmin: ROUTES.ADMIN_EMAIL_LOG.IS_ADMIN,
    authenticatedUser: true,
  },
];

export default RoutesList;
