import { useContext, useEffect, useState } from "react";

import { StepperContext } from "./StepperContext";

const useStepper = () => {
  const {
    steps,
    activeStepIndex,
    setupSteps,
    onNextStep,
    onPrevStep,
    resetSteps,
  } = useContext(StepperContext);

  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    setCurrentItem(steps[activeStepIndex]);
  }, [steps, activeStepIndex]);

  return {
    steps,
    activeStepIndex,
    currentItem,
    setupSteps,
    onNextStep,
    onPrevStep,
    resetSteps,
  };
};

export default useStepper;
