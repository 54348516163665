import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory as useHistoryRouterDOM,
  useLocation,
} from "react-router-dom";
import { useIntl } from "react-intl";
import { SinglePINLoader, MultiPINLoader } from "./Loader";
import personInformationSearchService from "@app/services/search/personInformationSearchService";
import { fetchAllProducts } from "@app/services/services/fetchProductById";
import { personInformationActions } from "@app/reducers/personInformationReducer";

import { authActions } from "@app/reducers/authReducer";
import { default as Div } from "@components/Div";
import Container from "@components/Container";
import Section from "@components/Section";
import { splitString, isAuthenticated, getPinWithFullYear } from "@utils/utils";
import {
  SSNNUMBER,
  SINGLE,
  MULTIPLE,
  PROTECTED,
  SUBSCRIPTION_STATUS,
  CONSENT_OPTIONAL,
  CONSENT_MANDATORY,
  NO_CONSENT,
  PRODUCT_TYPE_SERVICE,
  LOGIN,
} from "@utils/constant";
import useHistory from "@utils/useHistory";
import { trackPageView } from "@src/analyticsFunctions";
import SSNSearch from "./SSNSearch";
import getUserDetails from "@app/services/auth/getUserDetails";
import INITIAL_VALUES from "./types";
import AvailableServicesForCandidates from "./AvailableServicesForCandidates";

import useStepper from "../../shared/Wizard/useStepper";

export const BoxedDiv = styled(Div)`
  border: 1px dashed var(--turquoise);
  box-sizing: border-box;
`;

const SearchResults = () => {
  const isAuthenticatedFlag = isAuthenticated();
  const location = useLocation();
  const history = useHistoryRouterDOM();
  const customHistory = useHistory();
  const query = new URLSearchParams(location.search);
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { onNextStep } = useStepper();

  const [servicesData, setServicesData] = useState([]);

  const { customer: { status: subscriptionStatus, consent } = {} } = userInfo;

  const { messages } = useIntl();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    ...INITIAL_VALUES,
    consentCheck: consent === CONSENT_OPTIONAL,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [consentStatus, setConsentStatus] = useState(NO_CONSENT);
  const numberSsn = splitString(
    query.get(SSNNUMBER) ? query.get(SSNNUMBER).replaceAll(",", "\n") : "",
  );

  const _ssnNumber = numberSsn.map((value) => {
    const pin = value.replaceAll("-", "");
    if (pin.length === 10) {
      const appendedValue = getPinWithFullYear(pin);
      return appendedValue;
    }
    return pin;
  });

  const [ssnNumber, setSSNNumber] = useState([]);
  const [isError, setIsError] = useState(false);

  const resetStateValues = () => {
    setData({});
    setSSNNumber([]);
  };

  const sanitizeData = (servicesData, responseData, _ssnNumber) => {
    const defaultServiceData = servicesData.map((service) => ({
      ...service,
      isChecked: service.default_selected > 0,
    }));

    _ssnNumber.forEach((ssn) => {
      const pin = ssn && ssn.replaceAll("-", "");
      if (
        !responseData.some((resData) => {
          return resData.ssn === pin && resData.ssn_status !== PROTECTED;
        })
      ) {
        return responseData.push({
          ssn: ssn,
          error: true,
        });
      }
    });
    return {
      ...data,
      candidates: responseData
        .filter((i) => i.ssn_status !== PROTECTED)
        .map((ssnDetails) => ({
          ...ssnDetails,
          email: "",
          phone: "",
          services: defaultServiceData,
          showAllServices: false,
        })),
    };
  };

  const getPersonInformation = async (_ssnNumber = []) => {
    try {
      resetStateValues();
      setIsError(false);
      setIsFetching(true);

      let customer_id = "";

      if (isAuthenticated()) {
        const { data: { data: userData = {} } = {}, status = "" } =
          await getUserDetails();
        if (status === 401) {
          localStorage.clear();
          dispatch(authActions.storeUserInfo({ user: {}, isLogout: true }));
          customHistory.push(LOGIN);
        } else {
          dispatch(authActions.storeUserInfo(userData));
        }
        const { customer = {} } = userData;
        const { status: subscriptionStatus, consent } = customer;
        customer_id = customer.id;
        setConsentStatus(consent);
        dispatch(
          personInformationActions.setHasActiveSubscription(
            subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE,
          ),
        );
      }

      const servicesResponse = await fetchAllProducts({
        type: PRODUCT_TYPE_SERVICE,
        customer_id,
      });
      const { data = {} } = servicesResponse;
      const { data: serviceData = [] } = data;
      const availableServices = serviceData.filter(
        ({ default_available }) => default_available === 1,
      );
      setServicesData(availableServices);
      const response = await personInformationSearchService(_ssnNumber);
      const { data: responseData = [] } = response;

      if (responseData.length === 0) {
        const errorResponse = [
          {
            ssn: _ssnNumber,
            error: true,
            isChecked: false,
          },
        ];
        setData(errorResponse);
        setIsError(true);
      } else if (responseData.length !== _ssnNumber.length) {
        setData(sanitizeData(availableServices, responseData, _ssnNumber));
      } else {
        setData(sanitizeData(availableServices, responseData, _ssnNumber));
      }
      setSSNNumber(_ssnNumber);
      setIsFetching(false);
    } catch (e) {
      const errorResponse = [
        {
          ssn: _ssnNumber,
          error: true,
          isChecked: false,
        },
      ];
      setData(errorResponse);
      setIsError(true);
      setSSNNumber(_ssnNumber);
      setIsFetching(false);
    }
  };

  const optionalInfoLabel = () => {
    if (consentStatus === CONSENT_MANDATORY) {
      return messages.consent_mandatory_info;
    }
    return messages.consent_optional_text;
  };

  useEffect(() => {
    trackPageView();
    if (_ssnNumber.length) {
      getPersonInformation(_ssnNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchSSN = (pin = []) => {
    const param = pin.join(",");
    history.replace({
      pathname: location.pathname,
      search: `?ssnnumber=${param}`,
    });
    resetStateValues();
    return getPersonInformation(pin);
  };

  const getLoader = () => (ssnNumber.length > 1 ? MULTIPLE : SINGLE);

  const layoutType = getLoader();

  const renderLoader = () =>
    layoutType === SINGLE ? (
      <SinglePINLoader count={[ssnNumber]} />
    ) : (
      <MultiPINLoader count={ssnNumber} />
    );

  const renderForm = () => (
    <>
      <SSNSearch
        ssnNumbers={ssnNumber}
        onSearchSSN={onSearchSSN}
        isAuthenticated={isAuthenticatedFlag}
        onChange={setSSNNumber}
      />

      {!!data.candidates.length && (
        <AvailableServicesForCandidates
          data={data}
          setData={setData}
          ssnNumber={ssnNumber}
          isError={isError}
          consentStatus={consentStatus}
          setIsFetching={setIsFetching}
          onNextStep={onNextStep}
          servicesData={servicesData}
          setSSNNumber={setSSNNumber}
          subscriptionStatus={subscriptionStatus}
          optionalInfoLabel={optionalInfoLabel}
        />
      )}
    </>
  );

  return (
    <Section px={0} py={0}>
      <Container>
        <Div width={1}>{isFetching ? renderLoader() : renderForm()}</Div>
      </Container>
    </Section>
  );
};

export default SearchResults;
