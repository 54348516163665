import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { klarnaActions } from "@app/reducers/klarnaReducer";
import CreateOrder from "../../shared/CreateOrder";
import {
  CUSTOMER_USER,
  GUEST_USER,
  KLARNA_CHECKOUT,
  INVOICE_CHECKOUT,
  PAYMENT_METHOD_CREDITS_AND_INVOICE,
} from "@utils/constant";
import { authActions } from "@app/reducers/authReducer";
import { PrimaryButton } from "@components/Button";
import { trackPageView } from "@src/analyticsFunctions";
import { isCustomerUser } from "@utils/utils";
import ProgressSpinner from "@components/ProgressSpinner";

function PaymentOption(prop) {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    guestUserProps = {
      email: "",
    },
    htmlSnippet,
    paymentMethod = "",
    isConsent = false,
    promoCode = "",
    billingAccountId = null,
    onNextStep = () => {},
  } = prop;

  const personInfo = useSelector(
    (state) => state.personInformationReducer.personInfo,
  );

  const payload = [];
  personInfo.forEach((value) => {
    const {
      ssn = "",
      name = "",
      phone = "",
      email = "",
      services = [],
      zipcode: zip_code = "",
      firstName: person_first_name = "",
      lastName: person_last_name = "",
    } = value;
    services.forEach((service) =>
      payload.push({
        service_id: service.id,
        personal_number: ssn,
        person_name: name,
        phone: phone,
        email: email,
        zip_code,
        person_first_name,
        person_last_name,
      }),
    );
  });
  const type = isCustomerUser() ? CUSTOMER_USER : GUEST_USER;

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      guestUserProps.consent = isConsent;
      const response = await CreateOrder({
        payload,
        props: guestUserProps,
        paymentMethod,
        type,
        promoCode,
        billingAccountId,
      });

      const {
        data,
        data: {
          klarna: { original = {} },
        },
      } = response;
      dispatch(klarnaActions.klarnaInfo(original));

      if (paymentMethod === KLARNA_CHECKOUT) {
        htmlSnippet?.(original);
        dispatch(klarnaActions.ordersInfo(data));
        const { order: { user = {} } = {} } = response.data || {};
        if (!original.html_snippet) {
          dispatch(authActions.storeUserInfo({ ...user }));

          if (onNextStep) {
            onNextStep?.();
          }
        }
      } else {
        dispatch(klarnaActions.ordersInfo(data));
        const { order: { user = {} } = {} } = response.data || {};
        dispatch(authActions.storeUserInfo({ ...user }));

        if (onNextStep) {
          onNextStep?.();
        }
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    trackPageView();
    async function fetchData() {
      try {
        handleSubmit();
      } catch (e) {
        handleSubmit();
      }
    }
    if (paymentMethod === KLARNA_CHECKOUT) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const shouldCheckForBillingAccount =
    paymentMethod === INVOICE_CHECKOUT ||
    paymentMethod === PAYMENT_METHOD_CREDITS_AND_INVOICE;

  return (
    <>
      {paymentMethod !== KLARNA_CHECKOUT && (
        <>
          {isLoading && <ProgressSpinner />}
          <PrimaryButton
            py={2}
            px={4}
            width={[1, "auto"]}
            label={messages.label_create_order}
            onClick={handleSubmit}
            disabled={
              isLoading || (shouldCheckForBillingAccount && !billingAccountId)
            }
          />
        </>
      )}
    </>
  );
}

export default PaymentOption;
