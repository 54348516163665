import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import Container from "@components/Container";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { InputText } from "@components/InputText";
import { H2, H3 } from "@components/Heading";
import { LabelSemiBold } from "@components/Label";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form as FormikForm } from "formik";
import updateSubscription from "@app/services/subscription/updateSubscription";
import terminateSubscription from "@app/services/subscription/terminateSubscription";
import Dialog from "@components/Dialog";
import ProgressSpinner from "@components/ProgressSpinner";
import {
  PrimaryButtonIcon,
  PrimaryButton,
  PrimaryButtonIconOutlined,
} from "@components/Button";
import RadioButton from "@components/RadioButton";
import ActiveSubscription from "@pages/user/userProfile/ActiveSubscription";
import {
  Text,
  TextMediumWeight,
  ColouredMediumWeight,
  TextColoured,
} from "@components/Text";
import customerDetailsService from "@app/services/customers/customerDetailsService";
import { formatDateAndTime } from "@utils/utils";
import ErrorMessage from "../../shared/ErrorMessage";
import {
  loadActiveSubscriptionData,
  checkUserPermission,
  loadServicesData,
} from "@utils/common";
import {
  ROUTES,
  ADMIN_CUSTOMER_DETAILS,
  INPUT,
  SPECIAL_PACKAGE,
  ADMIN,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import SubscriptionUpdateValidation from "@pages/auth/schema/SpecialSubscriptionValidation";
import Breadcrumb from "@components/Breadcrumb";
import AdminContainer from "@layout/AdminContainer";

export const SubscriptionDetails = () => {
  const { messages } = useIntl();
  const loginUserInfo = useSelector((state) => state.authReducer.userInfo);
  const [subscriptionDetail, setSubscriptionDetail] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [getActivityLog, setGetActivityLog] = useState(false);
  const [customerDetail, setCustomerDetail] = useState(false);
  const [confirmStatusPopup, setConfirmStatusPopup] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [reloadAPI, setReloadAPI] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isSpecialInput, setIsSpecialInput] = useState(false);
  const [subscriptionPackage, setSubscriptionPackage] = useState({});
  const [newStatus, setNewStatus] = useState("");
  const [selectedPackage, setSelectedPackage] = useState(false);
  const [subsPackages, setSubsPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState({
    package: "",
    availableanalysis: "",
    totalanalysis: "",
    price: "",
    specialInput: isSpecialInput,
  });

  const updateSubscriptionByAdmin = async (values) => {
    const {
      package: packageName,
      price,
      availableanalysis,
      totalanalysis,
    } = values;
    const subscriptionPayload = {
      product_id: packageName,
      customer_id: customerDetail.customer_id,
      subscription_id: subscriptionDetail.id,
      signed_by: ADMIN + " " + loginUserInfo.email,
      status: subscriptionStatus,
    };
    subscriptionPayload.price = subscriptionPackage?.price;
    if (subscriptionPackage?.name === SPECIAL_PACKAGE) {
      subscriptionPayload.price = price;
      subscriptionPayload.available_credits = availableanalysis;
      subscriptionPayload.total_credits = totalanalysis;
    }
    try {
      setIsLoading(true);
      await updateSubscription(subscriptionPayload, subscriptionDetail.id);
      setShowSuccessPopup(!showSuccessPopup);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  };
  const { permissions: { subscription: subscriptionPermission = "" } = {} } =
    loginUserInfo;
  const stopSubscription = async () => {
    try {
      setIsLoading(true);
      if (newStatus === SUBSCRIPTION_STATUS.TERMINATED) {
        await terminateSubscription(subscriptionDetail.id, subscriptionStatus);
      } else {
        await updateSubscription(
          {
            product_id: subscriptionDetail.product_id,
            customer_id: customerDetail.customer_id,
            subscription_id: subscriptionDetail.id,
            signed_by: ADMIN + " " + loginUserInfo.email,
            status: newStatus,
          },
          subscriptionDetail.id,
        );
      }
      setConfirmStatusPopup(!confirmStatusPopup);
      setShowSuccessPopup(!showSuccessPopup);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      handleError(err);
    }
  };

  const handleError = ({
    response: {
      data: { error = "" },
    },
  }) => {
    setErrorMessage(error);
  };
  const onCancel = () => {
    setShowSuccessPopup(!showSuccessPopup);
    setReloadAPI(true);
    window.scrollTo(0, 0);
  };
  const confirmStatus = (status) => {
    setNewStatus(status);
    setConfirmStatusPopup(!confirmStatusPopup);
  };
  //* INPUT VALUE Const is here */
  const { PACKAGE, AVAILABLE_ANALYSIS, TOTAL_ANALYSIS, PRICE } = INPUT.NAME;

  // Form Validation Start Here
  const infoSchemaPackagesUpdate = SubscriptionUpdateValidation.concat(
    SubscriptionUpdateValidation.pick([PACKAGE]),
  );
  if (subscriptionPackage?.name === SPECIAL_PACKAGE) {
    SubscriptionUpdateValidation.concat(
      SubscriptionUpdateValidation.pick([AVAILABLE_ANALYSIS]),
    )
      .concat(SubscriptionUpdateValidation.pick([TOTAL_ANALYSIS]))
      .concat(SubscriptionUpdateValidation.pick([PRICE]));
  }
  // Form Validation End here
  const { TEXT } = INPUT.TYPE;
  //* Radio Buttom Checked Handler*/

  const breadCrumbItems = [
    {
      label: messages.subscriptions_title,
      url: ROUTES.SUBSCRIPTION_LIST.URL,
    },
    {
      label: `#${subscriptionDetail?.id}`,
    },
  ];
  const translateStatus = {
    active: messages.active,
    stopped: messages.stopped_label,
    terminated: messages.terminated_label,
    renewal: messages.renewal_label,
  };

  const handlePackage = (event, formik) => {
    const { values, setValues, setFieldTouched } = formik;
    const isSpecial = event.name === SPECIAL_PACKAGE;
    setIsSpecialInput(isSpecial);
    values.package = event.id;
    values.specialInputs = isSpecial;
    setValues(values);
    setSubscriptionPackage(event);
    setSelectedPackage(event.id);
    setFieldTouched(PACKAGE, true);
  };

  const popupTitleChange = {
    [SUBSCRIPTION_STATUS.STOPPED]: {
      title: messages.stop_subscription,
      description: messages.stop_subscription_message,
    },
    [SUBSCRIPTION_STATUS.TERMINATED]: {
      title: messages.terminate_subscription,
      description: messages.terminate_subscription_message,
    },

    [SUBSCRIPTION_STATUS.ACTIVE]: {
      title: messages.activate_subscription,
      description: messages.activate_subscription_message,
    },
  };
  useEffect(() => {
    if (!subscriptionDetail || reloadAPI) {
      const customerInfo = { customer: { id: id } };
      (async () => {
        checkUserPermission(loginUserInfo, history);
        const detail = await loadActiveSubscriptionData(customerInfo);
        setSubscriptionDetail(detail);
        const {
          product_id: productId = "",
          name = "",
          price = 0,
          available_credits: availableanalysis = 0,
          total_credits: totalanalysis = 0,
        } = detail;
        setSelectedPackage(productId);
        setIsSpecialInput(name === SPECIAL_PACKAGE);
        if (name === SPECIAL_PACKAGE) {
          setInitialData({
            package: productId,
            price,
            availableanalysis,
            totalanalysis,
            specialInput: true,
          });
        }
        const { orders = [] } = detail || {};
        const { status } = detail || {};
        setSubscriptionStatus(status);
        setGetActivityLog(orders);
        setReloadAPI(false);
      })();
    }
  }, [loginUserInfo, history, id, subscriptionDetail, reloadAPI]);

  //* Start Get Packages from API */
  useEffect(() => {
    const getPackages = async () => {
      const packageList = await loadServicesData(id);
      setSubsPackages(packageList);
    };
    getPackages();
  }, [id]);

  useEffect(() => {
    if (selectedPackage && subsPackages.length > 0) {
      setSubscriptionPackage(
        subsPackages.find(
          (service) => service.id === parseInt(selectedPackage),
        ),
      );
    }
  }, [selectedPackage, subsPackages]);
  useEffect(() => {
    if (!customerDetail) {
      const getCustomer = async () => {
        const customerData = await customerDetailsService(id);
        const { data } = customerData.data;
        setCustomerDetail(data);
      };
      getCustomer();
    }
  }, [customerDetail, id]);
  const redirectToCustomerDetails = () => {
    const { id: customerId = "", status: customerStatus = "" } = customerDetail;
    history.push(
      `${ROUTES.ADMIN_CUSTOMERS.URL}/${customerId}/${ADMIN_CUSTOMER_DETAILS}`,
      { id: customerId, status: customerStatus },
    );
  };

  const headerConfig = {
    title: `${messages.subscription} #${subscriptionDetail.id}`,
  };

  return (
    <AdminContainer config={headerConfig}>
      <Breadcrumb items={breadCrumbItems} p={0} pb={[1, 1, 1, 10]} />

      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <Div
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Div>
              <Text>
                {messages.label_created}{" "}
                {formatDateAndTime(subscriptionDetail.created_at)}
              </Text>
            </Div>
            <Div>
              <ColouredMediumWeight fontSize="var(--fs-text-m)">
                {translateStatus[subscriptionDetail.status]}
              </ColouredMediumWeight>
            </Div>
          </Div>
          <Div mt={4}>
            <H2>{messages.title_customers}</H2>
            <Div mt={3}>
              <TextMediumWeight>
                {messages.label_company_name}:{" "}
              </TextMediumWeight>
              <Text> {customerDetail.company_name}</Text>
            </Div>
            <Div mt={3}>
              <TextMediumWeight>
                {messages.label_company_number}:{" "}
              </TextMediumWeight>
              <Text> {customerDetail.registration_nr}</Text>
            </Div>
            <Div mt={3}>
              <TextMediumWeight>{messages.label_email}: </TextMediumWeight>
              <Text> {customerDetail.email}</Text>
            </Div>
            <Div mt={3}>
              <TextMediumWeight mr={1}>
                {`${messages.label_billing_mail}:`}
              </TextMediumWeight>
              <Text>{customerDetail.billing_email}</Text>
            </Div>
            <Div mt={3}>
              <TextMediumWeight>
                {messages.label_company_phone}:{" "}
              </TextMediumWeight>
              <Text> {customerDetail.phone}</Text>
            </Div>
            <Div mt={3}>
              <TextMediumWeight>{messages.user}: </TextMediumWeight>
              <Text> {customerDetail.name}</Text>
            </Div>
            <Div mt={3} mb={["30px", "40px"]}>
              <LinkArrow
                display={"flex"}
                label={messages.customer_details}
                onClick={redirectToCustomerDetails}
                mb={1}
                iconPos="right"
              >
                <Icon ml={1} name="headerarrowright" />
              </LinkArrow>
            </Div>
          </Div>
          <ActiveSubscription
            activePackage={subscriptionDetail}
            activityLogs={getActivityLog}
            isShowChangePlanButton={false}
          />
          {subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED && (
            <Container m={"0px !important"}>
              <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={infoSchemaPackagesUpdate}
                onSubmit={updateSubscriptionByAdmin}
              >
                {(formik) => {
                  const { isValid, values, touched } = formik;
                  return (
                    <FormikForm
                      textAlign={["center", "center", "center", "left"]}
                    >
                      <Div mt={4}>
                        <H2>{messages.change_plan}</H2>
                        <Div mt={4}>
                          {subsPackages.length &&
                            subsPackages.map((subscription, key) => {
                              const { name, price: totalPrice = "" } =
                                subscription;
                              return (
                                <Div
                                  width={1}
                                  display="flex"
                                  alignItems="center"
                                  mb={"30px"}
                                  key={key}
                                >
                                  <RadioButton
                                    name={PACKAGE}
                                    data={subscription.id}
                                    value={values && values.package}
                                    onChange={handlePackage.bind(
                                      this,
                                      subscription,
                                      formik,
                                    )}
                                    checked={selectedPackage == subscription.id}
                                    formikProps={formik}
                                  />
                                  <LabelSemiBold
                                    mx={2}
                                  >{`${name}, `}</LabelSemiBold>
                                  <TextColoured
                                    color="var(--grey)"
                                    fontSize="var(--fs-h4)"
                                  >
                                    {subscriptionDetail.product_id ==
                                    subscription.id
                                      ? messages.current
                                      : `${totalPrice?.toLocaleString(
                                          "sv-SE",
                                        )} ${messages.price_currency}`}
                                  </TextColoured>
                                </Div>
                              );
                            })}

                          {isSpecialInput && (
                            <Div
                              display="flex"
                              flexWrap="wrap"
                              mx={["0px", "-15px", "-15px"]}
                            >
                              <Div
                                px={[0, "15px", "15px"]}
                                width={[1, 1 / 3, 1 / 3]}
                              >
                                <InputText
                                  curved
                                  placeholder="1 000"
                                  label={messages.available_analysis}
                                  value={values.availableanalysis}
                                  name={AVAILABLE_ANALYSIS}
                                  formikProps={formik}
                                  type={TEXT}
                                  labelAlignment="left"
                                  width={1}
                                />
                              </Div>
                              <Div
                                px={[0, "15px", "15px"]}
                                width={[1, 1 / 3, 1 / 3]}
                              >
                                <InputText
                                  curved
                                  placeholder="1 000"
                                  label={messages.total_analysis}
                                  value={values.totalanalysis}
                                  name={TOTAL_ANALYSIS}
                                  formikProps={formik}
                                  type={TEXT}
                                  labelAlignment="left"
                                  width={1}
                                />
                              </Div>
                              <Div
                                px={[0, "15px", "15px"]}
                                width={[1, 1 / 3, 1 / 3]}
                              >
                                <InputText
                                  curved
                                  placeholder="537 000"
                                  label={messages.price_kr}
                                  value={values.price}
                                  name={PRICE}
                                  formikProps={formik}
                                  type={TEXT}
                                  labelAlignment="left"
                                  width={1}
                                />
                              </Div>
                            </Div>
                          )}
                        </Div>
                        {errorMessage && (
                          <Div display="flex" justifyContent="center">
                            <ErrorMessage errorMessage={errorMessage} />
                          </Div>
                        )}
                        <Div
                          mt={4}
                          display="flex"
                          flexWrap={["wrap", "nowrap", "nowrap"]}
                          alignItems="center"
                        >
                          {subscriptionPermission ===
                            SUBSCRIPTION_STATUS.WRITE && (
                            <PrimaryButtonIcon
                              mr={[0, 4, 4]}
                              mb={["20px", "0px"]}
                              minWidth={["100%", 193]}
                              type="submit"
                              disabled={
                                !(isValid && Object.keys(touched).length > 0)
                              }
                            >
                              <Icon
                                className={"check-rounded"}
                                backgroundColor={"var(--white)"}
                                fontSize={"var(--fs-icon-s)"}
                                mr={"10px"}
                                name="tick-mark"
                              ></Icon>
                              {messages.save_invoice}
                            </PrimaryButtonIcon>
                          )}
                          {subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE &&
                            subscriptionPermission ===
                              SUBSCRIPTION_STATUS.WRITE && (
                              <PrimaryButtonIconOutlined
                                mr={[0, 4, 4]}
                                mb={["20px", "0px"]}
                                minWidth={["100%", 214]}
                                type="button"
                                onClick={confirmStatus.bind(
                                  this,
                                  SUBSCRIPTION_STATUS.STOPPED,
                                )}
                              >
                                {messages.stop_subscription}
                              </PrimaryButtonIconOutlined>
                            )}
                          {subscriptionStatus === SUBSCRIPTION_STATUS.STOPPED &&
                            subscriptionPermission ===
                              SUBSCRIPTION_STATUS.WRITE && (
                              <PrimaryButtonIconOutlined
                                mr={[0, 4, 4]}
                                mb={["20px", "0px"]}
                                minWidth={["100%", 200]}
                                alignItems="center"
                                type="button"
                                onClick={confirmStatus.bind(
                                  this,
                                  SUBSCRIPTION_STATUS.ACTIVE,
                                )}
                              >
                                <Icon
                                  fontSize={"var(--fs-icon-m) !important"}
                                  mr={"10px"}
                                  name="restart"
                                ></Icon>
                                {messages.reopen_subscription}
                              </PrimaryButtonIconOutlined>
                            )}
                          {(subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ||
                            subscriptionStatus ===
                              SUBSCRIPTION_STATUS.STOPPED) &&
                            subscriptionPermission ===
                              SUBSCRIPTION_STATUS.WRITE && (
                              <PrimaryButtonIconOutlined
                                type="button"
                                minWidth={["100%", 273]}
                                onClick={confirmStatus.bind(
                                  this,
                                  SUBSCRIPTION_STATUS.TERMINATED,
                                )}
                              >
                                <Icon
                                  fontSize={"var(--fs-icon-m) !important"}
                                  mr={"10px"}
                                  name="rubber"
                                ></Icon>
                                {messages.terminate_subscription}
                              </PrimaryButtonIconOutlined>
                            )}
                        </Div>
                      </Div>
                    </FormikForm>
                  );
                }}
              </Formik>
            </Container>
          )}
          {showSuccessPopup && (
            <Dialog
              visible="displayBasic"
              draggable={false}
              width={[1, 1, 1, 445]}
              m={[3, 3, 3, "auto"]}
              onHide={onCancel}
            >
              <Div display="flex" flexDirection="column" alignItems="center">
                <Div mt={3} mb={4} textAlign={["center"]}>
                  <H3 mb={3}>{messages.label_done}</H3>
                  <Text>{messages.subscription_updated}</Text>
                </Div>
                <PrimaryButton
                  rounded
                  semibold
                  width={"150px"}
                  mb={[30, 0]}
                  label={messages.label_done}
                  onClick={onCancel}
                />
              </Div>
            </Dialog>
          )}
          {confirmStatusPopup && (
            <Dialog
              visible="displayBasic"
              draggable={false}
              width={[1, 1, 1, 445]}
              m={[3, 3, 3, "auto"]}
              onHide={confirmStatus}
            >
              <Div display="flex" flexDirection="column" alignItems="center">
                <Div mt={3} mb={4} textAlign={["center"]}>
                  <H3 mb={3}>{popupTitleChange[newStatus].title}</H3>
                  <Text>{popupTitleChange[newStatus].description}</Text>
                </Div>
                <Div display="flex" flexWrap="wrap">
                  <PrimaryButton
                    rounded
                    semibold
                    mb={[30, 0]}
                    width={"150px"}
                    mr={3}
                    label={messages.no_label}
                    onClick={confirmStatus}
                  />
                  <PrimaryButtonIconOutlined
                    width={"150px"}
                    type="button"
                    onClick={stopSubscription.bind(this)}
                  >
                    {messages.yes_label}
                  </PrimaryButtonIconOutlined>
                </Div>
              </Div>
            </Dialog>
          )}
        </>
      )}
    </AdminContainer>
  );
};

export default SubscriptionDetails;
