import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { ROUTES, WRITE, READ, ADMIN_CUSTOMER_DETAILS } from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";
import Container from "@components/Container";

const searchableColumns = [
  "id",
  "billing_account.invoice_remark",
  "billing_account.your_reference",
  "fortnox_id",
  "billing_account.name",
  "billing_account.fortnox_customer_id",
];

const FILTER_KEY_FROM = "filter[invoice_date][gte]";

const FILTER_KEY_TO = "filter[invoice_date][lte]";

const Invoices = () => {
  const { messages } = useIntl();

  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const hasReadCustomerPermission =
    permissions?.customers === WRITE || permissions?.customers === READ;

  const dataTableConfig = {
    header: {
      title: "subscription_order_invoice",
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
        },
      ],
    },
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_billing_invoices_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: "invoices.csv",
    api: {
      resource: `/invoices`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["include", "billingAccount,billingAccount.customer"]],
    },
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[invoice_date][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
    default_sort: {
      field: "fortnox_id",
      order: "desc",
    },
    columns: [
      {
        title: "label_invoice_id",
        db_field: "fortnox_id",
        type: "id",
        sortable: true,
        sortField: "fortnox_id",
      },
      {
        title: "created_at",
        db_field: "created_at",
        type: "text",
        formatter: (value) => {
          return value ? formatDateAndTime(value) : "-";
        },
      },
      {
        title: "label_invoice_date",
        db_field: "invoice_date",
        type: "text",
      },
      {
        title: "title_customer",
        db_field: "billing_account.customer.customer_name",
        type: "link",
        href: (values = {}) => {
          const customer_id = values["billing_account.customer_id"] || "";
          return `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_CUSTOMER_DETAILS}`;
        },
        className: hasReadCustomerPermission ? "text-blue" : "text-grey",
        sortable: true,
        sortField: "customer.name",
      },
      {
        title: "account",
        db_field: "billing_account.name",
        type: "text",
        sortable: true,
        sortField: "billing_account.name",
      },

      {
        title: "fortnox_customer",
        db_field: "billing_account.fortnox_customer_id",
        type: "text",
        sortable: true,
      },
      {
        title: "placeholder_your_reference_person",
        db_field: "billing_account.your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "billing_account.invoice_remark",
        type: "text",
      },
      {
        title: "amount",
        db_field: "amount",
        type: "currency",
        sortable: true,
      },
    ],
  };
  return (
    <Container m={"0px !important"}>
      <DataTable config={dataTableConfig} />
    </Container>
  );
};

export default Invoices;
