import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { FAILED_STATUS, PENDING_STATUS } from "@utils/constant";
import { isMobile } from "@utils/utils";

import Div from "@components/Div";
import BankIDModal from "../../pages/user/orderSummary/BankIDModal";
import { Error } from "@components/Heading";
import { PrimaryButton, PrimaryButtonIconOutlined } from "@components/Button";
import { useBankIDLogin } from "@hooks/useBankIDLogin";

const BankIDLogin = ({ ssn, onSuccess, onError }) => {
  const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState(false);
  const [error, setError] = useState(null);

  const {
    qrCodeData,
    bankIdFeedback,
    onUseQrCode,
    onUseBankIdApp,
    onCancelBankId,
  } = useBankIDLogin(isQRCodeModalVisible, onError);

  const { messages } = useIntl();

  useEffect(() => {
    const isValidSSN = (bankIdSSN) => {
      return ssn.toString().replace("-", "") === bankIdSSN.replace("-", "");
    };

    if (
      !bankIdFeedback.status ||
      [PENDING_STATUS, FAILED_STATUS].includes(bankIdFeedback.status)
    ) {
      return;
    }

    if (!isValidSSN(bankIdFeedback.personalNumber)) {
      setIsQRCodeModalVisible(false);
      setError(messages.bankid_ssn_mismatch);

      return;
    }

    onSuccess();
  }, [bankIdFeedback, onSuccess, ssn, messages.bankid_ssn_mismatch]);

  const handleUseBankIdApp = () => {
    onUseBankIdApp();
  };

  const handleUseQRCode = () => {
    onUseQrCode();
    setIsQRCodeModalVisible(true);
  };

  const handleCloseModal = () => {
    onCancelBankId();
  };

  const handleTryAgain = () => {
    onCancelBankId();
    onUseQrCode();
  };

  return (
    <Div>
      {error && <Error>{error}</Error>}
      {isMobile && (
        <PrimaryButton
          mt={15}
          mb={15}
          width={[1, 1, "fit-content"]}
          justifyContent="center"
          minHeight="40px"
          px={30}
          onClick={handleUseBankIdApp}
        >
          {messages.label_use_bankID_app}
        </PrimaryButton>
      )}
      <PrimaryButtonIconOutlined
        width={[1, 1, "fit-content"]}
        minHeight="40px"
        onClick={handleUseQRCode}
        type="button"
      >
        {messages.label_use_bankID_on_another_device}
      </PrimaryButtonIconOutlined>

      {isQRCodeModalVisible && qrCodeData && (
        <BankIDModal
          hideModal={handleCloseModal}
          qrCodeData={qrCodeData}
          bankIDFeedback={bankIdFeedback}
          onTryAgain={handleTryAgain}
        />
      )}
    </Div>
  );
};

BankIDLogin.propTypes = {
  ssn: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default BankIDLogin;
