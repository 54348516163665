import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { Text, TextMediumWeight, TextSemiBoldWeight } from "@components/Text";

import EditFormDialog from "./EditFormDialog";

import { FORM_INPUT_TYPE } from "@utils/enum";
import { isAdminUser, enumValueToTranslationKey } from "@utils/utils";

TextMediumWeight.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

Text.defaultProps = {
  fontSize: "var(--fs-text-m)",
};

const renderFiller = ({ key, index }) => (
  <Div
    key={`${key}_${index}`}
    flex="1 1 40%"
    display={["none", "none", "block", "block"]}
  />
);

const renderText = ({ key, index, label, value }) => (
  <Div key={`${key}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {`${label}: `}
      <Text>{value}</Text>
    </TextMediumWeight>
  </Div>
);

const renderDropdown = ({ label, value, optionFields, messages }) => (
  <>
    <Div flex="1 1 40%">
      <TextMediumWeight>
        {`${label}: `}
        <Text>
          {messages[
            `label_${enumValueToTranslationKey(String(value || ""))}`
          ] || (value === 0 || value === 1 ? value.toString() : value)}{" "}
        </Text>
      </TextMediumWeight>
    </Div>
    {optionFields?.[value]?.map(
      ({ key, value, translationKey, type }, index) => {
        const renderItem = components[type];

        return (
          <Div key={`${key}_${index}`} flex="1 1 20%" mt={[-2, -2, 0, 0]}>
            {renderItem?.({
              key,
              value,
              label: messages[translationKey],
              type,
            })}
          </Div>
        );
      },
    )}
  </>
);

const renderMultiselect = ({
  label,
  values = [],
  optionFields = {},
  messages,
}) => (
  <>
    <Div flex="1 1 40%">
      <TextMediumWeight>{`${label}: `}</TextMediumWeight>
      <Div>
        {values.map((selectedValue, index) => (
          <Text key={`${selectedValue}_${index}`}>
            {messages[
              `label_${enumValueToTranslationKey(selectedValue || "")}`
            ] || selectedValue}
          </Text>
        ))}
      </Div>
    </Div>

    {values.map((selectedValue, outerIndex) =>
      optionFields?.[selectedValue]?.map(
        ({ key, value, translationKey, type }, innerIndex) => {
          const renderItem = components[type];

          return (
            <Div
              key={`${key}_${outerIndex}_${innerIndex}`}
              flex="1 1 40%"
              mt={[-2, -2, 0, 0]}
            >
              {renderItem?.({
                key,
                value,
                label: messages[translationKey],
                type,
              })}
            </Div>
          );
        },
      ),
    )}
  </>
);

const renderSelectorValue = ({ key, index, label, value, selectorField }) => (
  <Div key={`${key}_${index}`} flex="1 1 40%">
    <TextMediumWeight>
      {`${label}: `}
      <Text>{value?.[selectorField]}</Text>
    </TextMediumWeight>
  </Div>
);

const components = {
  [FORM_INPUT_TYPE.FILLER]: renderFiller,
  [FORM_INPUT_TYPE.TEXT]: renderText,
  [FORM_INPUT_TYPE.DROPDOWN]: renderDropdown,
  [FORM_INPUT_TYPE.AUTOCOMPLETE]: renderSelectorValue,
  [FORM_INPUT_TYPE.CALENDAR]: renderText,
  [FORM_INPUT_TYPE.MULTISELECT]: renderMultiselect,
};

const EditableFieldsCard = ({
  fullWidthInputs,
  title,
  config,
  validationSchema,
  isEditPermission,
  onSubmit,
  isPaddingIncreased,
}) => {
  const { messages } = useIntl();
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);

  const handleEdit = () => {
    setIsEditDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setIsEditDialogVisible(false);
  };

  return (
    <Card my={3} p={isPaddingIncreased ? 4 : 3} borderRadius="20px">
      {isEditDialogVisible && (
        <EditFormDialog
          fullWidthInputs={fullWidthInputs}
          title={title}
          config={config}
          onHide={handleCloseDialog}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        />
      )}

      <Div
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <TextSemiBoldWeight>{title}</TextSemiBoldWeight>
        {isEditPermission && (
          <ButtonLinkIcon
            label={<TextMediumWeight>{messages.label_edit}</TextMediumWeight>}
            onClick={handleEdit}
            icon={
              <Icon
                name="pen"
                mr={2}
                color="var(--turquoise)"
                fontSize="var(--fs-icon-s)"
              />
            }
          />
        )}
      </Div>

      <Div width={1} height="1px" backgroundColor="var(--grey-lightest)" />

      <Div
        mt={3}
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
        gridGap={3}
      >
        {config?.map(
          (
            {
              key,
              translationKey,
              value,
              type,
              optionFields,
              isAdminField,
              selectorField,
            },
            index,
          ) => {
            if (isAdminField && !isAdminUser()) {
              return <></>;
            }

            const renderItem = components[type];

            return renderItem?.({
              key,
              index,
              label: messages[translationKey],
              value,
              optionFields,
              messages,
              selectorField,
            });
          },
        )}
      </Div>
    </Card>
  );
};

EditableFieldsCard.propTypes = {
  fullWidthInputs: PropTypes.bool,
  title: PropTypes.string,
  config: PropTypes.array,
  validationSchema: PropTypes.object,
  isEditPermission: PropTypes.bool,
  onSubmit: PropTypes.func,
  isPaddingIncreased: PropTypes.bool,
};

export default EditableFieldsCard;
