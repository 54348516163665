import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Div from "@components/Div";
import Span from "@components/Span";
import Icon from "@components/Icon";
import Dialog from "@components/Dialog";
import Breadcrumb from "@components/Breadcrumb";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";
import { default as Tabs } from "@components/Tabs";
import { LinkArrow } from "@components/Link";
import CompanyInfo from "./tabs/CompanyInfo";
import Users from "./tabs/Users";
import Orders from "./tabs/Orders";
import Subscription from "./tabs/Subscription";
import Analysis from "./tabs/Analysis";
import BillingAccountsTab from "./tabs/BillingAccountsTab";
import {
  ROUTES,
  READ,
  WRITE,
  ADMIN_CUSTOMER_DETAILS,
  ADMIN_CUSTOMER_USERS,
  ADMIN_CUSTOMER_ORDERS,
  ADMIN_CUSTOMER_CREDITS,
  GET_SUBSCRIPTION_DETAIL,
  ADMIN_BILLING_ACCOUNTS,
  ROLE_ANALYSIS,
  ADMIN_WATCHLIST_URL,
  INTEGRATIONS_API,
  RAPPORT,
} from "@utils/constant";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import DeleteUser from "@pages/admin/users/Delete";
import { formatDateAndTime, getUserRoleTranslationKey } from "@utils/utils";
import customerDetailsService from "@app/services/customers/customerDetailsService";
import getRolesService from "@app/services/users/getRolesService";
import {
  deleteCustomerProfile,
  deleteUserProfile,
} from "@app/services/users/deleteUserProfileService";
import ProgressSpinner from "@components/ProgressSpinner";
import { loadActiveSubscriptionData } from "@utils/common";
import List from "@pages/user/watchlist/list";
import Products from "./tabs/Products";
import IntegrationsAPILogs from "./tabs/IntegrationsAPILogs";
import AdminContainer from "@layout/AdminContainer";

import { USER_TYPE } from "@utils/enum";

import UserDialog from "../users/components/UserDialog";

const StyledTabs = styled(Tabs)`
  .p-tabview-nav {
    justify-content: start;
  }
  .p-tabview-nav li {
    min-width: 10%;
  }
`;

const Details = () => {
  const { messages } = useIntl();
  const history = useHistory();

  const { location: { state = {} } = {} } = history;
  const { id = "" } = useParams();
  const { status } = state;
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const [roles, setRoles] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [showCustomerProductDialog, setShowCustomerProductDialog] =
    useState(false);

  const CustomerName =
    customerData?.company_name ||
    customerData?.firstname + " " + customerData?.lastname ||
    messages.title_customer;

  const breadCrumbItems = [
    {
      label: messages.title_customers,
      url: ROUTES.ADMIN_CUSTOMERS.URL,
    },
    {
      label: CustomerName,
    },
  ];
  let isLoading = false;

  useEffect(() => {
    const fetchData = async () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isLoading = true;
      setIsFetching(true);
      try {
        // Fetch roles
        const rolesResponse = await getRolesService("customer");
        const availableRoles = rolesResponse.map((o) => ({
          ...o,
          label: messages[getUserRoleTranslationKey(o.value)],
        }));
        setRoles(availableRoles);

        // Fetch customer details
        setIsFetching(true);
        const customerDetailsResponse = await customerDetailsService(id);
        const { data } = customerDetailsResponse.data;
        setCustomerData({
          ...data,
          createdAt: data.created_at ? formatDateAndTime(data.created_at) : "",
        });
        loadActiveSubscriptionData({ customer: data }).then((response) => {
          setActiveSubscription(response);
        });
        setIsFetching(false);
        isLoading = false;
      } catch (e) {
        throw new Error(e);
      }
    };

    if (
      (!roles?.length ||
        !(customerData && Object?.entries(customerData)?.length)) &&
      !isLoading
    ) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUser = () => {
    if (showDialog) {
      setReloadList(!reloadList);
    }
    setShowDialog(!showDialog);
  };

  const handleDeleteModal = (data = {}) => {
    setSelectedData(data);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteUser = async () => {
    const { id: customerId = "" } = selectedData;
    try {
      await deleteUserProfile({ id: customerId });
      if (showDeleteModal) {
        setReloadList(!reloadList);
      }
      handleDeleteModal();
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleEditUserModal = (data = {}) => {
    setSelectedData(data);
    if (showEditUserModal) {
      setReloadList(!reloadList);
    }
    setShowEditUserModal(!showEditUserModal);
  };

  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDeleteDialog = async () => {
    try {
      const { id: customerId } = customerData;
      await deleteCustomerProfile({
        customerId,
      });
      history.push(ROUTES.ADMIN_CUSTOMERS.URL);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleSelectedRow = (e) => {
    const { data = {} } = e;
    setSelectedData(data);
  };

  const updateCustomerData = (data) => {
    setCustomerData({
      ...customerData,
      ...data,
    });
  };

  const customersTabContent = [
    {
      title: messages.label_details,
      content: (
        <>
          {!!Object.keys(customerData).length && (
            <CompanyInfo
              customerData={customerData}
              onCustomerDelete={handleCloseDialog}
              id={id}
              status={status}
              updateCustomerData={updateCustomerData}
            />
          )}
        </>
      ),
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
    },
  ];

  let usersTabContent = {
    title: messages.title_users,
    content: (
      <Users
        onInvite={handleAddUser}
        onEdit={handleEditUserModal}
        onDelete={handleDeleteModal}
        customerId={id}
        onSelectedRow={handleSelectedRow}
        reloadList={reloadList}
      />
    ),
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_USERS}`,
  };

  let ordersTabContent = {
    title: messages.label_order_page,
    content: <Orders customerId={id} />,
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_ORDERS}`,
  };

  let billingAccountsContent = {
    title: messages.tab_billing,
    content: (
      <BillingAccountsTab
        customer_id={id}
        updateCustomerData={updateCustomerData}
        customerData={customerData}
        activeSubscription={activeSubscription}
      />
    ),
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_BILLING_ACCOUNTS}`,
  };

  let customersApiTabContent = {
    title: messages.header_api,
    content: (
      <IntegrationsAPILogs
        customerData={customerData}
        updateCustomerDetails={updateCustomerData}
      />
    ),
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${INTEGRATIONS_API}`,
  };

  const redirectToSubscriptionDetailPage = () => {
    history.push(`/admin/${GET_SUBSCRIPTION_DETAIL}/${id}`);
  };

  let subscriptionTabContent = {
    title: messages.title_prepaid_account,
    content: (
      <Subscription
        customerId={id}
        activePackage={activeSubscription}
        customerData={{ customer: customerData }}
        redirectToSubscriptionDetailPage={redirectToSubscriptionDetailPage}
      />
    ),
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_CREDITS}`,
  };

  let analysisTabContent = {
    title: messages.menu_reports,
    content: <Analysis customerId={id} />,
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${RAPPORT}`,
  };

  let watchlistTabContent = {
    title: messages.menu_watchlist,
    content: <List customerId={id} />,
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_WATCHLIST_URL}`,
  };

  let productsTabContent = {
    title: messages.label_services,
    content: (
      <Products
        customerId={id}
        customerData={customerData}
        updateCustomerData={updateCustomerData}
        showCustomerProductDialog={showCustomerProductDialog}
        setShowCustomerProductDialog={setShowCustomerProductDialog}
      />
    ),
    url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/tjanster`,
  };

  const getTabContent = () => {
    const { analysis_type: analysisType = ROLE_ANALYSIS } = customerData;
    if (!permissions) {
      return;
    }
    let tabContent = [...new Set(customersTabContent)];
    let indexValue = 0;
    if (permissions.users === WRITE || permissions.users === READ) {
      indexValue = indexValue + 1;
      usersTabContent = { ...usersTabContent, indexValue };
      tabContent = [...tabContent, usersTabContent];
    }
    if (permissions.orders === WRITE || permissions.orders === READ) {
      indexValue = indexValue + 1;
      ordersTabContent = { ...ordersTabContent, indexValue };
      tabContent = [...tabContent, ordersTabContent];
    }
    if (activeSubscription) {
      indexValue = indexValue + 1;
      subscriptionTabContent = { ...subscriptionTabContent, indexValue };
      tabContent = [...tabContent, subscriptionTabContent];
    }
    if (
      analysisType === ROLE_ANALYSIS &&
      (permissions.orders === WRITE || permissions.orders === READ)
    ) {
      indexValue = indexValue + 1;
      analysisTabContent = { ...analysisTabContent, indexValue };
      tabContent = [...tabContent, analysisTabContent];
    }
    if (
      permissions["watch-lists"] === WRITE ||
      permissions["watch-lists"] === READ
    ) {
      indexValue = indexValue + 1;
      watchlistTabContent = { ...watchlistTabContent, indexValue };
      tabContent = [...tabContent, watchlistTabContent];
    }
    if (permissions.products === WRITE || permissions.products === READ) {
      indexValue = indexValue + 1;
      productsTabContent = { ...productsTabContent, indexValue };
      tabContent = [...tabContent, productsTabContent];
    }

    indexValue = indexValue + 1;
    billingAccountsContent = { ...billingAccountsContent, indexValue };
    tabContent = [...tabContent, billingAccountsContent];

    indexValue = indexValue + 1;
    customersApiTabContent = { ...customersApiTabContent, indexValue };
    tabContent = [...tabContent, customersApiTabContent];
    return tabContent;
  };

  useEffect(() => {
    const path = history.location.pathname;
    let indexValue;
    switch (path) {
      case usersTabContent?.url:
        indexValue = usersTabContent?.indexValue;
        break;
      case ordersTabContent?.url:
        indexValue = ordersTabContent?.indexValue;
        break;
      case subscriptionTabContent?.url:
        indexValue = subscriptionTabContent?.indexValue;
        break;
      case analysisTabContent?.url:
        indexValue = analysisTabContent?.indexValue;
        break;
      case watchlistTabContent?.url:
        indexValue = watchlistTabContent?.indexValue;
        break;
      case productsTabContent?.url:
        indexValue = productsTabContent?.indexValue;
        break;
      case billingAccountsContent?.url:
        indexValue = billingAccountsContent?.indexValue;
        break;
      case customersApiTabContent?.url:
        indexValue = customersApiTabContent?.indexValue;
        break;
      default:
        indexValue = 0;
    }
    if (indexValue !== activeIndex) {
      setActiveIndex(indexValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, activeSubscription, customerData]);

  const onTabChange = (e) => {
    if (e?.index !== activeIndex) {
      history.push({
        pathname: modifiedTabContent[e.index]?.url,
        search: "",
      });
    }
  };

  const modifiedTabContent = getTabContent();

  const dialogHeader = () => (
    <Div textAlign="center" width={1}>
      <Span upper semibold fontSize="var(--fs-h4) !important">
        {messages.label_delete_customer}
      </Span>
    </Div>
  );

  const handleCloseCustomerProduct = () => {
    setShowCustomerProductDialog(!showCustomerProductDialog);
  };

  const headerConfig = {
    title: CustomerName,
  };

  return (
    <AdminContainer config={headerConfig}>
      {isFetching ? (
        <ProgressSpinner />
      ) : (
        <Div px={0} py={0}>
          <Breadcrumb items={breadCrumbItems} p={0} mb={4} />
          <StyledTabs
            content={modifiedTabContent}
            onTabChange={onTabChange}
            activeIndex={activeIndex}
            isPlainPage={true}
          />
          {showCustomerProductDialog && (
            <Dialog
              header={<H3 textAlign="center">{messages.custom_products}</H3>}
              visible="displayBasic"
              draggable={false}
              onHide={handleCloseCustomerProduct}
              width={["90%", "90%", "90%", 445]}
            >
              <Div display="flex" alignItems="center" flexDirection="column">
                <Div my={3}>
                  <Text>{messages.updating_custom_product_value}</Text>
                </Div>
                <PrimaryButton
                  rounded
                  semibold
                  label={messages.label_ok}
                  onClick={handleCloseCustomerProduct}
                  mb={[4, 4, 4, 0]}
                  width={[1, 1, 1, "45%"]}
                />
              </Div>
            </Dialog>
          )}
          {openDialog && (
            <Dialog
              header={dialogHeader}
              visible="displayBasic"
              draggable={false}
              onHide={handleCloseDialog}
              width={["90%", "90%", "90%", 445]}
            >
              <>
                <Div
                  alignItems="center"
                  lineHeight="130%"
                  display="flex"
                  flexDirection="column"
                  mt={30}
                >
                  <Span medium light>
                    {messages.customer_delete_message}
                  </Span>
                  <Span medium light>
                    {messages.customer_delete_description}
                  </Span>
                  <Div mt={20}>
                    <LinkArrow mr={2} info semibold>
                      {messages.label_more_about_gdpr}
                      <Icon
                        name="headerarrowright"
                        color={"var(--turquoise)"}
                      />
                    </LinkArrow>
                  </Div>
                </Div>
                <Div
                  mt={27}
                  display="flex"
                  justifyContent="center"
                  flexDirection={[
                    "column-reverse",
                    "column-reverse",
                    "column-reverse",
                    "row",
                  ]}
                >
                  <PrimaryButtonOutlined
                    rounded
                    semibold
                    label={messages.label_cancel}
                    onClick={handleCloseDialog}
                    mr={[0, 0, 0, 30]}
                    width={[1, 1, 1, "45%"]}
                  />
                  <PrimaryButton
                    rounded
                    semibold
                    label={messages.label_delete}
                    onClick={handleDeleteDialog}
                    mb={[4, 4, 4, 0]}
                    width={[1, 1, 1, "45%"]}
                  />
                </Div>
              </>
            </Dialog>
          )}
          {showDialog && (
            <UserDialog
              title={messages.label_invite_sub_user}
              userType={USER_TYPE.CUSTOMER}
              customerId={id}
              handleClose={handleAddUser}
            />
          )}
          {showEditUserModal && (
            <UserDialog
              title={messages.label_edit_user}
              userType={USER_TYPE.CUSTOMER}
              userDetails={selectedData}
              handleClose={handleEditUserModal}
            />
          )}
          {showDeleteModal && (
            <DeleteUser
              onDelete={handleDeleteUser}
              onCancel={handleDeleteModal}
              subUser
            >
              <Div
                variant="primary"
                justifyContent="center"
                display="flex"
                fontWeight="var(--medium-weight)"
                textAlign="center"
              >
                {messages.text_confirm_delete_subuser}
              </Div>
            </DeleteUser>
          )}
        </Div>
      )}
    </AdminContainer>
  );
};
export default Details;
