import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import Div from "@components/Div";
import InputText from "@components/InputText";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import { INPUT } from "@utils/constant";
import Email from "@pages/auth/schema/Email";
import Name from "@pages/auth/schema/Name";
import Numbers from "@pages/auth/schema/Numbers";
import editUserInfoService from "@app/services/users/editUserInfoService";
import { authActions } from "@app/reducers/authReducer";
const RegistrationSchema = Email.concat(Name).concat(Numbers);

const EditUserForm = (prop) => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { EMAIL, NAME, LAST_NAME, PHONE } = INPUT.NAME;
  const { TEXT } = INPUT.TYPE;
  const { onCancelClick, id, editType, onCloseModal, userInfo } = prop;
  const { firstname = "", lastname = "", email = "", phone = "" } = userInfo;
  const EditUserSchema = RegistrationSchema.pick([
    EMAIL,
    NAME,
    LAST_NAME,
    PHONE,
  ]);

  const handleFormSubmit = async (values) => {
    try {
      const {
        firstname: formDataFirstName,
        lastname: formDataLastName,
        email: formDataEmail,
        phone: formDataPhone,
      } = values;
      const response = await editUserInfoService({
        firstname: formDataFirstName,
        lastname: formDataLastName,
        email: formDataEmail,
        phone: formDataPhone,
        id,
        type: editType,
      });
      const { data: user } = response.data || {};
      dispatch(authActions.storeUserInfo({ ...user, showConfirmDialog: true }));
      onCloseModal();
    } catch (e) {
      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          showConfirmDialog: true,
          isError: true,
        }),
      );
      onCloseModal();
      throw new Error(e);
    }
  };

  return (
    <Div py={0} display="flex" alignItems="center" flexDirection="column">
      <Formik
        initialValues={{
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone,
        }}
        validationSchema={EditUserSchema}
        onSubmit={handleFormSubmit}
      >
        {(formikProp) => {
          const { values, handleSubmit, isValid, dirty } = formikProp;
          return (
            <Form>
              <InputText
                curved
                placeholder={messages.label_firstname}
                value={values.firstname}
                name={NAME}
                type={TEXT}
                formikProps={formikProp}
                label={`${messages.label_firstname} *`}
                labelAlignment="left"
                minWidth={[323, 450]}
              />
              <InputText
                curved
                placeholder={messages.label_last_name}
                value={values.lastname}
                name={LAST_NAME}
                type={TEXT}
                formikProps={formikProp}
                label={`${messages.label_last_name} *`}
                labelAlignment="left"
                minWidth={[323, 450]}
              />
              <InputText
                curved
                placeholder={messages.label_email}
                value={values.email}
                name={EMAIL}
                formikProps={formikProp}
                label={`${messages.label_email} *`}
                labelAlignment="left"
                minWidth={[323, 450]}
              />
              <InputText
                curved
                placeholder={messages.label_phone}
                value={values.phone}
                name={PHONE}
                formikProps={formikProp}
                label={`${messages.label_phone} *`}
                labelAlignment="left"
                minWidth={[323, 450]}
              />
              <Div
                mt={4}
                display="flex"
                flexDirection={["column-reverse", "row"]}
                justifyContent="center"
              >
                <PrimaryButtonOutlined
                  rounded
                  semibold
                  label={messages.label_cancel}
                  onClick={onCancelClick}
                  width={[1, "40%"]}
                  mr={[0, 4]}
                />
                <PrimaryButtonIcon
                  rounded
                  semibold
                  onClick={handleSubmit}
                  disabled={!(isValid && dirty)}
                  label={messages.label_update}
                  mb={[3, 0]}
                />
              </Div>
            </Form>
          );
        }}
      </Formik>
    </Div>
  );
};

export default EditUserForm;
