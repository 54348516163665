import Axios from "@app/api/axios";
import { parseFilterParams, flattenObject } from "@utils/utils";

const constructParamForQueryBuilder = (params, search_fields) => {
  const {
    rows = 10,
    page = 1,
    sortOrder = "desc",
    filters = {},
    sortField = null,
    search = null,
  } = params;
  let payload = `?page=${page}&per_page=${rows}`;
  if (sortField) {
    const sort = `${sortOrder === "desc" ? "-" : ""}`;
    payload = `${payload}&sort=${sort}${sortField}`;
  }
  let filterParams = "";
  let searchParam = "";
  if (filters && Object.keys(filters)?.length) {
    filterParams = `&${parseFilterParams(["filter", filters])}`;
  }
  if (search) {
    searchParam = `&filter[search][value]=${search}&filter[search][columns]=${search_fields.join(
      ",",
    )}`;
  }
  return `${payload}${filterParams}${searchParam}`;
};

const constructParamForBackendCustomised = () => {
  return "";
};

const transformData = (data) => {
  return data.map((value) => {
    return flattenObject(value);
  });
};

const getData = (api, params, enable_query_builder) => {
  const { resource, search_fields } = api;
  const constructedParams = enable_query_builder
    ? constructParamForQueryBuilder(params, search_fields)
    : constructParamForBackendCustomised(params);
  let additionalParams;

  if (api?.params?.length) {
    additionalParams = api.params
      .map((o) => {
        const [label, value] = o;
        return `${label}=${Array.isArray(value) ? value.join(",") : value}`;
      })
      .join("&");
  }

  return Axios.get(resource + `${constructedParams}&${additionalParams}`).then(
    (response) => {
      const { status } = response;
      const { data, total } = response.data;
      const meta = { total };
      return { meta, status, data: transformData(data) };
    },
  );
};

export default getData;
