import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loadActiveSubscriptionData, checkUserPermission } from "@utils/common";
import ActiveSubscription from "../userProfile/ActiveSubscription";
import Subscriptions from "../userProfile/private/Subscriptions";
import { useHistory } from "react-router-dom";
import { SUBSCRIPTION_STATUS } from "@utils/constant";
import { useIntl } from "react-intl";
import UserContainer from "@layout/UserContainer";

const Subscription = () => {
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const [activePackage, setActivePackage] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [getActivityLog, setGetActivityLog] = useState(false);
  const history = useHistory();
  useEffect(() => {
    (async () => {
      if (!activePackage) {
        checkUserPermission(history, userInfo);
        const currentPckg = await loadActiveSubscriptionData(userInfo);
        setActivePackage(currentPckg || {});
        const { status = null } = currentPckg || {};
        const { orders = [] } = currentPckg || {};
        setGetActivityLog(orders);
        setSubscriptionStatus(status);
      }
    })();
  }, [userInfo, history, activePackage]);
  let headerConfig = {
    title: messages.subscription,
  };

  return (
    <UserContainer config={headerConfig}>
      {subscriptionStatus === SUBSCRIPTION_STATUS.TERMINATED && (
        <Subscriptions
          userInfo={userInfo}
          activePackage={activePackage}
          activityLogs={getActivityLog}
          terminated={true}
        />
      )}
      {subscriptionStatus &&
        subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED && (
          <ActiveSubscription
            activePackage={activePackage}
            activityLogs={getActivityLog}
          />
        )}
      {typeof activePackage === "object" &&
        Object.keys(activePackage).length === 0 && (
          <Subscriptions
            userInfo={userInfo}
            activePackage={false}
            activityLogs={getActivityLog}
          />
        )}
    </UserContainer>
  );
};

export default Subscription;
