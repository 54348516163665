import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import Divider from "@components/Divider";
import { H5 } from "@components/Heading";
import { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import { PriceText, TextLargeSemiBoldWeight } from "@components/Text";
import Tooltip from "@components/ToolTip";

import usePermissions from "@hooks/usePermissions";

import { BUNDLE } from "@utils/constant";
import { PERMISSIONS } from "@utils/enum";
import { convertSEK, getFormattedNumber } from "@utils/utils";

function OrderSummaryCard({
  selectedServices,
  isCreditOnly = false,
  isPriceOnly = false,
}) {
  const { messages } = useIntl();
  const { checkForReadPermission } = usePermissions();

  const isPricesPermission = checkForReadPermission(PERMISSIONS.PRICES);

  const renderDiscountedPrice = (service) => (
    <>
      <PriceText
        pb={[1, 1, 0, 0]}
        fontSize={[
          "var(--fs-text-m) !important",
          "var(--fs-text-m) !important",
          "var(--fs-h3) !important",
          "var(--fs-h3) !important",
        ]}
        whiteSpace={"nowrap"}
        textDecoration="line-through"
        color="var(--grey)"
      >
        {service.purchase_by_credits && !isPriceOnly
          ? `${messages.prepaid_price_label}`
          : `${getFormattedNumber(convertSEK(service.price))} ${
              messages.search_label_currency
            }`}
      </PriceText>
      <Div mx={1} />
    </>
  );

  const renderPrice = (service) => {
    const priceValue = service.discount
      ? service.priceWithDiscount
      : service.price;

    return (
      <Div display="flex">
        {!!service?.discount && renderDiscountedPrice()}
        <PriceText
          pb={[1, 1, 0, 0]}
          fontSize={[
            "var(--fs-text-m) !important",
            "var(--fs-text-m) !important",
            "var(--fs-h3) !important",
            "var(--fs-h3) !important",
          ]}
          whiteSpace={"nowrap"}
          color={service.discount ? "var(--blue-dark)" : "var(--grey-dark)"}
        >
          {service.purchase_by_credits && !isPriceOnly
            ? `${messages.prepaid_price_label}`
            : `${getFormattedNumber(convertSEK(priceValue))} ${
                messages.search_label_currency
              }`}
        </PriceText>
      </Div>
    );
  };

  return (
    <Div
      display="flex"
      flexWrap="wrap"
      flexDirection={["row"]}
      justifyContent="space-between"
      width="100%"
    >
      <Div
        display="flex"
        flexWrap="wrap"
        flexDirection={["row", "row", "row"]}
        justifyContent="space-between"
        width={"100%"}
      >
        <Div width={[0.7]}>
          <H5>{messages.chosen_service_label}</H5>
        </Div>
        <Div
          width={isPricesPermission ? 0.19 : 0.3}
          display={["none", "none", "block", "block"]}
        >
          <H5>{messages.delivery_time_label}</H5>
        </Div>
        {isPricesPermission && (
          <Div display={"flex"} justifyContent="end" width={0.11}>
            <H5>
              {isCreditOnly
                ? messages.amount_label
                : messages.watchlist_label_price}
            </H5>
          </Div>
        )}
      </Div>
      <Div
        display="flex"
        flexWrap="wrap"
        flexDirection={["row", "row", "row"]}
        justifyContent="space-between"
        width={"100%"}
      >
        {selectedServices.map((service) => {
          return (
            <Div
              key={service.id}
              display="flex"
              justifyContent="space-between"
              pt={[2, "30px", "8px", "8px"]}
              flexDirection={["column"]}
              width="100%"
            >
              <Div
                key={service.name}
                display="flex"
                flexDirection={["row"]}
                justifyContent="space-between"
                width={"100%"}
                pt={[1, 1, 3, 3]}
                pb={[2, 2, 3, 3]}
              >
                <Div
                  flex={service.purchase_by_credits ? [4, 14] : [8, 8, 14, 14]}
                >
                  <TextLargeSemiBoldWeight
                    pb={[1, 1, 0, 0]}
                    display="flex"
                    alignItems={["flex-start", "center"]}
                  >
                    {service.name}
                    {service.service_type === BUNDLE && (
                      <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
                    )}
                    <SmallEllipseIcon
                      display="flex"
                      justifyContent="center"
                      ml={2}
                      mt={[1, 0]}
                      name="info"
                      className={`serviceName-${service.id}`}
                    >
                      <Tooltip
                        target={`.serviceName-${service.id}`}
                        content={service.description}
                      />
                    </SmallEllipseIcon>
                  </TextLargeSemiBoldWeight>
                </Div>
                <Div flex={3} display={["none", "none", "block", "block"]}>
                  <PriceText pb={[1, 1, 0, 0]}>
                    {service.delivery_time}
                  </PriceText>
                </Div>
                <Div
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems="start"
                  flex={3}
                >
                  {isPricesPermission && renderPrice(service)}
                </Div>
              </Div>
              <Div
                display={["flex", "flex", "none", "none"]}
                flexDirection={"row"}
                justifyContent="space-between"
                width={"100%"}
                pb={[3, 3, 0, 0]}
              >
                <H5>{`${messages.delivery_time_label}:`}</H5>
                <H5>{service.delivery_time}</H5>
              </Div>
              <Divider m={"0 !important"} />
            </Div>
          );
        })}
      </Div>
    </Div>
  );
}

OrderSummaryCard.propTypes = {
  selectedServices: PropTypes.object,
  isCreditOnly: PropTypes.bool,
  isPriceOnly: PropTypes.bool,
};

export default OrderSummaryCard;
