import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import DataTable from "@components/DataTableV2/DataTable";
import {
  ROUTES,
  ADMIN_CUSTOMER_DETAILS,
  ASSIGNMENTS_API,
  WRITE,
  READ,
} from "@utils/constant";
import fetchProductById from "@app/services/services/fetchProductById";
import { formatDateAndTime } from "@utils/utils";
import userService from "@app/services/users/userService";

const searchableColumns = ["id", "customer.customer_name"];
import AdminContainer from "@layout/AdminContainer";

const List = () => {
  const { messages } = useIntl();
  const [services, setServices] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const hasReadCustomerPermission =
    permissions?.customers === WRITE || permissions?.customers === READ;

  useEffect(() => {
    const loadServices = async () => {
      try {
        const [servicesResponse, users] = await Promise.all([
          fetchProductById(),
          userService("filter[type]=admin&per_page=100"),
        ]);
        const {
          data: { data = [] },
        } = servicesResponse;
        const servicesData = data
          ?.filter((o) => o?.service_type === "assignment")
          ?.map((service) => {
            const { name = "", id = "" } = service;
            return { name: name, code: id };
          });
        const { data: { data: userData = [] } = {} } = users;
        await setUserData(
          userData.map((o) => ({ name: `${o?.name} #${o?.id}`, code: o?.id })),
        );
        await setServices(servicesData);
        setIsFilterValuesLoaded(true);
      } catch (e) {
        //catch error
      }
    };

    loadServices();
  }, []);

  const config = {
    header: {
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "checkbox",
        options: [
          {
            label: messages.new,
            value: "new",
            checked: false,
          },
          {
            label: messages.status_in_progress,
            value: "in-progress",
            checked: false,
          },
          {
            label: messages.completed,
            value: "completed",
            checked: false,
          },
        ],
      },
      {
        title: messages.investigator,
        id: "filter[user_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[user_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[user_id]",
            options: userData,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[orderItem.product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[orderItem.product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[orderItem.product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: "assignments.csv",
    backend_querying: true,
    no_records_message: "datatable_admin_assignments_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ASSIGNMENTS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["include", ["user", "customer", "orderItem"]]],
    },
    is_filter_options_updated: isFilterValuesLoaded,
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "link",
        formatter: (value) => {
          return value ? `#${value}` : "-";
        },
        width: "100px",
        className: "text-blue",
        href: ({ id }) => `${ROUTES.ASSIGNMENT_DETAILS.URL}?id=${id}`,
      },
      {
        title: "label_date_and_time",
        db_field: "created_at",
        type: "text",
        sortable: true,
        formatter: (value) => {
          return value ? formatDateAndTime(value) : "-";
        },
      },
      {
        db_field: "order_id",
        title: "watchlist_title_order_id",
        type: "link",
        className: "text-blue",
        width: "100px",
        formatter: (value) => {
          return value ? `#${value}` : "-";
        },
        href: (values) =>
          `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${values["order_id"]}`,
      },
      {
        db_field: "order_item.name",
        header: messages.label_service,
        type: "text",
        width: "28%",
        sortable: true,
        sortField: "orderItem.name",
      },

      {
        title: "title_customer",
        db_field: "customer.customer_name",
        type: "link",
        sortable: true,
        href: (values) =>
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${values["customer_id"]}/${ADMIN_CUSTOMER_DETAILS}`,
        className: hasReadCustomerPermission ? "text-blue" : "text-grey",
      },

      {
        db_field: "user.name",
        title: "investigator",
        type: "text",
        sortable: false,
      },
      {
        db_field: "status",
        width: "120px",
        title: "label_status",
        type: "enum",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: ({ id }) => `${ROUTES.ASSIGNMENT_DETAILS.URL}?id=${id}`,
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.title_assignments,
  };

  return (
    <AdminContainer config={headerConfig}>
      <DataTable config={config} />
    </AdminContainer>
  );
};

export default List;
