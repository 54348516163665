import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import queryString from "query-string";
import styled from "styled-components";
import Container from "@components/Container";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";
import { H2 } from "@components/Heading";
import { Text, TextSemiBoldWeight } from "@components/Text";
import Link from "@components/Link";
import Icon from "@components/Icon";
import { ROUTES, STATUS_EXPIRED, ADMIN } from "@utils/constant";
import ProgressSpinner from "@components/ProgressSpinner";
import { default as getDetails } from "@app/services/orders/fetchOrderDetails";
import getWatchListObjectOrderDetails from "@app/services/orders/getWatchListObjectOrderDetails";
import { formatDate, addHyphenToNumber } from "@utils/utils";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import Dialog from "@components/Dialog";
import { handleException } from "@utils/common";
import WatchListTables from "./WatchListTables";

const StyledIcon = styled(Icon)`
  transform: rotate(180deg);
`;
const WatchListObjectOrderDetails = () => {
  const { search = "" } = useLocation();
  const { messages } = useIntl();
  const [orderDetails, setOrderDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orderItem, setOrderItem] = useState([]);
  const [historyReports, setHistoryReports] = useState([]);
  const [ssnNumber, setSsnNumber] = useState([]);
  const [report, setReport] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const breadCrumbItems = [
    {
      label: messages.label_back,
      url:
        userInfo.type !== ADMIN
          ? `${ROUTES.ORDERS_DETAILS.URL}?id=${orderDetails?.order_id}`
          : `${ROUTES.ADMIN_ORDER_DETAILS.URL}?id=${orderDetails?.order_id}`,
    },
  ];

  const handleErrorPopup = () => {
    setShowErrorModal(false);
  };

  useEffect(() => {
    try {
      const { id: orderId = "", ssn } = queryString.parse(search);
      setSsnNumber(ssn);
      const getWatchListObject = (orderDetails = {}) => {
        const { order_items, reports } = orderDetails;
        const filteredOrderItem = order_items?.find(
          (item) => item.personal_number === ssn,
        );
        if (filteredOrderItem) {
          setOrderItem([filteredOrderItem]);
        }
        const filteredReports = reports?.find(
          (report) => report.personal_number === parseInt(ssn),
        );
        if (filteredReports) {
          setReport(filteredReports);
          loadReportsHistory(
            orderDetails?.customer?.id,
            filteredReports.watch_list_object_id,
            orderDetails?.order_id,
          );
        }
      };
      const loadReportsHistory = async (customerId, objectId, orderId) => {
        const response = await getWatchListObjectOrderDetails(
          objectId,
          customerId,
          orderId,
        );
        const { data: { data: reports = [] } = {} } = response;
        const updatedReports = reports.map((report) => {
          const completed_at = formatDate(report.completed_at);
          const seeReport =
            report.report_status !== STATUS_EXPIRED
              ? messages.watchlist_label_results
              : report.report_status;
          const { grades, order_item: orderItem = {} } = report || {};
          const { product = {} } = orderItem || {};
          const { grade_mapping: gradeMapping = {} } = product || {};
          const { grade = [] } = gradeMapping || {};
          const {
            color: gradeColor = "white",
            bgColor: gradeBgColor = "grey",
          } = grades ? grade?.find((o) => o?.grades === grades) || {} : {};
          return {
            ...report,
            gradeColor,
            gradeBgColor,
            completed_at,
            seeReport,
          };
        });
        setHistoryReports(updatedReports);
        setIsLoading(false);
      };

      const getOrderDetails = async () => {
        const res = await getDetails(orderId);
        setOrderDetails(res);
        getWatchListObject(res);
      };
      setIsLoading(true);
      getOrderDetails();
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  }, [search, messages]);

  return (
    <>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <Container m={"0 !important"}>
          <Div display="flex" alignItems="center" mt={1}>
            <Link>
              <StyledIcon name="headerarrowright" />
            </Link>
            <Breadcrumb items={breadCrumbItems} />
          </Div>
          <Div pb={2}>
            <Div
              display={["block", "flex"]}
              alignItems="center"
              justifyContent="space-between"
            >
              <H2>{report?.name}</H2>
            </Div>
            <Div
              mt={4}
              mb={3}
              display="flex"
              flexDirection={["column", "row"]}
              alignItems={["left", "center"]}
            >
              <Div>
                <TextSemiBoldWeight>
                  {`${messages.watchlist_label_personal_number}: `}
                </TextSemiBoldWeight>
                <Text ml={3}>{addHyphenToNumber(ssnNumber.toString(), 8)}</Text>
              </Div>
              <Div mt={[3, 0]} ml={[0, 5]}>
                <TextSemiBoldWeight>{`${messages.watchlist_label_watchlist}:`}</TextSemiBoldWeight>
                <Text ml={3}>{orderDetails?.watchList?.name}</Text>
              </Div>
            </Div>
          </Div>
          <WatchListTables
            content={orderItem}
            status={orderDetails?.status}
            isCustomer={userInfo.type !== ADMIN}
            isWatchList={
              orderDetails?.watchList && orderDetails?.watchList !== null
            }
            watchList={orderDetails?.watchList}
            reports={orderDetails?.reports}
            totalPrice={orderDetails?.total_price}
            totalCredits={orderDetails?.total_credits}
            isObject={true}
            historyReports={historyReports}
            report={report}
            orderItems={orderDetails?.order_items}
          />
        </Container>
      )}
      {showErrorModal && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleErrorPopup}
          width={["90%", "90%", "90%", 445]}
        >
          <ErrorModal
            handleConfirmPopup={handleErrorPopup}
            content={errorMessage}
          />
        </Dialog>
      )}
    </>
  );
};

export default WatchListObjectOrderDetails;
