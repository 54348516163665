import React, { useState } from "react";
import PropTypes from "prop-types";
import OrderSummary from "../orderSummary/OrderSummary";
import { useSelector } from "react-redux";
import { isCustomerUser } from "@utils/utils";
import {
  CREDITS_CHECKOUT,
  PAYMENT_METHOD_CREDITS_AND_INVOICE,
  INVOICE_CHECKOUT,
  KLARNA_CHECKOUT,
  SUBSCRIPTION_STATUS,
} from "../../../utils/constant";
import { useCouponCodeValidation } from "@hooks/useCouponCodeValidation";

import useStepper from "../../shared/Wizard/useStepper";

const initSelect = (personInfo, paymentMethod, creditInfo) => {
  return personInfo.map((item) => ({
    ...item,
    paymentMethod: paymentMethod,
    creditInfo: creditInfo,
  }));
};

const Checkout = () => {
  const isLoggedIn = localStorage.getItem("token");

  const {
    customer: {
      status: subscriptionStatus = "",
      available_credits: availableCredits = 0,
      invoice = 0,
    } = {},
  } = useSelector((state) => state.authReducer.userInfo);

  const { couponCodeDetails, couponCodeValidationError, validateCouponCode } =
    useCouponCodeValidation();

  const [billingAccountId, setBillingAccountId] = useState(null);

  const personInfo = useSelector(
    (state) => state.personInformationReducer.personInfo,
  );
  const creditInfo = useSelector(
    (state) => state.personInformationReducer.creditsData,
  );

  const { onNextStep, onPrevStep } = useStepper();

  let selectedServices = [];
  personInfo.forEach((obj) => {
    selectedServices = [...selectedServices, ...obj.services];
  });

  const orderTotalCredit =
    subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE
      ? selectedServices
          .filter((obj) => obj.purchase_by_credits)
          .reduce((n, { price_credits }) => n + parseInt(price_credits), 0)
      : 0;

  const isCreditOnly =
    isLoggedIn &&
    subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE &&
    selectedServices.filter((obj) => obj.purchase_by_credits).length ===
      selectedServices.length &&
    availableCredits >= orderTotalCredit;

  const isPriceOnly =
    !isLoggedIn ||
    subscriptionStatus !== SUBSCRIPTION_STATUS.ACTIVE ||
    selectedServices.filter((obj) => obj.purchase_by_credits).length === 0 ||
    orderTotalCredit > availableCredits;

  const isCreditsAndPrice = !isCreditOnly && !isPriceOnly;

  const servicesPaidByPrice = isPriceOnly
    ? selectedServices
    : selectedServices.filter((obj) => !obj.purchase_by_credits);

  const orderTotalPriceWithoutVat = servicesPaidByPrice.reduce(
    (n, { price }) => n + price,
    0,
  );

  const orderTotalVatPrice = (orderTotalPriceWithoutVat * 25) / 100;

  const orderTotalPriceWithVat = orderTotalVatPrice + orderTotalPriceWithoutVat;

  const isSubscriptionActive =
    isCustomerUser() && subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE;

  const isInvoice = isCustomerUser() && invoice === 1;

  const orderDetails = couponCodeDetails || {
    promoCode: "",
    orderTotalPriceWithoutVat,
    orderTotalVatPrice,
    orderTotalPriceWithVat,
  };

  const isZeroAmount = orderDetails.orderTotalPriceWithVat === 0;

  let paymentMethod = KLARNA_CHECKOUT;
  if (isCreditOnly) {
    paymentMethod = CREDITS_CHECKOUT;
  } else if (isPriceOnly && (isSubscriptionActive || isInvoice)) {
    paymentMethod = INVOICE_CHECKOUT;
  } else if (isCreditsAndPrice) {
    paymentMethod = PAYMENT_METHOD_CREDITS_AND_INVOICE;
  } else if (isZeroAmount) {
    paymentMethod = INVOICE_CHECKOUT;
  }

  const isCreditsExceed = orderTotalCredit > availableCredits;

  const isBillingAccountInfoVisible =
    paymentMethod === INVOICE_CHECKOUT ||
    paymentMethod === PAYMENT_METHOD_CREDITS_AND_INVOICE;

  return (
    <OrderSummary
      orderSummaryProps={
        couponCodeDetails
          ? couponCodeDetails.orderSummaryProps
          : initSelect(personInfo, paymentMethod, creditInfo)
      }
      selectedServices={selectedServices}
      paymentMethod={paymentMethod}
      isInvoice={isInvoice}
      isCreditsExceed={isCreditsExceed}
      isCreditOnly={isCreditOnly}
      isPriceOnly={isPriceOnly}
      isCreditsAndPrice={isCreditsAndPrice}
      orderTotalCredit={orderTotalCredit}
      orderTotalVatPrice={orderDetails.orderTotalVatPrice}
      orderTotalPriceWithoutVat={orderDetails.orderTotalPriceWithoutVat}
      orderTotalPriceWithVat={orderDetails.orderTotalPriceWithVat}
      promoCode={orderDetails.promoCode}
      couponCodeValidationError={couponCodeValidationError}
      validateCouponCode={validateCouponCode}
      isBillingAccountInfoVisible={isBillingAccountInfoVisible}
      billingAccountId={billingAccountId}
      onBillingAccountIdChange={setBillingAccountId}
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
    />
  );
};

Checkout.propTypes = {
  onPrevStep: PropTypes.func,
  onNextStep: PropTypes.func,
};

export default Checkout;
