import React from "react";
import { useIntl } from "react-intl";
import Proptypes from "prop-types";
import Div from "@components/Div";
import { TextMediumWeight, TextLargeSemiBoldWeight } from "@components/Text";
import { convertSEK, formatCurrency, getFormattedNumber } from "@utils/utils";

const Footer = (props) => {
  const { messages } = useIntl();
  const {
    isCreditOnly = false,
    isCreditsAndPrice = false,
    orderTotalCredit = 0,
    orderTotalVatPrice = 0,
    orderTotalPriceWithoutVat = 0,
    orderTotalPriceWithVat = 0,
    orderTotalDiscount = 0,
    orderTotalDiscountPercentage = 0,
  } = props;
  return (
    <Div color={"var(--grey-dark)"}>
      <>
        {isCreditsAndPrice && orderTotalCredit > 0 && (
          <Div
            display="flex"
            pb={2}
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <TextMediumWeight>
              {`${messages.total_prepaid_price}`}
            </TextMediumWeight>
            <TextMediumWeight>{`${orderTotalCredit} ${messages.label_credit}`}</TextMediumWeight>
          </Div>
        )}
        {!isCreditOnly && (
          <>
            {!!orderTotalDiscount && (
              <Div
                display="flex"
                pb={2}
                flexWrap="wrap"
                justifyContent="space-between"
              >
                <TextMediumWeight>{`${
                  messages.coupon_discount_label
                } (${parseFloat(
                  formatCurrency(orderTotalDiscountPercentage),
                ).toLocaleString("sv-SE")}%)`}</TextMediumWeight>
                <TextMediumWeight>
                  {`- ${getFormattedNumber(
                    convertSEK(formatCurrency(orderTotalDiscount)),
                  )} kr`}
                </TextMediumWeight>
              </Div>
            )}
            <Div
              display="flex"
              pb={2}
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <TextMediumWeight>{messages.label_moms}</TextMediumWeight>
              <TextMediumWeight>
                {`${getFormattedNumber(
                  convertSEK(formatCurrency(orderTotalVatPrice)),
                )} kr`}
              </TextMediumWeight>
            </Div>
            <Div
              display="flex"
              pb={2}
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <TextMediumWeight>{messages.label_ex_moms}</TextMediumWeight>
              <TextMediumWeight>
                {`${getFormattedNumber(
                  convertSEK(formatCurrency(orderTotalPriceWithoutVat)),
                )} kr`}
              </TextMediumWeight>
            </Div>
          </>
        )}
        <Div display="flex" flexWrap="wrap" justifyContent="space-between">
          <TextLargeSemiBoldWeight
            fontSize={[
              "var(--fs-text-m)",
              "var(--fs-text-m)",
              "var(--fs-h2) !important",
              "var(--fs-h2) !important",
            ]}
          >
            {isCreditOnly ? messages.total : messages.text_total_price}:
          </TextLargeSemiBoldWeight>
          {isCreditOnly ? (
            <Div display="flex" alignItems="center">
              <TextLargeSemiBoldWeight
                fontSize={[
                  "var(--fs-text-m)",
                  "var(--fs-text-m)",
                  "var(--fs-h2) !important",
                  "var(--fs-h2) !important",
                ]}
              >
                {messages.prepaid_price_label}
              </TextLargeSemiBoldWeight>
            </Div>
          ) : (
            <TextLargeSemiBoldWeight
              fontSize={[
                "var(--fs-text-m)",
                "var(--fs-text-m)",
                "var(--fs-h2) !important",
                "var(--fs-h2) !important",
              ]}
              color={
                orderTotalDiscount ? "var(--blue-dark) !important" : undefined
              }
            >
              {`${getFormattedNumber(
                convertSEK(formatCurrency(orderTotalPriceWithVat)),
              )} kr`}
            </TextLargeSemiBoldWeight>
          )}
        </Div>
      </>
    </Div>
  );
};

Footer.propTypes = {
  isCreditOnly: Proptypes.bool,
  isCreditsAndPrice: Proptypes.bool,
  orderTotalCredit: Proptypes.number,
  orderTotalVatPrice: Proptypes.number,
  orderTotalPriceWithoutVat: Proptypes.number,
  orderTotalPriceWithVat: Proptypes.number,
  orderTotalDiscount: Proptypes.number,
  orderTotalDiscountPercentage: Proptypes.number,
};

export default Footer;
