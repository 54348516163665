import React from "react";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Section from "@components/Section";
import Container from "@components/Container";
import PropTypes from "prop-types";
import { default as Accordion } from "@components/Accordion";
import Divider from "@components/Divider";
import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  .p-accordion-header-text {
    color: var(--grey-dark);
    font-size: var(--fs-link);
    line-height: var(--lh-h3);
  }
  .p-accordion-content {
    line-height: 150%;
    padding-right: 8%;
    background-color: var(--milk);
  }
`;
const FAQSection = ({ title, faqArr }) => {
  return (
    <Section backgroundColor="var(--milk)">
      <Container>
        <Div id="faq_div" ml={[0, 0, 0, "100px"]}>
          <H2 mt={3} mb={2} pb={1}>
            {title}
          </H2>
          <Divider />
          <StyledAccordion content={faqArr} />
        </Div>
      </Container>
    </Section>
  );
};
FAQSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  faqArr: PropTypes.array,
};
export default FAQSection;
