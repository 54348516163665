import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";

import SSNSearch from "./SSNSearch";

import AdminContainer from "@layout/AdminContainer";
import UserContainer from "@layout/UserContainer";

import { SSNNUMBER } from "@utils/constant";
import { isAdminUser } from "@utils/utils";
import useStepper from "../../shared/Wizard/useStepper";

import Stepper from "@pages/shared/Wizard/Stepper";
import CheckoutComponent from "@pages/user/guest/Checkout";
import SearchComponent from "@pages/user/search/SearchResults";
import OrderConfirmationComponent from "@pages/user/guest/OrderConfirmation";

const STEPS_ITEMS = [
  {
    label: <FormattedMessage id="search_text" />,
  },
  {
    label: <FormattedMessage id="title_select_services" />,
    component: <SearchComponent />,
  },
  {
    label: <FormattedMessage id="title_checkout" />,
    component: <CheckoutComponent />,
  },
  {
    label: <FormattedMessage id="confirm_order_label" />,
    component: <OrderConfirmationComponent />,
  },
];

const Search = () => {
  const { messages } = useIntl();
  const location = useLocation();
  const history = useHistory();

  const { setupSteps, onNextStep, resetSteps } = useStepper();

  const [isWizardDialogVisible, setIsWizardDialogVisible] = useState(false);
  const [ssnNumber, setSsnNumber] = useState([]);

  useEffect(() => {
    setupSteps(STEPS_ITEMS);
  }, [setupSteps]);

  const handleOpenWizardDialog = () => {
    const query = new URLSearchParams(location.search);
    query.set(SSNNUMBER, ssnNumber);

    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    });

    setIsWizardDialogVisible(true);
    onNextStep();
  };

  const handleCloseWizardDialog = () => {
    history.replace({
      pathname: location.pathname,
    });

    setIsWizardDialogVisible(false);
    setSsnNumber([]);
    resetSteps();
  };

  const headerConfig = {
    title: messages.search_text,
  };

  const WrapperContainer = isAdminUser() ? AdminContainer : UserContainer;

  return (
    <WrapperContainer config={headerConfig}>
      {isWizardDialogVisible && <Stepper onClose={handleCloseWizardDialog} />}
      <SSNSearch
        ssnNumbers={ssnNumber}
        onSearchSSN={handleOpenWizardDialog}
        onChange={setSsnNumber}
        isAuthenticated={true}
      />
    </WrapperContainer>
  );
};

export default Search;
