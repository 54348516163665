import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Div from "@components/Div";
import { H3, H4 } from "@components/Heading";
import { Text } from "@components/Text";
import StyledIcon from "@components/Icon";
import { PrimaryButtonIcon } from "@components/Button";
import deleteWatchList from "@app/services/watchlist/deleteWatchList";
import WatchListDeleteButtons from "@pages/user/watchlist/components/WatchListDeleteButtons";
import ROUTES from "@shared/Routes";
import { ADMIN_WATCHLIST_URL } from "@utils/constant";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import { handleException } from "@utils/common";

const DeleteWatchListModal = ({
  onCloseModal,
  watchListName,
  watchListObjectsCount,
  watchListId,
  isAdmin,
  customerId,
}) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [showDeletedSuccessfully, setShowDeletedSuccessfully] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    try {
      await deleteWatchList(watchListId);
      setShowDeletedSuccessfully(true);
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  };
  const handleClosePopup = () => {
    setShowErrorModal(false);
    onCloseModal();
  };

  const handleDone = () => {
    setShowDeletedSuccessfully(false);
    onCloseModal();
    isAdmin
      ? history.push(
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${customerId}/${ADMIN_WATCHLIST_URL}`,
        )
      : history.push(ROUTES.USER_WATCH_LIST.URL);
  };

  return (
    <Div
      py={0}
      display="flex"
      alignItems="center"
      flexDirection="column"
      maxWidth="450px"
    >
      {!showDeletedSuccessfully && !showErrorModal && (
        <>
          <Div display="flex" alignItems="center" flexDirection="column" mt={2}>
            <H3>{messages.watchlist_title_delete_watchlist}</H3>
          </Div>
          <Div
            display="grid"
            mt={2}
            alignItems="center"
            justifyContent="center"
          >
            <H4 justifySelf="center">{watchListName}</H4>
            <Div justifySelf="center" display="flex" alignItems="center">
              <StyledIcon
                mr={1}
                name="account-circle"
                color="var(--turquoise)"
                fontSize="var(--fs-icon-m)"
              />
              <H4>{`${watchListObjectsCount}  ${messages.watchlist_label_persons}`}</H4>
            </Div>
            <Text mt={3}>
              {messages.watchlist_message_delete_watchlist_confirm}
            </Text>
          </Div>
          <WatchListDeleteButtons
            handleSubmit={handleSubmit}
            onCloseModal={onCloseModal}
          />
        </>
      )}
      {showDeletedSuccessfully && !showErrorModal && (
        <Div display="flex" alignItems="center" flexDirection="column" mt={2}>
          <H3>{`${messages.watchlist_label_done}!`}</H3>
          <Text mt={4}>{messages.watchlist_message_deleted_object}</Text>
          <Div
            mt={4}
            display="flex"
            flexDirection={["column-reverse", "row"]}
            justifyContent="center"
          >
            <PrimaryButtonIcon
              rounded
              semibold
              width={[1, 150]}
              mb={[3, 0]}
              onClick={handleDone}
              label={messages.watchlist_label_ok}
            />
          </Div>
        </Div>
      )}
      {showErrorModal && (
        <ErrorModal
          handleConfirmPopup={handleClosePopup}
          content={errorMessage}
        />
      )}
    </Div>
  );
};

DeleteWatchListModal.propTypes = {
  onCloseModal: PropTypes.func,
  watchListName: PropTypes.string,
  watchListObjectsCount: PropTypes.number,
  watchListId: PropTypes.string,
  isAdmin: PropTypes.bool,
  customerId: PropTypes.number,
};

export default DeleteWatchListModal;
