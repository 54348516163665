import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import {
  Text,
  TextMediumWeight,
  TextUpperCaseSemiBold,
} from "@components/Text";
import Checkbox from "@components/Checkbox";
import Proptypes from "prop-types";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Config from "../../../config";
import { REPORTS } from "@utils/constant";

export const PrintReportModal = ({
  handleConfirmPopup,
  reportId = "",
  assignmentUrl = "",
}) => {
  const { messages } = useIntl();
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setChecked(e.checked);
  };
  const pdfDownloadref = useRef(null);

  const handleDownloadReport = async () => {
    await pdfDownloadref.current.click();
    handleConfirmPopup();
  };

  return (
    <>
      <Div mt={3} pt={1}>
        <Div display="flex" alignItems="center" flexDirection="column">
          <TextUpperCaseSemiBold mb={2}>
            {messages.label_download_report}
          </TextUpperCaseSemiBold>
          <Text my={3} textAlign="center">
            {messages.download_pdf_content}
          </Text>
        </Div>
        <Div display="flex" mt={2} mb={4} alignItems="flex-start">
          <Div>
            <Checkbox
              fontSize="var(--fs-text)"
              inputId="policy_check"
              checked={checked}
              onChange={handleChange}
            />
          </Div>
          <TextMediumWeight
            htmlFor="policy_check"
            ml={10}
            color="var(--grey-dark)"
            fontWeight="var(--light-weight)"
          >
            {messages.download_report_check}
          </TextMediumWeight>
        </Div>
        <Div
          px={3}
          max-width="250px"
          display={["block", "flex"]}
          alignItems="center"
          justifyContent="space-around"
        >
          <PrimaryButtonOutlined
            mr={2}
            mb={[2, 0]}
            minWidth="85px"
            minHeight="40px"
            width={[1, "auto"]}
            onClick={handleConfirmPopup}
          >
            {messages.label_cancel}
          </PrimaryButtonOutlined>
          <PrimaryButton
            py={2}
            px={4}
            width={[1, "200px"]}
            label={messages.label_download}
            onClick={handleDownloadReport}
            disabled={!checked}
          />
        </Div>
      </Div>
      <a
        href={
          assignmentUrl || `${Config.BASE_URL}${REPORTS}/${reportId}/report-pdf`
        }
        ref={pdfDownloadref}
        download
      />
    </>
  );
};

PrintReportModal.propTypes = {
  handleConfirmPopup: Proptypes.func,
  reportId: Proptypes.string,
  assignmentUrl: Proptypes.string,
};

export default PrintReportModal;
