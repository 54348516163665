import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { RoutesList } from "@shared/RoutesList";

import PrivateRoute from "@shared/PrivateRoute";
import ProgressSpinner from "@components/ProgressSpinner";
import AdminLayout from "./layout/AdminLayout";
import CustomerLayout from "./layout/CustomerLayout";
import CustomerGuestLayout from "./layout/GuestLayout";

const renderSelf = (components) =>
  components.map((Component, key) => <Component key={key} />);

const AppRoute = () => (
  <Router>
    <Switch>
      {RoutesList.map(
        (
          {
            url: route,
            component: components,
            title: title,
            isAdmin = false,
            authenticatedUser = false,
          },
          index,
        ) => (
          <PrivateRoute
            location={location}
            exact
            path={route}
            component={() => (
              <>
                <Suspense fallback={<ProgressSpinner />}>
                  {authenticatedUser && isAdmin && (
                    <AdminLayout>{renderSelf(components)}</AdminLayout>
                  )}
                  {authenticatedUser && !isAdmin && (
                    <CustomerLayout>{renderSelf(components)}</CustomerLayout>
                  )}

                  {!authenticatedUser && (
                    <CustomerGuestLayout title={title}>
                      {renderSelf(components)}
                    </CustomerGuestLayout>
                  )}
                </Suspense>
              </>
            )}
            key={index}
            title={title}
            isAdmin={isAdmin}
          />
        ),
      )}
    </Switch>
  </Router>
);
export default AppRoute;
