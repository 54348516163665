import Axios from "@app/api/axios";
import { PRODUCTS } from "@utils/constant";

const fetchProductById = (id, state = "active") => {
  let params = {
    customer_id: id,
    state: state,
  };
  return Axios.get(PRODUCTS, { params: params });
};

export const fetchAllProducts = ({
  type = "",
  customer_id = "",
  state = "active",
}) => {
  const params = new URLSearchParams();
  params.append("where[type]", type);
  params.append("whereHas[customerProducts][where][customer_id]", customer_id);
  params.append("where[state]", state);
  return Axios.get(`products?${params.toString()}`);
};

export default fetchProductById;
