import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Formik, Form as FormikForm } from "formik";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import {
  InputDate,
  InputDropdown,
  InputAutocomplete,
  InputRadioGroup,
} from "@components/Input";
import InputText from "@components/InputText";

import getAllProducts from "@app/services/products/getAllProducts";

import { INPUT } from "@utils/constant";
import { COUPON_STATUS, DISCOUNT_TYPE, APPLICATION_TYPE } from "@utils/enum";

InputText.defaultProps = {
  curved: true,
  isLowercaseLabel: true,
  labelAlignment: "left",
};

const { TEXT, NUMBER } = INPUT.TYPE;

const DISCOUNT_TYPE_RADIO_OPTIONS = [
  {
    id: DISCOUNT_TYPE.PERCENTAGE,
    name: "discount_type",
    value: DISCOUNT_TYPE.PERCENTAGE,
    inputId: `discount_type.${DISCOUNT_TYPE.PERCENTAGE}`,
    label: <FormattedMessage id="label_percent" />,
  },
  {
    id: DISCOUNT_TYPE.FIXED_AMOUNT,
    name: "discount_type",
    value: DISCOUNT_TYPE.FIXED_AMOUNT,
    inputId: `discount_type.${DISCOUNT_TYPE.FIXED_AMOUNT}`,
    label: <FormattedMessage id="label_fixed_amount" />,
  },
];

const APPLICATION_TYPE_RADIO_OPTIONS = [
  {
    id: APPLICATION_TYPE.ENTIRE_ORDER,
    name: "application_type",
    value: APPLICATION_TYPE.ENTIRE_ORDER,
    inputId: `application_type.${APPLICATION_TYPE.ENTIRE_ORDER}`,
    label: <FormattedMessage id="label_entire_order" />,
  },
  {
    id: APPLICATION_TYPE.SINGLE_ORDER_ITEM,
    name: "application_type",
    value: APPLICATION_TYPE.SINGLE_ORDER_ITEM,
    inputId: `application_type.${APPLICATION_TYPE.SINGLE_ORDER_ITEM}`,
    label: <FormattedMessage id="label_single_item" />,
  },
];

const COUPON_STATUSES = [
  {
    name: <FormattedMessage id="active" />,
    value: COUPON_STATUS.ACTIVE,
  },
  {
    name: <FormattedMessage id="label_inactive" />,
    value: COUPON_STATUS.INACTIVE,
  },
];

const Form = ({
  isUpdateForm,
  initialData,
  validationSchema,
  onSubmit,
  onCancel,
}) => {
  const { messages } = useIntl();

  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data: { data: allProducts = [] } = {} } = await getAllProducts(
          `?orderBy[listing_order]=asc`,
        );

        setProducts(allProducts);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      }
    };

    fetchProducts();
  }, [messages.exception_error_message]);

  const submitButtonLabel = isUpdateForm
    ? messages.label_save
    : messages.label_create;

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const handleCloseErrorModal = () => {
    setErrorMessage("");
  };

  const handleOnComplete = (event) => {
    const { query = "" } = event;

    if (!query.trim().length) {
      return;
    }

    setSearchQuery(query);
  };

  const filteredProducts = searchQuery
    ? products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : products;

  return (
    <Div width={1}>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onConfirm={handleCloseErrorModal}
          onHide={handleCloseErrorModal}
        />
      )}
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialData}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const { dirty, isValid, values, handleChange, setFieldValue } =
            formik;

          const handleRadioButtonChange = (event) => {
            const { value, target: { name = "" } = {} } = event;

            setFieldValue(name, value);
          };

          return (
            <FormikForm>
              <Div display="flex" flexDirection="column">
                <Div flex="1 1 40%">
                  <InputText
                    width={1}
                    type={TEXT}
                    formikProps={formik}
                    name={"code"}
                    label={messages.label_name}
                    placeholder={`${messages.label_name}...`}
                    value={values?.code}
                  />
                </Div>

                <InputRadioGroup
                  flex="1 1 40%"
                  formikProps={formik}
                  onChange={handleRadioButtonChange}
                  name={"discount_type"}
                  label={`${messages.label_discount_type}:`}
                  value={values?.discount_type}
                  options={DISCOUNT_TYPE_RADIO_OPTIONS}
                />

                <Div flex="1 1 40%">
                  <InputText
                    width={1}
                    type={NUMBER}
                    formikProps={formik}
                    name={"discount_value"}
                    label={messages.label_size_on_discount}
                    placeholder={`${messages.placeholder_size}...`}
                    value={values?.discount_value}
                  />
                </Div>

                <Div display="flex" gridGap={4}>
                  <InputDate
                    flex="1 1 40%"
                    formikProps={formik}
                    name={"valid_from"}
                    label={messages.label_valid_from}
                    placeholder={new Date().toLocaleDateString()}
                    value={values?.valid_from}
                    onChange={handleChange}
                  />

                  <InputDate
                    flex="1 1 40%"
                    formikProps={formik}
                    name={"valid_to"}
                    label={messages.label_expire_date}
                    placeholder={new Date().toLocaleDateString()}
                    value={values?.valid_to}
                    onChange={handleChange}
                  />
                </Div>

                <Div flex="1 1 40%">
                  <InputText
                    width={1}
                    type={NUMBER}
                    formikProps={formik}
                    name={"max_allowed_usages"}
                    label={messages.label_number_of_uses}
                    placeholder={messages.placeholder_number}
                    value={values?.max_allowed_usages}
                  />
                </Div>

                <InputRadioGroup
                  flex="1 1 40%"
                  formikProps={formik}
                  onChange={handleRadioButtonChange}
                  name={"application_type"}
                  label={`${messages.type_label}:`}
                  value={values?.application_type}
                  options={APPLICATION_TYPE_RADIO_OPTIONS}
                />

                <InputAutocomplete
                  flex="1 1 40%"
                  formikProps={formik}
                  onChange={handleChange}
                  name={"products"}
                  label={messages.label_products_for_discount}
                  placeholder={`${messages.placeholder_choose}...`}
                  value={values?.products}
                  options={filteredProducts}
                  optionLabel={"name"}
                  disabled={
                    values?.application_type === APPLICATION_TYPE.ENTIRE_ORDER
                  }
                  onComplete={handleOnComplete}
                />

                {isUpdateForm && (
                  <InputDropdown
                    flex="1 1 40%"
                    formikProps={formik}
                    onChange={handleChange}
                    name={"status"}
                    label={messages.label_status}
                    placeholder={messages.label_status}
                    value={values?.status}
                    options={COUPON_STATUSES}
                    optionLabel={"name"}
                  />
                )}

                <Div
                  mt={4}
                  display="flex"
                  flexDirection={["column", "column", "row", "row"]}
                  gridGap={[3, 3, 4, 4]}
                  justifyContent={isUpdateForm ? "center" : "flex-start"}
                >
                  <PrimaryButton
                    type="submit"
                    label={submitButtonLabel}
                    minWidth="150px"
                    disabled={!isValid || (isUpdateForm && !dirty)}
                  />
                  <PrimaryButtonOutlined
                    type="button"
                    onClick={onCancel}
                    label={messages.label_cancel}
                    minWidth="150px"
                  />
                </Div>
              </Div>
            </FormikForm>
          );
        }}
      </Formik>
    </Div>
  );
};

Form.propTypes = {
  isUpdateForm: PropTypes.bool,
  initialData: PropTypes.object,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default Form;
