import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import { H2 } from "@components/Heading";
import { ROUTES, ADMIN_CUSTOMER_DETAILS, WRITE, ADMIN } from "@utils/constant";
import Tabs from "@components/Tabs";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";
import Routes from "@shared/Routes";
import Link, { LinkArrow } from "@components/Link";
import { TextSemiBoldWeight } from "@components/Text";
import Icon from "@components/Icon";
import Dialog from "@components/Dialog";
import ProgressSpinner from "@components/ProgressSpinner";
import getUserDetails from "@app/services/auth/getUserDetails";
import getWatchList from "@app/services/watchlist/getWatchList";
import WatchlistDetailTab from "@pages/user/watchlist/components/WatchlistDetailTab";
import EditWatchListModal from "@pages/user/watchlist/components/EditWatchListModal";
import DeleteWatchListModal from "@pages/user/watchlist/components/DeleteWatchListModal";
import fetchProductById from "@app/services/services/fetchProductById";
import WatchlistHistoryTab from "@pages/user/watchlist/components/WatchlistHistoryTab";
import WatchlistRecurringTab from "@pages/user/watchlist/components/WatchlistRecurringTab";
import customerDetailsService from "@app/services/customers/customerDetailsService";
import getWatchLists from "@app/services/watchlist/getWatchLists";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import { handleException } from "@utils/common";

const StyledTabs = styled(Tabs)`
  .p-tabview-nav {
    justify-content: start;
  }
  .p-tabview-nav li {
    min-width: 10%;
  }
  @media (max-width: 767px) {
    .p-tabview-nav {
      justify-content: center;
    }
  }
`;

const WatchlistDetail = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const [watchList, setWatchList] = useState({});
  const { id = "", customer_id = "" } = useParams();
  const DETAIL_TAB_INDEX = 0;
  const [activeIndex, setActiveIndex] = useState(DETAIL_TAB_INDEX);
  const [showEditWatchListPopup, setShowEditWatchListPopup] = useState(false);
  const [showDeleteWatchListPopup, setShowDeleteWatchListPopup] =
    useState(false);
  const [customerService, setCustomerService] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetail, setCustomerDetail] = useState({});
  const [customerWatchLists, setCustomerWatchLists] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const customerEmails = useMemo(
    () => (customerDetail?.email ? [customerDetail.email] : []),
    [customerDetail?.email],
  );

  const notificationEmails = watchList?.notification_emails || customerEmails;

  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const { permissions = {} } = useSelector(
    (state) => state.authReducer.userInfo,
  );

  const tabTitles = useMemo(
    () => [
      messages.watchlist_tab_label_details,
      messages.watchlist_tab_title_recurring,
      messages.watchlist_tab_title_history,
    ],
    [messages],
  );

  const breadCrumbItems =
    userInfo.type !== ADMIN
      ? [
          {
            label: messages.watchlist_back_to_watchList,
            url: Routes.USER_WATCH_LIST.URL,
          },
        ]
      : [
          {
            label: messages.watchlist_label_customers,
            url: ROUTES.ADMIN_CUSTOMERS.URL,
          },
          {
            label: customerDetail?.name,
            url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_CUSTOMER_DETAILS}`,
          },
          { label: messages.watchlist_label_watchlist_details },
        ];

  useEffect(() => {
    setIsLoading(true);

    try {
      const loadWatchListData = (id) => {
        return getWatchList(id)
          .then((response) => {
            let { data: { data: watchList = {} } = {} } = response;
            setWatchList(watchList);
            loadCustomerWatchLists(watchList?.customer?.id).catch((error) => {
              // Handle the error
              throw new Error(error);
            });
          })
          .catch((error) => {
            handleException(
              setShowErrorModal,
              setErrorMessage,
              messages.exception_error_message,
              error,
            );
          });
      };
      const loadCustomerdetails = async () => {
        try {
          if (userInfo.type !== ADMIN) {
            const response = await getUserDetails();
            const { data: { data: userData = {} } = {} } = response;
            setCustomerDetail(userData?.customer);
            return userData?.customer?.product_id;
          } else {
            const response = await customerDetailsService(customer_id);
            const { data: { data = {} } = {} } = response || {};
            setCustomerDetail(data);
            return data?.product_id;
          }
        } catch (error) {
          // Handle the error
          throw new Error(error);
        }
      };
      const loadServices = (productId) => {
        return fetchProductById()
          .then((response) => {
            const { data: { data = [] } = {} } = response;
            setCustomerService(
              data.find((service) => service.id === productId),
            );
          })
          .catch((error) => {
            handleException(
              setShowErrorModal,
              setErrorMessage,
              messages.exception_error_message,
              error,
            );
          });
      };
      const getCustomerAndServicesDetail = async () => {
        try {
          const productId = await loadCustomerdetails();
          if (productId) {
            await loadServices(parseInt(productId));
          }
          setIsLoading(false);
        } catch (error) {
          // Handle the error
          throw new Error(error);
        }
      };

      const loadCustomerWatchLists = async (customerId) => {
        try {
          const response = await getWatchLists({
            customer_id: customerId,
          });
          const { data: { data: watchLists = [] } = [] } = response;
          setCustomerWatchLists(watchLists);
          setIsLoading(false);
        } catch (error) {
          // Handle the error
          throw new Error(error);
        }
      };
      loadWatchListData(id);
      getCustomerAndServicesDetail();
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  }, [id, userInfo.type, customer_id, messages]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");
    const tabIndex = tabTitles.indexOf(tabParam);

    if (tabIndex !== -1) {
      setActiveIndex(tabIndex);
    }
  }, [location.search, tabTitles]);

  const handleRedirectToUpgradePlan = () => {
    history.push(`${ROUTES.UPGRADE_SUBSCRIPTION.URL}`);
  };

  const handleClosePopup = () => {
    setShowEditWatchListPopup(false);
    setShowDeleteWatchListPopup(false);
    setShowErrorModal(false);
  };

  const handleEditWatchListName = () => {
    setShowEditWatchListPopup(true);
  };

  const handleDeleteWatchList = () => {
    setShowEditWatchListPopup(false);
    setShowDeleteWatchListPopup(true);
  };

  const handleWatchListUpdate = (updatedWatchList) => {
    setWatchList(updatedWatchList);
  };

  const handleBacktoWatchList = () => {
    history.push(Routes.USER_WATCH_LIST.URL);
  };

  const tabContent = [
    {
      title: messages.watchlist_tab_label_details,
      content: (
        <WatchlistDetailTab
          id={id}
          watchList={watchList}
          handleRedirectToUpgradePlan={handleRedirectToUpgradePlan}
          customerAvailableCredits={customerDetail?.available_credits}
          customerId={customerDetail?.id}
          handleWatchListUpdate={handleWatchListUpdate}
          activeSubscription={customerDetail?.status}
          isWatchlistWritable={
            permissions && permissions?.["watch-lists"] !== WRITE
          }
          customerWatchLists={customerWatchLists}
        />
      ),
    },
    {
      title: messages.watchlist_tab_title_recurring,
      content: (
        <WatchlistRecurringTab
          watchList={watchList}
          plan={customerService.name}
          notificationEmails={notificationEmails}
          handleWatchListUpdate={handleWatchListUpdate}
          isWatchlistWritable={
            permissions && permissions?.["watch-lists"] !== WRITE
          }
          customerPlanCredits={customerDetail?.total_credits}
        />
      ),
    },
    {
      title: messages.watchlist_tab_title_history,
      content: <WatchlistHistoryTab watchListId={id} />,
    },
  ];

  const onTabChange = (e) => {
    setActiveIndex(e.index);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("tab", tabTitles[e.index]);
    history.push({ search: queryParams.toString() });
  };

  return (
    <>
      <ScrollToTop>
        {isLoading ? (
          <ProgressSpinner />
        ) : (
          <Container m={"0 !important"}>
            <Div display="flex" alignItems="center" mt={1}>
              {userInfo.type !== ADMIN && (
                <Link mr={1} handleClick={handleBacktoWatchList}>
                  <Icon name="headerarrowright1" />
                </Link>
              )}
              <Breadcrumb pl={0} items={breadCrumbItems} />
            </Div>
            <Div
              display="flex"
              justifyContent="space-between"
              mt={2}
              mb={3}
              pb={1}
            >
              <H2>{watchList?.name}</H2>
              {permissions && permissions?.["watch-lists"] === WRITE && (
                <Link
                  label={messages.watchlist_label_edit}
                  direction="right"
                  iconPos="left"
                  onClick={handleEditWatchListName}
                >
                  <Icon name="pen" mr={1} />
                </Link>
              )}
            </Div>
            {userInfo.type !== ADMIN && (
              <>
                <TextSemiBoldWeight>
                  {`${messages.watchlist_label_available_credits_now} ${customerDetail?.available_credits}`}
                </TextSemiBoldWeight>
                <Div mt={3}>
                  <LinkArrow
                    label={messages.watchlist_label_upgrade_plan}
                    direction=""
                    variant="secondary"
                    mt={2}
                    onClick={handleRedirectToUpgradePlan}
                  >
                    <Icon ml={1} name="headerarrowright" />
                  </LinkArrow>
                </Div>
              </>
            )}
            <Div mt={3}>
              <StyledTabs
                content={tabContent}
                onTabChange={onTabChange}
                activeIndex={activeIndex}
              />
            </Div>
          </Container>
        )}
      </ScrollToTop>
      {(showEditWatchListPopup ||
        showDeleteWatchListPopup ||
        showErrorModal) && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleClosePopup}
          width={["90%", "90%", "90%", 445]}
        >
          {showEditWatchListPopup && (
            <EditWatchListModal
              onCloseModal={handleClosePopup}
              watchList={watchList}
              handleDeleteWatchList={handleDeleteWatchList}
            />
          )}
          {showDeleteWatchListPopup && (
            <DeleteWatchListModal
              onCloseModal={handleClosePopup}
              watchListName={watchList.name}
              watchListObjectsCount={watchList.watch_list_objects?.length}
              watchListId={watchList.id}
              isAdmin={userInfo.type === ADMIN}
              customerId={customer_id}
            />
          )}
          {showErrorModal && (
            <ErrorModal
              handleConfirmPopup={handleClosePopup}
              content={errorMessage}
            />
          )}
        </Dialog>
      )}
    </>
  );
};

export default WatchlistDetail;
