import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import AutoComplete from "@components/AutoComplete";
import Card from "@components/Card";
import Div from "@components/Div";
import { Error } from "@components/Heading";
import Icon from "@components/Icon";
import { Text, TextMediumWeight, TextSemiBoldWeight } from "@components/Text";

import { getBillingAccountsListService } from "@app/services/billingAccounts/getBillingAccountsListService";

const BillingInformation = ({ onSelectBillingAccount }) => {
  const { messages } = useIntl();

  const [billingAccounts, setBillingAccounts] = useState([]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");

  const autocompleteRef = useRef(null);

  useEffect(() => {
    const fetchBillingAccounts = async () => {
      try {
        const { data: { data: billingAccountsList } = {} } =
          await getBillingAccountsListService();

        setBillingAccounts(billingAccountsList);

        if (billingAccountsList.length === 1) {
          const [firstAccount] = billingAccountsList;

          setSelectedBillingAccount(firstAccount);
          onSelectBillingAccount(firstAccount.id);
        }
      } catch (error) {
        setError(messages.exception_error_message);
      }
    };

    fetchBillingAccounts();
  }, [onSelectBillingAccount, messages.exception_error_message]);

  const isSingleBillingAccount = billingAccounts.length === 1;

  const handleSearch = (event) => {
    const { query } = event;

    if (!query.trim().length) {
      return;
    }

    setSearchQuery(query.trim());
  };

  const handleChange = (event) => {
    const { value } = event;

    if (typeof value === "string") {
      setSearchValue(value);
    } else {
      setSearchValue(value.name);
      setSelectedBillingAccount(value);
      onSelectBillingAccount(value.id);
    }
  };

  const filteredBillingAccounts = searchQuery
    ? billingAccounts.filter(
        ({ name, your_reference, invoice_remark, address_1 }) => {
          const itemValuesString = Object.values({
            name,
            your_reference,
            invoice_remark,
            address_1,
          })
            .filter((value) => value != null)
            .reduce(
              (accumulator, value) => accumulator + String(value) + "|",
              "",
            );

          return itemValuesString
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        },
      )
    : billingAccounts;

  const renderItemTemplate = ({
    name,
    your_reference,
    invoice_remark,
    address_1,
  }) => {
    const descriptionOptions = [
      {
        label: messages.label_your_reference_person,
        value: your_reference,
      },
      {
        label: messages.heading_invoice_reference,
        value: invoice_remark,
      },
      {
        label: messages.label_address,
        value: address_1,
      },
    ];

    const formattedDescription = descriptionOptions
      .filter((option) => option.value != null)
      .map((option) => `${option.label}: ${option.value}`)
      .join(" | ");

    return (
      <Div display="flex" flexDirection="column" gridGap={1}>
        <TextMediumWeight>{name}</TextMediumWeight>
        <Text>{formattedDescription}</Text>
      </Div>
    );
  };

  const renderDropdownIcon = () => <Icon name="chevrondown" />;

  const handleShowDropdownMenu = (event) => {
    autocompleteRef.current?.search(event, searchValue || "|", "dropdown");
    autocompleteRef.current?.show();
  };

  return (
    <Card mt={4} mb={3} pb={3} borderRadius="30px">
      <Div
        px={4}
        py={3}
        backgroundColor="var(--blue-lightest)"
        borderTopLeftRadius="30px"
        borderTopRightRadius="30px"
      >
        <TextSemiBoldWeight>
          {messages.label_billing_information}
        </TextSemiBoldWeight>
      </Div>

      {!isSingleBillingAccount && (
        <Div
          display="flex"
          flex={1}
          px={4}
          mt={3}
          mb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <TextMediumWeight>
            {`${messages.label_choose_billing_account}: `}
          </TextMediumWeight>
          <AutoComplete
            ref={autocompleteRef}
            width="30%"
            ml={4}
            curved
            dropdown
            field="name"
            value={searchValue}
            placeholder={messages.placeholder_choose}
            delay={200}
            suggestions={filteredBillingAccounts}
            completeMethod={handleSearch}
            onChange={handleChange}
            itemTemplate={renderItemTemplate}
            dropdownIcon={renderDropdownIcon}
            onDropdownClick={handleShowDropdownMenu}
          />
        </Div>
      )}

      {selectedBillingAccount && (
        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
          gridGap={3}
          px={4}
          mt={3}
          mb={2}
        >
          <Div flex="1 1 40%">
            <TextSemiBoldWeight>
              {selectedBillingAccount?.name}
            </TextSemiBoldWeight>
          </Div>
          <Div flex="1 1 40%" display={["none", "none", "block", "block"]} />
          <Div flex="1 1 40%">
            <TextSemiBoldWeight>
              {`${messages.header_reference_person}: `}
              <Text>{selectedBillingAccount?.your_reference}</Text>
            </TextSemiBoldWeight>
          </Div>
          <Div flex="1 1 40%">
            <TextSemiBoldWeight>
              {`${messages.label_billing_email}: `}
              <Text>{selectedBillingAccount?.email_invoice}</Text>
            </TextSemiBoldWeight>
          </Div>
          <Div flex="1 1 40%">
            <TextSemiBoldWeight>
              {`${messages.heading_invoice_reference}: `}
              <Text>{selectedBillingAccount?.invoice_remark}</Text>
            </TextSemiBoldWeight>
          </Div>
          <Div flex="1 1 40%">
            <TextSemiBoldWeight>
              {`${messages.label_address}: `}
              <Text>{selectedBillingAccount?.address_1}</Text>
            </TextSemiBoldWeight>
          </Div>
          <Div flex="1 1 40%" display={["none", "none", "block", "block"]} />
        </Div>
      )}

      {error && (
        <Div display="flex" justifyContent="center">
          <Error>{error || "error"}</Error>
        </Div>
      )}
    </Card>
  );
};

BillingInformation.propTypes = {
  onSelectBillingAccount: PropTypes.func,
};

export default BillingInformation;
