import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Section from "@components/Section";
import Container from "@components/Container";
import { H3, H1 } from "@components/Heading";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { TextMediumWeight } from "@components/Text";
import { headingProps } from "./config";
import { PrimaryButtonOutlined } from "@components/Button";
import Dialog from "@components/Dialog";
import PDFPreview from "@components/PDFPreview";
import PrintReportModal from "./PrintReportModal";

const Button = styled(PrimaryButtonOutlined)`
  white-space: nowrap;
  padding-right: 32px;
  padding-left: 32px;
`;

const Heading = ({ reportId = "", assignment = {} }) => {
  const { messages } = useIntl();
  const { title, subTitle } = headingProps;
  const [orderReport, setOrderReport] = useState(false);

  function handlePrint() {
    setOrderReport(!orderReport);
  }

  return (
    <>
      {orderReport && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          width={[1, 445, 445, 445]}
          onHide={handlePrint}
          m={[3, 3, 3, "auto"]}
        >
          <PrintReportModal
            handleConfirmPopup={handlePrint}
            reportId={reportId}
            assignmentUrl={assignment?.url}
          />
        </Dialog>
      )}
      {assignment?.url ? (
        <Container alignItems="center" display="flex" flexDirection="column">
          <Div
            width={1}
            display="flex"
            justifyContent="space-between"
            alignItems={["flex-start", "center"]}
            flexDirection={["column", "row"]}
            mt={32}
          >
            <H1>{assignment.productName}</H1>
            <PrimaryButtonOutlined
              minWidth="217px"
              onClick={handlePrint}
              px={3}
              py={1}
              height={40}
              mt={[1, 0]}
            >
              <Icon alt="icon" name="download-assignment" mr={2} />
              {messages.label_download_as_pdf}
            </PrimaryButtonOutlined>
          </Div>
          <PDFPreview previewFile={{ url: assignment?.url }} />
        </Container>
      ) : (
        <>
          <Div
            display={["flex", "flex", "flex", "none"]}
            width={1}
            mt={32}
            flexDirection="column"
          >
            <H3 color="var(--blue-dark) !important" mx={[3, 3, 3, 64]} mb={3}>
              {messages[title]}
            </H3>
            <H1 mx={[3, 3, 3, 64]} mb={[4, 0, 0, 0]}>
              {messages[subTitle]}
            </H1>
            <Div ml={3} mb={3}>
              <Button width="auto" onClick={handlePrint}>
                <Icon alt="icon" name="headerarrowright" mr={2} />
                {messages.label_download_as_pdf}
              </Button>
            </Div>
          </Div>
          <Section
            p={"16px !important"}
            bg={["var(--yellow-light)", "white", "white", "white"]}
          >
            <Container bg={"white !important"} py={"0 !important"}>
              <Div
                display={["none", "none", "none", "flex"]}
                width={1}
                alignItems="center"
              >
                <Div width={1} display="flex" flexDirection="column">
                  <H3 color="var(--blue-dark) !important" my={3}>
                    {messages[title]}
                  </H3>
                  <H1 mb={[4, 4, 4, 4]}>{messages[subTitle]}</H1>
                </Div>
                <Button width="auto" onClick={handlePrint}>
                  <Icon alt="icon" name="download-assignment" mr={2} />
                  {messages.label_download_as_pdf}
                </Button>
              </Div>
              <Div
                px={[0, 0, 0, 3]}
                py={[0, 0, 0, 3]}
                bg={"var(--yellow-light)"}
                display="block"
              >
                <TextMediumWeight>
                  {messages.desc_report_summary}
                </TextMediumWeight>
              </Div>
            </Container>
          </Section>
        </>
      )}
    </>
  );
};

Heading.propTypes = {
  reportId: PropTypes.string,
  assignment: PropTypes.object,
};

export default Heading;
