import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import Link, { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import { PrimaryButtonIcon } from "@components/Button";
import {
  ColouredSemiBoldText,
  Text,
  ColouredMediumWeight,
} from "@components/Text";
import Config from "@src/config";
import { ValidateSwedishSSN, getPinWithFullYear } from "@utils/utils";
import { trackEventFocus } from "@src/analyticsFunctions";
import ROUTES from "../../../shared/Routes";
import InputTextArea from "@components/InputTextArea";
import RoundedPrimaryButton from "@components/RoundedPrimaryButton";
import RoundedInputText from "@components/RoundedInputText";

const SSNSearch = (props) => {
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);

  const { ssnNumbers = [""] } = props;
  const [isMultiple, setIsMultiple] = useState(ssnNumbers.length > 1);
  const [showMultipleSsnError, setShowMultipleSsnError] = useState([]);

  const handleOnSSNNumberChange = (e) => {
    setShowMultipleSsnError([]);
    props.onChange(e.target.value.split("\n"));
  };

  const handleMultipleSsnSearch = () => {
    setIsMultiple(true);
  };

  if (ssnNumbers && ssnNumbers.length >= 1) {
    trackEventFocus();
  }

  const handleOnSubmit = () => {
    if (ssnNumbers.length === 0) {
      return;
    }
    const formattedSSNNumbers = ssnNumbers.map((ssn) =>
      ssn.length < 12 ? getPinWithFullYear(ssn) : ssn,
    );
    const invalidObjects = formattedSSNNumbers.filter(
      (obj) => !ValidateSwedishSSN(obj),
    );
    const validObjects = formattedSSNNumbers.filter((obj) =>
      ValidateSwedishSSN(obj),
    );
    setShowMultipleSsnError(invalidObjects);
    if (invalidObjects.length === 0) {
      return props.onSearchSSN(validObjects);
    }
  };

  return (
    <Div>
      <Div
        display="flex"
        mt={4}
        flexDirection={["column", "column", "column", "row"]}
        flexWrap={["wrap"]}
      >
        {!isMultiple && (
          <Div
            display={["none", "none", "none", "flex"]}
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            position="relative"
            flex={1}
          >
            <RoundedInputText
              width={1}
              height="36px"
              placeholder={messages.placeholder_search}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
            />
            <PrimaryButtonIcon
              position="absolute"
              right={0}
              label={messages.search_text}
              icon="icon-search"
              onClick={handleOnSubmit.bind(this, false)}
              height="36px"
              py="0 !important"
            />
          </Div>
        )}
        {isMultiple && props.isAuthenticated && (
          <Div width={[343, 343, 540, 540]}>
            <InputTextArea
              width={"100%"}
              rows={4}
              cols={24}
              placeholder={messages.placeholder_search_multiple}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
              borderRadius={20}
              lineHeight={2}
            />
            <Div
              display="flex"
              justifyContent={["center", "center", "flex-end", "flex-end"]}
              pt={20}
            >
              <PrimaryButtonIcon
                width={[342, 342, "auto", "auto"]}
                label={messages.search_text}
                icon="icon-search"
                onClick={handleOnSubmit.bind(this, true)}
              />
            </Div>
          </Div>
        )}
        <Div>
          {isMultiple && (
            <Div
              backgroundColor={"var(--blue-lightest)"}
              flex={1}
              ml={4}
              mb={3}
              height={"auto"}
              display={["none", "none", "inline-flex", "inline-flex"]}
              borderRadius="5px"
            >
              <Text display="inline-flex" alignItems="center" py={2} mr={3}>
                <Div display="flex" py={1} justifyContent="center">
                  <Icon
                    name="info"
                    rounded={true}
                    ml={3}
                    mr={2}
                    mb={2}
                    height="18px"
                    width="18px"
                  />
                </Div>
                <Div display="flex" flexDirection="column">
                  <Text mt={1}>{messages.up_to_10_ids}</Text>
                  <Text my={1}>{messages.seperate_id_by_comma}</Text>
                </Div>
              </Text>
            </Div>
          )}
          <Div
            backgroundColor={"var(--blue-lightest)"}
            flex={1}
            ml={4}
            height={"auto"}
            my={[4, 4, 0, 0]}
            display={["none", "none", "flex", "flex"]}
            borderRadius="5px"
          >
            <Text display="flex" alignItems="center" py={2}>
              <Div display="flex" justifyContent="center">
                <Icon
                  name="info"
                  rounded={true}
                  ml={3}
                  mr={2}
                  height="18px"
                  width="18px"
                />
              </Div>
              <Text>{messages.security_number_details}</Text>
              <Div display="flex" alignItems="center">
                <LinkArrow
                  ml={2}
                  target="_blank"
                  direction="right"
                  href={`${Config.WP_URL}${ROUTES.INTERNATIONELLBAKGRUNDSANALYS.URL}`}
                  label={messages.search_label_click_here}
                  variant="secondary"
                  mr={3}
                >
                  <Icon ml={1} name="headerarrowright" />
                </LinkArrow>
              </Div>
            </Text>
          </Div>
        </Div>
      </Div>
      {!isMultiple && (
        <>
          <Div
            display={["none", "flex", "flex", "none"]}
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            position="relative"
            mt={[0, 0, 4, 4]}
          >
            <RoundedInputText
              width={1}
              placeholder={messages.placeholder_search_mobile}
              value={ssnNumbers.join("\n")}
              onChange={handleOnSSNNumberChange}
            />
            <PrimaryButtonIcon
              display={["none", "none", "flex", "flex"]}
              position="absolute"
              right={0}
              label={messages.search_text}
              icon="icon-search"
              onClick={handleOnSubmit.bind(this, false)}
              py="6px !important"
            />
          </Div>
          <Div
            display={["flex", "none"]}
            width={[1]}
            position="relative"
            mt={[0, 0, 4, 4]}
          >
            <RoundedInputText
              width={1}
              height="40px"
              placeholder={messages.placeholder_search_mobile}
              value={ssnNumbers.join("/n")}
              onChange={handleOnSSNNumberChange}
            />
            <RoundedPrimaryButton
              display={["none", "none", "flex", "flex"]}
              position="absolute"
              mt={4}
              icon="icon-search"
              onClick={handleOnSubmit.bind(this, false)}
            />
          </Div>
        </>
      )}
      {!isMultiple && (
        <Div
          display={["flex", "none", "none", "none"]}
          justifyContent={"center"}
          pt={20}
        >
          <PrimaryButtonIcon
            width={"100%"}
            label={messages.search_text}
            icon="icon-search"
            onClick={handleOnSubmit.bind(this, true)}
          />
        </Div>
      )}
      {showMultipleSsnError.length > 0 && (
        <Div width={1} m={1}>
          {!isMultiple ? (
            <ColouredSemiBoldText color="var(--red)">
              {ssnNumbers.length > 0
                ? messages.error_invalid_id
                : messages.error_empty_search}
            </ColouredSemiBoldText>
          ) : (
            <ColouredSemiBoldText color="var(--red)">
              {ssnNumbers.length === 0
                ? messages.error_empty_search
                : `${
                    messages.invalid_personal_numbers
                  }:${showMultipleSsnError.join(", ")}`}
            </ColouredSemiBoldText>
          )}
        </Div>
      )}
      {props.isAuthenticated && userInfo.name && !isMultiple && (
        <Div
          display="flex"
          mt={"20px"}
          mb={[4, 4, 0, 0]}
          justifyContent={["center", "center", "start", "start"]}
        >
          <Link onClick={handleMultipleSsnSearch}>
            <Icon
              mr={1}
              height="18px"
              width="18px"
              name="plus"
              color={"var(--blue-dark) !important"}
            />
            <ColouredMediumWeight>
              {messages.search_several_pin}
            </ColouredMediumWeight>
          </Link>
        </Div>
      )}
      {isMultiple && (
        <Div
          display={["flex", "flex", "none", "none"]}
          backgroundColor={"var(--blue-lightest)"}
          flex={1}
          mt={4}
          mb={3}
          height={"auto"}
          borderRadius="5px"
        >
          <Text display="flex" alignItems="center" py={2}>
            <Div display="flex" justifyContent="center">
              <Icon
                name="info"
                height="18px"
                width="18px"
                rounded={true}
                ml={3}
                mr={2}
                my={2}
              />
            </Div>
            <Div display="flex" flexDirection="column">
              <Text mt={1}>{messages.up_to_10_ids}</Text>
              <Text my={1}>{messages.seperate_id_by_comma}</Text>
            </Div>
          </Text>
        </Div>
      )}
      <Div
        display={["flex", "flex", "none", "none"]}
        backgroundColor={"var(--blue-lightest)"}
        flex={1}
        height="auto"
        mt={3}
        borderRadius="5px"
      >
        <Text display="block" alignItems="center" py={2}>
          <Div display="flex" justifyContent="center" mt={2}>
            <Icon
              name="info"
              rounded={true}
              ml={3}
              mr={2}
              height="18px"
              width="18px"
            />
            {messages.security_number_details}
          </Div>
          <Div display="flex" alignItems="center" mt={2}>
            <LinkArrow
              ml={2}
              target="_blank"
              direction="right"
              href={`${Config.WP_URL}${ROUTES.INTERNATIONELLBAKGRUNDSANALYS.URL}`}
              label={messages.search_label_click_here}
              variant="secondary"
              mb={1}
              mr={3}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          </Div>
        </Text>
      </Div>
    </Div>
  );
};

SSNSearch.propTypes = {
  ssnNumbers: PropTypes.array,
  onSearchSSN: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SSNSearch;
