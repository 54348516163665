import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DataTable from "@components/DataTableV2/DataTable";

import AdminContainer from "@layout/AdminContainer";

import {
  ROUTES,
  WRITE,
  ADMIN_CUSTOMER_DETAILS,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";

const searchableColumns = ["id", "customer_name"];

const FILTER_KEY_FROM = "filter[created_at][gte]";

const FILTER_KEY_TO = "filter[created_at][lte]";

const List = () => {
  const { messages } = useIntl();
  const history = useHistory();

  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const handleCreateCustomer = () => {
    history.push(ROUTES.ADMIN_CREATE_CUSTOMER.URL);
  };

  const dataTableConfig = {
    header: {
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "customers.csv",
    filters: [
      {
        title: messages.label_status,
        id: "filter[is_active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
            checked: false,
          },
          {
            label: messages.deleted,
            value: 0,
            checked: false,
          },
        ],
      },
      {
        title: messages.registered_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_customers_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/customers`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["include", "orders,subscription"]],
    },
    columns: [
      {
        title: "customer_id",
        width: "100px",
        db_field: "id",
        type: "text",
        sortable: true,
        sortField: "id",
        formatter: (value) => `#${value}`,
      },
      {
        title: "title_customer",
        width: "200px",
        db_field: "company_name",
        type: "text",
        sortable: true,
        sortField: "company_name",
        formatter: (value, data) => {
          const { type, customer_name } = data;
          return type === "company" ? value : customer_name;
        },
      },
      {
        title: "billing_orders",
        width: "100px",
        db_field: "orders_count",
        type: "text",
        sortable: true,
        sortField: "orders_count",
      },
      {
        title: "registered_date",
        db_field: "created_at",
        type: "text",
        formatter: (value) => {
          return value ? formatDateAndTime(value) : "-";
        },
      },
      {
        title: "label_credits",
        db_field: "available_credits",
        type: "text",
        formatter: (value, data) => {
          return data["subscription.status"] === SUBSCRIPTION_STATUS.ACTIVE &&
            data["subscription.available_credits"]
            ? `${data["subscription.available_credits"]} / ${data["subscription.total_credits"]}`
            : "-";
        },
      },
      {
        title: "label_payments",
        db_field: "invoice",
        type: "text",
        formatter: (value, data) => {
          if (data["subscription.status"] === "active") {
            return messages.subscription;
          } else if (data["invoice"] === 0) {
            return messages.klarna;
          } else {
            return messages.invoice;
          }
        },
      },
      {
        title: "label_status",
        db_field: "is_active",
        type: "enum",
        formatter: (value) => (value ? "active" : "deleted"),
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: ({ id }) =>
              `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
          },
        ],
      },
    ],
  };

  let headerConfig = [
    {
      title: messages.title_customers,
      headerActions: [
        {
          id: "new-customer",
          type: "button",
          label: messages.label_new_customer,
          onClick: handleCreateCustomer,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: permissions && permissions.customers !== WRITE,
        },
      ],
    },
  ];

  return (
    <AdminContainer config={headerConfig}>
      <DataTable config={dataTableConfig} />
    </AdminContainer>
  );
};

export default List;
