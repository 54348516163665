import React, { useRef, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CSVLink as PRCSVLink } from "react-csv";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import ParseData from "papaparse";
import Icon from "@components/Icon";
import DataTable from "@components/Table/Table";
import { H1 } from "@components/Heading";
import Breadcrumb from "@components/Breadcrumb";
import Container from "@components/Container";
import Div from "@components/Div";
import Dialog from "@components/Dialog";
import InputText from "@components/StyledInputText";
import {
  ROUTES,
  WATCH_LIST,
  ADMIN_CUSTOMER_DETAILS,
  ADMIN,
  ADMIN_WATCHLIST_URL,
  RECURRENT_TAB_URL,
  IMPORT_CSV_COLUMN_NAME,
} from "@utils/constant";
import { LinkArrow } from "@components/Link";
import {
  Text,
  TextUpperCase,
  TextMediumWeight,
  TextLargeSemiBoldWeight,
} from "@components/Text";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import AddSingleObjModal from "./components/AddSingleObjModal";
import DeleteUser from "../../admin/users/Delete";
import CreateWatchListService from "@app/services/watchlist/CreateWatchListService";
import DoneModal from "@pages/user/OrderResults/DoneModal";
import ErrorModal from "../OrderResults/ErrorModal";
import LoadObjectsModal from "./components/LoadObjectsModal";
import customerDetailsService from "@app/services/customers/customerDetailsService";
import { loadWatchListObjects, checkIfObject } from "@utils/common";
import UploadButton from "@components/UploadButton";

const CSVLink = styled(PRCSVLink)`
  display: none;
`;

const CreateWatchlist = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const downloadCsv = useRef(null);
  const [showAddObjectModal, setShowAddObjectModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [objects, setObjects] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [watchlistName, setWatchlistName] = useState("");
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showObjectsLoadedModal, setShowObjectsLoadedModal] = useState(false);
  const [loadedObjects, setLoadedObjects] = useState([]);
  const [collectedObjects, setCollectedObjects] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [invalidType, setInvalidType] = useState(false);
  const [errorMessage, setErrorMessage] = useState(messages.error_try_later);
  const [addedWatchListId, setAddedWatchListId] = useState(0);
  const [showCSVDownloadedMessage, setShowCSVDownloadedMessage] =
    useState(false);
  const [existingSSNs, setExistingSSNs] = useState(0);
  const [invalidSSNs, setInvalidSSNs] = useState(0);

  const csvDownloadData = [[IMPORT_CSV_COLUMN_NAME]];

  let { id = "" } = useParams();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer = {}, id: user_id = "" } = userInfo;
  const customer_id = userInfo.type !== ADMIN ? customer.id : id;

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (userInfo.type === ADMIN) {
        try {
          const customerDetailsResponse =
            await customerDetailsService(customer_id);
          const { data } = customerDetailsResponse?.data ?? {};
          setCustomerName(data.name);
        } catch (error) {
          // Handle error
        }
      }
    };

    fetchCustomerDetails().catch((error) => {
      // Handle error
      return error.response;
    });
  }, [customer_id, userInfo.type]);

  const breadCrumbItems =
    userInfo.type !== ADMIN
      ? [
          {
            label: messages.menu_watchlist,
            url: ROUTES.USER_WATCH_LIST.URL,
          },
          { label: messages.new_watchlist },
        ]
      : [
          {
            label: messages.watchlist_label_customers,
            url: ROUTES.ADMIN_CUSTOMERS.URL,
          },
          {
            label: customerName,
            url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_CUSTOMER_DETAILS}`,
          },
          { label: messages.label_new_watch_list },
        ];

  const handleClosePopup = () => {
    setShowCSVDownloadedMessage(false);
    setShowAddObjectModal(false);
    setShowDoneModal(false);
    setShowErrorModal(false);
    setShowObjectsLoadedModal(false);
    setInvalidType(false);
    setInvalidSSNs(0);
    setExistingSSNs(0);
  };

  const handleAddObject = (object = {}) => {
    setObjects([...objects, object]);
    handleClosePopup();
  };

  const handleShowAddObjModal = () => {
    setShowAddObjectModal(true);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteObject = () => {
    const { ssn = "" } = selectedData;
    setObjects(objects.filter((o) => o?.ssn !== ssn));
    setShowDeleteModal(false);
  };

  const handleChange = (e) => {
    const { target: { value = "" } = {} } = e;
    setWatchlistName(value);
  };

  const handleSelectionChange = (e) => {
    setSelectedObjects(e?.value);
  };

  const handleSearchChange = (e) => {
    const { target: { value = "" } = {} } = e;
    setSearchQuery(value);
  };

  const allowedTypes = ["text/csv", ".csv"];

  const parseSSNs = async (results) => {
    const rowsArray = results?.data?.map((d) => Object.keys(d));
    const [rows = []] = rowsArray;

    if (rows.includes(IMPORT_CSV_COLUMN_NAME)) {
      const collectAllSSNs = results.data.map((obj) => obj?.Personnummer);
      setCollectedObjects(collectAllSSNs);
      let objectsLoaded = [];
      let invalidSsnCsv = 0;
      let existingSsnCsv = 0;

      const noOfSSNs = collectAllSSNs.length;
      const noOfCalls = Math.ceil(noOfSSNs / 50);

      for (let i = 0; i < noOfCalls; i++) {
        const values = collectAllSSNs.slice(i * 50, (i + 1) * 50);
        const responseData = await loadWatchListObjects(values, customer_id);
        const {
          existing_ssn_list = [],
          invalid_ssn_list = [],
          valid_ssn_list = [],
        } = responseData;
        const existingSsnList = checkIfObject(existing_ssn_list);
        const invalidSsnList = checkIfObject(invalid_ssn_list);
        const validSsnsList = checkIfObject(valid_ssn_list);
        invalidSsnCsv = invalidSsnCsv + invalidSsnList?.length;
        existingSsnCsv += existingSsnList?.length;
        const validSsnList =
          validSsnsList?.length > 0
            ? validSsnsList.filter((validSsn) => {
                return !existingSsnList.find((existingSsn) =>
                  existingSsn.ssn_number.includes(validSsn.ssn),
                );
              })
            : validSsnsList;
        const filteredValidObjects =
          validSsnList?.length > 0
            ? validSsnList.filter(
                (o) => !objects.find((obj) => obj?.ssn === o?.ssn),
              )
            : validSsnList;
        existingSsnCsv =
          filteredValidObjects?.length < validSsnList?.length
            ? existingSsnCsv +
              (validSsnList?.length - filteredValidObjects?.length)
            : existingSsnCsv;
        objectsLoaded = [...objectsLoaded, ...filteredValidObjects];
      }
      setInvalidSSNs(invalidSSNs + invalidSsnCsv);
      setExistingSSNs(existingSSNs + existingSsnCsv);
      setShowObjectsLoadedModal(true);
      setLoadedObjects(objectsLoaded.map((o) => ({ ...o, id: o?.ssn })));
    } else {
      setInvalidType(true);
    }
  };

  const changeHandler = async (event) => {
    event.preventDefault();
    const file = event?.target?.files[0];

    if (file && allowedTypes.includes(file?.type)) {
      await ParseData.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          await parseSSNs(results);
        },
      });
    } else {
      setInvalidType(true);
    }
  };

  const handleDownloadSampleTemplate = () => {
    setShowCSVDownloadedMessage(true);
    downloadCsv?.current?.link?.click();
  };

  const handleCloseCSVDownloadedPopup = () => {
    setShowCSVDownloadedMessage(false);
  };

  const handleSearch = () => {
    setSearchFilter(searchQuery);
  };

  const handleClearSearch = () => {
    setSearchFilter("");
    setSearchQuery("");
  };

  const handleInvalidTypeError = () => {
    setInvalidType(false);
  };

  const handleCloseAndRedirectToList = () => {
    setShowDoneModal(false);
    setShowErrorModal(false);
    if (userInfo.type !== ADMIN) {
      history.push(ROUTES.USER_WATCH_LIST.URL);
    } else {
      history.push(
        `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_CUSTOMER_DETAILS}`,
      );
    }
  };

  const handleCloseAndRedirectToReccurentTab = () => {
    setShowDoneModal(false);
    if (userInfo.type !== ADMIN) {
      history.push(
        `${ROUTES.USER_WATCH_LIST.URL}/${addedWatchListId}?${RECURRENT_TAB_URL}`,
      );
    } else {
      history.push(
        `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_WATCHLIST_URL}/${addedWatchListId}/${ADMIN_CUSTOMER_DETAILS}?${RECURRENT_TAB_URL}`,
      );
    }
  };

  const handleDeleteSelectedObjects = () => {
    const filterUnSelectedObjects = objects
      .map((obj) => (selectedObjects.find((x) => x.id === obj.id) ? "" : obj))
      .filter((o) => o);
    setObjects(filterUnSelectedObjects);
    setSelectedObjects([]);
  };

  const MenuButton = {
    id: "menu-button",
    type: "menu-button",
    variant: "header",
    label: messages.watchlist_label_add,
    icon: "icon-plus",
    items: [
      {
        label: messages.add_object,
        icon: "icon-account-circle",
        command: handleShowAddObjModal,
      },
      {
        template: (
          <UploadButton
            changeHandler={changeHandler}
            allowedTypes={allowedTypes}
          />
        ),
        icon: "icon-upload-file",
        command: changeHandler,
      },
      {
        label: messages.download_template,
        icon: "icon-download-file",
        command: handleDownloadSampleTemplate,
      },
    ],
  };

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
      navigator.userAgent,
    );

  const config = [
    {
      title: `${messages.title_objects}: ${objects?.length}`,
      mobileHeaderActions: [
        {
          id: "select",
          label: messages.label_select_all,
          type: "link",
          variant: "header",
          onClick: handleDeleteSelectedObjects,
          disabled: !objects?.length,
        },
        {
          id: "delete",
          label: messages.label_delete,
          type: "link",
          icon: "rubber",
          iconPos: "left",
          variant: "header",
          onClick: handleDeleteSelectedObjects,
          disabled: !selectedObjects?.length,
        },
      ],
      headerActions: isMobile
        ? [
            MenuButton,
            {
              id: "search",
              type: "search",
              variant: "header",
              onSearch: handleSearch,
              onClear: handleClearSearch,
              onChange: handleSearchChange,
              value: searchQuery,
            },
          ]
        : [
            {
              id: "delete",
              label: messages.label_delete,
              type: "link",
              icon: "rubber",
              iconPos: "left",
              variant: "header",
              onClick: handleDeleteSelectedObjects,
              disabled: !selectedObjects?.length,
            },
            {
              id: "search",
              type: "search",
              variant: "header",
              onSearch: handleSearch,
              onClear: handleClearSearch,
              onChange: handleSearchChange,
              value: searchQuery,
            },
            MenuButton,
          ],
      columns: [
        {
          field: "name",
          header: messages.label_name,
          primary: true,
        },
        {
          field: "ssn",
          header: messages.label_personal_number,
          sortable: false,
        },
        { field: "results", header: messages.label_last_results },
        { field: "action", header: messages.title_actions },
      ],
      rowActions: [
        {
          id: "delete",
          label: messages.label_delete,
          type: "link",
          icon: "rubber",
          iconPos: "left",
          variant: "secondary",
          fontSize: 16,
          iconSize: 18,
          onClick: handleDeleteModal,
          isPrimary: true,
        },
      ],
      filterType: "dialog",
      filters: [],
      pagination: false,
      selectionMode: "checkbox",
    },
  ];

  const handleCancel = () => {
    setObjects([]);
    setSearchQuery("");
    setSearchFilter("");
    setSelectedObjects([]);
    setWatchlistName("");
  };

  const handleCreateWatchlist = async () => {
    const payload = {
      name: watchlistName,
      is_recurrent: false,
      customer_id: customer_id,
      user_id: user_id,
      watch_list_objects: objects.map((o) => ({
        ssn_number: o?.ssn,
        name: `${o?.firstName} ${o?.lastName}`,
      })),
    };
    try {
      const response = await CreateWatchListService(payload);
      if (response) {
        setShowDoneModal(true);
        const { data } = response?.data ?? {};
        setAddedWatchListId(data?.id);
      }
    } catch (e) {
      const { response: { data: { errors = {} } = {} } = {} } = e;
      if (Object.values(errors)?.length) {
        setErrorMessage(messages.ssns_already_in_use);
      }
      setShowErrorModal(true);
    }
  };

  const handleSelectedRow = (e) => {
    const { data } = e;
    setSelectedData(data);
  };

  const handleAddloadedObjects = () => {
    setObjects([...objects, ...loadedObjects]);
    handleClosePopup();
  };

  return (
    <>
      <CSVLink
        data={csvDownloadData}
        filename={`Mall bevakningslista.csv`}
        ref={downloadCsv}
        separator={";"}
      >
        click
      </CSVLink>
      {(showAddObjectModal ||
        showDoneModal ||
        showErrorModal ||
        showObjectsLoadedModal ||
        showCSVDownloadedMessage ||
        invalidType) && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleClosePopup}
          width={["90%", "90%", "90%", 445]}
        >
          {showAddObjectModal && (
            <AddSingleObjModal
              onClose={handleClosePopup}
              onSubmit={handleAddObject}
              customerId={customer_id}
              watchListObjects={objects}
            />
          )}
          {showObjectsLoadedModal && (
            <LoadObjectsModal
              collectedSSNs={collectedObjects?.length}
              loadedSSNs={loadedObjects?.length}
              onClose={handleClosePopup}
              onSubmit={handleAddloadedObjects}
              existingSSNs={existingSSNs}
              invalidSSNs={invalidSSNs}
            />
          )}
          {showDoneModal && (
            <DoneModal
              onCancel={handleCloseAndRedirectToList}
              handleConfirmPopup={handleCloseAndRedirectToReccurentTab}
              content={messages.watchlist_message_new_watchlist_added}
              okLabel={messages.watchlist_label_yes}
            />
          )}
          {showErrorModal && (
            <ErrorModal
              content={errorMessage}
              handleConfirmPopup={handleClosePopup}
              hideContactUsButton
            />
          )}
          {invalidType && (
            <ErrorModal
              content={messages.invalid_type_error}
              handleConfirmPopup={handleInvalidTypeError}
              hideContactUsButton
            />
          )}
          {showCSVDownloadedMessage && (
            <DoneModal
              title={messages.watchlist_csv_downloaded_title}
              content={
                <Div textAlign="left" ml={3}>
                  <li>{messages.watchlist_csv_downloaded_line_1}</li>
                  <li>{messages.watchlist_csv_downloaded_line_2}</li>
                  <li>{messages.watchlist_csv_downloaded_line_3}</li>
                </Div>
              }
              handleConfirmPopup={handleCloseCSVDownloadedPopup}
            />
          )}
        </Dialog>
      )}
      {showDeleteModal && (
        <DeleteUser onDelete={handleDeleteObject} onCancel={handleDeleteModal}>
          <Text display="flex" justifyContent="center">
            {messages.text_confirm_delete_object}
          </Text>
        </DeleteUser>
      )}
      <Container m={"0 !important"}>
        <Div>
          <Breadcrumb pl={0} items={breadCrumbItems} />
        </Div>
        <Div>
          <H1 my={3} display={["none", "none", "flex", "flex"]}>
            {messages.new_watchlist}
          </H1>
          <TextLargeSemiBoldWeight
            my={2}
            display={["flex", "flex", "none", "none"]}
          >
            {messages.new_watchlist}
          </TextLargeSemiBoldWeight>
          <Div display="flex" flexDirection="column">
            <TextUpperCase mb={2}>{messages.name_watchlist}</TextUpperCase>
            <InputText
              curved
              width={320}
              placeholder={messages.label_name}
              value={watchlistName}
              name={WATCH_LIST}
              type="text"
              onChange={handleChange}
            />
          </Div>
          <Div width={"100%"}>
            <DataTable
              config={config}
              onSelectionChange={handleSelectionChange}
              selectedProducts={selectedObjects}
              showActionButton
              tableData={objects}
              searchQuery={searchFilter}
              handleRedirectToDetails={handleSelectedRow}
              NoDataMessage={messages.watchlist_message_no_object_added}
            />
          </Div>
          <Div my={3}>
            <TextMediumWeight display="block" my={3}>
              {messages.new_watchlist_paragraph_1}
            </TextMediumWeight>
            <TextMediumWeight display="block" mb={2}>
              {messages.new_watchlist_paragraph_2}
            </TextMediumWeight>
            <Div ml={2}>
              <TextMediumWeight display="block">
                {messages.conditions_new_watchlist}
              </TextMediumWeight>
              <TextMediumWeight display="block">
                {messages.conditions_fill_it}
              </TextMediumWeight>
              <TextMediumWeight display="block">
                {messages.conditions_add_to_project}
              </TextMediumWeight>
            </Div>
            <LinkArrow
              label={messages.load_template}
              direction="left"
              variant="secondary"
              onClick={handleDownloadSampleTemplate}
              my={3}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          </Div>
        </Div>
        <Div my={3}>
          <PrimaryButton
            rounded
            semibold
            height={40}
            px={20}
            onClick={handleCreateWatchlist}
            label={messages.save_list}
            disabled={!(watchlistName && objects?.length)}
          />
          <PrimaryButtonOutlined
            mx={15}
            minWidth="150px"
            minHeight="40px"
            justifyContent="center"
            onClick={handleCancel}
          >
            {messages.label_cancel}
          </PrimaryButtonOutlined>
        </Div>
      </Container>
    </>
  );
};

export default CreateWatchlist;
