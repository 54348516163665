import {
  DeviationsGeneralAnalysis,
  DeviationsGeneralAnalysisActapublica,
} from "../pages/user/OrderResults/Deviations/index";
import { GENERAL_ANALYSIS, ACTAPUBLICA_ANALYSIS } from "@utils/constant";

const VersionMap = {
  [GENERAL_ANALYSIS]: DeviationsGeneralAnalysis,
  [ACTAPUBLICA_ANALYSIS]: DeviationsGeneralAnalysisActapublica,
};

const useAnalysisReport = ({ type = GENERAL_ANALYSIS }) => {
  return VersionMap[type] || DeviationsGeneralAnalysis;
};

export default useAnalysisReport;
