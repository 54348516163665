import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import { H1, H4 } from "@components/Heading";
import {
  STATUS_COMPLETED,
  KLARNA_CHECKOUT,
  SUBSCRIPTION_STATUS,
  COMPANY,
  PENDING_CONSENT,
  ANALYSIS,
} from "@utils/constant";
import UpgradePackage from "@components/UpgradePackage";
import { trackPageView } from "@src/analyticsFunctions";
import ObjectServicesCard from "../../shared/orderDetails/ObjectServicesCard";
import { klarnaActions } from "@app/reducers/klarnaReducer";
import ordersKlarnaService from "@app/services/orders/ordersKlarnaService";
import { default as getDetails } from "@app/services/orders/fetchOrderDetails";
import ResultsCardLoader from "../../shared/orderDetails/ResultsCardLoader";
import { TextLarge } from "../../../components/Text";

const Results = () => {
  const [loading, setLoading] = useState(false);
  const { messages } = useIntl();
  const [showPackage, setShowPackage] = useState(false);
  const [idle, setIdle] = useState(true);
  const dispatch = useDispatch();

  const paymentMethod = useSelector(
    (state) => state.personInformationReducer.paymentMethod,
  );

  const { order: orders } = useSelector(
    (state) => state.klarnaReducer.ordersInfo,
  );
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { personInfo = [] } = useSelector(
    (state) => state.personInformationReducer,
  );
  const { order_id: orderId } = orders;
  const query = useMemo(() => new URLSearchParams(location.search), []);

  // This is necessary to delay the backend to not make the call instantly
  useEffect(() => {
    setTimeout(() => {
      setIdle(false);
    }, 3000);
  }, []);

  useEffect(() => {
    trackPageView();
    const _orderId = query.get("order_id");
    if (_orderId && !idle) {
      setLoading(true);
      const getOrderDetailsUsingKlarnaID = async () => {
        const response = await ordersKlarnaService(_orderId);
        dispatch(klarnaActions.ordersInfo(response.data));
      };
      getOrderDetailsUsingKlarnaID();
    }
  }, [dispatch, query, idle]);

  useEffect(() => {
    let iterations = 0;
    let reportIterations;
    const getData = async () => {
      if (++iterations >= 9) {
        setLoading(false);
        clearInterval(reportIterations);
      }
      let order;
      let response;
      const isLoggedIn = localStorage.getItem("token");

      if (isLoggedIn) {
        response = await getDetails(orderId);
        order = response;
      } else {
        const _orderId = query.get("order_id");
        response = await ordersKlarnaService(_orderId);
        order = response.data.order;
      }
      const { order_items = [], reports = [] } = order;
      const filteredOrderItems = order_items.filter(
        (order_items) => order_items.service_type === ANALYSIS,
      );

      const completedReports = reports.filter(
        (obj) =>
          obj.report_status === STATUS_COMPLETED ||
          obj.report_status === PENDING_CONSENT,
      );
      if (completedReports.length === filteredOrderItems.length) {
        dispatch(klarnaActions.ordersInfo({ order }));
        setLoading(false);
        clearInterval(reportIterations);
      }
    };

    setLoading(true);
    if (!idle) {
      reportIterations = setInterval(getData, 3000);
    }

    return () => clearInterval(reportIterations);
  }, [dispatch, query, personInfo?.length, orderId, idle]);

  useEffect(() => {
    const loadActiveSubscriptionData = async () => {
      if (userInfo?.customer) {
        const {
          customer: { status: subscriptionStatus } = {},
          permissions = {},
        } = userInfo;
        if (
          userInfo.customer.type == COMPANY &&
          subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED &&
          subscriptionStatus !== SUBSCRIPTION_STATUS.STOPPED &&
          (permissions?.subscription === SUBSCRIPTION_STATUS.READ ||
            permissions?.subscription === SUBSCRIPTION_STATUS.WRITE)
        ) {
          setShowPackage(false);
        } else {
          setShowPackage(false);
        }
      } else if (Object.keys(userInfo).length === 0) {
        setShowPackage(false);
      }
    };
    loadActiveSubscriptionData();
  }, [userInfo]);
  const {
    order_items: orderItems = [],
    status = "",
    watchList = null,
    reports = [],
  } = orders;
  const hasAssignment = orderItems.some(
    (item) => item.service_type === "assignment",
  );

  return (
    <>
      <Section p={[3, 4]} pb={"0px !important"}>
        <Container>
          <Div maxWidth={"1110px"} mb={4}>
            <H1 mb={3}>{messages.title_order_summary}</H1>
            {paymentMethod === KLARNA_CHECKOUT && (
              <H4>{messages.payment_approved}</H4>
            )}
            {hasAssignment && (
              <TextLarge textAlign="left" whiteSpace="break-spaces">
                {messages.order_details_assigment_note}
              </TextLarge>
            )}
          </Div>
          {loading ? (
            <ResultsCardLoader
              showDelivery={true}
              orderItems={orderItems}
              orderItemCount={orderItems?.length}
              isWatchList={watchList && watchList !== null}
            />
          ) : (
            <ObjectServicesCard
              orderItems={orderItems}
              showDelivery={true}
              status={status}
              isWatchList={watchList && watchList !== null}
              reports={reports}
              showObject={true}
            />
          )}
        </Container>
      </Section>
      {showPackage && <UpgradePackage show_package_list={true} />}
    </>
  );
};

export default Results;
