import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useHistory from "@utils/useHistory";
import Proptypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { H1, H4 } from "@components/Heading";
import CardV2 from "@components/CardV2";
import { Text } from "@components/Text";
import Container from "@components/Container";
import Section from "@components/Section";
import Breadcrumb from "@components/Breadcrumb";
import Div from "@components/Div";
import Divider from "@components/Divider";
import OrderSummaryCard from "./OrderSummaryCard";
import Header from "./Header";
import Footer from "./Footer";
import CouponCodeInput from "./CouponCodeInput";
import CouponCodeModal from "./CouponCodeModal";
import SingleOrderSummary from "./SingleOrderSummary";
import MultipleOrderSummary from "./MultipleOrderSummary";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { ROUTES, KLARNA_CHECKOUT } from "@utils/constant";
import { PrimaryButton } from "@components/Button";
import BillingInformation from "./BillingInformation";

import usePermissions from "@hooks/usePermissions";
import { PERMISSIONS } from "@utils/enum";

// TODO: We don't need to use this component anymore and instead can be a simple back button
const StyledBreadcrumb = styled(Breadcrumb)`
  .p-breadcrumb-chevron {
    display: none;
  }
`;

const OrderSummary = ({
  orderSummaryProps = [],
  paymentMethod = "",
  isInvoice = false,
  isCreditsExceed = false,
  isCreditOnly = false,
  isPriceOnly = false,
  isCreditsAndPrice = false,
  orderTotalCredit = 0,
  orderTotalVatPrice = 0,
  orderTotalPriceWithoutVat = 0,
  orderTotalPriceWithVat = 0,
  orderTotalDiscount = 0,
  orderTotalDiscountPercentage = 0,
  promoCode = "",
  couponCodeValidationError = "",
  validateCouponCode = () => {},
  isBillingAccountInfoVisible = false,
  billingAccountId = null,
  onBillingAccountIdChange = () => {},
  onPrevStep = () => {},
  onNextStep = () => {},
}) => {
  const { messages } = useIntl();
  const history = useHistory();
  const { checkForReadPermission } = usePermissions();

  const [isCouponCodeModalVisible, setIsCouponCodeModalVisible] =
    useState(false);
  const [isOrderReadyToProceed, setIsOrderReadyToProceed] = useState(false);

  const isTwoStepCheckout = paymentMethod === KLARNA_CHECKOUT;

  const personInformation = useSelector(
    (state) => state.personInformationReducer,
  );

  const isConsent = useSelector((state) => state.authReducer).consent;

  const { hasActiveSubscription = false } = personInformation;

  const hasPricesPermission = checkForReadPermission(PERMISSIONS.PRICES);

  const constructBreadcrumbProps = {
    items: [
      {
        label: messages.label_back,
        command: () => {
          onPrevStep();
        },
      },
    ],
    home: {
      icon: "icon-headerarrowright",
      command: () => {
        onPrevStep();
      },
    },
  };

  const handleRedirectToUpgradePlan = () => {
    history.push(`${ROUTES.UPGRADE_SUBSCRIPTION.URL}`);
  };

  const handleCouponCodeValidation = (couponCode) => {
    validateCouponCode(
      orderSummaryProps,
      couponCode,
      orderTotalPriceWithoutVat,
      paymentMethod,
    );
  };

  const handleConfirmPayment = () => {
    setIsOrderReadyToProceed(!isOrderReadyToProceed);
  };

  useEffect(() => {
    setIsCouponCodeModalVisible(couponCodeValidationError !== null);
  }, [couponCodeValidationError]);

  return (
    <Div width={[1]}>
      <Section
        pt={["30px", "30px", "39px", "39px"]}
        pb={["37px", "37px", "70px", "70px"]}
      >
        <Container>
          <StyledBreadcrumb p={0} py={3} {...constructBreadcrumbProps} />
          {!(isOrderReadyToProceed && isTwoStepCheckout) && (
            <Div>
              <H1 mb={3}>
                {hasActiveSubscription
                  ? messages.title_checkout_prepaid
                  : messages.title_checkout_not_prepaid}
              </H1>
              {isCreditsExceed && (
                <Div
                  display={"flex"}
                  alignItems="center"
                  my={4}
                  p={3}
                  borderColor={"var(--turquoise)"}
                  borderWidth="1px"
                  borderStyle="dashed"
                >
                  <Icon
                    name="sign"
                    rounded={true}
                    mr={2}
                    width={36}
                    height={36}
                    backgroundColor={"var(--red) !important"}
                    borderColor={"var(--red)"}
                    color="var(--white)"
                  />
                  <Div display={"block"}>
                    <Text pl={[0, 0, 2, 2]} textAlign="left">
                      {messages.search_label_not_enough_credits}
                    </Text>
                    <LinkArrow
                      ml={[0, 0, 2, 2]}
                      label={messages.watchlist_label_upgrade_plan}
                      direction="right"
                      variant="secondary"
                      onClick={handleRedirectToUpgradePlan}
                    >
                      <Icon ml={1} name="headerarrowright" />
                    </LinkArrow>
                  </Div>
                </Div>
              )}
              {orderSummaryProps.length > 1 && (
                <H4 mb={3}>{messages.byline_checkout}</H4>
              )}
              <Text display="block" mb={3} width={75}>
                {messages.summary_order_message}
              </Text>
              {orderSummaryProps.map((list) => {
                return (
                  <CardV2
                    key={list.name}
                    header={
                      <Header
                        ssn={list.ssn}
                        name={list.name}
                        email={list.email}
                        isConsent={isConsent}
                      />
                    }
                    width={[1]}
                    mt={3}
                  >
                    <OrderSummaryCard
                      selectedServices={list?.services}
                      isCreditOnly={isCreditOnly}
                      isPriceOnly={isPriceOnly}
                    />
                  </CardV2>
                );
              })}
              {isBillingAccountInfoVisible && (
                <BillingInformation
                  onSelectBillingAccount={onBillingAccountIdChange}
                />
              )}
              {hasPricesPermission && (
                <CardV2 mt={4} mb={3}>
                  <Footer
                    isCreditOnly={isCreditOnly}
                    isCreditsAndPrice={isCreditsAndPrice}
                    orderTotalCredit={orderTotalCredit}
                    orderTotalVatPrice={orderTotalVatPrice}
                    orderTotalPriceWithoutVat={orderTotalPriceWithoutVat}
                    orderTotalPriceWithVat={orderTotalPriceWithVat}
                    orderTotalDiscount={orderTotalDiscount}
                    orderTotalDiscountPercentage={orderTotalDiscountPercentage}
                  />
                </CardV2>
              )}

              {isCouponCodeModalVisible && (
                <CouponCodeModal
                  hideModal={() => {
                    setIsCouponCodeModalVisible(false);
                  }}
                  errorKey="code_not_valid"
                />
              )}

              <Div px={3} pt={3} pb={0}>
                {hasPricesPermission && (
                  <Div
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                  >
                    <CouponCodeInput
                      hasError={!!couponCodeValidationError}
                      onCouponCodeSubmitted={handleCouponCodeValidation}
                    />
                  </Div>
                )}
                <Divider mb={3} />

                {!isOrderReadyToProceed && isTwoStepCheckout && (
                  <PrimaryButton
                    px={30}
                    height={40}
                    onClick={handleConfirmPayment}
                  >
                    {messages.label_payment}
                  </PrimaryButton>
                )}
              </Div>

              {isCouponCodeModalVisible && (
                <CouponCodeModal
                  hideModal={() => {
                    setIsCouponCodeModalVisible(false);
                  }}
                  errorKey="code_not_valid"
                />
              )}
            </Div>
          )}

          {!(!isOrderReadyToProceed && isTwoStepCheckout) && (
            <Div>
              {orderSummaryProps.length > 1 ? (
                <MultipleOrderSummary
                  paymentMethod={paymentMethod}
                  isInvoice={isInvoice}
                  isCreditOnly={isCreditOnly}
                  isConsent={isConsent}
                  promoCode={promoCode}
                  billingAccountId={billingAccountId}
                  onNextStep={onNextStep}
                />
              ) : (
                <SingleOrderSummary
                  paymentMethod={paymentMethod}
                  isInvoice={isInvoice}
                  isCreditOnly={isCreditOnly}
                  isConsent={isConsent}
                  promoCode={promoCode}
                  billingAccountId={billingAccountId}
                  onNextStep={onNextStep}
                />
              )}
            </Div>
          )}
        </Container>
      </Section>
    </Div>
  );
};

OrderSummary.propTypes = {
  orderSummaryProps: Proptypes.array,
  selectedServices: Proptypes.array,
  paymentMethod: Proptypes.string,
  isInvoice: Proptypes.bool,
  isCreditsExceed: Proptypes.bool,
  isCreditOnly: Proptypes.bool,
  isPriceOnly: Proptypes.bool,
  isCreditsAndPrice: Proptypes.bool,
  orderTotalCredit: Proptypes.number,
  orderTotalVatPrice: Proptypes.number,
  orderTotalPriceWithoutVat: Proptypes.number,
  orderTotalPriceWithVat: Proptypes.number,
  orderTotalDiscount: Proptypes.number,
  orderTotalDiscountPercentage: Proptypes.number,
  promoCode: Proptypes.string,
  couponCodeValidationError: Proptypes.string,
  validateCouponCode: Proptypes.func,
  isBillingAccountInfoVisible: Proptypes.bool,
  billingAccountId: Proptypes.string,
  onBillingAccountIdChange: Proptypes.func,
  onPrevStep: Proptypes.func,
  onNextStep: Proptypes.func,
};

export default OrderSummary;
