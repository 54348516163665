import React, { useState } from "react";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { TextMediumWeight, TextLargeSemiBoldWeight } from "@components/Text";
import Checkbox from "@components/Checkbox";
import Proptypes from "prop-types";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import DoneModal from "./DoneModal";
import ErrorModal from "./ErrorModal";
import getCreditReports from "@app/services/reports/getCreditReports";

export const OrderReportModal = ({
  handleConfirmPopup = {},
  reportID = "",
  reloadDetails = () => {},
}) => {
  const { messages } = useIntl();
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    setChecked(e.checked);
  };

  const [mailSent, setMailSent] = useState(false);
  const [mailNotSent, setMailNotSent] = useState(false);

  const resendToMail = async () => {
    try {
      await getCreditReports(reportID);
      setMailSent(true);
      setMailNotSent(false);
      reloadDetails();
    } catch (e) {
      setMailNotSent(true);
      setMailSent(false);
    }
  };

  return (
    <>
      {!mailSent && !mailNotSent && (
        <Div mt={3} pt={1}>
          <Div display="flex" alignItems="center" flexDirection="column">
            <TextLargeSemiBoldWeight mb={2}>
              {messages.finansial_report_header}
            </TextLargeSemiBoldWeight>
            <TextMediumWeight my={3}>
              {messages.finansial_popup_para}
            </TextMediumWeight>
            <TextMediumWeight mb={3}>
              {messages.financial_popup_content}
            </TextMediumWeight>
          </Div>
          <Div display="flex" mt={2} mb={4} alignItems="flex-start">
            <Div>
              <Checkbox
                fontSize="var(--fs-text)"
                inputId="policy_check"
                checked={checked}
                onChange={handleChange}
              />
            </Div>
            <TextMediumWeight
              htmlFor="policy_check"
              ml={10}
              color="var(--grey-dark)"
              fontWeight="var(--light-weight)"
            >
              {messages.finansial_popup_check_text}
            </TextMediumWeight>
          </Div>
          <Div
            px={3}
            max-width="250px"
            display={["block", "flex"]}
            alignItems="center"
            justifyContent="space-around"
          >
            <PrimaryButtonOutlined
              mr={2}
              mb={[2, 0]}
              minWidth="85px"
              minHeight="40px"
              width={[1, "auto"]}
              onClick={handleConfirmPopup}
            >
              {messages.label_cancel}
            </PrimaryButtonOutlined>
            <PrimaryButton
              py={2}
              px={4}
              width={[1, "200px"]}
              label={messages.send_report}
              onClick={resendToMail}
              disabled={!checked}
            />
          </Div>
        </Div>
      )}
      {mailSent && <DoneModal handleConfirmPopup={handleConfirmPopup} />}
      {mailNotSent && <ErrorModal handleConfirmPopup={handleConfirmPopup} />}
    </>
  );
};

OrderReportModal.propTypes = {
  handleConfirmPopup: Proptypes.func,
  reportID: Proptypes.string,
  reloadDetails: Proptypes.func,
};

export default OrderReportModal;
