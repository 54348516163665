import React from "react";

import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Icon from "@components/Icon";
import { ButtonLinkIcon } from "@components/Button";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButtonLinkIcon = styled(ButtonLinkIcon)`
  .p-button-label {
    font-size: var(--fs-link-m) !important;
    font-weight: var(--semibold-weight) !important;
  }
`;

const TabHeader = ({ title, actions = [] }) => {
  const item = actions
    .filter((action) => !action.isHidden)
    .map((action) => (
      <StyledButtonLinkIcon
        {...action}
        semibold
        key={action.id}
        id={action.id}
        label={action.label}
        icon={
          action.icon ? (
            <Icon
              name={action.icon}
              mr={action.label ? 2 : 0}
              color="var(--turquoise) !important"
              fontSize="var(--fs-icon-m)"
              fontWeight="var(--semibold-weight)"
            />
          ) : (
            ""
          )
        }
        color="var(--grey-dark) !important"
        fontSize="var(--fs-text-m) !important"
        minWidth={action.minWidth || "40px"}
        minHeight="30px"
        display="inline-flex"
        textAlign="center"
        whiteSpace
      />
    ));

  return (
    <Div display="flex" justifyContent="between" width={1}>
      <Div flex="1">
        <H2>{title}</H2>
      </Div>
      <Div>{item}</Div>
    </Div>
  );
};

TabHeader.propTypes = {
  actions: PropTypes.array,
  title: PropTypes.string,
};

export default TabHeader;
