import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureAxios from "@app/api/configureAxios";
import configureMarkerIO from "./utils/configureMarkerIO";
import "./index.scss";
import { ROUTES } from "@utils/constant";

const disableMarker = window.location.pathname === ROUTES.REPORT_PDF.URL;

if (!disableMarker) {
  configureMarkerIO();
}
configureAxios();

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
