import Axios from "@app/api/axios";
import { WATCH_LISTS, WATCH_LIST_OBJECTS_API } from "@utils/constant";
import { formatDate } from "@utils/utils";

const getWatchListObjects = async (
  screenName,
  payload,
  customerId,
  userInfo,
  watchListId,
) => {
  const getWatchListObjects = (responseData = []) => {
    const watchListObjects = responseData.map((object) => {
      const last_report_generated_date = object.last_report_generated_date
        ? formatDate(object.last_report_generated_date)
        : "";
      const grades = object.report_id !== 0 ? object.report?.grades : "";
      const { report = {} } = object || {};
      const { order_item: orderItem = {} } = report || {};
      const { product = {} } = orderItem || {};
      const { grade_mapping: gradeMapping = {} } = product || {};
      const { grade = [] } = gradeMapping || {};
      const { color: gradeColor = "white", bgColor: gradeBgColor = "grey" } =
        grades ? grade?.find((o) => o?.grades === grades) || {} : {};
      return {
        ...object,
        last_report_generated_date,
        grades,
        gradeBgColor,
        gradeColor,
      };
    });
    return watchListObjects;
  };

  try {
    const response = await Axios.get(
      `${WATCH_LISTS}/${watchListId}/${WATCH_LIST_OBJECTS_API}${payload}`,
    );
    const { status } = response;
    const { data = [], meta = {} } = response?.data || {};
    return { meta, status, data: getWatchListObjects(data) };
  } catch (error) {
    return error.response;
  }
};

export default getWatchListObjects;
