import React from "react";
import Div from "@components/Div";
import Link from "@components/Link";
import Icon from "@components/Icon";
import { useIntl } from "react-intl";
import { TextLargeSemiBoldWeight } from "./Text";
const Support = () => {
  const { messages } = useIntl();
  return (
    <Div
      p={"21px 0 29px 0"}
      display={["block", "block", "block", "flex"]}
      alignItems="center"
      justifyContent="center"
    >
      <TextLargeSemiBoldWeight
        display="inline-block"
        mr={[0, 0, 0, 17]}
        mb={[20, 20, 20, 0]}
      >
        {messages.support_contact_us}
      </TextLargeSemiBoldWeight>
      <Link
        href={`mailto: ${messages.support_email}`}
        label={messages.support_email}
        variant="secondary"
        display="inline-flex"
        color="var(--grey-dark)"
        iconPos="left"
        fontWeight="var(--semibold-weight)"
        fontSize="var(--fs-h4)"
      >
        <Icon mr={1} name="email" fontSize="var(--fs-icon-l)" />
      </Link>
    </Div>
  );
};
export default Support;
