import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AnalysDetails from "./AnalysDetails";
import getReportDetails from "@app/services/reports/getReportDetails";
import fetchOrderDetails from "@app/services/orders/fetchOrderDetails";

const AdminAnalysDetails = () => {
  const [analysData, setAnalysData] = useState({});
  const { id: reportId = "" } = useParams();
  const [orderData, setOrderData] = useState({});

  const fetchReportDetails = () => {
    getReportDetails(reportId).then(
      (res) => {
        const { data: { data = {} } = {} } = res;
        setAnalysData(data);
      },
      (e) => {
        throw new Error(e);
      },
    );
  };

  useEffect(() => {
    fetchReportDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const getOrderDetails = () => {
      fetchOrderDetails(analysData.order_id).then((res) => {
        setOrderData(res);
      });
    };
    getOrderDetails();
  }, [analysData.order_id, analysData.report_status]);

  return (
    <>
      <AnalysDetails
        analysData={analysData}
        cardData={orderData}
        isAdmin
        fetchReportDetails={fetchReportDetails}
      />
    </>
  );
};

export default AdminAnalysDetails;
