import React from "react";
import Div from "@components/Div";
import { TextColoured } from "@components/Text";
import PropTypes from "prop-types";

const StatusIndicator = ({ bgColor, color, value }) => (
  <Div
    width={40}
    height={24}
    bg={bgColor}
    borderRadius="50px"
    display={"flex"}
    alignItems="center"
    justifyContent="center"
  >
    <TextColoured color={color} fontWeight={`var(--medium-weight)`}>
      {value}
    </TextColoured>
  </Div>
);

StatusIndicator.propTypes = {
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export const ReportStatusComponent = (value, report) => {
  const parsedConfig = JSON.parse(report["product.report_config"]);
  const repor_array = parsedConfig ? parsedConfig?.grade : [];
  const foundGrade = repor_array?.find((grade) => grade.grades === value);
  const { color = "white", bgColor = "grey" } = foundGrade || {};

  if (
    report.analysis_type === "role-specific-analysis" &&
    (value === "-1" || value === "+1" || value === "1")
  ) {
    return (
      <Div display="flex" alignSelf="center">
        <StatusIndicator
          bgColor={`var(--${bgColor})`}
          color={`var(--${color})`}
          value={value}
        />
      </Div>
    );
  } else if (
    report.analysis_type === "role-specific-analysis" &&
    value === "-" &&
    report.status === "completed"
  ) {
    return (
      <Div display="flex" alignSelf="center">
        <StatusIndicator
          bgColor={`var(--grey)`}
          color={`var(--white)`}
          value={value}
        />
      </Div>
    );
  } else if (
    report.analysis_type === "general-analysis-actapublica" &&
    value === "1"
  ) {
    return (
      <Div display="flex" alignSelf="center">
        <StatusIndicator
          bgColor={`var(--${bgColor})`}
          color={`var(--${color})`}
          value="+1"
        />
      </Div>
    );
  } else {
    return value !== "-" ? (
      <Div display="flex" alignSelf="center">
        <StatusIndicator
          bgColor={`var(--${bgColor})`}
          color={`var(--${color})`}
          value={value}
        />
      </Div>
    ) : (
      <Div width={40} height={24} textAlign={["center"]}>
        {"-"}
      </Div>
    );
  }
};

export { StatusIndicator };
