import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import DataTable from "@components/DataTableV2/DataTable";
import TabHeader from "@components/TabHeader";

import usePermissions from "@hooks/usePermissions";

import { ROUTES, PRODUCTS } from "@utils/constant";
import { PERMISSIONS, PRODUCT_TYPE } from "@utils/enum";

const SEARCHABLE_COLUMNS = ["id", "name", "credits", "price", "type"];

const SubscriptionPackageList = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { checkForReadPermission, checkForWritePermission } = usePermissions();

  const hasProductsPermission = checkForWritePermission(PERMISSIONS.PRODUCTS);
  const hasPricesPermission = checkForReadPermission(PERMISSIONS.PRICES);

  const handleCreateSubscription = () => {
    history.push(ROUTES.ADMIN_CREATE_SUBSCRIPTION.URL);
  };

  const handleEditSubscription = (event) => {
    const { id } = event;

    return `/admin/produkter/abonnemang/${id}`;
  };

  const dataTableConfig = {
    header: {
      title: "label_subscription_packages",
    },
    enable_search: false,
    enable_filter: false,
    enable_csv_download: false,
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_query_builder: true,
    api: {
      resource: `/${PRODUCTS}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [
        ["fields", SEARCHABLE_COLUMNS],
        ["filter[type]", PRODUCT_TYPE.SUBSCRIPTION],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        db_field: "name",
        title: "label_name",
        type: "text",
      },
      {
        db_field: "credits",
        title: "analysis",
        type: "text",
      },
      {
        db_field: "price",
        title: "watchlist_label_price",
        type: "currency",
        isHidden: !hasPricesPermission,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: handleEditSubscription,
          },
        ],
      },
    ],
  };

  const tabHeader = {
    title: messages.label_subscription_packages,
    actions: [
      {
        id: "new-product",
        type: "button-raised",
        label: messages.button_add_new,
        onClick: handleCreateSubscription,
        width: "auto",
        icon: "plus",
        variant: "header",
        isHidden: !hasProductsPermission,
      },
    ],
  };

  return (
    <>
      <TabHeader {...tabHeader} />
      <DataTable config={dataTableConfig} />
    </>
  );
};

export default SubscriptionPackageList;
