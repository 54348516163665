import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { H3 } from "@components/Heading";
import Div from "@components/Div";
import { TextLarge } from "@components/Text";
import { recommendationProps } from "./config";
import { LinkArrow } from "@components/Link";
import Icon from "@components/Icon";
import Config from "@src/config";
import { ROUTES } from "@utils/constant";

const Recommendation = ({ isPrintable = false }) => {
  const { messages } = useIntl();

  const { indepthContent, recommendationContent, contactProps } =
    recommendationProps;

  const constructContent = (arr) =>
    arr.map((value, index) => (
      <Div key={index}>
        <H3
          color="var(--blue-dark) !important"
          mb={isPrintable ? 2 : 3}
          fontSize={isPrintable && "var(--fs-h5) !important"}
          lineHeight={isPrintable && "var(--lh-h5) !important"}
        >
          {messages[value.title]}
        </H3>
        <>
          {value.description.map((val, indice) => (
            <>
              <TextLarge
                display="block"
                key={indice}
                mb={42}
                fontSize={isPrintable && "var(--fs-text-s) !important"}
                lineHeight={isPrintable && "150% !important"}
              >
                {messages[val]}
              </TextLarge>
            </>
          ))}
        </>
      </Div>
    ));

  const constructContactProps = (arr) =>
    arr.map((value, index) => (
      <Div key={index}>
        <Div px={isPrintable ? 0 : [3, 3, 3, 0]}>
          {!isPrintable && (
            <LinkArrow
              direction="left"
              href={`${Config.WP_URL}${ROUTES.COUNSELLING.URL}`}
              label={messages.label_order_interview}
              mb={[4, 30]}
            >
              <Icon ml={1} name="headerarrowright" />
            </LinkArrow>
          )}
          <TextLarge
            display="block"
            mb={[3, 4]}
            fontSize={isPrintable && "var(--fs-text-s) !important"}
            lineHeight={isPrintable && "150% !important"}
          >
            {messages[value.description]}
          </TextLarge>
        </Div>
      </Div>
    ));

  return (
    <Div>
      <Div px={isPrintable ? 0 : [3, 3, 3, 0]} mt={"10px"}>
        <Div mb={2}>{constructContent(indepthContent)}</Div>
        <Div mb={2}>{constructContent(recommendationContent)}</Div>
      </Div>
      <Div mb={2} pt={isPrintable ? 0 : 3}>
        {constructContactProps(contactProps)}
      </Div>
    </Div>
  );
};

Recommendation.propTypes = {
  isPrintable: PropTypes.bool,
};

export default Recommendation;
