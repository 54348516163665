import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import SurveyBuilder from "@components/SurveyBuilder";

import QuestionDialog from "./QuestionDialog";

const SurveyBuilderDialog = ({
  data,
  surveyCreatorActiveTab,
  onHide,
  onUpdate,
}) => {
  const [isQuestionDialogVisible, setIsQuestionDialogVisible] = useState(false);

  const formDataRef = useRef(null);

  const handleOpenQuestionDialog = () => {
    setIsQuestionDialogVisible(true);
  };

  const handleCloseQuestionDialog = () => {
    setIsQuestionDialogVisible(false);
    onHide();
  };

  const handleUpdateFormData = useCallback((formDataJson) => {
    formDataRef.current = JSON.stringify(formDataJson);
  }, []);

  const handleSaveChanges = () => {
    if (!formDataRef.current) {
      return;
    }

    onUpdate(formDataRef.current);
    onHide();
  };

  return (
    <Dialog
      maximized
      visible="displayBasic"
      draggable={false}
      backgroundColor="red"
      onHide={handleOpenQuestionDialog}
    >
      {isQuestionDialogVisible && (
        <QuestionDialog
          onConfirm={handleSaveChanges}
          onCancel={handleCloseQuestionDialog}
        />
      )}

      <Div display="flex" flex={1} height="100%" py={1}>
        <SurveyBuilder
          data={data}
          activeTab={surveyCreatorActiveTab}
          onUpdate={handleUpdateFormData}
        />
      </Div>
    </Dialog>
  );
};

SurveyBuilderDialog.propTypes = {
  data: PropTypes.string,
  surveyCreatorActiveTab: PropTypes.string,
  onHide: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default SurveyBuilderDialog;
