import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Link from "@components/Link";
import {
  TextLargeSemiBoldWeight as StyledTextLargeSemiBoldWeight,
  TextLarge as StyledTextLarge,
} from "@components/Text";

import Config from "@src/config";

import { ADDRESS, CO_RESIDENTS, INPUT, MARITIAL_STATUS } from "@utils/constant";
import { addHyphenToNumber, getFormattedPinCode } from "@utils/utils";

const prepareCoResidents = (coResidents = [], messages = {}, reportId) => {
  const currentYear = new Date().getFullYear();
  const listOfCoResidents = coResidents.map((resident, index) => {
    if (resident?.born && currentYear - Number(resident.born) < 18) {
      const { gender = null, born = "" } = resident;
      return `${gender ? messages[resident.gender] : ""} ${
        messages.label_born
      } ${born}`;
    }

    if (resident?.ssn) {
      const { full_name = "", ssn = "" } = resident;
      const ssnLink = (
        <Link
          display="inline"
          key={`ssn-${reportId}-${index}`}
          handleClick={() =>
            window.open(
              `${Config.APP_URL}/sok?ssnnumber=${addHyphenToNumber(
                ssn.toString(),
                8,
              )}`,
            )
          }
        >
          ({addHyphenToNumber(ssn.toString(), 8)})
        </Link>
      );
      return [full_name, ssnLink]
        .filter((obj) => obj !== "")
        .reduce((accumulator, current) => [accumulator, " ", current]);
    }
  });

  return coResidents?.length
    ? listOfCoResidents.join(", ")
    : messages.co_residents_not_available;
};

const PersonDetails = ({ report = {}, isPrintable = false, config = {} }) => {
  const { id: reportId } = report;
  const { sectionTitle = "", content = [] } = config;
  const { messages } = useIntl();

  const TextLarge = styled(StyledTextLarge)`
    font-size: ${isPrintable && "var(--fs-text-s)"};
    line-height: ${isPrintable && "150%"};
  `;

  const TextLargeSemiBoldWeight = styled(StyledTextLargeSemiBoldWeight)`
    font-size: ${isPrintable && "var(--fs-text-s)"};
    line-height: ${isPrintable && "150%"};
  `;
  const { personal_number = "", co_residents } = report;

  const displayCoResidents = useMemo(
    () => prepareCoResidents(co_residents || [], messages, reportId),
    [messages, co_residents, reportId],
  );

  const constructContent = (arr) =>
    arr.map((value, index) => {
      const { title = "", key = "" } = value;
      return (
        <Div key={index} display={["block", "flex"]}>
          <Div
            pb={isPrintable ? 1 : [2, 2, 2, 3]}
            wordBreak={["break-word", ""]}
          >
            <TextLargeSemiBoldWeight>{`${messages[title]}:`}</TextLargeSemiBoldWeight>
            {key === ADDRESS && (
              <TextLarge
                pt={2}
                ml={isPrintable ? 1 : [0, 0, 0, 2]}
                display={
                  isPrintable
                    ? "inline-block"
                    : ["block", "block", "block", "inline-block"]
                }
              >
                {report.street_address
                  ? `${report.street_address}, ${getFormattedPinCode(
                      report.zip_code,
                    )} ${report.city}`
                  : ""}
              </TextLarge>
            )}
            {key === CO_RESIDENTS && (
              <TextLarge ml={isPrintable ? 1 : 2}>
                {displayCoResidents}
              </TextLarge>
            )}

            {key === MARITIAL_STATUS && (
              <TextLarge ml={isPrintable ? 1 : 2}>
                {messages[report[key]?.toLowerCase() || ""]}
              </TextLarge>
            )}

            {key !== MARITIAL_STATUS && key !== CO_RESIDENTS && (
              <TextLarge ml={isPrintable ? 1 : 2}>
                {key === INPUT.NAME.PERSONAL_NUMBER
                  ? addHyphenToNumber(personal_number.toString(), 8) || ""
                  : report[key] || ""}
              </TextLarge>
            )}
          </Div>
        </Div>
      );
    });

  return (
    <Div>
      <H2
        color="var(--blue-dark) !important"
        mb={isPrintable ? 2 : [3, 3, 3, 4]}
        lineHeight={isPrintable && "var(--fs-text) !important"}
        fontSize={isPrintable && "var(--fs-h4) !important"}
      >
        {messages[sectionTitle]}
      </H2>
      <Div mb={isPrintable ? 3 : 4}>{constructContent(content)}</Div>
    </Div>
  );
};

PersonDetails.propTypes = {
  report: PropTypes.object,
  isPrintable: PropTypes.bool,
  config: PropTypes.object,
};

export default PersonDetails;
