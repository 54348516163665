import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@components/Dialog";
import { default as Tabs } from "@components/Tabs";
import { authActions } from "@app/reducers/authReducer";
import ConfirmationDialog from "@pages/shared/ConfirmationDialog";
import BillingAccounts from "../billingAccounts/BillingAccounts";
import { loadActiveSubscriptionData } from "@utils/common";
import EditableFieldsCard from "@components/EditableFieldsCard";
import { FORM_INPUT_TYPE } from "@utils/enum";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import {
  ROUTES,
  NO_CONSENT,
  CONSENT_OPTIONAL,
  CONSENT_MANDATORY,
} from "@utils/constant";

import { addHyphenToNumber } from "@utils/utils";

import { EditCompanyForm } from "./EditCompanyForm";
import { ModalHeader } from "./ModalHeader";

import IntegrationsAPIList from "./tabs/IntegrationsAPIList";
import UserContainer from "@layout/UserContainer";

export const CompanyInfo = () => {
  const history = useHistory();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { customer = {}, showConfirmDialog } = userInfo;
  const { id: companyId, invoice = false } = customer;

  const handleModal = useCallback(() => {
    setOpenDialog(!openDialog);
  }, [openDialog]);

  const [companyDetails, setCompanyDetails] = useState({});

  const company = [
    {
      key: "company_name",
      translationKey: "label_company_name",
      value: userInfo.customer?.company_name,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: userInfo.customer?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: true,
    },
    {
      key: "address",
      translationKey: "label_company_address",
      value: userInfo.customer?.address,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: true,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: userInfo.customer?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: userInfo.customer?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: true,
    },
    {
      key: "created_at",
      translationKey: "created_at",
      value: userInfo.customer?.created_at,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: false,
    },
  ];

  const contact = [
    {
      key: "firstname",
      translationKey: "label_name",
      value: userInfo.customer?.firstname,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      enable: true,
    },
    {
      key: "lastname",
      translationKey: "label_last_name",
      value: userInfo.customer?.lastname,
      type: FORM_INPUT_TYPE.TEXT,
      enable: true,
    },
    {
      key: "email",
      translationKey: "label_email",
      value: userInfo.customer?.email,
      type: FORM_INPUT_TYPE.TEXT,
      enable: true,
    },
    {
      key: "phone",
      translationKey: "label_phone",
      value: userInfo.customer?.phone,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
      enable: true,
    },
  ];
  const consentDropDownOptions = [
    {
      label: messages.consent_mandatory,
      value: CONSENT_MANDATORY,
      message: messages.consent_mandatory_message,
    },
    {
      label: messages.consent_optional,
      value: CONSENT_OPTIONAL,
      message: messages.consent_optional_message,
    },
    {
      label: messages.consent_not_needed,
      value: NO_CONSENT,
      message: messages.consent_not_needed_message,
    },
  ];
  const settings = [
    {
      key: "consent",
      translationKey: "consent_title",
      value: userInfo.customer?.consent,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      mt: 3,
      name: "consentValue",
      options: consentDropDownOptions,
      width: [1, 1, 450, 450],
      enable: true,
    },
  ];

  useEffect(() => {
    setCompanyDetails({
      company_name: userInfo.customer.company_name,
      company_address: userInfo.customer?.address,
      registration_nr: addHyphenToNumber(
        userInfo.customer.registration_nr,
        "6",
      ),
      phone: userInfo.customer?.phone,
      firstname: userInfo.customer?.firstname,
      lastname: userInfo.customer?.lastname,
      email: userInfo.customer?.email,
      invoice: invoice === 1,
      billing_email: userInfo.customer?.billingEmail,
      id: companyId,
    });
  }, [userInfo.customer, companyId, invoice]);

  const handleSubmit = async (values) => {
    const newCompanyInfo = { ...companyDetails, ...values };
    delete newCompanyInfo.invoice;
    try {
      await editCustomerInfoService({
        id: companyId,
        ...newCompanyInfo,
      });

      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          customer: {
            ...userInfo.customer,
            ...newCompanyInfo,
          },
          showConfirmDialog: true,
          isError: true,
        }),
      );
    } catch (error) {
      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          showConfirmDialog: true,
          isError: true,
        }),
      );
    }
  };
  useEffect(() => {
    const getSubscriptionStatus = async () => {
      const response = await loadActiveSubscriptionData({ customer: customer });
      setActiveSubscription(response);
    };
    if (companyId) {
      getSubscriptionStatus();
    }
  }, [companyId, customer]);

  const tabview = () => {
    return (
      <div>
        <EditableFieldsCard
          title={messages.label_details}
          config={company}
          isEditPermission="true"
          onSubmit={handleSubmit}
        />
        <EditableFieldsCard
          title={messages.title_contact_person}
          config={contact}
          isEditPermission="true"
          onSubmit={handleSubmit}
        />
        <EditableFieldsCard
          title={messages.consent_title}
          config={settings}
          onSubmit={handleSubmit}
          isEditPermission="true"
        />
      </div>
    );
  };

  const viewtab = tabview();
  const tabViewContent = useMemo(
    () => [
      {
        title:
          messages.organisation.charAt(0).toUpperCase() +
          messages.organisation.slice(1),
        content: viewtab,
        url: ROUTES.COMPANY_INFO.URL,
      },
      {
        title: messages.tab_billing,
        content: <BillingAccounts customer_id={companyId} />,
        url: ROUTES.BILLING_ACCOUNTS_LIST.URL,
      },
      {
        title: messages.header_api,
        content: <IntegrationsAPIList />,
        url: ROUTES.INTEGRATIONS_API_LIST.URL,
      },
    ],
    [
      companyId,
      messages.organisation,
      messages.tab_billing,
      messages.header_api,
      viewtab,
    ],
  );

  useEffect(() => {
    const path = history.location.pathname;
    const selectedTabIndex = Math.max(
      tabViewContent.findIndex((item) => item.url === path),
      0,
    );

    setActiveIndex(selectedTabIndex);
  }, [history.location.pathname, tabViewContent]);

  const handleTabChange = (e) => {
    const targetUrl = tabViewContent[e.index].url;

    history.push(targetUrl);
  };

  let headerConfig = {
    title:
      messages.organisation.charAt(0).toUpperCase() +
      messages.organisation.slice(1),
  };

  return (
    <UserContainer config={headerConfig}>
      {openDialog && (
        <Dialog
          header={<ModalHeader />}
          visible="displayBasic"
          draggable={false}
          onHide={handleModal}
          width={[1, 1, 1, "auto"]}
          m={[3, 3, 3, "auto"]}
        >
          <EditCompanyForm
            id={companyId}
            userInfo={userInfo}
            onHideModal={handleModal}
          />
        </Dialog>
      )}
      {showConfirmDialog && (
        <ConfirmationDialog byline={messages.text_update_sucessful} />
      )}
      {invoice || activeSubscription ? (
        <Tabs
          content={tabViewContent}
          onTabChange={handleTabChange}
          activeIndex={activeIndex}
        />
      ) : (
        tabview()
      )}
    </UserContainer>
  );
};

export default CompanyInfo;
