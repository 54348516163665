import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Breadcrumb from "@components/Breadcrumb";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import ProgressSpinner from "@components/ProgressSpinner";
import SuccessDialog from "@components/SuccessDialog";

import AdminContainer from "@layout/AdminContainer";
import UserContainer from "@layout/UserContainer";

import ErrorModal from "@pages/user/OrderResults/ErrorModal";

import customerDetailsService from "@app/services/customers/customerDetailsService";
import { deleteBillingAccountService } from "@app/services/billingAccounts/deleteBillingAccountService";
import { getBillingAccountService } from "@app/services/billingAccounts/getBillingAccountService";
import patchAccountStatus from "@app/services/billingAccounts/patchAccountStatus";
import { updateBillingAccountService } from "@app/services/billingAccounts/updateBillingAccountService";

import { ADMIN_BILLING_ACCOUNTS, ROUTES, WRITE } from "@utils/constant";
import countries from "@utils/countries.json";
import { INVOICE_TYPE, FORM_INPUT_TYPE } from "@utils/enum";
import {
  isAdminUser,
  enumValueToTranslationKey,
  getCountryDetails,
} from "@utils/utils";

import {
  BasicInformationSchema,
  InvoiceReferenceSchema,
  PaymentSchema,
} from "./UpdateBillingInfoSchema";

const getCountryName = (code, originalName) => {
  return (
    getCountryDetails(code, "code", "name") ||
    getCountryDetails(originalName, "original_name", "name")
  );
};

const getCountryCode = (name) => {
  return (
    getCountryDetails(name, "name", "code") ||
    getCountryDetails(name, "original_name", "code")
  );
};

const getConfig = (billingAccountInfo) => ({
  billingInfo: [
    {
      key: "name",
      translationKey: "header_company_name",
      value: billingAccountInfo?.name,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: billingAccountInfo?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "address_1",
      translationKey: "label_invoice_address_line1",
      value:
        billingAccountInfo?.address_1 !== "Not Set"
          ? billingAccountInfo?.address_1
          : "",
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "address_2",
      translationKey: "label_invoice_address_line2",
      value: billingAccountInfo?.address_2,
      type: FORM_INPUT_TYPE.TEXT,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: billingAccountInfo?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: billingAccountInfo?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    {
      key: "country_name",
      translationKey: "label_country",
      value: getCountryName(
        billingAccountInfo?.country_code,
        billingAccountInfo?.country_name,
      ),
      placeholderKey: "placeholder_choose_country",
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      options: countries.map((countryItem) => countryItem.name),
    },
    { type: FORM_INPUT_TYPE.FILLER },
  ],
  invoiceReference: [
    {
      key: "your_reference",
      translationKey: "label_your_reference_person",
      value: billingAccountInfo?.your_reference,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
    },
    { type: FORM_INPUT_TYPE.FILLER },
    {
      key: "invoice_remark",
      translationKey: "heading_invoice_reference",
      value: billingAccountInfo?.invoice_remark,
      type: FORM_INPUT_TYPE.TEXT,
    },
    { type: FORM_INPUT_TYPE.FILLER },
  ],
  payment: [
    {
      key: "invoice_type",
      translationKey: "label_invoice_type",
      value: billingAccountInfo?.invoice_type,
      placeholderKey: "placeholder_choose_payment_option",
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      options: [
        {
          value: INVOICE_TYPE.E_INVOICE,
          label: (
            <FormattedMessage
              id={`label_${enumValueToTranslationKey(INVOICE_TYPE.E_INVOICE)}`}
            />
          ),
        },
        {
          value: INVOICE_TYPE.EMAIL_INVOICE,
          label: (
            <FormattedMessage
              id={`label_${enumValueToTranslationKey(
                INVOICE_TYPE.EMAIL_INVOICE,
              )}`}
            />
          ),
        },
      ],
      selectorField: "label",
      optionFields: {
        "E-invoice": [
          { type: FORM_INPUT_TYPE.FILLER },
          {
            key: "edi_identification",
            translationKey: "label_edi_identification",
            value: billingAccountInfo?.edi_identification,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
          {
            key: "peppol_address",
            translationKey: "label_electronic_address_in_peppol",
            value: billingAccountInfo?.peppol_address,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
          {
            key: "gln",
            translationKey: "label_gln_number",
            value: billingAccountInfo?.gln,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
          {
            key: "van_operator",
            translationKey: "label_van_operator",
            value: billingAccountInfo?.van_operator,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
        ],
        "Email invoice": [
          { type: FORM_INPUT_TYPE.FILLER },
          {
            key: "email_invoice",
            translationKey: "header_billing_email",
            value: billingAccountInfo?.email_invoice,
            type: FORM_INPUT_TYPE.TEXT,
            required: true,
          },
        ],
      },
    },
    { type: FORM_INPUT_TYPE.FILLER },
    {
      key: "fortnox_customer_id",
      translationKey: "label_fortnox_id",
      value: billingAccountInfo?.fortnox_customer_id,
      type: FORM_INPUT_TYPE.TEXT,
      isAdminField: true,
    },
  ],
});

const BillingAccountInfo = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { customer_id = "", billing_account_id = "" } = useParams();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer: { id: customerId = "" } = {}, permissions = {} } = userInfo;

  const [billingAccountInfo, setBillingAccountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [customerName, setCustomerName] = useState("");

  const shouldShowActivationToggleButton = isAdminUser()
    ? permissions["customers"] === WRITE
    : permissions["company-information"] === WRITE;
  const shouldShowDeleteButton =
    isAdminUser() && permissions["customers"] === WRITE;

  useEffect(() => {
    const fetchBillingAccountInfo = async () => {
      try {
        setIsLoading(true);

        const { data: { data: billingAccountData = {} } = {} } =
          await getBillingAccountService(billing_account_id);

        setBillingAccountInfo({
          ...billingAccountData,
          registration_nr: billingAccountData.organisation_number,
        });
      } catch (error) {
        setError(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCustomerName = async () => {
      try {
        setIsLoading(true);

        const { data: { data: { customer_name: name = "" } = {} } = {} } =
          await customerDetailsService(customer_id);

        setCustomerName(name);
      } catch (error) {
        setError(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingAccountInfo();

    if (isAdminUser()) {
      fetchCustomerName();
    }
  }, [billing_account_id, messages.exception_error_message, customer_id]);

  const adminBreadCrumbItems = [
    {
      label: messages.title_customers,
      url: ROUTES.ADMIN_CUSTOMERS.URL,
    },
    {
      label: customerName,
    },
    {
      label: messages.title_billing,
      url: `${ROUTES.ADMIN_CUSTOMERS.URL}/${customer_id}/${ADMIN_BILLING_ACCOUNTS}`,
    },
    {
      label: billingAccountInfo?.name,
    },
  ];

  const userBreadCrumbItems = [
    {
      label: messages.organisation,
      url: ROUTES.COMPANY_INFO.URL,
    },
    {
      label: messages.title_billing,
      url: ROUTES.BILLING_ACCOUNTS_LIST.URL,
    },
    {
      label: billingAccountInfo?.name,
    },
  ];

  const breadcrumbItems = isAdminUser()
    ? adminBreadCrumbItems
    : userBreadCrumbItems;

  const { billingInfo, invoiceReference, payment } =
    getConfig(billingAccountInfo);

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);

      const payload = {
        ...billingAccountInfo,
        ...values,
        id: billing_account_id,
        customer_id: isAdminUser() ? customer_id : customerId,
        organisation_number:
          values.registration_nr || billingAccountInfo.registration_nr,
      };

      if (payload.country_name) {
        const countryCode = getCountryCode(payload.country_name);

        payload.country_code = countryCode;
      }

      const { data: { data: updatedBillingAccountData = {} } = {} } =
        await updateBillingAccountService(payload);

      setBillingAccountInfo({
        ...updatedBillingAccountData,
        registration_nr: updatedBillingAccountData.organisation_number,
      });

      setSuccessMessage(messages.label_billing_account_updated_successfully);
    } catch (error) {
      const { response: { data: { errors } = {} } = {} } = error;

      const errorString = Object.keys(errors).reduce(
        (accumulator, currentKey) =>
          accumulator + messages[`validation_enter_${currentKey}`] + ". ",
        "",
      );

      setError(errorString || messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseErrorModal = () => {
    setError("");
  };

  const handleCloseSuccessModal = () => {
    setSuccessMessage("");
  };

  const handleToggleActiveStatus = async () => {
    try {
      setIsLoading(true);

      const { data: { data: updatedBillingAccountData = {} } = {} } =
        await patchAccountStatus({
          billing_account_id,
          active: Number(!billingAccountInfo?.active),
        });

      setBillingAccountInfo({
        ...updatedBillingAccountData,
        registration_nr: updatedBillingAccountData.organisation_number,
      });

      setSuccessMessage(
        updatedBillingAccountData.active
          ? messages.label_billing_account_activated_successfully
          : messages.label_billing_account_deactivated_successfully,
      );
    } catch (error) {
      setError(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteBillingAccount = async () => {
    try {
      setIsLoading(true);

      await deleteBillingAccountService(billing_account_id);

      setIsLoading(false);

      history.goBack();
    } catch (error) {
      setError(messages.exception_error_message);
      setIsLoading(false);
    }
  };

  const WrapperComponent = isAdminUser() ? AdminContainer : UserContainer;

  const containerConfig = {
    title: billingAccountInfo?.name,
  };

  return (
    <WrapperComponent config={containerConfig}>
      <Breadcrumb items={breadcrumbItems} px={0} pt={0} />

      {isLoading && <ProgressSpinner />}

      {error && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleCloseErrorModal}
        >
          <ErrorModal
            content={error}
            handleConfirmPopup={handleCloseErrorModal}
          />
        </Dialog>
      )}

      {successMessage && (
        <SuccessDialog
          message={successMessage}
          onConfirm={handleCloseSuccessModal}
        />
      )}

      <Div>
        <EditableFieldsCard
          title={messages.heading_basic_information}
          config={billingInfo}
          onSubmit={handleSubmit}
          validationSchema={BasicInformationSchema}
          isEditPermission={shouldShowActivationToggleButton}
        />
        <EditableFieldsCard
          title={messages.heading_invoice_reference}
          config={invoiceReference}
          onSubmit={handleSubmit}
          validationSchema={InvoiceReferenceSchema}
          isEditPermission={shouldShowActivationToggleButton}
        />
        <EditableFieldsCard
          title={messages.label_billing_info_payment}
          config={payment}
          onSubmit={handleSubmit}
          validationSchema={PaymentSchema}
          isEditPermission={shouldShowActivationToggleButton}
        />

        <Div
          mt={4}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={[3, 3, 4, 4]}
        >
          {shouldShowActivationToggleButton && (
            <PrimaryButton
              label={
                billingAccountInfo?.active
                  ? messages.label_deactivate_billing_account
                  : messages.label_activate_billing_account
              }
              onClick={handleToggleActiveStatus}
            />
          )}
          {shouldShowDeleteButton && (
            <PrimaryButtonOutlined
              width={[1, 1, "fit-content", "fit-content"]}
              label={messages.label_delete_billing_account}
              onClick={handleDeleteBillingAccount}
            />
          )}
        </Div>
      </Div>
    </WrapperComponent>
  );
};

export default BillingAccountInfo;
