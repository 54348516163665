import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { saveAs } from "file-saver";
import Papa from "papaparse";

import getPromoCodesList from "@app/services/promoCodes/getPromoCodesList";
import patchPromoCode from "@app/services/promoCodes/patchPromoCode";

import DataTable from "@components/DataTableV2/DataTable";
import ErrorDialog from "@components/ErrorDialog";
import SuccessDialog from "@components/SuccessDialog";
import TabHeader from "@components/TabHeader";

import { PROMO_CODES, ROUTES, WRITE } from "@utils/constant";
import { APPLICATION_TYPE, COUPON_STATUS, DISCOUNT_TYPE } from "@utils/enum";
import { enumValueToTranslationKey } from "@utils/utils";

import QuestionDialog from "../../coupons/components/QuestionDialog";

const SEARCHABLE_COLUMNS = [
  "id",
  "code",
  "discount_type",
  "discount_value",
  "application_type",
  "valid_to",
  "active",
];

const CouponsList = () => {
  const history = useHistory();

  const { messages } = useIntl();
  const { permissions = {} } = useSelector(
    (state) => state.authReducer.userInfo,
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false);
  const [deletedCouponName, setDeletedCouponName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirectToCreateCoupon = () => {
    history.push(ROUTES.ADMIN_CREATE_COUPON.URL);
  };

  const handleRedirectToEditCoupon = (event) => {
    const { id: couponId } = event;

    history.push(ROUTES.ADMIN_EDIT_COUPON.URL.replace(":coupon_id", couponId));
  };

  const handleOpenQuestionDialog = (event) => {
    const { name: couponName } = event;

    setDeletedCouponName(couponName);
  };

  const handleDeleteCoupon = () => {
    try {
      setIsLoading(true);
      setDeletedCouponName("");
      setIsSuccessDialogVisible(true);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogVisible(false);
  };

  const handleCloseQuestionDialog = () => {
    setDeletedCouponName("");
  };

  const updateCoupon = async (id, payload) => {
    try {
      setIsLoading(true);

      await patchPromoCode(id, payload);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleCouponActivation = (event) => {
    const { active, id } = event;

    const payload = {
      active: active ? 0 : 1,
    };

    updateCoupon(id, payload);
  };

  const formatCouponsToDownloadCSV = (data) => {
    const { id, name, discount_type, discount, type, expired_at, status } =
      data;

    return {
      ID: id,
      Name: name,
      "Discount Type": discount_type,
      "Discount Value": discount,
      Type: type,
      "Expire At": expired_at,
      Status: status,
    };
  };

  const handleDownloadCoupon = async () => {
    const { data = [] } = await getPromoCodesList("?export=1");

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    saveAs(blob, "coupons.csv");
  };

  const config = {
    header: {
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          content: messages.filter_description,
        },
        {
          id: "download",
          type: "button-tooltip",
          icon: "headerarrowright",
          variant: "header",
          px: 5,
          content: messages.download_csv_file,
          formatter: formatCouponsToDownloadCSV,
          onClick: handleDownloadCoupon,
        },
      ],
    },
    row_selection_checkbox: false,
    enable_filter: true,
    enable_csv_download: true,
    filters: [
      {
        title: messages.label_discount_type,
        id: "filter[discount_type]",
        type: "checkbox",
        options: [
          {
            label: messages.label_percent,
            value: DISCOUNT_TYPE.PERCENTAGE,
            checked: false,
          },
          {
            label: messages.label_fixed_amount,
            value: DISCOUNT_TYPE.FIXED_AMOUNT,
            checked: false,
          },
        ],
      },
      {
        title: messages.type_label,
        id: "filter[application_type]",
        type: "checkbox",
        options: [
          {
            label: messages.label_order,
            value: APPLICATION_TYPE.ENTIRE_ORDER,
            checked: false,
          },
          {
            label: messages.label_item,
            value: APPLICATION_TYPE.SINGLE_ORDER_ITEM,
            checked: false,
          },
          {
            label: messages.label_all_applicable_order_items,
            value: APPLICATION_TYPE.ALL_ITEMS,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
            checked: false,
          },
          {
            label: messages.label_inactive,
            value: 0,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_expire_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_expire_date,
        options: [
          {
            label: messages.filter_date_to,
            value: "",
            id: "filter[valid_to][lt]",
          },
        ],
      },
    ],
    enable_search: true,
    backend_querying: true,
    no_records_message: messages.label_no_coupons,
    enable_query_builder: true,
    api: {
      resource: `${PROMO_CODES}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [["fields", SEARCHABLE_COLUMNS]],
    },
    columns: [
      {
        title: "label_name",
        db_field: "code",
        sortable: true,
        type: "text",
        width: "17%",
      },
      {
        title: "label_discount_type",
        db_field: "discount_type",
        type: "text",
        width: "17%",
        formatter: (value) =>
          messages[`label_${enumValueToTranslationKey(value)}`],
      },
      {
        title: "label_discount",
        db_field: "discount_value",
        type: "text",
        width: "17%",
      },
      {
        title: "type_label",
        db_field: "application_type",
        type: "text",
        width: "17%",
        formatter: (value) =>
          messages[`label_${enumValueToTranslationKey(value)}`],
      },
      {
        title: "label_expire_date",
        db_field: "valid_to",
        sortable: true,
        type: "date",
        width: "17%",
      },
      {
        title: "label_status",
        db_field: "active",
        type: "enum",
        width: "12%",
        formatter: (value) =>
          value === 1 ? COUPON_STATUS.ACTIVE : COUPON_STATUS.INACTIVE,
      },
      {
        type: "actions",
        width: "6%",
        actions: [
          {
            label: messages.label_edit_coupon,
            icon: "icon-menu-eye",
            onClick: handleRedirectToEditCoupon,
          },
          {
            formatter: ({ active }) =>
              active ? messages.label_inactivate : messages.label_activate,
            iconSelector: ({ active }) =>
              active ? "icon-rubber" : "icon-restart",
            onClick: handleToggleCouponActivation,
          },
          {
            label: messages.label_delete,
            icon: "icon-menu-trash",
            onClick: handleOpenQuestionDialog,
          },
        ],
      },
    ],
  };

  const tabHeader = {
    title: messages.title_coupons,
    actions: [
      {
        type: "button",
        label: messages.button_add_new,
        icon: "plus",
        id: "create-coupon",
        onClick: handleRedirectToCreateCoupon,
        isHidden: permissions?.["products"] !== WRITE,
      },
    ],
  };

  return (
    <>
      <TabHeader {...tabHeader} />
      <DataTable config={config} resetData={!isLoading} />

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onConfirm={handleCloseErrorDialog}
          onHide={handleCloseErrorDialog}
        />
      )}

      {deletedCouponName && (
        <QuestionDialog
          couponName={deletedCouponName}
          onCancel={handleCloseQuestionDialog}
          onConfirm={handleDeleteCoupon}
        />
      )}

      {isSuccessDialogVisible && (
        <SuccessDialog
          message={messages.message_coupon_deleted}
          onConfirm={handleCloseSuccessDialog}
        />
      )}
    </>
  );
};

export default CouponsList;
