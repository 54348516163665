const host =
  "https://api.beleven-development-cjnjgwy-tbtwobhlrthdi.eu-5.platformsh.site/";
const wpHost = "https://stage.bakgrundsanalys.se";

export default {
  domain: host,
  BASE_URL: `${host}api/v1/`,
  baseName: "/",
  language: "sv",
  mobileBreakPoint: "desktopSmall",
  WP_URL: wpHost,
  markerDestinationId: "6516b46f0ed8ef89c0dce485",
  APP_URL:
    "https://beleven-development-cjnjgwy-tbtwobhlrthdi.eu-5.platformsh.site/",
};
