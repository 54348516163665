import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { default as Div } from "@components/Div";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";
import { MyProfileButton, PrimaryButtonOutlined } from "@components/Button";
import Icon from "@components/Icon";
import Link from "@components/Link";
import Sidebar from "@components/Sidebar";

import useHistory from "@utils/useHistory";
import { H3 } from "@components/Heading";
import { LOGIN, BOOK_DEMO } from "@utils/constant";
import { isAuthenticated, getRedirectPage } from "@utils/utils";
import Config from "@src/config";

import SideMenuContent from "./SideMenuContent";
import SideMenu from "./SideMenu";

const BookDemoButton = styled(PrimaryButtonOutlined)`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 118px;
  border-color: var(--yellow);
  &.p-button-rounded {
    color: var(--blue-dark);
    border: 2px solid var(--yellow) !important;
    .p-button-label {
      color: var(--yellow);
      font-size: var(--fs-text-m);
      line-height: 22px;
      font-weight: var(--semibold-weight);
    }
    &:not(a):not(.p-disabled):hover {
      background-color: var(--yellow) !important;
      border: 2px solid var(--yellow);
      .p-button-label {
        color: var(--grey-dark) !important;
      }
    }
  }
`;

const UserHeader = (props) => {
  const { navItems = [], sideMenuNavItems } = props;
  const history = useHistory();
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { name } = userInfo;
  const isAuthenticatedFlag = isAuthenticated();

  const redirectToLogin = () => {
    localStorage.clear();
    history.push(LOGIN);
  };

  const MyProfileButtonProps = {
    label: isAuthenticatedFlag ? messages.label_log_out : messages.login,
    onClick: redirectToLogin,
    width: "auto",
  };

  const handleRedirectToWPpage = (url) => {
    window.location = url;
  };

  const handleRedirection = () => {
    const redirectPage = getRedirectPage(userInfo);
    return history.push(redirectPage);
  };

  const getBookDemoButton = () => {
    return (
      <Div
        alignItems={"center"}
        display="flex"
        mb={[3, 3, 3, 0]}
        justifyContent="center"
      >
        <BookDemoButton
          px="20px"
          label={messages.book_demo}
          onClick={handleRedirectToWPpage.bind(this, BOOK_DEMO)}
        />
      </Div>
    );
  };

  const getUserName = () => {
    return (
      <Div
        display={"flex"}
        justifyContent="center"
        p={"15px 20px"}
        alignItems="center"
        backgroundColor={"rgba(var(--grey-lightest-rgba), .3)"}
      >
        <Link handleClick={handleRedirection}>
          <Icon mr={1} color="var(--blue-medium) !important" name="avatar" />
          {name}
        </Link>
      </Div>
    );
  };

  const WebSideBar = () => {
    const [visibleFullScreen, setVisibleFullScreen] = useState(false);
    const navProps = {
      navItems: navItems,
    };
    const sidemenuNavProps = {
      navItems: sideMenuNavItems,
    };
    return (
      <>
        <Link
          label={messages.header_menu}
          onClick={() => setVisibleFullScreen(true)}
          darkblue
          variant="primary"
          flexDirection={"row-reverse !important"}
          display={["flex", "flex", "flex", "none"]}
        >
          <Icon name="hamburger" pr={2} />
        </Link>
        <Sidebar
          visible={visibleFullScreen}
          fullScreen
          icons={
            <H3 mx={"auto"} pl={30}>
              {messages.header_menu}
            </H3>
          }
          onHide={() => setVisibleFullScreen(false)}
        >
          <Div
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {isAuthenticatedFlag ? (
              <>
                <Div>
                  <SideMenuContent {...navProps} />
                  {getUserName()}
                  <SideMenuContent {...sidemenuNavProps} />
                </Div>
              </>
            ) : (
              <>
                <Div>
                  <SideMenuContent {...navProps} />
                  {getBookDemoButton()}
                </Div>
              </>
            )}

            <Div>
              <Div
                display="flex"
                py={3}
                borderTop="1px solid var(--turquoise-light)"
              >
                {isAuthenticatedFlag ? (
                  <>
                    <Div
                      width={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MyProfileButton {...MyProfileButtonProps} px={2} />
                      <Icon
                        name="headerarrowright"
                        color="var(--turquoise)"
                        fontSize="var(--fs-h4)"
                      />
                    </Div>
                  </>
                ) : (
                  <>
                    <Div width={1 / 1} textAlign={"center"} pr={3}>
                      <MyProfileButton {...MyProfileButtonProps} />
                    </Div>
                  </>
                )}
              </Div>
              <Div
                display="flex"
                justifyContent="center"
                backgroundColor={"rgba(var(--grey-lightest-rgba), .3)"}
                p={"15px 20px"}
              >
                <Icon pr={13} name="call" color="var(--turquoise)" />
                <Link
                  label={messages.phone}
                  fontSize="var(--fs-h3)"
                  fontWeight="var(--semibold-weight)"
                />
              </Div>
            </Div>
          </Div>
        </Sidebar>
      </>
    );
  };

  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  const start = (
    <>
      <Logo
        logo={LogoFull}
        width="auto"
        maxHeight="60px"
        onClick={handleRedirect}
      />
      <WebSideBar />
    </>
  );

  const end = (
    <>
      {isAuthenticatedFlag ? (
        <Div
          display={["none", "none", "flex", "flex"]}
          alignItems="center"
          pr={[0, 0, 3, 24]}
          pt={73}
          pb={38}
          whiteSpace
        >
          <Icon color="var(--blue-medium)" name="avatar" mr={1} />
          <Link handleClick={handleRedirection}>{name}</Link>
        </Div>
      ) : (
        <Div
          display={["none", "none", "flex", "flex"]}
          alignItems="center"
          pr={[0, 0, 3, 24]}
          pt={73}
          pb={38}
          whiteSpace
        >
          <MyProfileButton
            {...MyProfileButtonProps}
            display={["none", "unset"]}
          />
        </Div>
      )}

      <Div
        display={["none", "none", "flex"]}
        backgroundColor={"var(--grey-lighter)"}
        alignItems="center"
        px={[0, 0, 3, 24]}
        pt={74}
        pb={38}
        whiteSpace
      >
        <Icon pr={13} color="var(--turquoise)" name="call" />
        <Link label={messages.phone} />
      </Div>
    </>
  );
  const bookDemo = [
    {
      label: <BookDemoButton label={messages.book_demo} />,
      url: BOOK_DEMO,
    },
  ];
  let headerItems = [...navItems];

  if (!isAuthenticatedFlag) {
    headerItems = [...navItems, ...bookDemo];
  }
  return (
    <SideMenu model={headerItems} start={start} end={end} width={[1, 1, 1]} />
  );
};

UserHeader.propTypes = {
  config: PropTypes.object,
  navItems: PropTypes.array,
  sideMenuNavItems: PropTypes.array,
};

export default UserHeader;
