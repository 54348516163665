import React from "react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

/**
 * Subscription fields validation
 */
const SubscriptionValidation = Yup.object().shape({
  customer_id: Yup.object().required(
    <FormattedMessage
      id="error_select_customer"
      defaultMessage="Select Customer"
    />,
  ),
  user_id: Yup.number().required(
    <FormattedMessage
      id="error_select_sub_user"
      defaultMessage="Select User"
    />,
  ),
});

export default SubscriptionValidation;
