import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Tooltip as PrimeTooltip } from "primereact/tooltip";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  color,
} from "styled-system";

const tooltipStyles = css`
  background-color: var(--white);
  color: var(--grey-dark);
  font-size: var(--fs-h5);
  line-height: var(--lh-text-m);
  font-weight: var(--medium-weight);
  max-width: 651px;
  padding: 20px;
  border-radius: 10px;
  strong {
    font-weight: var(--semibold-weight);
  }
  em {
    font-style: italic;
  }
  li {
    margin-left: 24px;
  }
  ul {
    list-style: disc;
  }
`;

const ToolTip = styled(PrimeTooltip)`
  ${compose(layout, space, flexbox, position, typography, color)};
  .p-tooltip {
    ${tooltipStyles}
  }

  .p-tooltip-text {
    ${tooltipStyles}
  }
`;

const Tooltip = ({ content, ...props }) => {
  return (
    <ToolTip {...props}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ToolTip>
  );
};

Tooltip.propTypes = {
  content: PropTypes.node,
};

export default Tooltip;
