import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getProductById from "@app/services/services/getProductById";
import { ROUTES } from "@utils/constant";
import { useIntl } from "react-intl";
import { H1 } from "@components/Heading";
import Breadcrumb from "@components/Breadcrumb";
import Div from "@components/Div";
import Form from "./Form";
import { TextUpperCaseSemiBold, Text } from "@components/Text";
import updateProductById from "@app/services/services/updateProductById";
import ProgressSpinner from "@components/ProgressSpinner";

const UpdateSubscription = () => {
  const { id: productId = "" } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { messages } = useIntl();
  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: data?.name || "",
    },
  ];

  const formatAndSetData = (responseData) => {
    const { name, price, description, credits, type, id, state, discount } =
      responseData;
    setData({
      id,
      name,
      price,
      description,
      credits,
      type,
      state,
      discount,
    });
  };

  const handleUpdateSubscription = async (payload) => {
    setIsLoading(true);
    try {
      const { data: response = {} } = await updateProductById({
        ...payload,
      });
      formatAndSetData(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);
      try {
        const response = await getProductById(productId);
        const {
          data: { data: respData = [] },
        } = response;
        const [values = {}] = respData;
        formatAndSetData(values);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };
    fetchProduct();
  }, [productId]);

  return (
    <>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <Div px={2} py={3}>
          <Breadcrumb items={breadCrumbItems} p={0} pb={[2, 2, 3, 3]} />
          <Div
            width={[1, 1, 1, 800]}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <H1>{data?.name}</H1>
            <Div display="flex" mt={3} justifyContent="space-between" width={1}>
              <Div width={[1, 1, 1 / 2, 1 / 2]} mb={3}>
                <TextUpperCaseSemiBold>
                  {messages.label_id}:
                </TextUpperCaseSemiBold>
                &nbsp;
                <Text>{data?.id}</Text>
              </Div>
              <Div width={[1, 1, 1 / 2, 1 / 2]} mb={3}>
                <TextUpperCaseSemiBold>
                  {messages.label_product_type}:
                </TextUpperCaseSemiBold>
                &nbsp;
                <Text>{messages.subscription}</Text>
              </Div>
            </Div>
            <Form
              onSubmit={handleUpdateSubscription}
              data={data}
              isEditSubscription
            />
          </Div>
        </Div>
      )}
    </>
  );
};

export default UpdateSubscription;
