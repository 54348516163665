import React from "react";
import Div from "@components/Div";
import { useIntl } from "react-intl";
import { Text, TextSemiBoldWeight } from "@components/Text";
import PropTypes from "prop-types";
import { CloseButton } from "@components/Button";
import { addHyphenToNumber } from "@utils/utils";

const Header = ({
  name = "",
  street = "",
  zipcode = "",
  city = "",
  ssn = "",
  onRemoveObject,
  index = -1,
}) => {
  const { messages } = useIntl();
  const NoAddress = street === null || city === null || zipcode === null;

  return (
    <>
      <CloseButton
        display={["none", "none", "flex", "flex"]}
        icon="pi pi-times"
        severity="secondary"
        onClick={onRemoveObject.bind(this, index)}
        rounded
        text
        position={"absolute"}
        top={0}
        right={0}
        m={"10px"}
        alignItems={"center"}
        justifyContent={"center"}
      />
      <CloseButton
        display={["flex", "flex", "none", "none"]}
        ml={3}
        icon="pi pi-times"
        severity="secondary"
        onClick={onRemoveObject.bind(this, index)}
        rounded
        text
        position={"absolute"}
        top={-10}
        right={0}
        m={"10px"}
        alignItems={"center"}
        justifyContent={"center"}
      />
      <Div p={0} position="relative" m={0}>
        <Div
          p={0}
          m={0}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          justifyContent="space-between"
          alignItems={["", "", "center", "center"]}
        >
          <Div
            display="flex"
            flexDirection={["row", "row", "column", "column"]}
            justifyContent={"space-between"}
            mt={3}
            alignItems="start"
          >
            <Text>{messages.label_name}</Text>
            <Div
              display="flex"
              alignItems="center"
              maxWidth={["150px", "100%"]}
            >
              <TextSemiBoldWeight mt={[0, 0, "10px", "10px"]}>
                {name}
              </TextSemiBoldWeight>
            </Div>
          </Div>
          <Div
            display="flex"
            flexDirection={["row", "row", "column", "column"]}
            justifyContent={"space-between"}
            mt={3}
            alignItems="start"
          >
            <Text>{messages.label_personal_number}</Text>
            <TextSemiBoldWeight mt={[0, 0, "10px", "10px"]}>
              {addHyphenToNumber(ssn, 8)}
            </TextSemiBoldWeight>
          </Div>
          {!NoAddress && (
            <Div
              display="flex"
              flexDirection={["row", "row", "column", "column"]}
              justifyContent={"space-between"}
              mt={3}
              alignItems="start"
            >
              <>
                <Text flex={3}>{messages.search_label_address}</Text>

                <TextSemiBoldWeight
                  display={["none", "none", "block", "block"]}
                  mt={"10px"}
                >
                  {`${street} ${zipcode} ${city}`}
                </TextSemiBoldWeight>
              </>
              <Div
                display={["flex", "flex", "none", "none"]}
                flexDirection="column"
              >
                <>
                  <TextSemiBoldWeight>{street}</TextSemiBoldWeight>
                  <TextSemiBoldWeight>{`${zipcode} ${city}`}</TextSemiBoldWeight>
                </>
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    </>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  ssn: PropTypes.string,
  onRemoveObject: PropTypes.func,
  index: PropTypes.string,
};

export default Header;
