import { Steps as PRSteps } from "primereact/steps";
import styled from "styled-components";

const Steps = styled(PRSteps)`
  padding-top: 16px;
  padding-left: 0px;

  .p-steps-item {
    &::before {
      top: 60%;
      border-color: var(--grey-lightest);
    }

    &.p-disabled {
      opacity: 1;
    }

    .p-menuitem-link {
      .p-steps-number {
        background-color: var(--blue-lightest) !important;
        border-radius: 7px !important;

        min-width: 14px !important;
        height: 14px !important;

        font-size: 8px !important;
        font-weight: var(--medium-weight) !important;
        line-height: var(--fs-grade-text) !important;

        border: 1px solid var(--grey-lightest) !important;
      }

      .p-steps-title {
        margin-top: 2px;
        font-size: var(--fs-text-s) !important;
        font-weight: var(--medium-weight) !important;
        line-height: 15px !important;

        color: var(--grey) !important;
      }
    }

    &.p-steps-current {
      .p-menuitem-link {
        .p-steps-number {
          background-color: var(--blue-dark) !important;
          color: var(--white) !important;

          border: none !important;
        }

        .p-steps-title {
          color: var(--blue-dark) !important;
        }
      }
    }
  }
`;

export default Steps;
