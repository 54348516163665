import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Div from "@components/Div";
import Footer from "@components/Footer";
import { authActions } from "@app/reducers/authReducer";
import {
  HEADER_NAV,
  MYACCOUNTS_NAV,
  INDIVIDUAL,
  COMPANY,
} from "@utils/constant";
import ScrollToTop from "@utils/ScrollToTop";
import getUserDetails from "@app/services/auth/getUserDetails";
import { isAuthenticated } from "@utils/utils";
import { ROUTES, SVLANG } from "../utils/constant";
import CandidateHeader from "@pages/candidate/CandidateHeader";

import UserHeader from "./UserHeader";

export const StyledDiv = styled(Div)`
  display: flex;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
`;

export const GuestLayout = ({ children, title }) => {
  const dispatch = useDispatch();
  const { userInfo: { customer = {}, permissions = [], id = "" } = {} } =
    useSelector((state) => state.authReducer);
  const isAuthenticatedFlag = isAuthenticated();
  const { pathname = "" } = useLocation();
  const isReportPdf = pathname === ROUTES.REPORT_PDF.URL;

  const { type: customerType = INDIVIDUAL, prepaid_account: isPrepaidUser } =
    customer;

  const myAccountsNav = MYACCOUNTS_NAV.filter((i) => {
    if (customerType === INDIVIDUAL) {
      if (isPrepaidUser === 1) {
        return i.value !== "company-information" && i.value !== "users";
      } else {
        return !(
          i.value === "subscription" ||
          i.value === "company-information" ||
          i.value === "users"
        );
      }
    }
    if (customerType === COMPANY && isPrepaidUser === 0) {
      return i.value !== "subscription";
    }
    return i;
  });

  const availablePages = Object.keys(permissions).map((obj) => obj);

  let menuItems = myAccountsNav.filter(
    (obj) => availablePages.includes(obj.value) || obj.value === "user-info",
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await getUserDetails();
        const { data: { data: user = {} } = {}, status = "" } = res;
        if (status === 401) {
          localStorage.clear();
          dispatch(authActions.storeUserInfo({ user: {}, isLogout: true }));
        } else {
          dispatch(authActions.storeUserInfo(user));
        }
      } catch (e) {
        //catch the error
      }
    };

    if (!id && isAuthenticatedFlag) {
      fetchUser();
    }
  }, [dispatch, id, isAuthenticatedFlag]);

  const props = {
    navItems: HEADER_NAV,
    sideMenuNavItems: isAuthenticatedFlag ? menuItems : [],
  };
  return (
    <Div
      display="flex"
      alignItems="center"
      flexDirection="column"
      alignContent="space-between"
      justifyContent="space-between"
      minHeight={"100vh"}
    >
      {title !== SVLANG.title_candidate && !isReportPdf && (
        <UserHeader {...props} />
      )}
      {title === SVLANG.title_candidate && <CandidateHeader />}
      <ScrollToTop>{children}</ScrollToTop>
      {!isReportPdf && <Footer />}
    </Div>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.array,
  title: PropTypes.string,
};

export default GuestLayout;
