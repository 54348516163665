import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useIntl } from "react-intl";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import { default as Accordion } from "@components/Accordion";
import { H2 } from "@components/Heading";
import PersonDetails from "./PersonDetails";
import RiskStatement from "./RiskStatement";
import Method from "./Method";
import Recommendatations from "./Recommendation";
import StatementScale from "./StatementScale";
import getResults from "@app/services/results/getResults";
import { trackPageView } from "@src/analyticsFunctions";
import { STATUS_EXPIRED, WP_REPORT_MISSING } from "@utils/constant";
import Config from "@src/config";
import Heading from "./Heading";
import ProgressSpinner from "@components/ProgressSpinner";
import getReportAnalys from "@app/services/reports/getReportDetails";
import { isCustomerUser } from "@utils/utils";
import useAnalysisReport from "@utils/useAnalysisReport";
import PropTypes from "prop-types";

const OrderResults = ({ reportId, currentReport }) => {
  const { messages } = useIntl();
  const location = useHistory();
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { search = "" } = useLocation();
  const {
    product: _product = {},
    documents = [],
    analysis_type: analysisType = "",
  } = report;
  const { grade_mapping: gradeMapping = {} } = _product;

  if (reportId === undefined) {
    const { id } = queryString.parse(search);
    reportId = id;
  }
  const isLoggedIn = localStorage.getItem("token");

  const isAssignment = analysisType === "manual";
  const assignmentReport = documents[0];
  const assignmentDetails = {
    url: assignmentReport?.url,
    productName: report?.product?.name,
  };

  const getdetails = useCallback(async () => {
    if (!reportId) return;
    try {
      const response =
        isLoggedIn && isCustomerUser()
          ? await getReportAnalys(reportId)
          : await getResults(reportId);

      const { data: { data: _report = {} } = {} } = response;
      setReport(_report);
    } catch (e) {
      location.push("/error404");
      //   throw new Error(e);
    }
  }, [reportId, isLoggedIn, location]);

  useEffect(() => {
    currentReport && setReport(currentReport);
  }, [currentReport]);

  const redirectReports = async (_search) => {
    setIsLoading(true);
    try {
      const { id: reportId = "" } = queryString.parse(_search);
      const response = await getResults(reportId);
      const { data: { data: reportStatus = {} } = {} } = response;
      const { report_status: reportStatusLabel = "" } = reportStatus;
      if (reportStatusLabel === STATUS_EXPIRED) {
        window.location = `${Config.WP_URL}/${WP_REPORT_MISSING}`;
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!search) return;
    redirectReports(search);
    trackPageView();
    getdetails();
  }, [search, getdetails]);

  const reloadDetails = () => {
    getdetails();
  };

  const Deviations = useAnalysisReport({ type: analysisType });

  const data = useMemo(
    () => [
      {
        title: (
          <H2 color="var(--blue-dark) !important">
            {messages.title_recommendations}
          </H2>
        ),
        content: <Recommendatations />,
      },
      {
        title: (
          <H2 color="var(--blue-dark) !important">{messages.title_method}</H2>
        ),
        content: <Method />,
      },
      {
        title: (
          <H2 color="var(--blue-dark) !important">
            {messages.title_statement_scale}
          </H2>
        ),
        content: <StatementScale config={gradeMapping} />,
      },
    ],
    [messages, gradeMapping],
  );

  return (
    <>
      {isLoading && (
        <Div height={400} width={1}>
          <ProgressSpinner />
        </Div>
      )}
      {isAssignment ? (
        <>{!isLoading && <Heading assignment={assignmentDetails} />}</>
      ) : (
        <>
          {!isLoading && <Heading reportId={reportId} />}
          <Section
            mx={["auto", "auto", "auto", "0%"]}
            pb={"0px !important"}
            px={"16px !important"}
          >
            <Container>
              {!isLoading && (
                <Div>
                  <PersonDetails
                    report={report}
                    config={gradeMapping?.personalDetails}
                  />
                  <RiskStatement report={report} config={gradeMapping} />
                  <Deviations report={report} reloadDetails={reloadDetails} />
                </Div>
              )}
            </Container>
          </Section>
          {!isLoading && (
            <Section
              mx={["auto", "auto", "auto", "0%"]}
              px={"16px !important"}
              pt={"0px !important"}
            >
              <Container>
                <Accordion content={data} alignArrowLeft />
              </Container>
            </Section>
          )}
        </>
      )}
    </>
  );
};

OrderResults.propTypes = {
  reportId: PropTypes.string,
  currentReport: PropTypes.object,
};

export default OrderResults;
