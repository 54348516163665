import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import DataTable from "@components/DataTableV2/DataTable";
import usePermissions from "@hooks/usePermissions";
import { USERS } from "@utils/constant";
import { PERMISSIONS } from "@utils/enum";
import { getUserRoleTranslationKey } from "@utils/utils";
const searchableColumns = [
  "id",
  "firstname",
  "lastname",
  "email",
  "customer.customer_name",
];
const AdminListTab = ({
  roleFilters,
  isFilterValuesLoaded,
  reloadList,
  onAddUser,
  onEditUser,
  onDeleteUser,
}) => {
  const { messages } = useIntl();
  const { checkForWritePermission } = usePermissions();
  const config = {
    header: {
      title: "title_admins",
      actions: [
        {
          id: "new-admin",
          type: "button",
          label: messages.label_new_user,
          width: "auto",
          icon: "plus",
          variant: "header",
          height: 40,
          px: 4,
          py: 20,
          onClick: onAddUser,
          isHidden: checkForWritePermission(PERMISSIONS.USERS),
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
        },
      ],
    },
    is_filter_options_updated: isFilterValuesLoaded,
    enable_csv_download: true,
    csv_filename: "users.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_role,
        id: "filter[roles.id]",
        type: "checkbox",
        options: roleFilters,
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_users_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    columns: [
      {
        title: "user",
        db_field: "name",
        type: "text",
        sortable: true,
        sortField: "firstname",
      },
      {
        title: "label_email",
        db_field: "email",
        type: "text",
      },
      {
        title: "label_role",
        db_field: "roles",
        type: "text",
        formatter: (data) => {
          const [values] = data;
          return values?.name
            ? messages[getUserRoleTranslationKey(values?.name)]
            : "-";
        },
      },
      {
        title: "label_phone",
        db_field: "phone",
        type: "text",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "button",
            onClick: onEditUser,
            isHidden: checkForWritePermission(PERMISSIONS.USERS),
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            onClick: onDeleteUser,
            type: "button",
            isHidden: checkForWritePermission(PERMISSIONS.USERS),
          },
        ],
      },
    ],
    api: {
      resource: `/${USERS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", ["roles", "roles.permissions", "permissions"]],
        ["filter[type]", "admin"],
      ],
    },
  };
  return <DataTable config={config} resetData={reloadList} />;
};
AdminListTab.propTypes = {
  roleFilters: PropTypes.array,
  isFilterValuesLoaded: PropTypes.bool,
  reloadList: PropTypes.bool,
  onAddUser: PropTypes.func,
  onEditUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
};
export default AdminListTab;
