import Axios from "@app/api/axios";
import { WATCH_LISTS, ORDERS_API, CREDITS_PAYMENT } from "@utils/constant";

const getWatchListOrders = async (
  screenName,
  payload,
  customerId,
  userInfo,
  watchListId,
) => {
  const getWatchListOrders = (responseData) => {
    const watchListOrders = responseData.map((order) => {
      const payment_type =
        order?.payments && order.payments[0]?.payable_type
          ? order.payments[0]?.payable_type
          : "";
      const price =
        payment_type === CREDITS_PAYMENT
          ? order.total_credits
          : order.total_price;
      const sub_user = order.user.name;
      const object = order.order_items.length;
      return { ...order, price, sub_user, object, payment_type };
    });
    return watchListOrders;
  };

  try {
    const response = await Axios.get(
      `${WATCH_LISTS}/${watchListId}/${ORDERS_API}${payload}`,
    );
    const { status } = response;
    const { data, meta } = response.data;
    return { meta, status, data: getWatchListOrders(data) };
  } catch (error) {
    // Handle the error
    return error.response;
  }
};

export default getWatchListOrders;
