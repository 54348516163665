import React, { useEffect } from "react";
import Proptypes from "prop-types";
import {
  BAR,
  BOTTOM,
  SLIDE,
  BOX,
  ACCEPT_ALL,
  ACCEPT_NECESSARY,
} from "@utils/constant";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/dist/cookieconsent.js";

export const CookieConsent = ({ config }) => {
  useEffect(() => {
    const cc = window.initCookieConsent();

    cc.run({
      current_lang: "sv",
      onAccept: function (cookie) {
        localStorage.setItem("cookieConsent", JSON.stringify(cookie.level));
      },
      onFirstAction: function () {
        window.location.reload();
      },
      gui_options: {
        consent_modal: {
          layout: BAR, // box/cloud/bar
          position: BOTTOM, // bottom/middle/top + left/right/center
          transition: SLIDE, // zoom/slide
          swap_buttons: true, // enable to invert buttons
        },
        settings_modal: {
          layout: BOX, // box/bar
          // position: 'left',           // left/right
          transition: SLIDE, // zoom/slide
        },
      },
      languages: {
        sv: {
          consent_modal: {
            // title: "",
            description: config.description,
            primary_btn: {
              text: config.primary_btn_text,
              role: ACCEPT_ALL, //'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: config.secondary_btn_text,
              role: ACCEPT_NECESSARY, //'settings' or 'accept_necessary'
            },
          },
          settings_modal: config.settings_modal,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

CookieConsent.propTypes = {
  config: Proptypes.object,
};

export default CookieConsent;
