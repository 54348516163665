import { useEffect, useRef, useState } from "react";

import bankIDAuthService from "@app/services/bank-id/bankIDAuth";
import bankIDQRCodeService from "@app/services/bank-id/bankIDQRCode";
import bankIDCollectService from "@app/services/bank-id/bankIDCollect";
import { BANKID_UNIVERSAL_LINK } from "@utils/constant";

const TIMEOUT = 30;
const INITIAL_FEEDBACK = {
  messageTerm: "",
  status: "",
  personalNumber: "",
};

const redirectToBankIdApp = (autostartToken) => {
  setTimeout(() => {
    window.open(
      `${BANKID_UNIVERSAL_LINK}?autostarttoken=${autostartToken}&redirect=null`,
    );
  }, 0);
};

const clearIntervalRef = ({ qrCode, collect }) => {
  clearInterval(qrCode);
  clearInterval(collect);
};

export const useBankIDLogin = (handleError = () => {}) => {
  const [orderRef, setOrderRef] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [bankIdFeedback, setBankIdFeedback] = useState(INITIAL_FEEDBACK);
  const [isUseQRCode, setIsUseQRCode] = useState(false);

  const seconds = useRef(0);
  const intervalRef = useRef({});

  const fetchOrderRef = async () => {
    const response = await bankIDAuthService();
    return response.data;
  };

  const onUseQrCode = async () => {
    try {
      const { qr_code = "", order_reference = "" } = await fetchOrderRef();
      setOrderRef(order_reference);
      setQRCodeData(qr_code);
      setIsUseQRCode(true);
    } catch (error) {
      handleError?.(error.response?.data);
    }
  };

  const onUseBankIdApp = async () => {
    try {
      const { order_reference = "", autostart_token = "" } =
        await fetchOrderRef();
      setOrderRef(order_reference);
      setIsUseQRCode(false);
      redirectToBankIdApp(autostart_token);
    } catch (error) {
      handleError?.(error.response?.data);
    }
  };

  const onCancelBankId = () => {
    clearIntervalRef(intervalRef.current);
    setQRCodeData(null);
    setOrderRef(null);
  };

  useEffect(() => {
    if (!orderRef) {
      return;
    }

    try {
      if (isUseQRCode) {
        const fetchQrCodeData = async () => {
          const response = await bankIDQRCodeService({
            order_reference: orderRef,
          });

          setQRCodeData(response.data?.qr_code);
        };

        intervalRef.current.qrCode = setInterval(() => {
          seconds.current += 1;

          if (seconds.current >= TIMEOUT) {
            clearIntervalRef(intervalRef.current);
            setQRCodeData(null);
            setOrderRef(null);
          }

          fetchQrCodeData();
        }, [1000]);
      }

      const fetchCollect = async () => {
        const response = await bankIDCollectService({
          order_reference: orderRef,
        });

        if (response.data?.status !== "pending") {
          clearIntervalRef(intervalRef.current);
        }

        setBankIdFeedback({
          messageTerm: response.data?.message_term,
          status: response.data?.status,
          personalNumber: response.data?.ssn,
          orderRef: orderRef,
        });
      };

      intervalRef.current.collect = setInterval(() => {
        fetchCollect();
      }, [2000]);

      const { qrCode, collect } = intervalRef.current;
      return () => {
        clearInterval(qrCode);
        clearInterval(collect);
      };
    } catch (error) {
      handleError?.(error.response?.data);
    }
  }, [orderRef, handleError, isUseQRCode]);

  return {
    qrCodeData,
    bankIdFeedback,
    redirectToBankIdApp,
    onUseQrCode,
    onUseBankIdApp,
    onCancelBankId,
  };
};
