import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import { PrimaryButtonIcon } from "@components/Button";
import Icon from "@components/Icon";
import { USERS, WRITE, CUSTOMER_USER } from "@utils/constant";
import { getUserRoleTranslationKey } from "@utils/utils";
import getRolesService from "@app/services/users/getRolesService";

const searchableColumns = ["id", "firstname", "lastname", "email"];

const Users = ({ onInvite, onEdit, onDelete, customerId }) => {
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;
  const [roleFilters, setRoleFilters] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await getRolesService(CUSTOMER_USER);
        setRoleFilters(
          res.map((o) => ({
            label: messages[getUserRoleTranslationKey(o.value)],
            value: o.id,
            checked: false,
          })),
        );
        setIsFilterValuesLoaded(true);
      } catch (e) {
        throw new Error(e);
      }
    };
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataTableConfig = {
    header: {
      title: "title_users",
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_role,
        id: "filter[roles.id]",
        type: "checkbox",
        options: roleFilters,
      },
    ],
    is_filter_options_updated: isFilterValuesLoaded,
    backend_querying: true,
    no_records_message: "datatable_admin_customer_users_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: `users.csv`,
    api: {
      resource: `/${USERS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", ["roles", "roles.permissions", "permissions"]],
        ["filter[customer.id]", customerId],
      ],
    },
    columns: [
      {
        title: "label_name",
        db_field: "name",
        type: "text",
        sortable: true,
        sortField: "firstname",
      },
      {
        title: "label_email",
        db_field: "email",
        type: "text",
      },
      {
        title: "label_role",
        db_field: "roles",
        type: "text",
        formatter: (data) => {
          const [values] = data;
          return values?.name
            ? messages[getUserRoleTranslationKey(values?.name)]
            : "-";
        },
      },
      {
        title: "label_rights",
        db_field: "roleName",
        type: "text",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "button",
            onClick: onEdit,
            isHidden: permissions && permissions.users !== WRITE,
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            onClick: onDelete,
            type: "button",
            isHidden: permissions && permissions.users !== WRITE,
          },
        ],
      },
    ],
  };

  return (
    <Container m={"0px !important"}>
      <DataTable config={dataTableConfig} />
      {!!permissions && permissions.users === WRITE && (
        <PrimaryButtonIcon
          rounded
          semibold
          minWidth={199}
          my={30}
          width={[1, 1, 1, "auto"]}
          height={40}
          icon={<Icon name="send" mr={2} />}
          label={messages.label_invite_sub_user}
          onClick={onInvite}
        />
      )}
    </Container>
  );
};

Users.propTypes = {
  onInvite: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  customerId: PropTypes.string,
};
export default Users;
