/* eslint-disable no-unused-vars */
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { H2 } from "@components/Heading";
import { TextLarge, TextLargeSemiBoldWeight } from "@components/Text";
import Div from "@components/Div";

const getGrades = (item) => {
  const g = Number(item);
  if (g === 0) {
    return "0";
  }
  if (g < 0) {
    return `${item}`;
  }
  return `+${g}`;
};

const RiskStatement = ({ report, isPrintable = false, config = {} }) => {
  const { messages } = useIntl();
  const { grades: _grades = "", name = "" } = report || {};
  const grades = getGrades(_grades || "");

  const { risk = {}, grade = [] } = config || {};
  const { sectionTitle: riskSectionTitle, sectionFooter: riskSectionFooter } =
    risk;

  const gradeDetails = grade.find(
    ({ grades: grade = "" }) => grade === _grades,
  );

  const {
    statement = [],
    color = "yellow",
    bgColor = "yellow-medium",
  } = gradeDetails || {};

  const constructContent = (arr) =>
    arr.map((value, index) => (
      <TextLargeSemiBoldWeight
        key={index}
        px={[20, 20, 50]}
        py={isPrintable ? 12 : [20, 20, 20]}
        mr={isPrintable ? 17 : 4}
        mb={isPrintable ? 0 : [3, 3, 3, 0]}
        backgroundColor={`var(--${bgColor})`}
        color={`var(--${color}) !important`}
        alignItems="center"
        fontSize={isPrintable && "var(--fs-grade) !important"}
        lineHeight={isPrintable && "150% !important"}
      >
        {value === "grade" ? `${messages[value]} ${grades}` : messages[value]}
      </TextLargeSemiBoldWeight>
    ));

  return (
    <Div>
      <H2
        color="var(--blue-dark) !important"
        mt={isPrintable ? 3 : 4}
        mb={isPrintable ? 2 : 3}
        fontSize={isPrintable && "var(--fs-h4) !important"}
        lineHeight={isPrintable && "var(--fs-text) !important"}
      >
        {messages[riskSectionTitle]}
      </H2>
      <Div display="flex" mb={isPrintable ? 10 : 3}>
        <TextLarge
          lineHeight={isPrintable && "150% !important"}
          fontSize={isPrintable && "var(--fs-text-s) !important"}
        >
          {gradeDetails?.riskStatement && (
            <FormattedMessage
              id={gradeDetails?.riskStatement}
              values={{
                name: name,
              }}
            />
          )}
        </TextLarge>
      </Div>

      <Div display={isPrintable ? "flex" : ["none", "none", "none", "flex"]}>
        {constructContent(statement)}
      </Div>
      <Div
        display={isPrintable ? "none" : ["flex", "flex", "flex", "none"]}
        flexDirection={"row"}
      >
        <TextLargeSemiBoldWeight
          px={[30, 20, 50]}
          py={[20, 20, 20]}
          mr={4}
          mb={[3, 3, 3, 0]}
          backgroundColor={`var(--${bgColor})`}
          color={`var(--${color}) !important`}
          minWidth={[140, "auto"]}
          maxHeight={70}
          width={[1 / 2, 1 / 2, 1 / 2, "auto"]}
        >
          {`${messages[statement[0]]} ${grades}`}
        </TextLargeSemiBoldWeight>
        <TextLargeSemiBoldWeight
          px={[30, 20, 50]}
          py={[1, 1, 1, 1]}
          mr={[0, 0, 0, 4]}
          mb={[3, 3, 3, 0]}
          display="flex"
          alignItems="center"
          backgroundColor={`var(--${bgColor})`}
          minWidth={[140, "auto"]}
          maxHeight={70}
          color={`var(--${color}) !important`}
          width={[1 / 2, 1 / 2, 1 / 2, "auto"]}
        >
          {messages[statement[1]]}
        </TextLargeSemiBoldWeight>
      </Div>
      <Div display={isPrintable ? "none" : ["block", "block", "block", "none"]}>
        <TextLargeSemiBoldWeight
          display="block"
          px={[10, 20, 50]}
          py={[20, 20, 20]}
          mr={0}
          mb={[3, 3, 0]}
          backgroundColor={`var(--${bgColor})`}
          color={`var(--${color}) !important`}
          textAlign="center"
        >
          {messages[statement[2]]}
        </TextLargeSemiBoldWeight>
      </Div>
      <TextLarge
        display="block"
        my={isPrintable ? 10 : 4}
        lineHeight={isPrintable && "150% !important"}
        fontSize={isPrintable && "var(--fs-text-s) !important"}
      >
        {messages[riskSectionFooter]}
      </TextLarge>
    </Div>
  );
};

RiskStatement.propTypes = {
  report: PropTypes.object,
  isPrintable: PropTypes.bool,
  config: PropTypes.object.isRequired,
};

export default RiskStatement;
