import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { default as Div } from "@components/Div";
import Icon from "@components/Icon";
import Link from "@components/Link";
import Sidebar from "@components/Sidebar";
import { H3 } from "@components/Heading";
import { LOGIN, BOOK_DEMO } from "@utils/constant";
import { Text } from "@components/Text";
import Skeleton from "@components/Skeleton";

import { isAuthenticated, getRedirectPage } from "@utils/utils";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";
import { HEADER_NAV_MAIN, MYACCOUNTS_NAV, HEADER_NAV } from "../utils/constant";
import { Menu as ModelMenubar } from "primereact/menu";
import { Menubar as PRStyledMenubar } from "primereact/menubar";
import { compose, space, flexbox, layout } from "styled-system";
import styled from "styled-components";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";
import useHistory from "@utils/useHistory";
import logoutService from "@app/services/auth/logoutService";
import { authActions } from "@app/reducers/authReducer";
import {
  ButtonLinkIcon,
  ButtonRaisedIcon,
  MyProfileButton,
  PrimaryButtonOutlined,
} from "@components/Button";
import SearchBar from "@components/DataTable/Utils/SearchBar";
import Multiselect from "@components/DataTable/Utils/Multiselect";
import UtilCalendar from "@components/DataTable/Utils/Calendar";
import CheckBoxes from "@components/DataTable/Utils/Checkbox";
import SideMenuContent from "./SideMenuContent";

const mobileBreakPoint = Config.mobileBreakPoint;
const MenuBar = styled(PRStyledMenubar)`
  position: sticky;
  max-height: 80px;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  border: none;
  .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: none;
  }
  ${compose(space, flexbox, layout)};
  .p-menubar-root-list {
    width: -webkit-fill-available;
    .p-menuitem {
      .p-menuitem-link {
        padding: 0px 15px;
      }
    }
  }
  .p-menubar-button {
    display: none;
  }
  .p-menubar-start {
    white-space: nowrap;
  }
  .p-menubar-end {
    height: 60px;
    display: inline-flex;
    cursor: pointer;
    margin-right: 80px;
    & > div:first-child {
      display: flex;
      flex-direction: row;
      &:hover {
        border-bottom: 4px solid var(--blue-medium); /* Background color on hover */
      }
    }
  }
  &.p-menubar.p-component {
    padding: 0px 70px 0px 40px;
    background: var(--white);
    width: 1320px;
    .p-menubar-root-list {
      padding-left: 20px;
      padding-top: 5px;
      .p-menuitem {
        &:last-child {
          .p-menuitem-link {
            &:hover {
              background: var(--transparent);
            }
            .p-menuitem-text {
              &:hover {
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
        .p-menuitem-link {
          background: transparent;
          transition: none !important;
          &:hover {
            background: var(--white);
          }
          .p-menuitem-text {
            padding-bottom: 30px;
            border-bottom: 5px solid transparent;
            color: var(--grey-dark) !important;
            font-size: var(--fs-text-m);
            line-height: 22px;
            font-weight: var(--medium-weight) !important;
            white-space: nowrap;
            &:hover {
              border-bottom: 5px solid var(--blue-medium);
              color: var(--blue-medium) !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    min-height: 83px;

    .p-menubar-button,
    .p-menubar-end {
      display: none !important;
    }
    .p-menubar-start {
      width: 100%;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
    }
    &.p-menubar.p-component {
      padding: 16px 23px 22px;
      width: 100%;
      .p-menubar-root-list {
        display: none !important;
      }
    }
  }
`;
const Menu = styled(ModelMenubar)`
  ${compose(space, flexbox, layout)};
  top: 60px !important;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  width: 230px;
  text: center;
  position: fixed;
  .p-menuitem-link {
    &:focus {
      box-shadow: none;
    }
    width: 230px;
    text-align: left;
  }
  .p-menuitem-text {
    color: var(--grey-dark) !important;
    font-size: var(--fs-text-m);
    line-height: 22px;
    font-weight: var(--medium-weight) !important;
    width: 100%;
  }
`;
const ButtonRaisedIconCustom = styled(ButtonRaisedIcon)`
  background-color: inherit !important;
  box-shadow: none !important;
  padding: 8px 16px 8px 0;
  margin: 0;
  color: var(--grey-dark) !important;
  i {
    font-weight: var(--semibold-weight);
    color: var(--turquoise);
    margin-bottom: 2px;
  }
`;

const TransparentButtonCustom = styled(ButtonRaisedIcon)`
  background-color: none;
  text-transform: uppercase;
  font-weight: var(--medium-weight) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border: none;
  padding: 8px 16px;
  .p-button-label {
    flex: none;
  }
  i {
    color: var(--blue-dark);
    font-weight: var(--medium-weight);
    font-size: var(--fs-icon-l);
    &:hover {
      color: var(--blue-dark);
    }
  }
`;

const BookDemoButton = styled(PrimaryButtonOutlined)`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 118px;
  border-color: var(--yellow);
  &.p-button-rounded {
    color: var(--blue-dark);
    border: 2px solid var(--yellow) !important;
    .p-button-label {
      color: var(--yellow);
      font-size: var(--fs-text-m);
      line-height: 22px;
      font-weight: var(--semibold-weight);
    }
    &:not(a):not(.p-disabled):hover {
      background-color: var(--yellow) !important;
      border: 2px solid var(--yellow);
      .p-button-label {
        color: var(--grey-dark) !important;
      }
    }
  }
`;

const WebSideBar = () => {
  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const isAuthenticatedFlag = isAuthenticated();
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { name } = userInfo;
  const history = useHistory();

  const navProps = {
    navItems: HEADER_NAV,
  };
  const sidemenuNavProps = {
    navItems: MYACCOUNTS_NAV,
  };
  const handleRedirectToWPpage = (url) => {
    window.location = url;
  };

  const handleRedirection = () => {
    const redirectPage = getRedirectPage(userInfo);
    return history.push(redirectPage);
  };
  const redirectToLogin = () => {
    localStorage.clear();
    history.push(LOGIN);
  };
  const MyProfileButtonProps = {
    label: isAuthenticatedFlag ? messages.label_log_out : messages.login,
    onClick: redirectToLogin,
    width: "auto",
  };

  const getBookDemoButton = () => {
    return (
      <Div
        alignItems={"center"}
        display="flex"
        mb={[3, 3, 3, 0]}
        justifyContent="center"
      >
        <BookDemoButton
          px="20px"
          label={messages.book_demo}
          onClick={handleRedirectToWPpage.bind(this, BOOK_DEMO)}
        />
      </Div>
    );
  };
  const getUserName = () => {
    return (
      <Div
        display={"flex"}
        justifyContent="center"
        p={"15px 20px"}
        alignItems="center"
        backgroundColor={"rgba(var(--grey-lightest-rgba), .3)"}
      >
        <Link handleClick={handleRedirection}>
          <Icon mr={1} color="var(--blue-medium) !important" name="avatar" />
          {name}
        </Link>
      </Div>
    );
  };
  return (
    <>
      <Link
        label={messages.header_menu}
        onClick={() => setVisibleFullScreen(true)}
        darkblue
        variant="primary"
        flexDirection={"row-reverse !important"}
        display={["flex", "flex", "flex", "none"]}
      >
        <Icon name="hamburger" pr={2} />
      </Link>
      <Sidebar
        visible={visibleFullScreen}
        fullScreen
        icons={
          <H3 mx={"auto"} pl={30}>
            {messages.header_menu}
          </H3>
        }
        onHide={() => setVisibleFullScreen(false)}
      >
        <Div
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          {isAuthenticatedFlag ? (
            <Div>
              <SideMenuContent {...navProps} />
              {getUserName()}
              <SideMenuContent {...sidemenuNavProps} />
            </Div>
          ) : (
            <Div>
              <SideMenuContent {...navProps} />
              {getBookDemoButton()}
            </Div>
          )}

          <Div>
            <Div
              display="flex"
              py={3}
              borderTop="1px solid var(--turquoise-light)"
            >
              {isAuthenticatedFlag ? (
                <Div
                  width={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MyProfileButton {...MyProfileButtonProps} px={2} />
                  <Icon
                    name="headerarrowright"
                    color="var(--turquoise)"
                    fontSize="var(--fs-h4)"
                  />
                </Div>
              ) : (
                <Div width={1} textAlign={"center"} pr={3}>
                  <MyProfileButton {...MyProfileButtonProps} />
                </Div>
              )}
            </Div>
            <Div
              display="flex"
              justifyContent="center"
              backgroundColor={"rgba(var(--grey-lightest-rgba), .3)"}
              p={"15px 20px"}
            >
              <Icon pr={13} name="call" color="var(--turquoise)" />
              <Link
                label={messages.phone}
                fontSize="var(--fs-h3)"
                fontWeight="var(--semibold-weight)"
              />
            </Div>
          </Div>
        </Div>
      </Sidebar>
    </>
  );
};

const UserHeaderMain = (props) => {
  const { config } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const history = useHistory();
  const endRef = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { name } = userInfo;
  const passedData = Array.isArray(config) ? config[0] : config;
  const isLoading = !passedData.title;

  const handleLogout = async () => {
    await logoutService(localStorage.getItem("token"));
    localStorage.clear();
    dispatch(authActions.storeUserInfo({ user: {}, isLogout: true }));
    history.push(LOGIN);
  };

  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  const handleToggle = (event) => {
    setMenuVisible((menuVisible) => !menuVisible);
    endRef.current.toggle(event);
  };

  let items = HEADER_NAV_MAIN.map((item) => {
    const {
      redirectTo = "",
      isWpPage = "",
      value = "",
      preventRedirect = false,
      isDropDown = false,
    } = item;

    if (isWpPage) {
      item.url = redirectTo;
    } else if (!isDropDown) {
      item.command = () => {
        if (!preventRedirect) {
          history.push(value);
        }
      };
    }
    return item;
  });
  items.push({
    label: (
      <Div>
        <Icon
          color="var(--turquoise)"
          mr={"8px"}
          fontSize={"var(--fs-text-secondary) !important"}
          name={"logout"}
        />
        Logout
      </Div>
    ),
    command: () => {
      handleLogout();
    },
  });

  const start = () => (
    <>
      <Div>
        <Logo
          logo={LogoFull}
          width="auto"
          maxHeight="60px"
          onClick={handleRedirect}
          display={["flex", "flex", "flex", "none"]}
        />
        {isLoading ? (
          <Skeleton height={20} width={200} />
        ) : (
          <Text
            color="var(--blue-dark) !important"
            fontWeight="var(--semibold-weight) !important"
            fontSize={"var(--fs-h2) !important"}
            wordBreak="none !important"
            display={["none", "none", "none", "flex"]}
          >
            {passedData.title}
          </Text>
        )}
      </Div>
      <WebSideBar />
    </>
  );

  const item = passedData.headerActions?.map((action) => ({
    label: action.label,
    template: (
      <div>
        {(() => {
          switch (action.type) {
            case "checkbox":
              return (
                <CheckBoxes
                  type={action.type}
                  value={action}
                  options={action.options}
                />
              );
            case "calendar":
              return <UtilCalendar value={action} />;
            case "button":
              return (
                <ButtonLinkIcon
                  {...action}
                  semibold
                  id={action.id}
                  label={action.label}
                  icon={
                    action.icon ? (
                      <Icon
                        name={action.icon}
                        mr={action.label ? 2 : 0}
                        color="var(--turquoise) !important"
                        fontSize="var(--fs-icon-m)"
                        fontWeight="var(--semibold-weight)"
                        mt={"2px"}
                      />
                    ) : (
                      ""
                    )
                  }
                  color="var(--grey-dark) !important"
                  fontSize="var(--fs-text-m) !important"
                  px={3}
                  py="8px"
                  minWidth={action.minWidth || "40px"}
                  minHeight="30px"
                  display="inline-flex"
                  textAlign="center"
                  whiteSpace
                />
              );
            case "button-transparent":
              return (
                <TransparentButtonCustom
                  {...action}
                  key={action.id}
                  id={action.id}
                  mx={3}
                  label={action.label}
                  semibold
                  px="20px"
                  py="6px"
                  minWidth={150}
                  alignSelf="center"
                  icon={<Icon name={action.icon} mr={1} cursor="pointer" />}
                />
              );
            case "button-raised":
              return (
                <ButtonRaisedIconCustom
                  {...action}
                  key={action.id}
                  id={action.id}
                  mx={2}
                  label={action.label}
                  /* padding top condition is done for "SCV" button style */
                  icon={
                    <Icon name={action.icon} pr={`${action.label ? 1 : 0}`} />
                  }
                />
              );
            case "search":
              return <SearchBar type={action} />;
            case "multiselect":
              return (
                <Multiselect
                  type={action.type}
                  placeholder={action.placeholder}
                  name={action.name}
                  onChange={action.onClick}
                  inputValue={action.inputValue}
                  id={action.id}
                  options={action.options}
                  width={action.width}
                  value={action.value}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    ),
  }));

  const end = (
    <>
      <Div
        display={["none", "none", "flex"]}
        backgroundColor={"var(--grey-lighter)"}
        alignItems="center"
        justifyContent="space-between"
        width="232px"
        px={[0, 0, 3, 24]}
        pt={25}
        pb={21}
        borderBottom={`4px solid ${
          menuVisible ? "var(--blue-medium)" : "transparent"
        }`}
        borderTop="4px solid var(--grey-lighter)"
        whiteSpace
        height={60}
        onClick={handleToggle}
        aria-controls="menu_left"
        aria-haspopup
      >
        <Div display={["none", "none", "flex"]} alignItems="center">
          <Icon pr={13} color="var(--blue-medium) !important" name="avatar" />
          <Link label={name} />
        </Div>
        <Icon
          pl={13}
          fontSize={10}
          color="var(--blue-medium) !important"
          name="arrowbig-down"
        />
      </Div>
      <Menu
        model={items}
        popup
        ref={endRef}
        id="popup_menu"
        onHide={() => setMenuVisible(false)}
      />
    </>
  );

  return (
    <Div
      backgroundColor={"var(--white)"}
      borderBottom={"2px solid var(--grey-lightest)"}
    >
      <MenuBar model={item} start={start} end={end} width={[1, 1, 1]} />
    </Div>
  );
};

UserHeaderMain.propTypes = {
  config: PropTypes.object,
  navItems: PropTypes.array,
  sideMenuNavItems: PropTypes.array,
  children: PropTypes.node,
};

export default UserHeaderMain;
