import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, Link as PRLink } from "react-router-dom";
import styled from "styled-components";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import CountTag from "@components/CountTag";
import { H5 } from "@components/Heading";
import Icon from "@components/Icon";

const Link = styled(PRLink)`
  text-decoration: none;
`;

const StyledDiv = styled(Div)`
  margin: ${({ dropDownItem }) => (dropDownItem ? "0" : "0px 0px")};
  padding: 12px 20px;
  display: flex;
  color: var(--grey-dark);
  align-items: center;

  &:first-child {
    margin-top: 0px;
  }

  &:hover {
    background: var(--grey-lightest);
  }

  span {
    font-size: var(--fs-h4);
    margin-right: 0;
  }
`;

const SideMenuContent = (props) => {
  const { pathname = "" } = useLocation();
  const [showDropDown, setShowDropDown] = useState(false);
  let { Component = () => <></>, navItems: items = [] } = props;

  const ActiveMenuItem = (url) => pathname.includes(url);

  const handleWpPageRedirection = (url) => () => {
    window.location = url;
  };

  const menuItem = (item) => {
    return (
      <StyledDiv
        dropDownItem={item?.dropDownItem}
        width={[1, 1, 1, 190]}
        justifyContent={["center", "center", "center", "start"]}
        bg={ActiveMenuItem(item.redirectTo) && "var(--grey-lightest)"}
      >
        <Icon
          color="var(--turquoise)"
          mr={"8px"}
          fontSize={"16px !important"}
          width={"16px !important"}
          name={item.tabIcon}
        />
        <TextMediumWeight
          wordBreak="break-all"
          mr={4}
          fontSize={"14px !important"}
          letterSpacing={"0.3px !important"}
        >
          {item.label}
        </TextMediumWeight>
        {item.count && <CountTag count={item.count} />}
      </StyledDiv>
    );
  };

  const handleExpandAccordion = () => {
    setShowDropDown(!showDropDown);
  };

  if (showDropDown) {
    const { dropDownOptions: filterItems = [] } = items.find(
      (o) => o.isDropDown,
    );
    let checkItems = [];
    items.map((o) => {
      if (o.isDropDown) {
        checkItems = [
          ...checkItems,
          ...[o],
          ...filterItems.map((o) => ({ ...o, dropDownItem: true })),
        ];
      } else {
        checkItems = [...checkItems, ...[o]];
      }
    });
    items = checkItems;
  }
  return (
    <Div>
      <Component />
      {items.map((item) => {
        if (item?.isWpPage) {
          return (
            <Link
              width={1}
              key={item.label}
              onClick={handleWpPageRedirection(item.redirectTo)}
            >
              {menuItem(item)}
            </Link>
          );
        } else if (item.isDropDown) {
          return (
            <H5 width={1} key={item.label} onClick={handleExpandAccordion}>
              {menuItem(item)}
            </H5>
          );
        } else {
          return (
            <Link to={item.redirectTo} width={1} key={item.label}>
              {menuItem(item)}
            </Link>
          );
        }
      })}
    </Div>
  );
};

SideMenuContent.propTypes = {
  Component: PropTypes.node,
  navItems: PropTypes.array,
};

export default SideMenuContent;
