import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  ButtonLinkIcon,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "@components/Button";
import DataTable from "@components/DataTable/DataTable";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H2, H3, H4 } from "@components/Heading";
import Icon from "@components/Icon";
import { LinkArrow } from "@components/Link";
import { Text, TextMediumWeight } from "@components/Text";

import ErrorModal from "@pages/user/OrderResults/ErrorModal";

import getCredentials from "@app/services/integrations/getCredentials";
import addCredentials from "@app/services/integrations/addCredentials";

import Routes from "@shared/Routes";

import { BACKGROUND_SE } from "@utils/constant";
import { formatDateAndTime } from "@utils/utils";
import CredentialsDialog from "./components/CredentialsDialog";

const IntegrationsAPIList = () => {
  const { messages } = useIntl();

  const [isQuestionDialogVisible, setIsQuestionDialogVisible] = useState(false);
  const [newCredentials, setNewCredentials] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchCredentials = async () => {
    try {
      const { data: credentialsData } = await getCredentials();

      const formattedData = credentialsData.data.map((item) => ({
        ...item,
        status: item.active ? messages.active : messages.label_disabled,
        created_at: formatDateAndTime(item.created_at),
        secret: "********",
      }));

      return { data: formattedData };
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const handleQuestionModal = () => {
    setIsQuestionDialogVisible(!isQuestionDialogVisible);
  };

  const renderEmptyMessage = () => {
    return (
      <Div display="flex" flexDirection="column" gridGap={3}>
        <H4>{messages.message_no_api_keys}</H4>
        <Text>{messages.message_start_by_creating_one}</Text>
        <Text>{messages.message_need_to_understand_api}</Text>
        <LinkArrow
          href={BACKGROUND_SE + Routes.CONTACT_US_ID.URL}
          label={messages.contact_us}
          width={1}
          direction="left"
          variant="secondary"
        >
          <Icon ml={1} name="headerarrowright" />
        </LinkArrow>
      </Div>
    );
  };

  const config = {
    columns: [
      {
        field: "client_id",
        header: messages.header_client_id,
        sortable: false,
        body: "general",
        width: "30%",
      },
      {
        field: "secret",
        header: messages.header_secret,
        sortable: false,
        body: "general",
      },
      {
        field: "created_at",
        header: messages.header_created_on,
        sortable: false,
        body: "dateAndTime",
      },
      {
        field: "status",
        header: messages.label_status,
        body: "general",
        sortable: false,
      },
      {
        field: "action",
        body: "cancel",
        sortable: false,
        header: "",
        width: "50px",
      },
    ],
    sortable: true,
    loadData: fetchCredentials,
    emptyMessage: renderEmptyMessage,
  };

  const handleCloseCredentialsModal = () => {
    setNewCredentials(null);
  };

  const handleCreateAPIKey = async () => {
    handleQuestionModal();

    try {
      const { data: credentialsData = {} } = await addCredentials();

      setNewCredentials(credentialsData);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorMessage("");
  };

  const renderHeader = () => (
    <Div display="flex" justifyContent="center">
      <H3>{messages.header_generate_new_api_keys?.toUpperCase()}</H3>
    </Div>
  );

  return (
    <>
      <Div display="flex" alignItems="center" justifyContent="space-between">
        <H2 color="var(--blue-dark) !important">
          {messages.header_manage_api_keys}
        </H2>

        <ButtonLinkIcon
          label={
            <TextMediumWeight>
              {messages.label_generate_new_api_key}
            </TextMediumWeight>
          }
          iconPos="left"
          icon={<Icon mr={1} name="plus" color="var(--turquoise)" />}
          onClick={handleQuestionModal}
        />
      </Div>

      <DataTable config={config} />

      {errorMessage && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleCloseErrorModal}
          width={[1, 1, "auto", "auto"]}
          m={[3, 3, "auto", "auto"]}
        >
          <ErrorModal
            handleConfirmPopup={handleCloseErrorModal}
            content={errorMessage}
          />
        </Dialog>
      )}

      {isQuestionDialogVisible && (
        <Dialog
          header={renderHeader}
          visible="displayBasic"
          draggable={false}
          onHide={handleQuestionModal}
          width={[1, 1, 1, "450px"]}
          m={[3, 3, 3, "auto"]}
        >
          <Div
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridGap={20}
            pt={20}
          >
            <Text>{messages.label_about_to_create_new_api_keys}</Text>

            <Div width={1} display="flex" gridGap={15} px={30}>
              <PrimaryButton
                width={[1, "50%"]}
                label={messages.label_create_keys}
                onClick={handleCreateAPIKey}
              />
              <PrimaryButtonOutlined
                width={[1, "50%"]}
                label={messages.label_cancel}
                onClick={handleQuestionModal}
              />
            </Div>
          </Div>
        </Dialog>
      )}

      {newCredentials && (
        <CredentialsDialog
          credentialsData={newCredentials}
          onClose={handleCloseCredentialsModal}
        />
      )}
    </>
  );
};

export default IntegrationsAPIList;
