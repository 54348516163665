import React, { useState } from "react";
import { H2 } from "@components/Heading";
import Div from "@components/Div";
import { TextMediumWeight } from "@components/Text";
import ActivityLog from "@components/ActivityLog";
import ContactUs from "@components/contactUs";
import SubscriptionPackages from "@components/SubscriptionPackages";
import { useIntl } from "react-intl";

const Subscriptions = (prop) => {
  const { messages } = useIntl();
  const { activePackage, activityLogs, userInfo, isAdmin = false } = prop;
  const [pageSectionShow, setPageSectionShow] = useState(false);
  const subscriptionListLoaded = () => {
    setPageSectionShow(true);
  };
  const terminated = prop.terminated ? prop.terminated : false;
  return (
    <Div>
      <H2 mb={"20px"} display={["flex", "flex", "flex", "none"]}>
        {messages.subscriptions_title}
      </H2>
      {terminated && (
        <TextMediumWeight mb={"14px"} display="flex">
          {messages.your_subscription_is_termonated_now}
        </TextMediumWeight>
      )}
      <Div display="flex" flexWrap="wrap">
        {!isAdmin && (
          <SubscriptionPackages
            userInfo={userInfo}
            activePackage={activePackage}
            subscriptionListLoaded={subscriptionListLoaded}
            pageSectionShow={pageSectionShow}
          />
        )}
        {pageSectionShow && (
          <>
            {!isAdmin && (
              <Div width={1}>
                <ContactUs backgroundColor="rgba(var(--grey-lighter-rgba),0.2)" />{" "}
              </Div>
            )}
            <ActivityLog activityLogs={activityLogs} width={1} />
          </>
        )}
        {isAdmin && <ActivityLog activityLogs={activityLogs} />}
      </Div>
    </Div>
  );
};

export default Subscriptions;
