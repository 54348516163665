import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  border,
  color,
  shadow,
  gridGap,
  gridColumnGap,
} from "styled-system";

const StyledDiv = styled.div`
  ${compose(
    layout,
    space,
    flexbox,
    position,
    typography,
    border,
    color,
    shadow,
    gridGap,
    gridColumnGap,
  )};
  white-space: ${({ whiteSpace }) => (whiteSpace ? "nowrap" : "inherit")};
  cursor: ${({ cursor }) => cursor || "inherit"};
`;

const Div = (props) => {
  const { className, children } = props;

  return (
    <StyledDiv className={className} {...props}>
      {children}
    </StyledDiv>
  );
};

export const DivBg = styled("div")`
  ${compose(space, layout, color)};
  display: inline-flex;
  margin-top: 10px;
  width: auto;
  padding: 8px 17px;
  background-color: rgba(var(--grey-lightest-rgba), 0.4);
  align-items: center;
`;

export const DivBgIcon = styled(DivBg)`
  ${compose(space, layout, color)};
  display: flex;
  padding: 10px;
  background-color: rgba(var(--grey-lightest-rgba), 0.4);
  align-items: center;
`;

Div.propTypes = {
  className: Proptypes.string,
  children: Proptypes.node,
};

export default Div;
