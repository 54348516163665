import Span from "@components/Span";
import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Link, { LinkArrow } from "@components/Link";
import Div from "@components/Div";
import Icon, { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import { useIntl } from "react-intl";
import {
  ROUTES,
  ANALYSIS,
  STATUS_EXPIRED,
  STATUS_EXPIRED_NO_CONSENT,
  STATUS_NOT_PAID,
  STATUS_PAID,
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
  STATUS_CANCELLED,
  STATUS_NEW,
  STATUS_ERROR,
  STATUS_FAILED,
  ADMIN,
  ROLE_ANALYSIS,
  GENERAL_ANALYSIS,
  ASSIGNMENT,
  BUNDLE,
  PENDING_CONSENT,
  PENDING_CONSENT_TO_SHARE,
  READ,
  GRADES,
} from "@utils/constant";
import { ButtonText } from "@components/Button";
import { diffDays, convertSEK, getFormattedNumber } from "@utils/utils";
import resendReportService from "@app/services/results/resendReportService";
import ProgressSpinner from "@components/ProgressSpinner";
import Config from "@src/config";
import Tooltip from "@components/ToolTip";
import { H5 } from "@components/Heading";
import {
  Text,
  TextSmall,
  TextLargeSemiBoldWeight,
  ColouredSemiBoldText,
} from "@components/Text";
import ConsentResultCard from "../../user/results/ConsentResults";

import usePermissions from "@hooks/usePermissions";
import { PERMISSIONS } from "@utils/enum";

const ResultsCard = ({
  isAdmin,
  status = "",
  isDetails = false,
  showAnalysLink,
  orderItem,
  product,
  report,
  assignment,
  isReport,
  fetchReportDetails,
  isChild,
}) => {
  const { messages } = useIntl();
  const { checkForReadPermission } = usePermissions();

  const hasPricesPermission = checkForReadPermission(PERMISSIONS.PRICES);

  const {
    service_type: serviceType = "",
    price: orderPrice = 0,
    credits: orderCredits = 0,
    name: service = "",
  } = orderItem;

  const {
    description: serviceDetail,
    grade_mapping,
    delivery_time = "",
    id: productID,
  } = product || {};

  const {
    grades,
    id: reportId,
    report_status: reportStatus = "",
    expired_at: reportExpiryDate = "",
  } = report;

  const assignmentStatus = assignment?.status;
  const assignmentId = assignment?.id;
  const TextComponent = isChild ? H5 : TextLargeSemiBoldWeight;
  const history = useHistory();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { type: userType, permissions } = userInfo;
  const reportAnalysAccess = permissions?.["report-analysis"] || "";
  const showGradeResults =
    reportAnalysAccess === READ || reportAnalysAccess === GRADES;
  const showGradeActionButtons = reportAnalysAccess === READ;

  const showDelivery = serviceType === BUNDLE || serviceType === ASSIGNMENT;
  const { analysis_type: analysisType = GENERAL_ANALYSIS } = product || {};
  const isRoleAnalysis = analysisType === ROLE_ANALYSIS;
  const isConsent =
    reportStatus === PENDING_CONSENT ||
    reportStatus === PENDING_CONSENT_TO_SHARE;
  const [retryResponse, setRetryResponse] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const formattedOrderPrice = (Math.round(orderPrice * 100) / 100).toFixed(2);

  const handleRedirectToReportDetails = useCallback(
    () => history.push(`${ROUTES.ADMIN_ANALYSIS_LIST.URL}/${reportId}`),
    [reportId, history],
  );

  const handleRedirectToAssigmentDetails = useCallback(
    () => history.push(`${ROUTES.ASSIGNMENT_DETAILS.URL}?id=${assignmentId}`),
    [assignmentId, history],
  );

  const handleRedirectToReport = useCallback(
    () => window.open(`${ROUTES.ORDER_RESULTS.URL}?id=${reportId}`, "_blank"),
    [reportId],
  );

  const handleRestartAnalysis = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await resendReportService(reportId);
      const { status: resStatus = "" } = response || {};
      setIsFetching(false);
      setRetryResponse(resStatus);
    } catch (e) {
      setIsFetching(false);
      throw new Error(e);
    }
  }, [reportId]);

  const handleStatus = useCallback(
    (orderStatus) => {
      switch (orderStatus) {
        case STATUS_NOT_PAID:
          return messages.status_not_started;
        case STATUS_PAID:
          return messages.paid;
        case STATUS_COMPLETED:
          return messages.completed;
        case STATUS_IN_PROGRESS:
          return messages.status_in_progress;
        case STATUS_CANCELLED:
          return messages.cancelled;
        case STATUS_NEW:
          return messages.new;
        case PENDING_CONSENT:
          return messages.consent_label_pending_consent;
        case PENDING_CONSENT_TO_SHARE:
          return messages.consent_label_pending_consent_share;
        case STATUS_ERROR:
          return (
            <ColouredSemiBoldText color="var(--red)">
              {messages.waiting}
            </ColouredSemiBoldText>
          );
        case STATUS_EXPIRED:
          return messages.expired_status_label;
        default:
          return orderStatus;
      }
    },
    [messages],
  );
  const renderReportStatusMsg = useCallback(
    (status) => {
      switch (status) {
        case PENDING_CONSENT:
        case PENDING_CONSENT_TO_SHARE:
          return messages.assignment_pending_consent_message;
        case STATUS_EXPIRED_NO_CONSENT:
          return messages.assignment_expired_consent_message;
        case STATUS_IN_PROGRESS:
          return messages.consent_status_in_progress_description;
        case STATUS_EXPIRED:
          return messages.assignment_expired_message;
        default:
          return null;
      }
    },
    [messages],
  );

  const renderAssignmentStatusMsg = useCallback(
    (status) => {
      switch (status) {
        case STATUS_EXPIRED:
          return (
            <Div mt={3} mb={3}>
              <TextSmall>{messages.assignment_expired_message}</TextSmall>
            </Div>
          );
        default:
          return null;
      }
    },
    [messages],
  );

  const getActionButtons = () => {
    return (
      <>
        {(showGradeActionButtons || isAdmin) && (
          <LinkArrow
            label={messages.see_results}
            direction="right"
            variant="secondary"
            mt={2}
            mr={3}
            mb={2}
            handleClick={handleRedirectToReport}
          >
            <Span px={1}>
              <Icon name="headerarrowright" />
            </Span>
          </LinkArrow>
        )}
        {userType !== ADMIN && showGradeActionButtons && (
          <CopyToClipboard
            text={`${Config.APP_URL}${ROUTES.ORDER_RESULTS.URL}?id=${reportId}`}
          >
            <Link
              label={messages.label_copy}
              direction="right"
              variant="secondary"
              mt={2}
              mr={3}
              mb={2}
              iconPos={"left"}
              whiteSpace="nowrap"
              width="auto"
            >
              <Div px={1}>
                <Icon name="copy" mr={1} />
              </Div>
            </Link>
          </CopyToClipboard>
        )}
      </>
    );
  };

  const getExpiryMessage = () => {
    let expiryDaysCount = "";
    expiryDaysCount = diffDays(reportExpiryDate);
    if (!expiryDaysCount) return "";
    if (expiryDaysCount > 0)
      return `${messages.label_expires_in} ${expiryDaysCount} ${messages.label_days}`;
  };

  const renderContent = () => {
    let content = "";

    if (serviceType === ANALYSIS) {
      if (isRoleAnalysis && reportStatus === STATUS_COMPLETED) {
        content = <>{constructResultSummary(grades)}</>;
      } else if (status === STATUS_CANCELLED) {
        content = (
          <Text display="block">{messages.message_order_cancelled}</Text>
        );
      } else if (status === STATUS_NOT_PAID) {
        content = (
          <Text display="block">{messages.message_order_not_paid}</Text>
        );
      } else if (status === STATUS_EXPIRED) {
        content = (
          <Text display="block">{messages.assignment_expired_message}</Text>
        );
      } else if (isConsent && isReport && isAdmin) {
        content = (
          <ConsentResultCard
            orderDetails={orderItem}
            report={report}
            showConsentModal={true}
            isReport={isReport}
            fetchReportDetails={fetchReportDetails}
          />
        );
      } else if (reportStatus === STATUS_FAILED) {
        content = (
          <>
            <Text display="block">{messages.assignment_failed_message}</Text>
            {userType === ADMIN && (
              <Div display={["block", "flex"]} my={2}>
                <LinkArrow
                  label={messages.label_restart_analysis}
                  direction="right"
                  variant="secondary"
                  mr={3}
                  handleClick={handleRestartAnalysis}
                >
                  <Span px={1}>
                    <Icon name="headerarrowright" />
                  </Span>
                </LinkArrow>
              </Div>
            )}
          </>
        );
      } else if (reportStatus === STATUS_COMPLETED) {
        content = (
          <>
            <Div
              display="flex"
              alignItems="center"
              justifyContent={["center", "space-between"]}
              maxWidth="1050px"
            >
              <Div>{constructResultSummary(grades)}</Div>
            </Div>
            {(showGradeActionButtons || isAdmin) && (
              <Div
                display="flex"
                flexDirection={["column", "column", "column", "row"]}
                mt={[2, 3]}
                mb={[0, 3]}
              >
                {getActionButtons()}
                {isAdmin && showAnalysLink && (
                  <Link
                    mr={2}
                    mb={[2, 0]}
                    mt={[0, 0, 0, 2]}
                    label={messages.title_detail_report}
                    direction="right"
                    iconPos={"left"}
                    onClick={handleRedirectToReportDetails}
                  >
                    <Div px={1}>
                      <Icon name="file-download" />
                    </Div>
                  </Link>
                )}
              </Div>
            )}
          </>
        );
      }
    } else if (status === STATUS_CANCELLED) {
      content = <Text display="block">{messages.message_order_cancelled}</Text>;
    } else if (status === STATUS_NOT_PAID) {
      content = (
        <Text display="block">{messages.assignment_message_not_paid}</Text>
      );
    } else if (status === STATUS_EXPIRED) {
      content = (
        <Text display="block">{messages.assignment_expired_message}</Text>
      );
    } else if (assignmentStatus) {
      content = (
        <Div display={["block", "flex"]}>
          {assignmentStatus === STATUS_COMPLETED && getActionButtons()}
          {userType === ADMIN && (
            <Link
              label={messages.label_details}
              direction="right"
              iconPos={"left"}
              mt={2}
              mr={3}
              onClick={handleRedirectToAssigmentDetails}
            >
              <Div px={1}>
                <Icon name="file-download" />
              </Div>
            </Link>
          )}
        </Div>
      );
    }
    return content;
  };
  const constructResultSummary = () => {
    const GradData = grade_mapping?.grade?.find(
      (item) => item.grades === grades,
    );
    const {
      bgColor,
      color,
      grades: gradeValue = "",
      statement = [],
    } = GradData || {};
    return (
      <>
        {(userType === ADMIN || showGradeResults) &&
          statement.map((i, index) => {
            return (
              <ButtonText
                key={i}
                px={[
                  "15px !important",
                  "15px !important",
                  "15px !important",
                  20,
                ]}
                py={"6px"}
                my={3}
                mr={[2]}
                ml={index && 2}
                label={
                  i === "grade" && !isRoleAnalysis
                    ? `${messages[i]} ${gradeValue}`
                    : `${messages[i]}`
                }
                bg={`var(--${bgColor}) !important`}
                color={`var(--${color}) !important`}
              />
            );
          })}
        <></>
      </>
    );
  };

  const renderExpiredDays = () => {
    if (status === STATUS_PAID || status === STATUS_COMPLETED) {
      return <TextSmall ml={4}>{getExpiryMessage()}</TextSmall>;
    }
    return "";
  };

  const renderPrice = () => {
    let updatedPrice = getFormattedNumber(formattedOrderPrice);

    if (orderItem?.adjustments?.length > 0) {
      const [adjustment] = orderItem.adjustments;

      if (adjustment) {
        const discountAmount = (
          Math.round(adjustment.amount * 100) / 100
        ).toFixed(2);

        updatedPrice = Math.max(formattedOrderPrice - discountAmount, 0);
      }
    }

    if (!hasPricesPermission) {
      return <Div />;
    }

    if (orderCredits >= 1) {
      return (
        <>
          <Div display={["flex", "none"]}>
            <H5 mt={1}>{messages.prepaid_price_label}</H5>
          </Div>
          <Div display={["none", "flex"]}>
            <TextLargeSemiBoldWeight>
              {messages.prepaid_price_label}
            </TextLargeSemiBoldWeight>
          </Div>
        </>
      );
    }

    if (!hasPricesPermission) {
      return <Div />;
    }

    return (
      <>
        <Div display={["flex", "none"]}>
          <TextLargeSemiBoldWeight>{`${convertSEK(
            updatedPrice,
          )} Kr`}</TextLargeSemiBoldWeight>
        </Div>
        <Div display={["none", "flex"]}>
          <TextLargeSemiBoldWeight>{`${convertSEK(
            updatedPrice,
          )} Kr`}</TextLargeSemiBoldWeight>
        </Div>
      </>
    );
  };

  return (
    <>
      <Div
        display={"flex"}
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
      >
        <Div display={"flex"} alignItems="center" minWidth={["0px", "575px"]}>
          <Div display="flex" flexDirection={["column", "row"]}>
            <Div
              display={"flex"}
              width={["200px", "100%"]}
              alignItems={["baseline", "center"]}
            >
              <TextComponent>
                {service}
                {serviceType === BUNDLE && (
                  <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
                )}
                {isDetails && (
                  <SmallEllipseIcon
                    ml={2}
                    name="info"
                    className={`description_${productID}`}
                  >
                    <Tooltip
                      target={`.description_${productID}`}
                      content={serviceDetail}
                    ></Tooltip>
                  </SmallEllipseIcon>
                )}
              </TextComponent>
            </Div>
            {isRoleAnalysis &&
              reportStatus === STATUS_COMPLETED &&
              grades === null &&
              !isAdmin && (
                <TextComponent display={["none", "flex"]} ml={4}>
                  {messages.label_one_day}
                </TextComponent>
              )}
          </Div>
          {isDetails && (
            <Div
              display={
                isRoleAnalysis &&
                reportStatus === STATUS_COMPLETED &&
                grades === null
                  ? "none"
                  : "flex"
              }
              alignItems="center"
            >
              <TextSmall ml={[0, 4]}>
                {((serviceType === ANALYSIS || serviceType === ASSIGNMENT) &&
                  status === STATUS_PAID) ||
                ((serviceType === ANALYSIS || serviceType === ASSIGNMENT) &&
                  status === STATUS_COMPLETED)
                  ? handleStatus(reportStatus)
                  : null}
              </TextSmall>
            </Div>
          )}
          <Div display={["none", "flex"]}>
            {!isRoleAnalysis &&
              (serviceType === ANALYSIS || serviceType === ASSIGNMENT) &&
              isDetails &&
              renderExpiredDays()}
          </Div>
        </Div>
        {!isDetails &&
          !isReport &&
          (showDelivery || reportStatus === STATUS_COMPLETED) && (
            <TextComponent display={["none", "flex"]} justifyContent="flex-end">
              {delivery_time}
            </TextComponent>
          )}
        {isDetails && !isReport && (
          <Div
            display="flex"
            justifyContent="flex-end"
            minWidth={["0px", "125px"]}
          >
            {!isChild && renderPrice()}
          </Div>
        )}
      </Div>
      <>
        {serviceType === ANALYSIS && (
          <Div mt={3} mb={3} display="flex">
            <TextSmall>{renderReportStatusMsg(reportStatus)}</TextSmall>
            <Div minWidth={["0px", "125px"]}></Div>
          </Div>
        )}
        {serviceType === ASSIGNMENT && renderAssignmentStatusMsg(reportStatus)}
        <Div display="flex" justifyContent="space-between">
          <Div>{renderContent()}</Div>
        </Div>

        <Div
          mt={3}
          mb={3}
          display={
            isRoleAnalysis &&
            reportStatus === STATUS_COMPLETED &&
            grades === null
              ? "block"
              : "none"
          }
        >
          <TextSmall>{messages.grade_grey_message}</TextSmall>
        </Div>
        {isFetching && <ProgressSpinner />}
        {!isFetching && retryResponse === 200 && (
          <TextLargeSemiBoldWeight color="var(--blue-dark) !important">
            {messages.retry_success}
          </TextLargeSemiBoldWeight>
        )}
      </>
    </>
  );
};

ResultsCard.propTypes = {
  orderItem: PropTypes.object,
  product: PropTypes.object,
  report: PropTypes.object,
  assignment: PropTypes.object,
  grades: PropTypes.string,
  status: PropTypes.string,
  isAdmin: PropTypes.bool,
  isReport: PropTypes.bool,
  isDetails: PropTypes.bool,
  isChild: PropTypes.bool,
  showAnalysLink: PropTypes.bool,
  fetchReportDetails: PropTypes.func,
};

export default ResultsCard;
