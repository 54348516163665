import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { deleteUserProfile } from "@app/services/users/deleteUserProfileService";
import getRolesService from "@app/services/users/getRolesService";

import { PrimaryButtonIcon } from "@components/Button";
import DataTable from "@components/DataTableV2/DataTable";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { Text } from "@components/Text";

import DeleteUser from "@pages/admin/users/Delete";

import { USERS, WRITE, CUSTOMER_USER } from "@utils/constant";
import { USER_TYPE } from "@utils/enum";
import { getUserRoleTranslationKey } from "@utils/utils";

const searchableColumns = ["id", "firstname", "lastname", "email"];
import UserContainer from "@layout/UserContainer";
import UserDialog from "../admin/users/components/UserDialog";

const SubUsers = () => {
  const { messages } = useIntl();
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer: { id: customerId = "" } = {}, permissions = {} } =
    userInfo || {};

  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [roleFilters, setRoleFilters] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  const handleAddUser = () => {
    if (showInviteDialog) {
      setReloadList(!reloadList);
    }
    setShowInviteDialog(!showInviteDialog);
  };

  const handleDeleteModal = (data = {}) => {
    setSelectedData(data);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteUser = async () => {
    const { id: selectedCustomerId = "" } = selectedData;
    try {
      await deleteUserProfile({ id: selectedCustomerId });
      if (showDeleteModal) {
        setReloadList(!reloadList);
      }
      handleDeleteModal();
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleEditUserModal = (data = {}) => {
    setSelectedData(data);
    if (showEditUserModal) {
      setReloadList(!reloadList);
    }
    setShowEditUserModal(!showEditUserModal);
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await getRolesService(CUSTOMER_USER);
        setRoleFilters(
          res.map((o) => ({
            label: messages[getUserRoleTranslationKey(o.value)],
            value: o.id,
            checked: false,
          })),
        );
        setIsFilterValuesLoaded(true);
      } catch (e) {
        throw new Error(e);
      }
    };
    getRoles();
  }, [messages]);

  const config = {
    header: {
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_role,
        id: "filter[roles.id]",
        type: "checkbox",
        options: roleFilters,
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_customer_users_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: `users.csv`,
    is_filter_options_updated: isFilterValuesLoaded,
    api: {
      resource: `/${USERS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", ["roles", "roles.permissions", "permissions"]],
        ["filter[customer.id]", customerId],
      ],
    },
    columns: [
      {
        title: "label_name",
        db_field: "name",
        type: "text",
      },
      {
        title: "label_email",
        db_field: "email",
        type: "text",
      },
      {
        title: "label_role",
        db_field: "roles",
        type: "text",
        formatter: (data) => {
          const [values] = data;
          return values?.name
            ? messages[getUserRoleTranslationKey(values?.name)]
            : "-";
        },
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.label_edit,
            icon: "icon-pen",
            type: "button",
            onClick: handleEditUserModal,
            isHidden: permissions && permissions.users !== WRITE,
          },
          {
            label: messages.label_delete,
            icon: "icon-rubber",
            onClick: handleDeleteModal,
            type: "button",
            isHidden: permissions && permissions.users !== WRITE,
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.user,
    headerActions: [
      {
        id: "new-order",
        type: "button",
        label: messages.label_new_user,
        width: "auto",
        icon: "plus",
        variant: "header",
        height: 40,
        px: 4,
        py: 20,
        onClick: handleAddUser,
        isHidden: permissions && permissions.users !== WRITE,
      },
    ],
  };

  return (
    <UserContainer config={headerConfig}>
      <DataTable config={config} reloadList={reloadList} />
      <PrimaryButtonIcon
        minWidth={188}
        my={20}
        width={[1, 1, 1, "auto"]}
        height={40}
        icon={<Icon name="send" mr={2} />}
        label={messages.label_invite_sub_user}
        onClick={handleAddUser}
      />
      {showInviteDialog && (
        <UserDialog
          title={messages.label_invite_sub_user}
          userType={USER_TYPE.CUSTOMER}
          customerId={customerId}
          handleClose={handleAddUser}
        />
      )}
      {showEditUserModal && (
        <UserDialog
          title={messages.label_edit_user}
          userType={USER_TYPE.CUSTOMER}
          userDetails={userInfo.customer}
          handleClose={handleEditUserModal}
        />
      )}
      {showDeleteModal && (
        <DeleteUser
          onDelete={handleDeleteUser}
          onCancel={handleDeleteModal}
          subUser
        >
          <Div justifyContent="center" textAlign="center" display="flex">
            <Text>
              {`${messages.label_delete_user_confirmation_first} ${selectedData.name} ${messages.label_delete_user_confirmation_second}`}
            </Text>
          </Div>
        </DeleteUser>
      )}
    </UserContainer>
  );
};
export default SubUsers;
