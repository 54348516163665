import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Div from "@components/Div";
import AdminSideMenu from "./AdminSideMenu";
import { authActions } from "@app/reducers/authReducer";
import getUserDetails from "@app/services/auth/getUserDetails";

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { userInfo = {} } = useSelector((state) => state.authReducer);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await getUserDetails();
        const { data: { data: userData = {} } = {}, status = "" } = response;
        if (status === 401) {
          localStorage.clear();
          dispatch(authActions.storeUserInfo({ user: {}, isLogout: true }));
        } else {
          dispatch(authActions.storeUserInfo(userData));
        }
      } catch (e) {
        //catch the error
      }
    };
    if (!userInfo.id) {
      fetchUserDetails();
    }
  }, [dispatch, userInfo]);

  return (
    <Div display="flex" maxWidth="100vw" overflow="hidden">
      <Div
        width={[1, 1, 1, "192px"]}
        minHeight="100vh"
        minWidth={192}
        display={["none", "none", "none", "block"]}
        borderRight={"2px solid var(--grey-lightest)"}
        zIndex={1000}
        backgroundColor={"var(--white)"}
      >
        <Div
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <AdminSideMenu />
        </Div>
      </Div>
      <Div
        width={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
        maxWidth={[
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw)",
          "calc(100vw - 192px)",
        ]}
        backgroundColor={"var(--site-background-color)"}
      >
        {children}
      </Div>
    </Div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
  title: PropTypes.string,
};

export default AdminLayout;
