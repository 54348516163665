import React from "react";
import { useIntl } from "react-intl";
import useHistory from "@utils/useHistory";
import DataTable from "@components/Table/Table";
import PropTypes from "prop-types";
import { WATCH_LIST_HISTORY, ORDERS_DETAILS } from "@utils/constant";
import getWatchListOrders from "@app/services/watchlist/getWatchListOrders";

const WatchlistHistoryTab = ({ watchListId }) => {
  const { messages } = useIntl();
  const history = useHistory();

  const handleRedirectToDetails = (e) => {
    const id = e.data?.order_id;
    if (id) {
      history.push(ORDERS_DETAILS, `?id=${id}`);
    }
  };

  const config = [
    {
      title: `${messages.watchlist_tab_title_history}`,
      subTitle: `${messages.watchlist_sub_title_history}`,
      headerActions: [],
      columns: [
        {
          field: "order_id",
          header: messages.watchlist_title_order_id,
          primary: true,
          sortable: false,
        },
        {
          field: "object",
          header: messages.watchlist_title_object,
          sortable: false,
        },
        {
          field: "price",
          header: messages.watchlist_label_price,
          sortable: false,
        },
        {
          field: "sub_user",
          header: messages.watchlist_title_sub_user,
          sortable: false,
        },
        { field: "action", header: messages.title_actions },
      ],
      rowActions: [
        {
          id: "more",
          label: messages.label_more,
          type: "link",
          icon: "headerarrowright",
          iconPos: "right",
          variant: "column",
          isPrimary: true,
        },
      ],
      filterType: "dialog",
      filters: [],
      pagination: true,
    },
  ];

  return (
    <DataTable
      config={config}
      screenName={WATCH_LIST_HISTORY}
      watchListId={watchListId}
      loadData={getWatchListOrders}
      handleRedirectToDetails={handleRedirectToDetails}
    />
  );
};

WatchlistHistoryTab.propTypes = {
  watchListId: PropTypes.number,
};

export default WatchlistHistoryTab;
