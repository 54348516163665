import React from "react";
import PropTypes from "prop-types";

import { CloseButton } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H2 } from "@components/Heading";
import Icon from "@components/Icon";
import Steps from "@components/Steps";

import useStepper from "./useStepper";

const Stepper = ({ onClose }) => {
  const { steps, activeStepIndex, currentItem } = useStepper();

  const renderDialogHeader = () => (
    <Div width="100%" m={0} p={0}>
      <Div flex={1} display="flex" alignItems="center">
        <Div flex={2} display="flex" alignItems="center">
          <H2 color="var(--blue-dark) !important" mr={5}>
            {currentItem?.label}
          </H2>

          <Div flex={1}>
            <Steps model={steps} activeIndex={activeStepIndex} />
          </Div>
        </Div>

        <Div ml={5} display="flex" justifyContent="flex-end">
          <CloseButton
            rounded
            text
            icon={
              <Icon
                p={2}
                name="close"
                color="var(--grey)"
                fontSize="var(--fs-icon-m)"
              />
            }
            borderRadius="5px !important"
            backgroundColor="var(--grey-lightest) !important"
            onClick={onClose}
          />
        </Div>
      </Div>
    </Div>
  );

  return (
    <Dialog
      hideCloseButton
      maximized
      draggable={false}
      header={renderDialogHeader}
      visible="displayBasic"
      width="100%"
      height="100%"
      onHide={onClose}
    >
      {currentItem?.component}
    </Dialog>
  );
};

Stepper.propTypes = {
  onClose: PropTypes.func,
};

export default Stepper;
