import { AutoComplete as PRAutocomplete } from "primereact/autocomplete";
import styled from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  color,
  border,
  width,
} from "styled-system";

export const AutoComplete = styled(PRAutocomplete)`
  ${compose(
    layout,
    space,
    flexbox,
    position,
    typography,
    color,
    border,
    width,
  )};
  .p-inputtext {
    border-radius: ${({ curved }) => (curved ? "10px" : "none")};
    border-color: ${({ error }) =>
      error ? "var(--red-dark)" : "var(--turquoise)"};
    &:enabled {
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--white);
        border-color: var(--turquoise);
      }
      &:hover {
        border-color: var(--turquoise);
        box-shadow: none;
      }
    }
  }

  .p-autocomplete-dropdown {
    position: absolute;
    right: 0;

    background-color: transparent;
    border-color: transparent;
    color: var(--turquoise);

    &:hover,
    &:active {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--turquoise) !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
`;

export default AutoComplete;
