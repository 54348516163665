import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3, H5 } from "@components/Heading";
import { Text, TextMediumWeight, TextSmall } from "@components/Text";
import PropTypes from "prop-types";
import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";

import { INVOICE_MODAL_TYPES } from "@utils/enum";
import { getFormattedNumber } from "@utils/utils";

const INVOICE_MODAL_CONTENT = {
  [INVOICE_MODAL_TYPES.CONFIRM]: {
    heading: <FormattedMessage id="confirm_invoices" />,
    text: <FormattedMessage id="text_confirm_invoice" />,
    submitButtonLabel: <FormattedMessage id="confirm_invoices" />,
  },
  [INVOICE_MODAL_TYPES.CANCEL]: {
    heading: <FormattedMessage id="cancel_invoices" />,
    text: <FormattedMessage id="text_cancel_invoice" />,
    submitButtonLabel: <FormattedMessage id="cancel_invoices" />,
  },
};

const ConfirmInvoiceModal = ({
  type,
  data,
  onCancel,
  onSubmit,
  ...restProps
}) => {
  const { messages } = useIntl();

  const { heading, text, submitButtonLabel } = INVOICE_MODAL_CONTENT[type];

  const { billing_accounts_count, orders_count, invoice_items, total_amount } =
    data;

  return (
    <Dialog
      hideCloseButton
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "auto", "auto"]}
      m={[3, 3, "auto", "auto"]}
      onClose={onCancel}
      {...restProps}
    >
      <Div display="flex" flexDirection="column" alignItems="center">
        <H3>{heading}</H3>

        <Div width={1} mt={3} display="flex" flexDirection="column" gridGap={2}>
          <Text textAlign="center" pb={3}>
            {text}
          </Text>

          <Div
            width={1}
            p={2}
            borderRadius="10px"
            bg="var(--blue-lightest)"
            display="flex"
            flexDirection="column"
            gridGap={2}
          >
            <Div display="flex" justifyContent="space-between">
              <H5>{`${messages.title_billing_accounts}:`}</H5>
              <H5>{billing_accounts_count}</H5>
            </Div>

            <Div display="flex" justifyContent="space-between">
              <H5>{`${messages.title_orders}:`}</H5>
              <H5>{orders_count}</H5>
            </Div>
          </Div>

          <Div
            mt={2}
            width={1}
            p={2}
            borderRadius="10px"
            bg="var(--blue-lightest)"
            flex={1}
          >
            <Div display="flex" justifyContent="space-between">
              <TextSmall fontWeight="var(--light-weight)">
                {messages.label_services}
              </TextSmall>
              <Div display="flex" gridGap={5}>
                <TextSmall fontWeight="var(--light-weight)">{`${messages.label_quantity}:`}</TextSmall>
                <TextSmall fontWeight="var(--light-weight)">
                  {messages.amount}
                </TextSmall>
              </Div>
            </Div>
          </Div>

          <Div
            width={1}
            p={2}
            borderRadius="10px"
            bg="var(--blue-lightest)"
            display="flex"
            flexDirection="column"
            gridGap={2}
          >
            {invoice_items.map(({ name, quantity, amount }) => (
              <Div key={name} display="flex" justifyContent="space-between">
                <H5>{name}</H5>
                <Div
                  display="flex"
                  justifyContent="space-between"
                  minWidth="130px"
                >
                  <H5>{quantity}</H5>
                  <H5>{`${getFormattedNumber(amount)} kr`}</H5>
                </Div>
              </Div>
            ))}
          </Div>

          <Div width={1} p={2} borderRadius="10px" bg="var(--blue-lightest)">
            <Div display="flex" justifyContent="space-between">
              <TextMediumWeight>{`${messages.total}:`}</TextMediumWeight>
              <TextMediumWeight>
                {`${getFormattedNumber(total_amount)} kr`}
              </TextMediumWeight>
            </Div>
          </Div>
        </Div>

        <Div
          mt={[4, 4, 3, 3]}
          width={1}
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          justifyContent={"center"}
          gridGap={[3, 3, 4, 4]}
        >
          <PrimaryButton
            px={4}
            width={[1, 1, "auto", "auto"]}
            label={submitButtonLabel}
            onClick={onSubmit}
          />
          <PrimaryButtonOutlined
            width={[1, 1, "130px", "130px"]}
            label={messages.label_cancel}
            onClick={onCancel}
          />
        </Div>
      </Div>
    </Dialog>
  );
};

ConfirmInvoiceModal.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ConfirmInvoiceModal;
