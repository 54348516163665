import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Switch from "@components/Switches";
import DataTable from "@components/DataTable/DataTable";
import { TextLargeSemiBoldWeight } from "@components/Text";

import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import getApiLogs from "@app/services/integrations/getApiLogs";

import ErrorModal from "@pages/user/OrderResults/ErrorModal";

import { parseFilterParams } from "@utils/utils";

const endpoints = [
  {
    name: "person-information",
    code: "/integrations/v1/person-information",
  },
  {
    name: "products",
    code: "/integrations/v1/products",
  },
  {
    name: "orders",
    code: "/integrations/v1/orders",
  },
  {
    name: "reports",
    code: "/integrations/v1/reports",
  },
  {
    name: "reports",
    code: "/integrations/v1/reports/{id}",
  },
];

const statuses = [
  {
    name: "200",
    code: "200",
  },
  {
    name: "500",
    code: "500",
  },
  {
    name: "403",
    code: "403",
  },
  {
    name: "404",
    code: "404",
  },
];

const IntegrationsAPILogs = ({ customerData, updateCustomerDetails }) => {
  const { messages } = useIntl();
  const { integration_api_enabled: customersApiEnabled } = customerData || {};

  const [isIntegrationsApiEnabled, setIsIntegrationsApiEnabled] =
    useState(customersApiEnabled);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchApiLogs = async (params = "") => {
    try {
      const {
        rows = 10,
        page = 1,
        sortOrder = 1,
        filters = {},
        sortField = "timestamp",
        first = 0,
      } = params;

      const sort = sortOrder === 1 || sortOrder === "desc" ? "desc" : "asc";

      let payload = `?page=${page}&per_page=${rows}&orderBy=${sortField},${sort}`;

      if (filters) {
        payload = `${payload}&${parseFilterParams(["filter", filters])}`;
      }

      const urlParam = `${payload}&first=${first}`;

      const response = await getApiLogs(urlParam);

      return response;
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const config = {
    title: "",
    appliedFilters: [],
    dataTableActions: [
      {
        id: "filter",
        type: "table-action",
        icon: "filter",
        variant: "header",
        borderRadius: 0,
        content: messages.filter_description,
      },
      {
        id: "download",
        type: "table-action",
        icon: "headerarrowright",
        variant: "header",
        height: 40,
        px: 3,
        content: messages.download_csv_file,
      },
    ],
    headerActions: [],
    filterType: "dialog",
    filters: [
      {
        title: messages.header_endpoint,
        id: "whereIn[endpoint]",
        type: "multiselect",
        options: [
          {
            name: "whereIn[endpoint]",
            value: "",
            placeholder: messages.placeholder_choose,
            id: "whereIn[endpoint]",
            options: endpoints,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_date,
        id: "whereBetween[timestamp]",
        type: "calendar",
        label: messages.label_date,
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: "whereBetween[timestamp]",
            selectionMode: "range",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "whereIn[status]",
        type: "multiselect",
        options: [
          {
            name: "whereIn[status]",
            value: "",
            placeholder: messages.placeholder_choose,
            id: "whereIn[status]",
            options: statuses,
            type: "multiselect",
          },
        ],
      },
    ],
    columns: [
      {
        field: "endpoint",
        header: messages.header_endpoint,
        sortable: false,
        body: "general",
      },
      {
        field: "method",
        header: messages.header_method,
        sortable: false,
        body: "general",
      },
      {
        field: "timestamp",
        header: messages.label_date_and_time,
        sortable: false,
        body: "dateAndTime",
      },
      {
        field: "id",
        header: messages.header_client_id,
        sortable: false,
        body: "general",
      },
      {
        field: "ip",
        header: messages.header_ip_address,
        sortable: false,
        body: "general",
      },
    ],
    pagination: true,
    sortable: true,
    isFilterValuesLoaded: true,
    loadData: fetchApiLogs,
  };

  const handleCustomersApiActivationChange = async (event) => {
    const { value } = event;

    const { data: updatedCustomerData = {} } = await editCustomerInfoService({
      id: customerData.id,
      integration_api_enabled: value,
    });

    updateCustomerDetails({
      ...updatedCustomerData,
      integration_api_enabled: value,
    });

    setIsIntegrationsApiEnabled(value);
  };

  const handleCloseErrorModal = () => {
    setErrorMessage("");
  };

  return (
    <Div>
      {errorMessage && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          onHide={handleCloseErrorModal}
          width={[1, 1, "auto", "auto"]}
          m={[3, 3, "auto", "auto"]}
        >
          <ErrorModal
            content={errorMessage}
            handleConfirmPopup={handleCloseErrorModal}
          />
        </Dialog>
      )}

      <Div display="flex" alignItems="center">
        <Switch
          mr={3}
          checked={isIntegrationsApiEnabled}
          onChange={handleCustomersApiActivationChange}
        />
        <TextLargeSemiBoldWeight>
          {`${messages.label_api_is} ${
            customersApiEnabled ? messages.active : messages.label_disabled
          }`}
        </TextLargeSemiBoldWeight>
      </Div>

      {isIntegrationsApiEnabled && <DataTable config={config} />}
    </Div>
  );
};

IntegrationsAPILogs.propTypes = {
  customerData: PropTypes.object,
  updateCustomerDetails: PropTypes.func,
};

export default IntegrationsAPILogs;
