import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import DataTable from "@components/DataTableV2/DataTable";
import TabHeader from "@components/TabHeader";
import usePermissions from "@hooks/usePermissions";

import { ROUTES, PRODUCTS } from "@utils/constant";
import {
  COUPON_STATUS,
  PERMISSIONS,
  PRODUCT_TYPE,
  SERVICE_TYPE,
} from "@utils/enum";

const SEARCHABLE_COLUMNS = [
  "id",
  "name",
  "service_type",
  "default_available",
  "listing_order",
  "price",
  "type",
];

const ServicesList = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { checkForReadPermission, checkForWritePermission } = usePermissions();

  const isPricesPermission = checkForReadPermission(PERMISSIONS.PRICES);
  const isProductsPermission = checkForWritePermission(PERMISSIONS.PRODUCTS);

  const handleEditProduct = (event) => {
    const { id } = event;

    return `/admin/produkter/tjanster/${id}`;
  };

  const handleCreateProduct = () => {
    history.push(ROUTES.ADMIN_CREATE_SERVICE.URL);
  };

  const config = {
    header: {
      title: "label_services",
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    filters: [
      {
        title: messages.label_availability,
        id: "default_available",
        type: "checkbox",
        options: [
          {
            label: messages.yes_label,
            value: 1,
            checked: false,
          },
          {
            label: messages.no_label,
            value: 0,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_service_type,
        id: "filter[service_type]",
        type: "checkbox",
        options: [
          {
            label: messages.analysis,
            value: messages[SERVICE_TYPE.ANALYSIS],
            checked: false,
          },
          {
            label: messages.assignment,
            value: messages[SERVICE_TYPE.ASSIGNMENT],
            checked: false,
          },
          {
            label: messages.servicetype_bundle,
            value: messages[`servicetype_${SERVICE_TYPE.BUNDLE}`],
            checked: false,
          },
        ],
      },
      {
        title: messages.label_status,
        id: "state",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: COUPON_STATUS.ACTIVE,
            checked: true,
          },
          {
            label: messages.state_in_active,
            value: COUPON_STATUS.INACTIVE,
            checked: false,
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: "services.csv",
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${PRODUCTS}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [
        ["fields", SEARCHABLE_COLUMNS],
        ["filter[type]", PRODUCT_TYPE.SERVICE],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "text",
        sortable: true,
        width: "70px",
      },
      {
        db_field: "name",
        title: "label_name",
        type: "service",
        width: "30%",
      },
      {
        db_field: "service_type",
        title: "label_service_type",
        type: "enum",
        formatter: (value) => {
          return value === SERVICE_TYPE.BUNDLE ? `servicetype_${value}` : value;
        },
      },
      {
        db_field: "default_available",
        title: "header_availability",
        type: "text",
        formatter: (value) => {
          return value ? messages.yes_label : messages.no_label;
        },
      },
      {
        db_field: "listing_order",
        title: "label_listing_order",
        type: "text",
        sortable: true,
      },
      {
        db_field: "price",
        title: "watchlist_label_price",
        type: "currency",
        sortable: true,
        isHidden: !isPricesPermission,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: handleEditProduct,
          },
        ],
      },
    ],
  };

  const tabHeader = {
    title: messages.label_services,
    actions: [
      {
        id: "new-service",
        type: "button",
        label: messages.button_add_new,
        width: "auto",
        icon: "plus",
        variant: "header",
        isHidden: !isProductsPermission,
        onClick: handleCreateProduct,
      },
    ],
  };

  return (
    <>
      <TabHeader {...tabHeader} />
      <DataTable config={config} />
    </>
  );
};

export default ServicesList;
