import React, { useState } from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { H1 } from "@components/Heading";
// import { Text, TextMediumWeight } from "@components/Text";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import ProgressSpinner from "@components/ProgressSpinner";
import NewOrExistingAccount from "@pages/auth/shared/NewOrExistingAccount";
import RegisterAccountForm from "./RegisterAccountForm";
import registrationService from "@app/services/auth/registrationService";
import createCustomerService from "@app/services/auth/createCustomerService";
import { ROUTES, ADMIN_CUSTOMER_DETAILS, COMPANY } from "@utils/constant";
import ErrorMessage from "@pages/shared/ErrorMessage";
import { isCustomerUser } from "../../../utils/utils";
import ErrorModal from "../../user/OrderResults/ErrorModal";
import Dialog from "@components/Dialog";

export const RegisterAccount = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { messages } = useIntl();
  const location = useHistory();
  const { search = "" } = useLocation();
  const { customer_id: customerId = "" } = queryString.parse(search);
  const { isCreateCustomer = false } = props;

  if (isCustomerUser()) {
    location.push(ROUTES.COMPANY_INFO.URL);
  }
  const handleRegistrationSubmit = async (data) => {
    try {
      setIsFetching(true);
      const type = COMPANY;

      let payload = {
        type: type,
        ...data,
      };
      let response = [];

      if (customerId) {
        payload = { ...payload, customer_id: customerId };
      }

      if (isCreateCustomer) {
        response = await createCustomerService(payload);
      } else {
        response = await registrationService(payload);
      }

      const { data: { customer = {} } = {} } = response;
      const { id = "" } = customer || {};
      setIsFetching(false);
      isCreateCustomer
        ? location.push(
            `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
            { id: id },
          )
        : location.push(ROUTES.REGISTRATION_SUCCESSFUL.URL);
    } catch (e) {
      setIsFetching(false);
      setErrorMessage(messages.message_account_already_exist);
      throw new Error(e);
    }
  };

  const handleErrorModal = () => {
    setErrorMessage("");
  };

  return (
    <>
      <Section
        width="unset"
        px={"10px !important"}
        py={`${isCreateCustomer}? "0 !important": ""`}
      >
        <Container>
          {isFetching ? (
            <Div height={[200, 200, 300, 400]}>
              <ProgressSpinner />
            </Div>
          ) : (
            <Div>
              {!isCreateCustomer && (
                <Div
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <H1 mt={4}>{messages.title_registration}</H1>
                </Div>
              )}
              <ErrorMessage />
              {errorMessage && (
                <Dialog
                  visible="displayBasic"
                  draggable={false}
                  onHide={handleErrorModal}
                  width={["90%", "90%", "90%", 445]}
                >
                  <ErrorModal
                    handleConfirmPopup={handleErrorModal}
                    content={errorMessage}
                    hideContactUsButton
                  />
                </Dialog>
              )}
              <RegisterAccountForm
                isCompany
                handleRegistrationSubmit={handleRegistrationSubmit}
                errorMessage={errorMessage}
                {...props}
              />
            </Div>
          )}
        </Container>
      </Section>
      {!isCreateCustomer && !isFetching && (
        <NewOrExistingAccount isNew={false} />
      )}
    </>
  );
};

RegisterAccount.propTypes = {
  isAdmin: Proptypes.bool,
  isCreateCustomer: Proptypes.bool,
};

export default RegisterAccount;
