import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { default as Div } from "@components/Div";
import { useIntl } from "react-intl";
import Logo from "../Logo";
import { H2, H4 } from "@components/Heading";
import ActionTemplate from "./ActionTemplate";
import FilterTemplate from "./FilterTemplate";
import { onFilterSelectedData } from "@utils/utils";
import { Text, TextMediumWeight } from "@components/Text";
import CloseIcon from "@assets/close-icon.svg";
import Link from "@components/Link";

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: var(--grey);
  font-weight: var(--medium-weight);
  cursor: pointer;
`;

export const HeaderFilterTemplate = (props) => {
  const { messages } = useIntl();
  const [displayBasic, setDisplayBasic] = useState(false);

  const {
    title,
    filterType,
    subTitle,
    headerActions,
    searchAction,
    dataTableActions,
    filters,
    onApplyFilter,
    onCancelFilter,
    appliedFilters,
  } = props;

  let [options, setOptions] = useState(filters);

  const handleOnClick = () => {
    setDisplayBasic(true);
  };

  useEffect(() => {
    setOptions(filters);
  }, [filters]);

  const handleOnHide = () => {
    setDisplayBasic(false);
  };

  const getFilterCapsule = (datelabel, title, label, key, onClick) => (
    <Div
      display="flex"
      alignItems="center"
      bg="var(--white)"
      p={"4px 8px"}
      borderRadius={"5px"}
      key={key}
      mr={14}
      mb={1}
      onClick={onClick}
    >
      <Logo width={9} height={9} mr={10} logo={CloseIcon} />
      <Text
        color="var(--light-gray) !important"
        fontWeight="var(--light-weight) !important"
        fontSize={"var(--fs-text-small) !important"}
        lineHeight={"var(--fs-text-small) !important"}
      >
        {datelabel || title}:
      </Text>
      <TextMediumWeight
        ml={1}
        lineHeight={"var(--fs-text-small) !important"}
        fontSize={"var(--fs-text-small) !important"}
      >
        {label}
      </TextMediumWeight>
    </Div>
  );

  const filterSelectedValues = (values = [], data = {}) => {
    const {
      title = "",
      options = [],
      type = "",
      id: filterColumnId = "",
    } = data;
    if (type === "calendar") {
      const [daterange = ""] = values;
      const [start_date = "", end_date = ""] = daterange.split(",");
      return getFilterCapsule(
        messages.label_date,
        title,
        `${start_date} - ${end_date}`,
        `filter-date-`,
        handleOnChangeOfFilterValue(
          values,
          type,
          "whereBetween[created_at]",
          "",
        ),
      );
    } else if (type === "checkbox") {
      const filterItems = options.filter((o) => values?.includes(o?.value));
      const label = filterItems.map((o) => o.label).join(", ");
      return getFilterCapsule(
        "",
        title,
        label,
        `filter-${filterColumnId}-`,
        handleOnChangeOfFilterValue(values, type, filterColumnId),
      );
    }
    return values?.map((value, i) => {
      let {
        label = "",
        id = "",
        options: multiselectOptions = [],
      } = options.find((opt) => opt.value === value) || {};
      let datelabel = "",
        filterId = "",
        optionId = "";
      if (type === "calendar") {
        label = value;
        datelabel = i ? messages.filter_date_to : messages.filter_date_from;
        filterId = i ? "end_date" : "start_date";
      }
      if (type === "input") {
        label = value;
        filterId = id;
      }
      if (type === "multiselect") {
        const filterValues = value.map((o) => o?.code);
        const finalValues = multiselectOptions.filter((obj) =>
          filterValues.includes(obj?.code),
        );
        label = finalValues.map((o) => o?.name).join(",");
        optionId = value;
        filterId = id;
      }
      return getFilterCapsule(
        datelabel,
        title,
        label,
        `filter-${filterId}-${optionId}`,
        handleOnChangeOfFilterValue(value, type, filterId, optionId),
      );
    });
  };

  const renderFiterCapules = () => {
    return appliedFilters?.map((obj) => {
      const { id, values } = obj;
      const data = filters.find((filter) => filter.id === id);
      return filterSelectedValues(values, data);
    });
  };

  const handleOnChangeOfFilterValue = (value, type, id, optionId) => (e) => {
    const filterSelectedData = options?.map((optValues) => {
      if (optValues.options)
        optValues.options = optValues.options?.map((obj) => {
          if (type === "calendar" && obj.id === id) {
            return { ...obj, value: "" };
          } else if (type === "input" && obj.id === id) {
            return { ...obj, value: "" };
          } else if (type === "multiselect" && obj.id === id && optionId) {
            return { ...obj, value: "" };
          } else if (
            value?.includes(obj?.value) &&
            type === "checkbox" &&
            id === optValues.id
          ) {
            return { ...obj, checked: !obj.checked };
          }
          return obj;
        });
      return optValues;
    });
    setOptions(filterSelectedData);
    const selectedData = onFilterSelectedData(options);
    onApplyFilter(selectedData, filterSelectedData, e);
  };

  const filterTemplateProps = {
    filterType,
    filters,
    onApplyFilter,
    onCancelFilter,
    options,
    setOptions,
    handleOnChangeOfFilterValue,
    onFilterSelectedData,
  };

  const handleClearAllFilters = (e) => {
    const clearfilters = options.map((obj) => {
      const { options: objOptions } = obj;
      obj.options = objOptions.map((opt) => {
        const { id = "" } = opt;
        if (
          id === "whereBetween[created_at]" ||
          obj.type === "input" ||
          obj.type === "multiselect"
        ) {
          return { ...opt, value: "" };
        }
        return { ...opt, checked: false };
      });
      return obj;
    });
    setOptions(clearfilters);
    const selectedData = onFilterSelectedData(options);
    onApplyFilter(selectedData, clearfilters, e);
  };

  const headerTemp = (searchAction, dataTableActions) => {
    return (
      <Div
        display="flex"
        bg="var(--turquoise-light)"
        justifyContent="space-between"
        pb={"14px"}
        flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
        alignItems="center"
        borderBottom="1px solid var(--white)"
      >
        <Div pt="14px">
          {searchAction && ActionTemplate("search", { ...searchAction })}
        </Div>
        <Div display="flex" pt="14px" ml={3} alignItems="center">
          {!!appliedFilters?.length && (
            <>
              <Div
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="right"
              >
                {renderFiterCapules()}
              </Div>
              <StyledLink
                fontSize={"var(--fs-text-small) !important"}
                lineHeight={"var(--fs-text-small) !important"}
                onClick={handleClearAllFilters}
                pr={2}
              >
                {messages.label_clear_filters}
              </StyledLink>
            </>
          )}
          {dataTableActions?.map((obj) =>
            ActionTemplate(obj.type, {
              ...obj,
              onClick: obj.id === "filter" ? handleOnClick : props.onDownload,
            }),
          )}
        </Div>
      </Div>
    );
  };

  const headerComponent = (children) => (
    <>
      <FilterTemplate
        {...filterTemplateProps}
        showDialog={displayBasic}
        onHide={handleOnHide}
      />

      <Div
        className="table-header"
        flexDirection={["column", "column", "row", "row"]}
      >
        <Div flexDirection={"column"} width={[1, 1, "auto", "auto"]}>
          <H2 my={3}>{title}</H2>
          {subTitle && <H4 my={3}>{subTitle}</H4>}
        </Div>
        <Div
          alignItems={"center"}
          width={[1, 1, "auto", "auto"]}
          mb={[3, 3, 0, 0]}
        >
          {children}
        </Div>
      </Div>

      {!!(searchAction || dataTableActions?.length) &&
        headerTemp(searchAction, dataTableActions)}
    </>
  );
  const headerEl = headerActions?.map((value) => {
    if (value.id === "menu-button") {
      return ActionTemplate(value.type, { ...value });
    }
    return ActionTemplate(value.type, value);
  });
  return headerComponent(headerEl);
};
