import React from "react";
import Container from "@components/Container";
import DataTable from "@components/Table/Table";
import ScrollToTop from "@utils/ScrollToTop";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";

const ReportList = ({ analysData = {}, isRoleAnalysis, isActaPublica }) => {
  const { messages } = useIntl();
  const { report_items: reportItems = [] } = analysData;

  let crimesList = reportItems
    .filter((o) => Number(o.points) > 0)
    .map((o) => ({
      ...o,
      case_nr: o?.cases?.case_nr,
      court: o?.cases?.court,
      date: o?.cases?.date,
    }));

  const ColumnsForRoleAnalysis = [
    {
      field: "keyname",
      header: messages.label_name,
      primary: true,
      width: "350px",
    },
    { field: "case_nr", header: messages.header_case_nr, sortable: false },
    { field: "court", header: messages.header_court },
    { field: "date", header: messages.label_date },
    { field: "points", header: messages.title_points },
    { field: "id", header: messages.title_id },
  ];

  const ColumnsForGeneralAnalysis = [
    {
      field: "keyname",
      header: messages.title_keyname,
      primary: true,
      width: "350px",
    },
    { field: "id", header: messages.title_id, sortable: false },
    { field: "value", header: messages.title_value },
    { field: "points", header: messages.title_points },
    { field: "count", header: messages.title_count },
    { field: "status", header: messages.title_status },
    { field: "id", header: messages.title_id },
  ];

  const ColumnsForActaPublicaAnalysis = [
    {
      field: "keyname",
      header: messages.title_keyname,
      primary: true,
      width: "350px",
    },
    { field: "id", header: messages.title_id, sortable: false },
    { field: "points", header: messages.title_points },
    { field: "status", header: messages.title_status },
    { field: "id", header: messages.title_id },
  ];

  let column = null;
  if (isActaPublica) {
    column = ColumnsForActaPublicaAnalysis;
  } else if (isRoleAnalysis) {
    column = ColumnsForRoleAnalysis;
  } else {
    column = ColumnsForGeneralAnalysis;
  }
  let config = [
    {
      title: isRoleAnalysis
        ? messages.title_crime_items
        : messages.title_report_items,
      headerActions: [],
      filterType: "dialog",
      filters: [],
      columns: column,
      rowActions: [],
      pagination: false,
      sortable: true,
      selectionMode: "multiple",
    },
  ];

  return (
    <ScrollToTop>
      <Container>
        {!!isRoleAnalysis && !!crimesList?.length && (
          <DataTable config={config} tableData={crimesList} />
        )}
        {!isRoleAnalysis && (
          <DataTable config={config} tableData={reportItems} />
        )}
      </Container>
    </ScrollToTop>
  );
};

ReportList.propTypes = {
  analysData: Proptypes.array,
  isRoleAnalysis: Proptypes.bool,
  isActaPublica: Proptypes.bool,
};

export default ReportList;
