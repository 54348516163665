import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import { TextMediumWeight } from "@components/Text";
import PropTypes from "prop-types";
import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import patchAccountStatus from "@app/services/billingAccounts/patchAccountStatus";
import Switch from "@components/Switches";
import { INPUT } from "@utils/constant";
import { SmallEllipseIcon } from "@components/Icon";
import Tooltip from "@components/ToolTip";

const BillingAccountsTab = ({
  customer_id,
  customerData,
  updateCustomerData,
  activeSubscription,
}) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { invoice } = customerData;
  const [isPaymentTypeInvoice, setIsPaymentTypeInvoice] = useState(!!invoice);

  const handleOnChange = async (e) => {
    const {
      target: { value },
    } = e;
    setLoading(true);
    setIsPaymentTypeInvoice(value);
    try {
      await editCustomerInfoService({
        id: customer_id,
        invoice: value ? 1 : 0,
      });
      updateCustomerData({
        invoice: value ? 1 : 0,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const { INVOICE } = INPUT.NAME;

  const handleRedirectToCreateBilling = () => {
    history.push(`/admin/kunder/${customer_id}/faktura/skapa-fakturakonto`);
  };

  const handleRedirectToUpdateBilling = (values) => {
    const { id } = values;
    history.push(`/admin/kunder/${customer_id}/faktura/${id}/detaljer`);
  };

  const handleDeactivateAccount = async (values) => {
    const { id } = values;
    try {
      setLoading(true);
      await patchAccountStatus({
        billing_account_id: id,
        active: 0,
      });
    } catch (_error) {
      // Show error message when failed to deactivate account
    } finally {
      setLoading(false);
    }
  };

  const searchableColumns = [
    "name",
    "email_invoice",
    "your_reference",
    "invoice_remark",
  ];

  const config = {
    header: {
      title: "header_billing_accounts",
      actions: [
        {
          type: "button",
          label: messages.button_add_new,
          icon: "plus",
          id: "create-billing",
          onClick: handleRedirectToCreateBilling,
          isHidden: false,
        },
      ],
    },
    row_selection_checkbox: false,
    enable_filter: true,
    backend_querying: true,
    no_records_message: "billing_no_records_found",
    row_selection_actions: [],
    enable_search: true,
    enable_query_builder: true,
    enable_csv_download: true,
    csv_filename: `billing-accounts.csv`,
    api: {
      resource: `/billing-accounts`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["filter[customer_id]", customer_id],
        ["filter[active]", 1],
        [
          "fields",
          [
            "id",
            "name",
            "email_invoice",
            "your_reference",
            "invoice_remark",
            "active",
          ],
        ],
      ],
    },
    columns: [
      {
        title: "header_company_name",
        db_field: "name",
        type: "text",
      },
      {
        title: "header_billing_email",
        db_field: "email_invoice",
        type: "text",
      },
      {
        title: "header_reference_person",
        db_field: "your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "invoice_remark",
        type: "text",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.view_details,
            icon: "icon-showeye",
            onClick: handleRedirectToUpdateBilling,
          },
          {
            label: messages.deactivate_acc,
            icon: "icon-rubber",
            onClick: handleDeactivateAccount,
          },
        ],
      },
    ],
  };
  return (
    <Container m={"0px !important"}>
      <Div display="flex" alignItems="center" mt={4}>
        <Switch
          name={INVOICE}
          checked={isPaymentTypeInvoice}
          onChange={handleOnChange}
          mr={3}
        />
        <TextMediumWeight>
          {messages.label_invoiced_in_arrears}
        </TextMediumWeight>
        <SmallEllipseIcon ml={2} className={"invoice-info"} name="info">
          <Tooltip
            content={messages.pay_as_u_go_info}
            target={`.invoice-info`}
          />
        </SmallEllipseIcon>
      </Div>
      {(activeSubscription || isPaymentTypeInvoice) && (
        <DataTable config={config} resetData={!loading} />
      )}
    </Container>
  );
};

BillingAccountsTab.propTypes = {
  customer_id: PropTypes.id,
  customerData: PropTypes.object,
  updateCustomerData: PropTypes.func,
  activeSubscription: PropTypes.bool,
};

export default BillingAccountsTab;
