import styled from "styled-components";
import { PrimaryButton } from "@components/Button";

export const RoundedPrimaryButton = styled(PrimaryButton)`
  &.p-button-icon-only {
    &.p-button-rounded {
      height: 40px;
      border-radius: 50px;
    }
  }
`;

export default RoundedPrimaryButton;
