import React from "react";
import styled from "styled-components";
import Div from "@components/Div";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";

const mobileBreakPoint = Config.mobileBreakPoint;

const StyledDiv = styled(Div)`
  .overlay {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  @-webkit-keyframes "spinner-fade" {
    0% {
      background-color: var(--blue-dark);
    }
    100% {
      background-color: transparent;
    }
  }
  @keyframes "spinner-fade" {
    0% {
      background-color: var(--blue-dark);
    }
    100% {
      background-color: transparent;
    }
  }
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    .spinner.center {
      bottom: 50% !important;
    }
  }
  .spinner {
    font-size: 95px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  .spinner.center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .spinner-blade {
    position: absolute;
    left: 47%;
    bottom: 14%;
    right: 0px;
    width: 5px;
    height: 16px;
    border-radius: 0.5em;
    background-color: transparent;
    transform-origin: center -0.18em;
    -webkit-animation: spinner-fade 1s infinite linear;
    animation: spinner-fade 1s infinite linear;
    &:nth-child(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
      transform: rotate(0deg);
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.083s;
      animation-delay: 0.083s;
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.166s;
      animation-delay: 0.166s;
      transform: rotate(90deg);
    }
    &:nth-child(4) {
      -webkit-animation-delay: 0.249s;
      animation-delay: 0.249s;
      transform: rotate(135deg);
    }
    &:nth-child(5) {
      -webkit-animation-delay: 0.332s;
      animation-delay: 0.332s;
      transform: rotate(180deg);
    }
    &:nth-child(6) {
      -webkit-animation-delay: 0.415s;
      animation-delay: 0.415s;
      transform: rotate(225deg);
    }
    &:nth-child(7) {
      -webkit-animation-delay: 0.498s;
      animation-delay: 0.498s;
      transform: rotate(270deg);
    }
    &:nth-child(8) {
      -webkit-animation-delay: 0.581s;
      animation-delay: 0.581s;
      transform: rotate(315deg);
    }
    &:nth-child(9) {
      -webkit-animation-delay: 0.664s;
      animation-delay: 0.664s;
      transform: rotate(360deg);
    }
  }
`;
const ProgressSpinner = () => {
  return (
    <StyledDiv className="overlay">
      <StyledDiv className="spinner center">
        {[...Array(8)].map((_, index) => (
          <StyledDiv key={`ps-${index}`} className="spinner-blade"></StyledDiv>
        ))}
      </StyledDiv>
    </StyledDiv>
  );
};

export default ProgressSpinner;
