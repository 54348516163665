import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Container from "@components/Container";
import { H2, H4 } from "@components/Heading";
import { TextLarge } from "@components/Text";
import { getFormattedNumber } from "@utils/utils";

const Credits = () => {
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { customer = {} } = userInfo;
  const {
    prepaid_reports: totalCredits = 0,
    available_credits: availableCredits = 0,
  } = customer;
  const { messages } = useIntl();

  return (
    <Container m={"0px !important"}>
      <H2 my={3}>{messages.title_prepaid_account}</H2>
      <TextLarge>{messages.status_current_credits}</TextLarge>
      <TextLarge display="block" mt={20}>
        {messages.message_current_credits}
      </TextLarge>
      <H4 mt={20}>
        {messages.text_remaining_credits}:{" "}
        {getFormattedNumber(availableCredits)}
      </H4>
      <H4 mt={20} mb={[50, 200]}>
        {messages.text_total_credits}: {getFormattedNumber(totalCredits)}
      </H4>
    </Container>
  );
};

export default Credits;
