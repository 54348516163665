import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Container from "@components/Container";
import DataTable from "@components/DataTableV2/DataTable";
import ErrorDialog from "@components/ErrorDialog";
import SuccessDialog from "@components/SuccessDialog";

import createInvoice from "@app/services/billingAccounts/createInvoice";
import getTentativeDetails from "@app/services/billingAccounts/getTentativeDetails";
import patchOrderInvoiceStatus from "@app/services/billingAccounts/patchOrderInvoiceStatus";

import {
  ROUTES,
  WRITE,
  READ,
  ADMIN_CUSTOMER_DETAILS,
  NOT_INVOICED,
  INVOICED,
  INVOICE_CANCELLED,
} from "@utils/constant";

import { INVOICE_MODAL_TYPES } from "@utils/enum";

import { getFormattedNumber } from "@utils/utils";

import ConfirmInvoiceModal from "./ConfirmInvoiceModal";

const searchableColumns = [
  "id",
  "name",
  "email_invoice",
  "your_reference",
  "invoice_remark",
  "fortnox_customer_id",
];

const FILTER_KEY_FROM = "filter[orders.created_at][gte]";

const FILTER_KEY_TO = "filter[orders.created_at][lte]";

const TYPE_DEPENDANT_INVOICE_DETAILS = {
  [INVOICE_MODAL_TYPES.CONFIRM]: {
    nextStatus: INVOICED,
    messagePlural: <FormattedMessage id="invoices_confirmed_text" />,
    messageSingular: <FormattedMessage id="invoice_confirmed_text" />,
  },
  [INVOICE_MODAL_TYPES.CANCEL]: {
    nextStatus: INVOICE_CANCELLED,
    messagePlural: <FormattedMessage id="invoices_canceled_text" />,
    messageSingular: <FormattedMessage id="invoice_canceled_text" />,
  },
};

const UnBilledAccounts = () => {
  const { messages } = useIntl();

  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoiceDialogType, setInvoiceDialogType] = useState(
    INVOICE_MODAL_TYPES.CONFIRM,
  );
  const [selectedData, setSelectedData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [tentativeDetails, setTentativeDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const shouldShowConfirmDialog = tentativeDetails !== null;
  const shouldShowSuccessDialog = successMessage !== "";
  const shouldShowErrorDialog = errorMessage !== "";

  const handleOnFiltersApply = (values) => {
    setFilters(values);
  };

  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const { permissions = {} } = userInfo;

  const hasReadCustomerPermission =
    permissions?.customers === WRITE || permissions?.customers === READ;

  const buildPayload = (data = []) => {
    let billing_accounts;

    if (Array.isArray(data)) {
      billing_accounts = data.map((o) => o?.id);
    } else {
      let { id = "" } = data;

      billing_accounts = [id];
    }

    const payload = { billing_accounts };

    filters.forEach((filter) => {
      const { id, values } = filter;
      const [date = ""] = values;

      if (id === FILTER_KEY_FROM) {
        payload.start_date = date;
      }

      if (id === FILTER_KEY_TO) {
        payload.end_date = date;
      }
    });

    return payload;
  };

  const getInvoiceDetails = async (data) => {
    try {
      setLoading(false);

      const payload = buildPayload(data);
      const response = await getTentativeDetails(payload);

      setTentativeDetails(response);
    } catch (error) {
      setErrorMessage(messages.error_try_later);
    } finally {
      setLoading(false);
    }
  };

  const setDataForInvoiceDetails = (data, type) => {
    setInvoiceDialogType(type);
    setSelectedData(data);

    getInvoiceDetails(data);
  };

  const updateInvoiceStatus = async (data, new_status, message) => {
    try {
      setTentativeDetails(null);
      setLoading(true);

      const payload = buildPayload(data);

      if (new_status === INVOICED) {
        await createInvoice(payload);
      } else {
        payload.existing_status = NOT_INVOICED;
        payload.new_status = new_status;

        await patchOrderInvoiceStatus(payload);
      }

      setSuccessMessage(message);
    } catch (e) {
      setErrorMessage(messages.error_try_later);
    } finally {
      setLoading(false);
    }
  };

  const handleInvoiceSelectedAccounts = (_, data = []) => {
    setDataForInvoiceDetails(data, INVOICE_MODAL_TYPES.CONFIRM);
  };

  const handleDoNotInvoiceSelectedAccounts = (_, data = []) => {
    setDataForInvoiceDetails(data, INVOICE_MODAL_TYPES.CANCEL);
  };

  const handleConfirmInvoiceForAccount = (data = []) => {
    setDataForInvoiceDetails(data, INVOICE_MODAL_TYPES.CONFIRM);
  };

  const handleCancelInvoiceForAccount = (data = []) => {
    setDataForInvoiceDetails(data, INVOICE_MODAL_TYPES.CANCEL);
  };

  const handleCloseSuccessPopup = () => {
    setSuccessMessage("");
  };

  const handleCloseConfirmDialog = () => {
    setTentativeDetails(null);
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const handleConfirmInvoice = () => {
    const { nextStatus, messagePlural, messageSingular } =
      TYPE_DEPENDANT_INVOICE_DETAILS[invoiceDialogType];
    const message = selectedData > 1 ? messagePlural : messageSingular;

    updateInvoiceStatus(selectedData, nextStatus, message);
  };

  const dataTableConfig = {
    header: {
      title: "label_not_invoiced",
      actions: [
        {
          type: "button",
          label: messages.invoice_all_selected,
          icon: "mark",
          id: "invoice-billing",
          onClick: handleInvoiceSelectedAccounts,
          verifyBtnIsHidden: (data) => !data?.length,
          formatter: (label, data = []) => `${label} (${data?.length})`,
        },
        {
          type: "button",
          label: messages.do_not_invoice_selected,
          icon: "rubber",
          id: "invoice-billing",
          onClick: handleDoNotInvoiceSelectedAccounts,
          verifyBtnIsHidden: (data) => !data?.length,
          formatter: (label, data = []) => `${label} (${data?.length})`,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "unbilled_accounts.csv",
    enable_row_selection: true,
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_billing_unbilled_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/billing-accounts`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["include", "orders,customer"],
        ["filter[orders.invoice_status]", "Not invoiced"],
        [
          "fields",
          [
            "id",
            "name",
            "invoice_remark",
            "active",
            "customer_id",
            "fortnox_customer_id",
            "your_reference",
          ],
        ],
      ],
    },
    default_sort: {
      field: "id",
      order: "desc",
    },
    columns: [
      {
        title: "label_fortnox_id",
        db_field: "fortnox_customer_id",
        type: "id",
        sortable: true,
        width: "115px",
      },
      {
        title: "label_customer",
        db_field: "customer.customer_name",
        type: "link",
        className: hasReadCustomerPermission ? "text-blue" : "text-grey",
        href: (values) =>
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${values["customer_id"]}/${ADMIN_CUSTOMER_DETAILS}`,
      },
      {
        title: "account",
        db_field: "name",
        type: "text",
        sortable: true,
      },

      {
        title: "placeholder_your_reference_person",
        db_field: "your_reference",
        type: "text",
      },
      {
        title: "heading_invoice_reference",
        db_field: "invoice_remark",
        type: "text",
        sortable: true,
      },
      {
        title: "billing_orders",
        db_field: "orders",
        formatter: (value) => {
          return value ? `${value?.[0]?.["ordersCount"]}` : "-";
        },
        type: "text",
        width: "120px",
      },
      {
        title: "amount",
        db_field: "orders",
        type: "text",
        formatter: (value) => {
          return value
            ? `${getFormattedNumber(value?.[0]?.["ordersTotal"])} kr`
            : "-";
        },
        width: "90px",
      },

      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.confirm_invoice,
            icon: "icon-mark",
            type: "button",
            onClick: handleConfirmInvoiceForAccount,
          },
          {
            label: messages.do_not_invoice,
            icon: "icon-close",
            type: "button",
            onClick: handleCancelInvoiceForAccount,
          },
        ],
      },
    ],
    onFiltersApply: handleOnFiltersApply,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[orders.created_at][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
    ],
  };

  return (
    <>
      {shouldShowErrorDialog && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      {shouldShowSuccessDialog && (
        <SuccessDialog
          message={successMessage}
          onConfirm={handleCloseSuccessPopup}
        />
      )}

      {shouldShowConfirmDialog && (
        <ConfirmInvoiceModal
          type={invoiceDialogType}
          data={tentativeDetails}
          onCancel={handleCloseConfirmDialog}
          onSubmit={handleConfirmInvoice}
        />
      )}

      <Container m={"0px !important"}>
        <DataTable config={dataTableConfig} resetData={!loading} />
      </Container>
    </>
  );
};

export default UnBilledAccounts;
