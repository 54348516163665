import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import fetchAllProducts from "@app/services/services/fetchProductById";
import getDataSources from "@app/services/products/getDataSources";
import getProductById from "@app/services/services/getProductById";
import updateProductById from "@app/services/services/updateProductById";

import Breadcrumb from "@components/Breadcrumb";
import ErrorDialog from "@components/ErrorDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import Tabs from "@components/Tabs";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES } from "@utils/constant";
import { SERVICE_TYPE } from "@utils/enum";

import DetailsTab from "./tabs/DetailsTab";
import FormsTab from "./tabs/FormsTab";

const UpdateService = () => {
  const { messages } = useIntl();
  const { id: productId = "" } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataSourceTypes, setDataSourceTypes] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const breadCrumbItems = [
    {
      label: messages.label_products,
      url: ROUTES.ADMIN_PRODUCTS_LIST.URL,
    },
    {
      label: data?.name || "",
    },
  ];

  const formatAndSetData = (
    responseData = {},
    dataSources = dataSourceTypes,
    servicesInBundle = serviceData,
  ) => {
    const formattedResponse = {
      ...responseData,
      default_selected: !!responseData.default_selected,
      default_available: !!responseData.default_available,
      purchase_by_credits: !!responseData.purchase_by_credits,
      state: responseData.state || "",
      service_type: responseData.service_type || "",
      analysis_type: responseData.analysis_type || "",
      data_source: responseData.data_source
        ? dataSources?.filter((o) => responseData.data_source?.includes(o.code))
        : [],
      child: responseData.child
        ? servicesInBundle?.filter((o) => responseData.child?.includes(o?.code))
        : [],
    };

    setData(formattedResponse);
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);

      try {
        const [
          { data: dataSources = [] },
          { data: { data: serviceData = [] } = {} },
          response,
        ] = await Promise.all([
          getDataSources(),
          fetchAllProducts(),
          getProductById(productId),
        ]);

        const formattedDataSources = dataSources.map((dataSource) => ({
          name: messages[`datasource_${dataSource}`],
          code: dataSource,
        }));
        const formattedServiceData = serviceData
          .filter((service) => service?.type === "service")
          .map((service) => ({ name: service?.name, code: service?.id }));

        const {
          data: { data: respData = [] },
        } = response;
        const selectedService =
          respData.find(({ id }) => id === Number(productId)) || {};

        formatAndSetData(
          selectedService,
          formattedDataSources,
          formattedServiceData,
        );

        setDataSourceTypes(formattedDataSources);
        setServiceData(formattedServiceData);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event) => {
    setActiveTabIndex(event.index);
  };

  const handleUpdateService = async (payload) => {
    setIsLoading(true);

    try {
      const { data: response = {} } = await updateProductById({
        ...payload,
        data_source: payload?.data_source?.map((o) => o.code),
        child: payload?.child?.map((o) => o.code),
      });
      formatAndSetData(response);
    } catch (e) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateServiceWithFormData = (payload) => {
    const formattedPayload = {
      ...data,
      ...payload,
    };

    handleUpdateService(formattedPayload);
  };

  const handleHideErrorDialog = () => {
    setErrorMessage("");
  };

  const customerFormData = {
    customer_form: data.customer_form,
  };

  const candidateFormData = {
    candidate_form: data.candidate_form,
  };

  const reportConfigData = {
    report_form: data.report_form,
  };

  const assignmentTabs = [
    {
      title: messages.title_customer_form,
      content: (
        <FormsTab
          formsData={customerFormData}
          onUpdateService={handleUpdateServiceWithFormData}
        />
      ),
    },
    {
      title: messages.title_candidate_form,
      content: (
        <FormsTab
          formsData={candidateFormData}
          onUpdateService={handleUpdateServiceWithFormData}
        />
      ),
    },
    {
      title: messages.title_report_config,
      content: (
        <FormsTab
          formsData={reportConfigData}
          onUpdateService={handleUpdateServiceWithFormData}
        />
      ),
    },
    {
      title: messages.title_rules,
      content: <>{messages.title_rules}</>,
    },
  ];

  const tabContent = [
    {
      title: messages.title_service_info,
      content: (
        <DetailsTab
          data={data}
          dataSourceTypes={dataSourceTypes}
          onUpdateService={handleUpdateService}
        />
      ),
    },
  ];

  if (data?.service_type === SERVICE_TYPE.ASSIGNMENT) {
    tabContent.push(...assignmentTabs);
  }

  const containerConfig = {
    title: data?.name,
  };

  return (
    <AdminContainer config={containerConfig}>
      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleHideErrorDialog}
          onConfirm={handleHideErrorDialog}
        />
      )}

      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <>
          <Breadcrumb items={breadCrumbItems} p={0} pb={2} />
          <Tabs
            activeIndex={activeTabIndex}
            onTabChange={handleTabChange}
            content={tabContent}
          />
        </>
      )}
    </AdminContainer>
  );
};

export default UpdateService;
