import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import customerDetailsService from "@app/services/customers/customerDetailsService";

import ErrorDialog from "@components/ErrorDialog";
import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";

import {
  ASSIGNEE_TYPE,
  CASES_DATA_STATUS_TYPE,
  CASES_STATUS_TYPE,
  PROCESS_TYPE,
} from "@utils/enum";

const SEARCHABLE_FIELDS = ["id", "customer.name"];

const CasesListTab = () => {
  const { messages } = useIntl();

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isFilterValuesLoaded = customers?.length !== 0;

  useEffect(() => {
    const fetchCustomers = async () => {
      setIsLoading(true);

      try {
        const payload = `?fields=id,customer_name`;
        const { data: { data = [] } = {} } =
          await customerDetailsService(payload);

        const formattedData = data.map(({ id, customer_name }) => ({
          id,
          name: customer_name,
        }));

        setCustomers(formattedData);
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const formatAssignee = (value, event) => {
    const process_type = event["product.process_type"];
    const isEmptyAssignee = !value;

    if (!isEmptyAssignee) {
      return value;
    }

    if (process_type === PROCESS_TYPE.MANUAL) {
      return ASSIGNEE_TYPE.UNASSIGNED;
    } else {
      return "-";
    }
  };

  const formatDataStatus = (dataSources) => {
    if (dataSources || !Array.isArray(dataSources)) {
      return "-";
    }

    const statusPriority = [
      {
        status: CASES_DATA_STATUS_TYPE.COMPLETED,
        checker: (dataSource) =>
          dataSource?.status !== CASES_DATA_STATUS_TYPE.COMPLETED,
      },
      {
        status: CASES_DATA_STATUS_TYPE.PENDING,
        checker: (dataSource) =>
          dataSource?.status === CASES_DATA_STATUS_TYPE.PENDING,
      },
      {
        status: CASES_DATA_STATUS_TYPE.MANUAL,
        checker: (dataSource) =>
          dataSource?.status === CASES_DATA_STATUS_TYPE.MANUAL,
      },
    ];

    statusPriority.forEach(({ status, checker }) => {
      if (dataSources?.some(checker)) {
        return status;
      }
    });

    return "";
  };

  const dataTableConfig = {
    header: {
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    is_filter_options_updated: isFilterValuesLoaded,
    filters: [
      {
        title: messages.label_assignee,
        id: "filter[user]",
        type: "checkbox",
        options: [
          {
            label: messages[ASSIGNEE_TYPE.UNASSIGNED],
            value: ASSIGNEE_TYPE.UNASSIGNED,
            checked: false,
          },
          {
            label: messages[ASSIGNEE_TYPE.ASSIGNEE_ID],
            value: ASSIGNEE_TYPE.ASSIGNEE_ID,
            checked: false,
          },
          {
            label: messages.label_name,
            value: ASSIGNEE_TYPE.NAME,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_customer_name,
        id: "filter[customer.name]",
        type: "multiselect",
        options: [
          {
            id: "filter[customer.name]",
            name: "filter[customer.name]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: customers,
          },
        ],
      },
      {
        title: messages.registered_date,
        id: "filter[created_at]",
        type: "calendar",
        options: [
          {
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
            label: messages.calendar_date_select_placeholder,
            value: "",
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "checkbox",
        options: [
          {
            label: messages[CASES_STATUS_TYPE.IN_PROGRESS],
            value: CASES_STATUS_TYPE.IN_PROGRESS,
            checked: false,
          },
          {
            label: messages[CASES_STATUS_TYPE.COMPLETED],
            value: CASES_STATUS_TYPE.COMPLETED,
            checked: false,
          },
          {
            label: messages[CASES_STATUS_TYPE.CANCELLED],
            value: CASES_STATUS_TYPE.CANCELLED,
            checked: false,
          },
          {
            label: messages.label_pending,
            value: CASES_STATUS_TYPE.PENDING,
            checked: false,
          },
          {
            label: messages.label_manual,
            value: CASES_STATUS_TYPE.MANUAL,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_data_status,
        id: "filter[product.data_source]",
        type: "checkbox",
        options: [
          {
            label: messages.label_pending,
            value: CASES_DATA_STATUS_TYPE.PENDING,
            checked: false,
          },
          {
            label: messages[CASES_DATA_STATUS_TYPE.COMPLETED],
            value: CASES_DATA_STATUS_TYPE.COMPLETED,
            checked: false,
          },
          {
            label: messages.label_manual,
            value: CASES_DATA_STATUS_TYPE.MANUAL,
            checked: false,
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: "cases.csv",
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: "/cases",
      method: "GET",
      search_fields: SEARCHABLE_FIELDS,
      params: [["include", ["orderItem", "user", "product"]]],
    },
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "text",
        formatter: (value) => (value ? `#${value}` : "-"),
        width: "8%",
        sortable: true,
      },
      {
        db_field: "customer",
        title: "title_customers",
        type: "link",
        formatter: (value) => value?.name || "-",
        href: (value) => `/admin/kunder/${value?.id || ""}/detaljer`,
        width: "15%",
      },
      {
        db_field: "created_at",
        title: "title_registered",
        type: "date",
        width: "12%",
        sortable: true,
      },
      {
        db_field: "product.name",
        title: "label_product",
        type: "text",
        width: "15%",
      },
      {
        db_field: "user.name",
        title: "label_assignee",
        type: "text",
        width: "15%",
        formatter: formatAssignee,
      },
      {
        db_field: "product.data_source",
        title: "label_data_status",
        type: "service",
        formatter: formatDataStatus,
        width: "11%",
      },
      {
        db_field: "status",
        title: "label_status",
        type: "enum",
        width: "10%",
      },
      {
        db_field: "report_status",
        title: "label_report",
        type: "enum",
        width: "8%",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: "Case Details",
            icon: "icon-headerarrowright",
            type: "link",
            href: (value) => `${value}`,
          },
          {
            label: "Source Data",
            icon: "icon-file-download",
            type: "link",
            href: (value) => `${value}`,
          },
          {
            label: "Comments",
            icon: "icon-comment",
            type: "link",
            href: (value) => `${value}`,
          },
          {
            label: "See report",
            icon: "icon-menu-report",
            type: "link",
            href: (value) => `${value}`,
          },
        ],
      },
    ],
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      <DataTable config={dataTableConfig} />
    </>
  );
};

export default CasesListTab;
