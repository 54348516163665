import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import Axios from "@app/api/axios";
import Div from "@components/Div";
import {
  INPUT,
  INDIVIDUAL,
  NO_CONSENT,
  CONSENT_OPTIONAL,
  CONSENT_MANDATORY,
  USERS,
} from "@utils/constant";
import NameSchema from "@pages/auth/schema/Name";
import NumbersSchema from "@pages/auth/schema/Numbers";
import EmailSchema from "@pages/auth/schema/Email";
import BillingEmailSchema from "@pages/auth/schema/BillingEmail";

import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import ConfirmationDialog from "@pages/shared/ConfirmationDialog";
import { authActions } from "@app/reducers/authReducer";
import { addHyphenToNumber } from "@utils/utils";

import EditableFieldsCard from "@components/EditableFieldsCard";

import { FORM_INPUT_TYPE } from "@utils/enum";

const CompanyInfo = ({
  // onCustomerDelete = () => {},
  customerData = {},
  id = "0",
  // status: companyStatus = "Active",
  updateCustomerData,
}) => {
  const { messages } = useIntl();
  const {
    // PHONE,
    COMPANY_NAME,
    COMPANY_NUMBER,
    NAME,
    LAST_NAME,
    // EMAIL,
    BILLING_EMAIL,
  } = INPUT.NAME;

  const isIndividualCustomer = customerData.type === INDIVIDUAL;

  const IndividualInfoSchema = NameSchema.pick([NAME, LAST_NAME]).concat(
    EmailSchema,
  );
  const CompanyInfoSchema = isIndividualCustomer
    ? IndividualInfoSchema
    : IndividualInfoSchema.concat(
        NameSchema.pick([COMPANY_NAME])
          .concat(NumbersSchema.pick([COMPANY_NUMBER]))
          .concat(BillingEmailSchema.pick([BILLING_EMAIL])),
      );

  const [companyDetails, setCompanyDetails] = useState({});
  const [accountManager, setAccountManager] = useState(0);
  const [isError, setIsError] = useState(false);
  const userInfo = useSelector((state) => state.authReducer.userInfo);
  const showDialog = userInfo.showConfirmDialog;

  const [user] = useState([]);
  const [users, setUsers] = useState([]);
  const [setSelectedManagers] = useState(null);

  const dispatch = useDispatch();

  const [customerConsent] = useState(customerData.consent);

  const {
    company_name,
    address,
    registration_nr,
    phone,
    firstname,
    lastname,
    email,
    invoice,
    billing_email: billingEmail,
    city,
    zip_code,
    created_by,
    order_confirmation_email,
    test_account,
    pub_agreement,
    pub_date,
    integration_api_enabled,
  } = customerData || {};

  // JSON VALUES FOR RENDERING ALL DROPDOWN AND MULTISELECT
  const consentDropDownOptions = [
    {
      label: messages.consent_mandatory,
      value: CONSENT_MANDATORY,
      message: messages.consent_mandatory_message,
    },
    {
      label: messages.consent_optional,
      value: CONSENT_OPTIONAL,
      message: messages.consent_optional_message,
    },
    {
      label: messages.consent_not_needed,
      value: NO_CONSENT,
      message: messages.consent_not_needed_message,
    },
  ];
  const customerStatus = [
    {
      label: messages.stopped_label,
      value: "stopped",
    },
    {
      label: messages.active,
      value: "active",
    },
    {
      label: messages.terminated,
      value: "terminated",
    },
    {
      label: messages.label_read,
      value: "read",
    },
  ];

  const confirmationEmail = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const apiIntegration = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const testAccount = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const PUBAgreement = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const company = [
    {
      key: "company_name",
      translationKey: "label_company_name",
      value: companyDetails?.company_name,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: true,
    },
    {
      key: "registration_nr",
      translationKey: "label_organisation_number",
      value: companyDetails?.registration_nr,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: true,
    },
    {
      key: "address",
      translationKey: "label_company_address",
      value: companyDetails?.company_address,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: true,
    },
    {
      key: "zip_code",
      translationKey: "label_postal_code",
      value: companyDetails?.zip_code,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: true,
    },
    {
      key: "city",
      translationKey: "label_city",
      value: companyDetails?.city,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: true,
    },
    {
      key: "account_manager_id",
      translationKey: "account_manager",
      value: accountManager?.name,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: false,
      values: { user },
      options: users,
      onChange: { setSelectedManagers },
      optionFields: users,
      optionLabel: "label",
      maxSelectedLabels: 3,
      className: "w-full md:w-20rem",
      editable: true,
      label: { user },
    },
    {
      key: "created_by",
      translationKey: "label_created_by",
      value: companyDetails?.created_by,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: false,
    },
    {
      key: "created_at",
      translationKey: "created_at",
      value: customerData.createdAt,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: false,
    },
  ];

  const contact = [
    {
      key: "firstname",
      translationKey: "label_name",
      value: companyDetails?.firstname,
      type: FORM_INPUT_TYPE.TEXT,
      required: false,
      editable: true,
    },
    {
      key: "lastname",
      translationKey: "label_last_name",
      value: companyDetails?.lastname,
      type: FORM_INPUT_TYPE.TEXT,
      editable: true,
    },
    {
      key: "email",
      translationKey: "label_email",
      value: companyDetails?.email,
      type: FORM_INPUT_TYPE.TEXT,
      editable: true,
    },
    {
      key: "phone",
      translationKey: "label_phone",
      value: companyDetails?.phone,
      type: FORM_INPUT_TYPE.TEXT,
      required: true,
      editable: true,
    },
  ];

  const settings = [
    {
      key: "consent",
      translationKey: "consent_title",
      value: customerConsent,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      mt: 3,
      name: "consentValue",
      options: consentDropDownOptions,
      width: [1, 1, 450, 450],
      editable: true,
    },
    {
      key: "status",
      translationKey: "consent_label_status",
      value: userInfo?.status,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      name: "status",
      options: customerStatus,
      editable: true,
    },
    {
      key: "order_confirmation_email",
      translationKey: "order_email_notification",
      value: companyDetails?.order_confirmation_email,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      name: "order_confirmation",
      options: confirmationEmail,
      editable: true,
    },
    {
      key: "integration_api_enabled",
      translationKey: "api_integration",
      value: companyDetails?.integration_api_enabled,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      name: "api_integration",
      options: apiIntegration,
      editable: true,
    },
    {
      key: "test_account",
      translationKey: "test_account",
      value: companyDetails?.test_account,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      name: "testaccount",
      options: testAccount,
      editable: true,
    },
  ];

  const pub = [
    {
      key: "pub_agreement",
      translationKey: "pub_agreement",
      value: companyDetails?.pub_agreement,
      type: FORM_INPUT_TYPE.DROPDOWN,
      required: true,
      name: "PUBAgreement",
      options: PUBAgreement,
      editable: true,
    },
    {
      key: "pub_date",
      translationKey: "pub_date",
      value: companyDetails?.pub_date,
      type: FORM_INPUT_TYPE.CALENDAR,
      editable: true,
    },
  ];

  useEffect(() => {
    setCompanyDetails({
      company_name,
      company_address: address,
      registration_nr: addHyphenToNumber(registration_nr, "6"),
      phone: phone,
      firstname,
      lastname,
      email,
      invoice: invoice === 1,
      billing_email: billingEmail,
      city,
      zip_code,
      created_by,
      order_confirmation_email,
      test_account,
      pub_agreement,
      pub_date,
      integration_api_enabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    test_account,
    pub_agreement,
    pub_date,
    company_name,
    address,
    registration_nr,
    phone,
    firstname,
    lastname,
    email,
    city,
    zip_code,
    order_confirmation_email,
    integration_api_enabled,
  ]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await Axios.get(`/${USERS}`, {
          params: {
            ["filter[customer.id]"]: id,
          },
        });

        const { data: { data: users = [] } = {} } = response;
        const userDropDownOptions = users.map(({ name = "", id = "" }) => ({
          label: `${name} - #${id}`,
          value: id,
        }));

        setUsers(userDropDownOptions);
      } catch (error) {
        setIsError(true);
      }
    }

    fetchUsers();
  }, [id]);

  useEffect(() => {
    async function fetchSingleUser() {
      try {
        const response = await Axios.get(`/${USERS}`, {
          params: {
            ["filter[id]"]: userInfo.account_manager_id,
          },
        });
        const { data: { data: users = [] } = {} } = response;
        setAccountManager(users[0]);
        return true;
      } catch (error) {
        setIsError(true);
      }
    }
    fetchSingleUser();
  }, [userInfo.account_manager_id]);

  const handleSubmit = async (values) => {
    const {
      registration_nr: registerNumber,
      company_address,
      billing_email,
      invoice: isPaymentTypeInvoice,
      integration_api_enabled,
      city,
      zip_code,
      created_by,
      order_confirmation_email,
      test_account,
      pub_agreement,
      pub_date,
    } = values;

    const registrationNumber =
      registerNumber && registerNumber.replace(/-/g, "");
    const newCompanyInfo = { ...companyDetails, ...values };
    delete newCompanyInfo.invoice;

    try {
      await editCustomerInfoService({
        ...newCompanyInfo,
        registration_nr: registrationNumber,
        address: company_address,
        billing_email,
        city,
        id,
        zip_code,
        created_by,
        order_confirmation_email,
        invoice: isPaymentTypeInvoice ? 1 : 0,
        integration_api_enabled,
        test_account,
        pub_agreement,
        pub_date,
      });
      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          showConfirmDialog: true,
        }),
      );

      updateCustomerData({
        ...newCompanyInfo,
        registration_nr: registrationNumber,
        address: company_address,
        integration_api_enabled: integration_api_enabled,
      });
      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          ...newCompanyInfo,
          showConfirmDialog: true,
        }),
      );
    } catch (e) {
      setIsError(true);
      dispatch(
        authActions.storeUserInfo({
          ...userInfo,
          ...newCompanyInfo,
          showConfirmDialog: true,
          isError: true,
        }),
      );
    }
  };
  return (
    <Div>
      {showDialog && (
        <ConfirmationDialog
          isError={isError}
          byline={
            isError ? messages.invalid_data : messages.text_update_sucessful
          }
        />
      )}

      <Div display="flex" flexDirection="column" width={[1, 1, 1, 1140]} pb={1}>
        <EditableFieldsCard
          title={messages.label_details}
          config={company}
          onSubmit={handleSubmit}
          validationSchema={CompanyInfoSchema}
          isEditPermission="true"
          values={user}
          options={users}
          onChange={setSelectedManagers}
          optionFields={users}
          optionLabel={users}
          maxSelectedLabels={3}
          className="w-full md:w-20rem"
        />
        <EditableFieldsCard
          title={messages.title_contact_person}
          config={contact}
          onSubmit={handleSubmit}
          validationSchema={CompanyInfoSchema}
          isEditPermission="true"
        />
        <EditableFieldsCard
          title={messages.Settings}
          config={settings}
          onSubmit={handleSubmit}
          validationSchema={CompanyInfoSchema}
          isEditPermission="true"
        />
        <EditableFieldsCard
          title="PUB"
          config={pub}
          onSubmit={handleSubmit}
          validationSchema={CompanyInfoSchema}
          isEditPermission="true"
        />
      </Div>
    </Div>
  );
};
CompanyInfo.propTypes = {
  customerData: PropTypes.node,
  id: PropTypes.number,
  updateCustomerData: PropTypes.func,
};
export default CompanyInfo;
