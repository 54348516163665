import React from "react";
import PropTypes from "prop-types";

import Div from "@components/Div";
import FormListItem from "./components/FormListItem";

const FormsTab = ({ formsData, onUpdateService }) => {
  const formsConfig = Object.entries(formsData).map(([key, value]) => {
    return {
      type: key,
      data: value,
      onUpdate: (formData) => {
        onUpdateService({ [key]: formData });
      },
    };
  });

  return (
    <Div
      with={[1, 1, 1, "80%"]}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      {formsConfig.map((configItem) => (
        <FormListItem key={configItem.type} {...configItem} />
      ))}
    </Div>
  );
};

FormsTab.propTypes = {
  formsData: PropTypes.object,
  onUpdateService: PropTypes.func,
};

export default FormsTab;
