import ordersGuestService from "@app/services/orders/ordersGuestService";
import ordersAdminService from "@app/services/orders/ordersAdminService";
import { GUEST_USER, CUSTOMER_USER, ADMIN } from "@utils/constant";
import { getUserType } from "@utils/utils";

export const fetchResults = ({
  payload,
  props,
  isPrepaidAccount,
  discount,
  isPaymentMethodInPending,
  isCustomerHasInvoice,
  hasActiveSubscription = false,
}) => {
  const UserType = getUserType(
    isPrepaidAccount,
    discount === 100,
    isPaymentMethodInPending,
    isCustomerHasInvoice,
    hasActiveSubscription,
  );
  const { type, payment_method } = UserType;

  if (type === ADMIN) {
    const finalPayload = {
      ...props,
      payment_method,
      order_items: [...payload],
    };
    return ordersAdminService(finalPayload);
  }
};

export const CreateOrder = ({
  payload,
  props,
  paymentMethod,
  type,
  promoCode,
  billingAccountId,
}) => {
  let finalPayload = {
    payment_method: paymentMethod,
    order_items: [...payload],
    promo_code: promoCode,
    billing_account_id: billingAccountId,
  };

  if (type === GUEST_USER) {
    const { email = "" } = props;
    finalPayload.email = email;
    return ordersGuestService(finalPayload);
  }

  if (type === CUSTOMER_USER) {
    finalPayload.consent = props.consent ? 1 : 0;
    return ordersAdminService(finalPayload);
  }
};

export default CreateOrder;
