import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PrimaryButtonIcon, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import InputText from "@components/InputText";
import { LinkArrow } from "@components/Link";
import { Text } from "@components/Text";
import { Form, Formik } from "formik";
import updateWatchlist from "@app/services/watchlist/updateWatchlist";
import ErrorModal from "@pages/user/OrderResults/ErrorModal";
import { handleException } from "@utils/common";

const StyledText = styled(Text)`
  text-transform: uppercase;
  font-weight: var(--semibold-weight);
`;

const EditWatchListModal = ({
  onCloseModal,
  watchList,
  handleDeleteWatchList,
}) => {
  const { messages } = useIntl();
  const history = useHistory();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (values) => {
    try {
      const updatedWatchList = {
        ...watchList,
        name: values.watchlistName,
        notification_emails: watchList.notification_emails,
      };
      await updateWatchlist(watchList.id, updatedWatchList);
      onCloseModal();
      history.go(0);
    } catch (error) {
      handleException(
        setShowErrorModal,
        setErrorMessage,
        messages.exception_error_message,
        error,
      );
    }
  };

  const handleClosePopup = () => {
    setShowErrorModal(false);
    onCloseModal();
  };

  return (
    <Div
      py={0}
      display="flex"
      alignItems="center"
      flexDirection="column"
      maxWidth="450px"
    >
      <Div display="flex" alignItems="center" flexDirection="column" mt={2}>
        <H3>{messages.watchlist_title_edit_watchlist}</H3>
      </Div>
      <Formik
        initialValues={{
          watchlistName: watchList.name,
        }}
        onSubmit={handleSubmit}
      >
        {(formikProp) => {
          const { values, handleSubmit } = formikProp;
          return (
            <Form>
              <InputText
                curved
                value={values.watchlistName}
                name={"watchlistName"}
                formikProps={formikProp}
                label={messages.watchlist_label_edit_watchlist_modal}
                labelAlignment="left"
                width={1}
              />
              <Div display="grid" mt={4}>
                <StyledText>
                  {messages.watchlist_title_delete_watchlist}
                </StyledText>
                <Text mt={3}>
                  {messages.watchlist_label_delete_watchlist_confirm}
                </Text>
              </Div>
              <Div mt={3}>
                <LinkArrow
                  direction="left"
                  label={`${messages.watchlist_title_delete_watchlist} ${watchList?.name}`}
                  onClick={handleDeleteWatchList}
                >
                  <Icon ml={1} name="headerarrowright" />
                </LinkArrow>
              </Div>
              <Div
                mt={4}
                display="flex"
                flexDirection={["column-reverse", "row"]}
                justifyContent="center"
              >
                <PrimaryButtonOutlined
                  rounded
                  semibold
                  label={messages.watchlist_label_cancel}
                  onClick={onCloseModal}
                  width={[1, "40%"]}
                  mr={[0, 4]}
                />
                <PrimaryButtonIcon
                  rounded
                  semibold
                  width={[1, "38%"]}
                  mb={[3, 0]}
                  onClick={handleSubmit}
                  label={messages.watchlist_button_save}
                  disabled={
                    values.watchlistName.length < 3 ||
                    values.watchlistName === watchList.name
                  }
                />
              </Div>
            </Form>
          );
        }}
      </Formik>
      {showErrorModal && (
        <ErrorModal
          handleConfirmPopup={handleClosePopup}
          content={errorMessage}
        />
      )}
    </Div>
  );
};

EditWatchListModal.propTypes = {
  onCloseModal: PropTypes.func,
  watchList: PropTypes.object,
  handleDeleteWatchList: PropTypes.func,
};

export default EditWatchListModal;
