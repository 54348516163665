import { useSelector } from "react-redux";

import { PERMISSION_TYPE } from "@utils/enum";

const usePermissions = () => {
  const { permissions = {} } = useSelector(
    (state) => state.authReducer.userInfo,
  );

  const checkForReadPermission = (permissionKey) => {
    const permissionValue = permissions[permissionKey];

    return permissionValue === PERMISSION_TYPE.READ;
  };

  const checkForWritePermission = (permissionKey) => {
    const permissionValue = permissions[permissionKey];

    return permissionValue === PERMISSION_TYPE.WRITE;
  };

  const checkForFullPermission = (permissionKey) => {
    const isReadPermission = checkForReadPermission(permissionKey);
    const isWritePermission = checkForWritePermission(permissionKey);

    return isReadPermission && isWritePermission;
  };

  return {
    checkForReadPermission,
    checkForWritePermission,
    checkForFullPermission,
  };
};

export default usePermissions;
