import { DISCOUNT_TYPE, APPLICATION_TYPE } from "@utils/enum";

export const INITIAL_COUPON_DATA = {
  code: "",
  discount_type: DISCOUNT_TYPE.PERCENTAGE,
  discount_value: "",
  valid_from: "",
  valid_to: "",
  max_allowed_usages: null,
  application_type: APPLICATION_TYPE.ENTIRE_ORDER,
  products: null,
};
