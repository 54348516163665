import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion as PRAccordion,
  AccordionTab as PRAccordionTab,
} from "primereact/accordion";
import styled from "styled-components";
import { compose, space, layout, flexbox, position } from "styled-system";
import Dialog from "@components/Dialog";
import Link from "@components/Link";
import Icon from "@components/Icon";
import OrderReportModal from "@pages/user/OrderResults/OrderReportModal";
import { useIntl } from "react-intl";
import { isAuthenticated } from "@utils/utils";

const StyledAccordion = styled(PRAccordion)`
  ${compose(space, flexbox, layout, position)};
  .p-accordion-tab {
    margin-bottom: 16px;
    margin-right: 64px;

    .p-accordion-header {
      position: relative;
      background-color: var(--turquoise-light);

      .p-accordion-header-link {
        padding: 16px;

        .p-accordion-header-template {
          .p-accordion-value-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -50px;

            .icon-tick-mark,
            .icon-error,
            .icon-warning {
              margin: 0px;
            }
          }
          .p-accordion-header-text {
            color: var(--grey-dark);
            font-weight: var(--semibold-weight);
            font-size: var(--fs-text);
          }
        }
      }
    }

    .p-accordion-content {
      padding: 16px;
    }

    .p-accordion-toggle-icon {
      color: var(--grey-dark);
      font-size: var(--fs-icon-xs);
    }
  }
`;

const Accordion = ({
  content = [],
  activeIndex = [],
  onTabChange: handleTabChange,
  reportId,
  creditReport,
  showCreditReport = true,
}) => {
  const [confirmPopup, setConfirmPopup] = useState(false);
  const { messages } = useIntl();
  const handleConfirmPopup = () => {
    setConfirmPopup(!confirmPopup);
  };

  const renderCreditReport = () => (
    <>
      {showCreditReport && isAuthenticated() && (
        <>
          {confirmPopup && (
            <Dialog
              visible="displayBasic"
              draggable={false}
              width={[1, 445, 445, 445]}
              onHide={handleConfirmPopup}
              m={[3, 3, 3, "auto"]}
            >
              <OrderReportModal
                reportID={reportId}
                handleConfirmPopup={handleConfirmPopup}
              />
            </Dialog>
          )}
          {!creditReport ? (
            <Link
              mt={2}
              display="flex"
              alignItems="center"
              handleClick={handleConfirmPopup}
            >
              <Icon name="file-download" mr={2} fontSize="var(--fs-icon-m)" />
              {messages.order_report}
            </Link>
          ) : (
            <>
              <Link
                mt={2}
                display="flex"
                alignItems="center"
                handleClick={handleConfirmPopup}
              >
                <Icon name="email" mr={2} fontSize="var(--fs-icon-m)" />
                {messages.label_send_mail_again}
              </Link>
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <StyledAccordion
      multiple
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
    >
      {content.map((item) => (
        <PRAccordionTab key={item?.title} headerTemplate={item?.headerTemplate}>
          {item?.content}
          {item?.title === "label_economy" && <>{renderCreditReport()}</>}
        </PRAccordionTab>
      ))}
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.objectOf({
      title: PropTypes.string,
      headerTemplate: PropTypes.string,
    }),
  ).isRequired,
  activeIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
  onTabChange: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  creditReport: PropTypes.bool.isRequired,
  showCreditReport: PropTypes.bool,
};

export default Accordion;
