import React from "react";
import Div from "@components/Div";
import PropTypes from "prop-types";
import Skeleton from "@components/Skeleton";
import { useIntl } from "react-intl";
import Container from "@components/Container";
import ScrollToTop from "@utils/ScrollToTop";
import Card from "@components/Card";
import styled from "styled-components";
import Divider from "@components/Divider";
import { H5 } from "@components/Heading";
import { Text } from "@components/Text";
import { TextLargeSemiBoldWeight } from "../../../components/Text";
import { addHyphenToNumber } from "@utils/utils";
import { ASSIGNMENT, BUNDLE } from "@utils/constant";
import { BundleIcon } from "@components/Icon";

const StyledContainer = styled(Container)`
  position: relative;
  background: var(--blue-lightest);
  box-shadow: none;
`;
const StyledCard = styled(Card)`
  border-radius: 20px;
`;
const ResultsCardLoader = (props) => {
  const { messages } = useIntl();
  const { orderItems = [] } = props;
  const groupedData = orderItems.reduce((result, item) => {
    const category = item.personal_number;
    if (!result[category]) {
      result[category] = {
        items: [],
        person_name: item.person_name,
        personal_number: item.personal_number,
        id: item.id,
      };
    }
    result[category].items.push(item);
    return result;
  }, {});

  return (
    <ScrollToTop>
      {Object.keys(groupedData).map((category) => {
        const { items, person_name, personal_number } = groupedData[category];
        return (
          <Div key={category}>
            <StyledCard mb={5} p={0} maxWidth="1110px">
              <Div>
                <StyledContainer
                  color="var(--blue-lightest)"
                  width={1}
                  py={4}
                  px={[3, 4]}
                  maxWidth="1110px"
                >
                  <Div
                    display="flex"
                    alignItems={["left", "center"]}
                    justifyContent="space-between"
                    flexDirection={["column", "row"]}
                  >
                    <Div
                      pt={[1, 0]}
                      mt={[3, 0]}
                      display="flex"
                      flexDirection={["row", "column"]}
                      justifyContent="space-between"
                      alignItems={"flex-start"}
                    >
                      <Text>{messages.label_name}</Text>
                      <TextLargeSemiBoldWeight mt={2}>
                        {person_name}
                      </TextLargeSemiBoldWeight>
                    </Div>{" "}
                    <Div
                      pt={[1, 0]}
                      mt={[3, 0]}
                      display="flex"
                      flexDirection={["row", "column"]}
                      justifyContent="space-between"
                      alignItems={"flex-end"}
                    >
                      <Text>{messages.label_personal_number}</Text>
                      <TextLargeSemiBoldWeight mt={2}>
                        {addHyphenToNumber(personal_number.toString(), 8)}
                      </TextLargeSemiBoldWeight>
                    </Div>
                  </Div>
                </StyledContainer>
                <Container
                  mr={[0, 0, 0, 4]}
                  width={1}
                  pt={(0, 3)}
                  px={[3, 4]}
                  minWidth={["0px", "610px"]}
                  maxWidth="1110px"
                >
                  <Div
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Div minWidth={["0px", "575px"]}>
                      <H5>{messages.object_services_title_services}</H5>
                    </Div>
                    <Div textAlign={"right"} minWidth={["0px", "100px"]}></Div>
                  </Div>
                </Container>
              </Div>
              {items.map((item) => (
                <>
                  {item.service_type === ASSIGNMENT ? (
                    (Number(item.price) > 0 || item.credits > 0) && (
                      <Div key={item.id}>
                        <Container
                          mr={[0, 0, 0, 4]}
                          width={1}
                          pt={3}
                          pb={4}
                          px={[3, 4]}
                          color={"var(--grey-dark)"}
                          maxWidth="1110px"
                        >
                          <Div display="flex" flexDirection={["column", "row"]}>
                            <Div display={"flex"} alignItems="center">
                              <TextLargeSemiBoldWeight>
                                {item.name}
                                {item.service_type === BUNDLE && (
                                  <BundleIcon
                                    ml={2}
                                    name="bundle-tag"
                                  ></BundleIcon>
                                )}
                              </TextLargeSemiBoldWeight>
                            </Div>
                          </Div>
                          <Skeleton mt={3} maxWidth="515px" height="35px" />
                          <Skeleton mt={3} maxWidth="140px" height="20px" />
                          <Div
                            mt={3}
                            display={["flex", "none"]}
                            justifyContent="space-between"
                          ></Div>
                        </Container>
                        <Divider m={"0px !important"}></Divider>
                      </Div>
                    )
                  ) : (
                    <Div key={item.id}>
                      <Container
                        mr={[0, 0, 0, 4]}
                        width={1}
                        pt={3}
                        pb={4}
                        px={[3, 4]}
                        color={"var(--grey-dark)"}
                        maxWidth="1110px"
                      >
                        <Div display="flex" flexDirection={["column", "row"]}>
                          <Div display={"flex"} alignItems="center">
                            <TextLargeSemiBoldWeight>
                              {item.name}
                              {item.service_type === BUNDLE && (
                                <BundleIcon
                                  ml={2}
                                  name="bundle-tag"
                                ></BundleIcon>
                              )}
                            </TextLargeSemiBoldWeight>
                          </Div>
                        </Div>
                        <Skeleton mt={3} maxWidth="515px" height="35px" />
                        <Skeleton mt={3} maxWidth="140px" height="20px" />
                        <Div
                          mt={3}
                          display={["flex", "none"]}
                          justifyContent="space-between"
                        ></Div>
                      </Container>
                      <Divider m={"0px !important"}></Divider>
                    </Div>
                  )}
                </>
              ))}
            </StyledCard>
          </Div>
        );
      })}
    </ScrollToTop>
  );
};

ResultsCardLoader.propTypes = {
  orderItems: PropTypes.object,
};
export default ResultsCardLoader;
