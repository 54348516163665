import { useState } from "react";
import couponCodeValidationService from "@app/services/orders/couponCodeValidationService";
import { calculateVat, calculatePercentage } from "@utils/utils";

export const useCouponCodeValidation = () => {
  const [couponCodeDetails, setCouponCodeDetails] = useState(null);
  const [couponCodeValidationError, setCouponCodeValidationError] =
    useState(null);

  const validateCouponCode = async (
    orderSummaryProps,
    couponCode,
    orderTotalPriceWithoutVat,
    paymentMethod,
  ) => {
    try {
      setCouponCodeValidationError(null);

      const orderItems = orderSummaryProps.flatMap((person) =>
        person.services.map((service) => ({
          service_id: service.id,
          personal_number: person.ssn,
          person_name: person.name,
          phone: person.phone,
          email: person.email,
        })),
      );

      const body = {
        promo_code: couponCode,
        payment_method: paymentMethod,
        order_items: orderItems,
      };
      const response = await couponCodeValidationService(body);
      if (response.status !== 200) {
        setCouponCodeValidationError(
          response.data.message_term ?? "code_not_valid",
        );

        return;
      }

      const {
        data: { order_draft: orderDraft },
      } = response;

      const orderTotalVat = calculateVat(orderDraft.order_total_with_discount);
      const rawSummaryPropsServices = Object.values(orderDraft.orderItems);

      let rawSummaryPropsServiceIndex = 0;
      const orderTotalDetails = {
        promoCode: couponCode,
        orderTotalVatPrice: orderTotalVat,
        orderTotalPriceWithoutVat: orderDraft.order_total_with_discount,
        orderTotalPriceWithVat:
          orderDraft.order_total_with_discount + orderTotalVat,
        orderTotalDiscount: orderDraft.order_discount,
        orderTotalDiscountPercentage: calculatePercentage(
          orderDraft.order_discount,
          orderTotalPriceWithoutVat,
        ),
        orderSummaryProps: orderSummaryProps.map((item) => ({
          ...item,
          services: item.services.map((service) => {
            const updatedService =
              rawSummaryPropsServices[rawSummaryPropsServiceIndex];

            rawSummaryPropsServiceIndex++;

            return {
              ...service,
              priceWithDiscount: updatedService?.total_with_discount,
              discount: updatedService?.discount,
            };
          }),
        })),
      };

      setCouponCodeDetails(orderTotalDetails);
    } catch (error) {
      setCouponCodeValidationError("code_not_valid");
    }
  };

  return {
    couponCodeDetails,
    couponCodeValidationError,
    validateCouponCode,
  };
};
