import styled from "styled-components";
import { Card as PRCard } from "primereact/card";
import {
  compose,
  space,
  layout,
  flexbox,
  color,
  typography,
} from "styled-system";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";

const mobileBreakPoint = Config.mobileBreakPoint;

const CardV2 = styled(PRCard)`
  ${compose(layout, space, flexbox, color, typography)};
  border: 1px solid var(--grey-lightest);
  border-radius: 10px;
  .p-card-header {
    position: relative;
    padding: 30px;
    background-color: var(--blue-lightest);
    min-height: 120px;
  }
  .p-card-body {
    height: auto;
    padding: 0px;
    .p-card-content {
      padding: 30px;
      height: auto;
    }
  }
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    .p-card-header {
      position: relative;
      padding: 20px;
      .p-button.p-button-icon-only {
        width: auto !important;
      }
    }
    .p-card-body {
      padding: 20px;
      .p-card-content {
        padding: 0px;
      }
    }
  }
`;

export default CardV2;
