import Axios from "@app/api/axios";
import { ROLES } from "@utils/constant";

const getRolesService = (payload = "") =>
  Axios.get(`${ROLES}?type=${payload}`).then((response) => {
    const { data: { data: roles = [] } = {} } = response;
    const res = roles.map((obj) => {
      const { name: roleName = "", permissions = [], id } = obj;
      const rights = {};
      permissions.map((permission) => {
        const { name = "" } = permission;
        if (name.includes("write")) {
          rights[name.split("write-")[1]] = "write";
        }
        if (name.includes("read")) {
          rights[name.split("read-")[1]] = "read";
        }
      });
      return { value: roleName, permissions: rights, id };
    });
    return res;
  });

export default getRolesService;
