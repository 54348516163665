import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import getPromoCodesList from "@app/services/promoCodes/getPromoCodesList";
import patchPromoCode from "@app/services/promoCodes/patchPromoCode";

import BreadCrumb from "@components/Breadcrumb";
import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import ErrorDialog from "@components/ErrorDialog";
import { H3, H4 } from "@components/Heading";
import Icon from "@components/Icon";
import ProgressSpinner from "@components/ProgressSpinner";
import SuccessDialog from "@components/SuccessDialog";
import { Text, TextMediumWeight } from "@components/Text";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES, WRITE } from "@utils/constant";
import { COUPON_STATUS } from "@utils/enum";
import { enumValueToTranslationKey, formatDate } from "@utils/utils";

import UpdateCouponForm from "./Form";
import ValidationSchema from "./UpdateSchema";
import { INITIAL_COUPON_DATA } from "./types";

const BREADCRUMB_ITEMS = [
  {
    label: <FormattedMessage id="title_coupons" />,
    url: ROUTES.ADMIN_COUPONS_LIST.URL,
  },
  {
    label: <FormattedMessage id="title_edit_coupon" />,
  },
];

const UpdateCoupon = () => {
  const { messages } = useIntl();
  const { coupon_id: couponId } = useParams();
  const { permissions = {} } = useSelector(
    (state) => state.authReducer.userInfo,
  );

  const [couponDetails, setCouponDetails] = useState(INITIAL_COUPON_DATA);
  const [isUpdateDialogVisible, setIsUpdateDialogVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isEditPermission = permissions["products"] === WRITE;

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        setIsLoading(true);

        const {
          data: [allCouponDetails],
        } = await getPromoCodesList(`?filter[id]=${couponId}`);

        setCouponDetails(allCouponDetails);
      } catch (error) {
        setErrorMessage(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCouponDetails();
  }, [couponId, messages.exception_error_message]);

  const config = {
    couponConfiguration: [
      {
        label: messages.label_name,
        value: couponDetails?.code,
      },
      {
        label: messages.label_status,
        value: couponDetails?.active
          ? messages.active
          : messages.label_inactive,
      },
      {
        label: messages.label_discount_type,
        value:
          messages[
            `label_${enumValueToTranslationKey(couponDetails?.discount_type)}`
          ],
      },
      {
        label: messages.label_discount_value,
        value: couponDetails?.discount_value,
      },
      {
        label: messages.label_valid_from,
        value: formatDate(couponDetails?.valid_from),
      },
      {
        label: messages.label_expire_date,
        value: formatDate(couponDetails?.valid_to),
      },
      {
        label: messages.label_number_of_uses,
        value: couponDetails?.max_allowed_usages,
      },
      {
        label: messages.type_label,
        value:
          messages[
            `label_${enumValueToTranslationKey(
              couponDetails?.application_type,
            )}`
          ],
      },
    ],
    registration: [
      {
        label: messages.created_at,
        value: formatDate(couponDetails?.created_at),
      },
      {
        label: messages.label_created_by,
        value: couponDetails?.created_by,
      },
    ],
  };

  const handleCloseSuccessDialog = () => {
    setSuccessMessage("");
  };

  const handleCloseErrorModal = () => {
    setErrorMessage("");
  };

  const handleShowUpdateForm = () => {
    setIsUpdateDialogVisible(true);
  };

  const handleCloseUpdateForm = () => {
    setIsUpdateDialogVisible(false);
  };

  const updateCoupon = async (payload) => {
    try {
      setIsLoading(true);

      const { data: updatedCoupon } = await patchPromoCode(couponId, payload);

      setCouponDetails(updatedCoupon);

      handleCloseUpdateForm();

      setSuccessMessage(messages.message_coupon_updated);
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCoupon = (values) => {
    const payload = {
      ...values,
      active: Number(values.status === COUPON_STATUS.ACTIVE),
    };

    delete payload.status;

    updateCoupon(payload);
  };

  const headerConfig = {
    title: messages.title_edit_coupon,
  };

  return (
    <AdminContainer config={headerConfig}>
      <Div width={[1, 1, "66%", "70%"]}>
        <BreadCrumb p={0} mb={4} items={BREADCRUMB_ITEMS} />

        {isLoading && <ProgressSpinner />}

        <Card my={3} p={3} borderRadius="20px">
          <Div
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <H4>{messages.title_coupon_configuration}</H4>

            {isEditPermission && (
              <ButtonLinkIcon
                label={
                  <TextMediumWeight>{messages.label_edit}</TextMediumWeight>
                }
                onClick={handleShowUpdateForm}
                icon={
                  <Icon
                    name="pen"
                    mr={2}
                    color="var(--turquoise)"
                    fontSize="var(--fs-icon-s)"
                  />
                }
              />
            )}
          </Div>

          <Div
            mt={2}
            width={1}
            height="1px"
            backgroundColor="var(--grey-lightest)"
          />

          <Div
            mt={3}
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
            gridGap={3}
          >
            {config.couponConfiguration.map(({ label, value }) => (
              <Div flex="1 1 40%" key={label}>
                <TextMediumWeight>
                  {`${label}: `}
                  <Text>{value}</Text>
                </TextMediumWeight>
              </Div>
            ))}
          </Div>
        </Card>

        <Card my={3} p={3} borderRadius="20px">
          <H4>{messages.title_registration}</H4>

          <Div
            mt={2}
            width={1}
            height="1px"
            backgroundColor="var(--grey-lightest)"
          />

          <Div
            mt={3}
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
            gridGap={3}
          >
            {config.registration.map(({ label, value }) => (
              <Div flex="1 1 40%" key={label}>
                <TextMediumWeight>
                  {`${label}: `}
                  <Text>{value}</Text>
                </TextMediumWeight>
              </Div>
            ))}
          </Div>
        </Card>

        {isUpdateDialogVisible && (
          <Dialog
            visible="displayBasic"
            draggable={false}
            onHide={handleCloseUpdateForm}
            header={
              <H3 textAlign="center">{messages.heading_basic_information}</H3>
            }
            width={[1, 1, "463px", "463px"]}
          >
            <UpdateCouponForm
              isUpdateForm={true}
              initialData={couponDetails}
              validationSchema={ValidationSchema}
              onSubmit={handleUpdateCoupon}
              onCancel={handleCloseUpdateForm}
            />
          </Dialog>
        )}

        {successMessage && (
          <SuccessDialog
            message={successMessage}
            onConfirm={handleCloseSuccessDialog}
          />
        )}

        {errorMessage && (
          <ErrorDialog
            errorMessage={errorMessage}
            onConfirm={handleCloseErrorModal}
            onHide={handleCloseErrorModal}
          />
        )}
      </Div>
    </AdminContainer>
  );
};

export default UpdateCoupon;
